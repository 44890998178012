import { type FC } from 'react';

import styles from './Loading.module.css';

const Dots: FC = () => (
  <div className={styles.dotContainer}>
    <div className={styles.dot} style={{ animationDelay: '0s', opacity: '1' }} />
    <div className={styles.dot} style={{ animationDelay: '0.33s', opacity: '0.5' }} />
    <div className={styles.dot} style={{ animationDelay: '0.66s', opacity: '0.25' }} />
  </div>
);

export default Dots;
