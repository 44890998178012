import { createSlice, createEntityAdapter, createSelector } from '@reduxjs/toolkit';

export type QueryParamInput = { key: string; value: any; options?: any };

// State with default values
// In this case we are using the entity adapter
// which normalizes the state by key and entities
const queryParamsAdapter = createEntityAdapter<QueryParamInput>({ selectId: (queryParam) => queryParam.key });
const initialState = queryParamsAdapter.getInitialState();

export const queryParamsSlice = createSlice({
  name: 'queryParams',
  initialState, // set our initial state with default values
  reducers: {
    setQueryParams: queryParamsAdapter.setMany,
  },
});

// Actions
export const { setQueryParams } = queryParamsSlice.actions;

// Selectors
export const { selectById: privateSelectById } = queryParamsAdapter.getSelectors<any>((state) => state.queryParams);

// Finds our queryParam and returns the value
export const selectQueryParam = createSelector([privateSelectById], (queryParam) =>
  queryParam ? queryParam.value : undefined
);

// Reducer
export default queryParamsSlice.reducer;
