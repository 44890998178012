/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectFormContext, selectStepContext } from '@core/reducers/eventingSlice';
import { track } from '@core/services/cohesion';
import type { UseFieldEvents, FieldEventsBase } from '@core/ts/cohesion';
import deepMerge from '@core/utils/deepMerge';

const useFieldEvents = (baseProperties: FieldEventsBase = {}): UseFieldEvents => {
  const formContext = useSelector(selectFormContext);
  const stepContext = useSelector(selectStepContext);

  const buildFieldEventMethod =
    <T>(eventName: string) =>
    (overrideProperties: Partial<T>) => {
      const properties = deepMerge(
        {},
        {
          formContext,
          stepContext,
        },
        baseProperties,
        overrideProperties
      );

      // Changes formName and formType values into slug case format
      // For consistency in eventing

      const formatProperty = (str) => str?.replace(/[A-Z]/g, (m) => `-${m.toLowerCase()}`);

      properties.userInputField.fieldName = formatProperty(properties.userInputField.fieldName);

      properties.userInputField.fieldType = formatProperty(properties.userInputField.fieldType);

      track({ eventName, properties });
    };

  return {
    fieldSelected: buildFieldEventMethod<FieldEventsBase>('FieldSelected'),
    fieldInputted: buildFieldEventMethod<FieldEventsBase>('FieldInputted'),
    fieldViewed: buildFieldEventMethod<FieldEventsBase>('FieldViewed'),
  };
};

export default useFieldEvents;
