import cn from '@core/utils/classNames';

const TextWidget = (props) => {
  const { onChange, uiSchema } = props;
  const title = uiSchema?.['ui:title'];
  const id = uiSchema?.['ui:id'];
  const autocomplete = uiSchema?.['ui:autocomplete'];

  return (
    <input
      className={cn('form-control', 'prq')}
      id={id}
      placeholder={title}
      onBlur={(event) => onChange(event.target.value)}
      type="text"
      autoComplete={autocomplete ?? 'off'}
    />
  );
};

export default TextWidget;
