import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useElementEvents from '@core/hooks/cohesion/useElementEvents';
import { selectViewCorrelationIdMap } from '@core/reducers/eventingSlice';
import { setLeadDelivery } from '@core/reducers/matchesSlice';

import styles from './SubmissionsCard.module.css';
import type { TSubmissionsCard } from './types';

const SubmissionsCard: TSubmissionsCard = ({ data, position }) => {
  const dispatch = useDispatch();

  const viewCorrelationIdMap = useSelector(selectViewCorrelationIdMap);
  const viewCorrelationId = viewCorrelationIdMap[data.program.id];

  const { elementViewed, elementClicked } = useElementEvents({
    correlationId: viewCorrelationId,
    webElement: {
      position: String(position),
      elementType: 'exit-element',
      text: 'Next steps',
      location: 'results-page-card',
      htmlId: 'view-next-steps-cta',
      name: 'view-next-steps',
    },
  });

  const imageUrl = `https://simple-storage-server.highereducation.com/${data.school.slug}.png?w=130&h=80`;

  const showExitModal = () => {
    dispatch(
      setLeadDelivery({
        show: true,
        currentPrograms: [data],
        showExitStep: true,
      })
    );
    elementClicked();
  };

  useEffect(() => {
    elementViewed();
  }, []);

  return (
    <div className={styles.submissionsCard}>
      <header className={styles.cardHeader}>
        <div className={styles.cardLogo}>
          <img src={imageUrl} alt={data.school.name} />
        </div>
        <div className={styles.headerText}>
          <p>{data.program.name}</p>
          <h4>{data.school.name}</h4>
        </div>
      </header>
      <button onClick={showExitModal} type="button" className={styles.cta}>
        Next Steps
      </button>
    </div>
  );
};

export default SubmissionsCard;
