import { type FC } from 'react';

import { FieldContext } from '@core/ts/cohesion';

import CalloutField from '../components/Fields/CalloutField';
import CheckboxField from '../components/Fields/CheckboxField';
import Conditional from '../components/Fields/ConditionalField';
import { ConditionalType } from '../components/Fields/ConditionalField/types';
import OptionGroupField from '../components/Fields/OptionGroupField';
import PhoneField from '../components/Fields/PhoneField';
import RadioField from '../components/Fields/RadioField';
import SelectField from '../components/Fields/SelectField';
import TextField from '../components/Fields/TextField';
import ZipField from '../components/Fields/ZipField';
import { Field } from '../components/Fields/types';

const fieldTypeDictionary = {
  zip: ZipField,
  phone: PhoneField,
  text: TextField,
  radio: RadioField,
  select: SelectField,
  checkbox: CheckboxField,
  callout: CalloutField,
  conditional: Conditional,
  optionGroup: OptionGroupField,

  // affiliatedSchoolList: AffiliatedSchoolListField,
  // loading: LoadingField,
  // disciplineDescriptionPanel: DisciplineDescriptionPanelField,
};

export type FieldComponentProps<T extends Field | ConditionalType = Field> = {
  field: T;
  heading?: string;
  errors?: Error[];
  fieldNumber?: FieldContext['fieldNumber'];
  onContinue?: () => void;
};

export type FieldComponent = FC<FieldComponentProps>;

export type FieldType = keyof typeof fieldTypeDictionary;

export default fieldTypeDictionary as Record<FieldType, FieldComponent>;
