import { type FC } from 'react';
import { useSelector } from 'react-redux';

import { selectFacets } from '@core/reducers/configSlice';

import CustomRefinementList from '../CustomRefinementList';
import styles from './BootcampSidebar.module.css';

const BootcampSidebar: FC = () => {
  const facets = useSelector(selectFacets);
  return (
    <section className={styles.sidebar}>
      <div className={styles.category}>
        <CustomRefinementList
          attribute="program.subject.name"
          defaultRefinement={facets['program.subject.name']}
          showMore
          limit={undefined}
          showMoreLimit={undefined}
          refinementTitle="Course"
        />
      </div>
      <div className={styles.category}>
        <CustomRefinementList
          attribute="program.miscellaneous.timeCommitment"
          defaultRefinement={facets['program.miscellaneous.timeCommitment']}
          showMore
          limit={0}
          showMoreLimit={undefined}
          refinementTitle="Time Commitment"
        />
      </div>
      <div className={styles.category}>
        <CustomRefinementList
          attribute="program.miscellaneous.learningFormat"
          defaultRefinement={facets['program.miscellaneous.learningFormat']}
          showMore
          limit={0}
          showMoreLimit={undefined}
          refinementTitle="Learning Format"
        />
      </div>
      <div className={styles.category}>
        <CustomRefinementList
          attribute="program.miscellaneous.programLength"
          showMore
          limit={0}
          showMoreLimit={undefined}
          refinementTitle="Duration"
        />
      </div>
      <div className={styles.category}>
        <CustomRefinementList
          attribute="program.miscellaneous.price"
          showMore
          limit={0}
          showMoreLimit={undefined}
          refinementTitle="Price"
        />
      </div>
      <div className={styles.category}>
        <CustomRefinementList
          attribute="school.name"
          showMore
          limit={0}
          showMoreLimit={undefined}
          refinementTitle="School"
        />
      </div>
    </section>
  );
};

export default BootcampSidebar;
