import { EduRequestV2 } from '@core/ts/leadDelivery';
import { DeepPartial } from '@core/ts/types';

import deepMerge from '../deepMerge';

const defaultRequest: EduRequestV2 = {
  lead: {
    firstName: '',
    lastName: '',
    email: '',
  },
  trackingContext: {},
  messageContext: {
    timestamp: 0,
    guid: '',
  },
  schoolContext: {
    id: 0,
    slug: '',
  },
  programContext: {
    subject: {
      name: '',
    },
  },
};

export const generateEduRequestV2 = (eduRequest: DeepPartial<EduRequestV2>) => deepMerge(defaultRequest, eduRequest);
