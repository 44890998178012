import { useEffect, useMemo, useState, useRef, type ChangeEventHandler, type MouseEventHandler, type FC } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { useSelector, useDispatch } from 'react-redux';

import type { Option, FieldComponentProps } from '@core/blocks/edu-flow/components/Fields/types.d';
import useFieldEvents from '@core/hooks/cohesion/useFieldEvents';
import { selectFormCorrelationId } from '@core/reducers/eventingSlice';
import { selectInput, setInput } from '@core/reducers/inputsSlice';
import cn from '@core/utils/classNames';

import styles from './Select.module.css';

const SelectField: FC<FieldComponentProps> = ({ field, heading, fieldNumber }) => {
  // Action Dispatcher
  const dispatch = useDispatch();

  // Ref to Scroll to the year 2020 on the graduationYear Select
  const yearRef = useRef<HTMLButtonElement>(null);

  // Form Correlation Id for events
  const formCorrelationId = useSelector(selectFormCorrelationId);

  const { fieldSelected, fieldViewed } = useFieldEvents({
    correlationId: formCorrelationId,
    userInputField: {
      autofilled: false,
      fieldName: field.name,
      fieldLabel: heading,
      fieldType: field.type,
      fieldNumber,
    },
  });

  useEffect(() => {
    fieldViewed();
  }, []);

  // Inputs State properties
  const fieldInput = useSelector((state) => selectInput(state, field?.name as string));
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  // function that opens/closes dropDown options when dropDown is clicked
  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };
  useEffect(() => {
    if (isOptionsOpen && yearRef.current != null) {
      yearRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isOptionsOpen]);

  // function that runs whenever something is selected in the dropdown
  const handleSelection: ChangeEventHandler<HTMLSelectElement> = ({ target: option }) => {
    const { value } = option;

    dispatch(setInput({ key: field.name as string, value }));
    setIsOptionsOpen(false);

    fieldSelected({
      userInputField: {
        fieldValue: `${value}`,
      },
    });
  };

  // converts the field options into valid react-select options
  // useMemo to make sure that we only run this once unless the field.options change
  const selectOptions = useMemo(() => field?.options as Option[], [field?.options]);

  // Generate graduation year dropdown options
  let valueLabelArray: Option[];
  if (field.min && field.max) {
    valueLabelArray = Array.from(Array(field.max - field.min).keys())
      .reverse()
      .map((opt) => {
        const val = opt + Number(field.min) + 1;
        return {
          value: `${val}`,
          label: `${val}`,
        };
      });
  } else {
    valueLabelArray = selectOptions;
  }

  return (
    <>
      <div data-testid="select-field-select" className={styles.container}>
        <select className={styles.selectOverlay} onChange={handleSelection} defaultValue={undefined}>
          <option disabled>Select</option>
          {valueLabelArray?.map(({ label, value }) => {
            const props = {
              ref: field.name === 'graduationYear' && value === '2020' ? yearRef : undefined,
            };
            return (
              <option key={label} value={value}>
                {label}
              </option>
            );
          })}
        </select>
        <button
          data-testid="select-field-select-button"
          type="button"
          className={cn(styles.dropDownContainer, isOptionsOpen && styles.activeDropDownContainer)}
          id={`select-${field?.name}-input`}
          onClick={toggleOptions}
        >
          <div className={cn(styles.contentText, fieldInput && styles.fieldInputText)}>
            {fieldInput ?? field?.placeholder}
          </div>
          <div className={styles.iconContainer}>
            <BsChevronDown
              size="12px"
              className={cn(styles.chevronIcon, isOptionsOpen && styles.activeChevronIcon)}
              strokeWidth="2"
            />
          </div>
        </button>
      </div>

      <div className={styles.dropDownOptionsContainer}>
        <div
          data-testid="select-field-dropdown-wrapper"
          className={cn(styles.dropDownOptionsWrapper, isOptionsOpen && styles.expanded)}
        >
          {valueLabelArray?.map(({ label, value }, optionIndex) => {
            const props = {
              ref: field.name === 'graduationYear' && value === '2020' ? yearRef : undefined,
            };
            return (
              <button
                className={cn(styles.dropDownOption, fieldInput === value && styles.optionSelected)}
                onClick={handleSelection as unknown as MouseEventHandler<HTMLButtonElement>}
                value={value}
                key={label}
                type="button"
                id={`select-${field?.name}-option-${optionIndex}`}
                {...props}
              >
                {label}
              </button>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default SelectField;
