/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useFieldEvents from '@core/hooks/cohesion/useFieldEvents';
import { selectFormCorrelationId } from '@core/reducers/eventingSlice';
import { setInput } from '@core/reducers/inputsSlice';

import { FieldComponent } from '../../../utils/fieldTypeDictionary';
import styles from './CheckboxField.module.css';

const CheckboxField: FieldComponent = ({ field, fieldNumber }) => {
  const isPrechecked = Boolean(field.default);
  const [fieldInput, setFieldInput] = useState(isPrechecked);
  const dispatch = useDispatch();

  // Form Correlation Id for events
  const formCorrelationId = useSelector(selectFormCorrelationId);

  const userInputField = {
    autofilled: isPrechecked,
    fieldName: field.name,
    fieldLabel: field.label,
    fieldType: field.type,
    fieldValue: `${fieldInput}`,
    fieldNumber,
  };

  const { fieldSelected, fieldViewed } = useFieldEvents({
    correlationId: formCorrelationId,
    userInputField,
  });

  useEffect(() => {
    fieldViewed();
    dispatch(setInput({ key: field?.name as string, value: fieldInput }));
    if (isPrechecked) fieldSelected();
  }, []);

  const handleChange = () => {
    dispatch(setInput({ key: field?.name as string, value: !fieldInput }));
    setFieldInput(!fieldInput);
    fieldSelected({
      userInputField: {
        ...userInputField,
        fieldValue: `${!fieldInput}`,
      },
    });
  };

  return (
    <div className={styles.checkboxContainer}>
      <input
        type="checkbox"
        name={field.name}
        id={field.name}
        checked={fieldInput as boolean}
        onChange={handleChange}
        aria-label={field.label}
      />
      <label
        className={styles.label}
        htmlFor={field.name}
        dangerouslySetInnerHTML={{ __html: field?.label as string }}
      />
    </div>
  );
};

export default CheckboxField;
