import { createSlice, createEntityAdapter, createSelector } from '@reduxjs/toolkit';

import { RootState } from '@core/store';

export type VoyagerInput = { key: string; value: any; options?: any };
export type VoyagerInputs = Record<string, VoyagerInput>;

// State with default values
// In this case we are using the entity adapter
// which normalizes the state by key and entities
const inputsAdapter = createEntityAdapter<VoyagerInput>({ selectId: (input) => input.key });
const initialState = inputsAdapter.getInitialState();

export const inputsSlice = createSlice({
  name: 'inputs',
  initialState, // set our initial state with default values
  reducers: {
    setInput: inputsAdapter.setOne,
    setInputs: inputsAdapter.setMany,
    addInitialInputs: inputsAdapter.upsertMany,
    // For e2e tests
    clearInputs: (state) => {
      state.entities = {
        zip: state.entities.zip,
        someCollege: state.entities.someCollege,
      };
    },
  },
});

// Actions
export const { addInitialInputs, setInput, setInputs } = inputsSlice.actions;

// Selectors
export const { selectById: privateSelectById } = inputsAdapter.getSelectors<any>((state) => state.inputs);
export const selectAllInputs = (state: RootState): VoyagerInputs => state.inputs.entities as VoyagerInputs;

// Finds our input and returns the value
export const selectInput = createSelector([privateSelectById], (input) => (input ? input.value : undefined));
export const selectInputObject = createSelector([privateSelectById], (input) => input);

// Reducer
export default inputsSlice.reducer;
