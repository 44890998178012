import { RecommenderPopup } from '@edu-match/components';
import EmbeddedRecommender from '@edu-match/components/EmbeddedRecommender';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import prospectScriptConfig from '@core/config/prospectScriptSchoolIds.json';
import { useFeatureFlags } from '@core/context/FeatureFlagsContext';
import { computeDcsConsolidation, programDifference } from '@core/hooks/useDCSConsolidation';
import { setContactId } from '@core/reducers/contactSlice';
import { selectDcs } from '@core/reducers/dcsSlice';
import { selectAllInputs, selectInput, setInput } from '@core/reducers/inputsSlice';
import {
  selectIsRecommenderPopupOpen,
  selectLeadDelivery,
  selectShowDuplicatePopup,
  setIsRecommenderPopupOpen,
} from '@core/reducers/matchesSlice';
import { ALGOLIA_BASE_INDEX_NAME } from '@core/services/AlgoliaService/v2';
import {
  fetchEnhancedSchoolData,
  saveContact,
  SaveContactApiResponseFailure,
  SaveContactResult,
} from '@core/services/hub';
import { PageActions } from '@core/services/newRelic';
import UserExperienceSurvey from '@core/shared/features/UserExperienceSurvey';
import { Hit } from '@core/ts/algolia';
import { VoyagerRecommenderResult, VoyagerResult } from '@core/ts/results';
import buildLeadObject, { BuildLeadObjectInputs } from '@core/utils/buildLeadObject';
import cn from '@core/utils/classNames';
import { AddActiveProspectScript } from '@core/utils/scripts/AddActiveProspectScript';

import RevampLoading from '../edu-flow-2/components/Loading/index';
import DuplicatePopup from '../edu-match/components/DuplicatePopup';
import styles from './EduResults.module.css';
import { NoResults } from './components/NoResults';
import ResultsList from './components/ResultsList';
import useResults from './hooks/useResults';

const EduResults = () => {
  const dispatch = useDispatch();
  const userFirstName: string = useSelector((state) => selectInput(state, 'firstName'));
  const showRecommenderPopup = useSelector(selectIsRecommenderPopupOpen);
  const showDuplicatePopup = useSelector(selectShowDuplicatePopup);
  const dcs = useSelector(selectDcs);
  const {
    dcsCategories: [category],
    dcsDegrees: [degree],
    dcsSubjects: [subject],
  } = dcs;

  const inputs: Record<string, any> = useSelector(selectAllInputs);

  const { currentPrograms } = useSelector(selectLeadDelivery);

  // Prospect script schools
  const propsectScriptSchooIdList = prospectScriptConfig;
  const currentProgramsIdList = currentPrograms?.map((cp) => cp?.school?.id);
  const isActiveProspectScriptRequired = currentProgramsIdList?.some((cp) => propsectScriptSchooIdList.includes(cp));

  const {
    loading,
    searchClient,
    showEmbeddedRecommender,
    recommenderResults,
    exactResults,
    relatedResults,
    fetchRecommenderResults,
    fetchExactResults,
    fetchRelatedResults,
    enhancedSchoolData,
  } = useResults({
    dcs,
    inputs,
  });

  const flags = useFeatureFlags();
  const resultsPageEngagement = flags.resultsPageEngagementTest;
  const showAdVerbiageTest = flags?.adVerbiageTest === 'test';

  // Fetch Recommender, Related Results
  useEffect(() => {
    /* Fetch Recommended results and filter them out of exact matches */
    (async () => {
      const recommendedResults = await fetchRecommenderResults();
      if (recommendedResults?.recommender?.data) dispatch(setIsRecommenderPopupOpen(true));

      await fetchExactResults(recommendedResults.recommender as VoyagerRecommenderResult);
    })();
    fetchRelatedResults();

    PageActions.ResultsLoaded({
      resultsPage: 'eduResults',
      anonymousId: window?._Cohesion.anonymousId,
      sessionId: window?._Cohesion.sessionId,
    });
  }, [resultsPageEngagement]);

  useEffect(() => {
    if (!flags.useContactDb) return;
    saveContact({
      degree,
      category,
      subject,
      cohesionSessionId: window?._Cohesion?.sessionId,
      lead: buildLeadObject(inputs as BuildLeadObjectInputs),
    }).then((res: SaveContactResult) => {
      if (res?.data?.contactId) {
        dispatch(setContactId(res.data.contactId));
      }
    });
  }, [inputs, degree, category, subject, flags]);

  /*
   * DCS Consolidation hook that grabs unique hits from related matches and consolidates them into exact matches
   * Returns a list of unique matches
   */
  // DCS consolidation Flag
  const consolidatedResults = computeDcsConsolidation<VoyagerResult>({
    degree,
    exactMatches: exactResults as VoyagerResult[],
    relatedMatches: relatedResults as VoyagerResult[],
  });

  if (loading || !exactResults) {
    return <RevampLoading />;
  }

  const dcsConsolidationResults = [...exactResults, ...consolidatedResults];

  const unborrowedMatches = programDifference<VoyagerResult>(
    relatedResults as VoyagerResult[],
    dcsConsolidationResults
  );

  const exactMatchesHeader =
    showEmbeddedRecommender && Boolean(recommenderResults?.recommender?.data?.length)
      ? 'More Matches For You'
      : `Welcome to your results, ${userFirstName}`;

  const isGradResultsTest = flags?.voyagerGradResultsPage === 'test';

  const exactMatchesSubheader = `${
    showEmbeddedRecommender
      ? ''
      : 'Pick your favorite school, set up a call with an admissions administrator, and start your application — all in one place.'
  } ${isGradResultsTest ? '' : `These schools offer programs in ${subject} or areas closely related to this subject.`}`;
  const recommenderData = recommenderResults?.recommender?.data;
  const popupData = recommenderResults?.spotlight?.data || recommenderResults?.recommender?.data;
  const popupEventing = recommenderResults?.spotlight?.eventing || recommenderResults?.recommender?.eventing;

  return (
    <div className={isGradResultsTest && styles.bgContainer}>
      {isActiveProspectScriptRequired && <AddActiveProspectScript />}
      <div className={cn(styles.eduResultsContainer, isGradResultsTest && styles.gradRPTest)}>
        {showEmbeddedRecommender && !!recommenderData?.length && (
          <div className={styles.eduResultsIntro}>
            <h1 className={styles.eduResultsHeader}>Welcome to your results, {userFirstName}!</h1>
            <p className={styles.eduResultsSubheader}>
              Pick your favorite school, set up a call with an admissions administrator, and start your application —
              all in one place.
            </p>
          </div>
        )}
        {showDuplicatePopup && (
          <DuplicatePopup searchClient={searchClient} indexName={ALGOLIA_BASE_INDEX_NAME} resultsRevamp />
        )}
        {showRecommenderPopup && !!popupData?.length && (
          <RecommenderPopup
            hits={popupData as Hit[]}
            eventingOverrides={popupEventing}
            enhancedSchoolData={enhancedSchoolData}
            showAdVerbiageTest={showAdVerbiageTest}
          />
        )}

        {showEmbeddedRecommender && !!recommenderData?.length && (
          <EmbeddedRecommender
            hits={recommenderData as Hit[]}
            newResults
            enhancedSchoolData={enhancedSchoolData}
            showAdVerbiageTest={showAdVerbiageTest}
          />
        )}

        {dcsConsolidationResults?.length > 0 ? (
          <ResultsList
            enhancedSchoolData={enhancedSchoolData}
            header={exactMatchesHeader}
            subheader={exactMatchesSubheader}
            results={exactResults}
            borrowedResults={consolidatedResults}
            isExactMatch
            showAdVerbiageTest={showAdVerbiageTest}
          />
        ) : (
          ((showEmbeddedRecommender && recommenderData?.length && recommenderData?.length < 1) ||
            !showEmbeddedRecommender) &&
          unborrowedMatches?.length < 1 && <NoResults />
        )}
        {unborrowedMatches?.length > 0 && (
          <ResultsList
            enhancedSchoolData={enhancedSchoolData}
            header="Other School Programs You Might Like"
            subheader={`These schools offer similar programs to ${subject}.`}
            results={unborrowedMatches}
            showAdVerbiageTest={showAdVerbiageTest}
          />
        )}
      </div>
      <div className={styles.banners}>
        {flags?.userExperienceSurvey === 'test' && !showRecommenderPopup && <UserExperienceSurvey />}
      </div>
    </div>
  );
};

export default EduResults;
