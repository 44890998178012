// Format our inputs into valid objects to be stored in the cookies
// Example of our input: {"zip":{"key":"zip","value":"00936"}}
// Formatted input: {"zip":"00936"}

const inputFormatter = (object: { [k: string]: { value: unknown } }) =>
  Object.entries(object).reduce((accumulator, [key, value]) => {
    accumulator[key] = value.value;
    return accumulator;
  }, {});

export default inputFormatter;
