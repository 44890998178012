import { Hit } from '@core/ts/algolia';
import { VoyagerResult } from '@core/ts/results';

export const mapHitsToResults = (hits: Hit[]) => {
  const results: VoyagerResult[] = hits.map(({ program, school, cap, url }: Hit) => {
    const result: VoyagerResult = {
      cap,
      school,
      program,
      url,
    };
    return result;
  });
  return results;
};
