import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { type Option } from '@core/blocks/edu-flow-2/components/Fields/types';
import { type FieldComponent } from '@core/blocks/edu-flow-2/utils/fieldTypeDictionary';
import useFieldEvents from '@core/hooks/cohesion/useFieldEvents';
import { setDcsValue } from '@core/reducers/dcsSlice';
import { selectFormCorrelationId, selectStepContext } from '@core/reducers/eventingSlice';
import { type VoyagerInput, selectInputObject, setInput } from '@core/reducers/inputsSlice';

import OptionGroupOptions from './OptionGroupOptions';

export const isSelected = (selection: VoyagerInput, option: Option) => {
  const selectedOption = selection?.options as unknown as Option;
  if (!selectedOption?.value && !selectedOption?.label) return false;
  return selectedOption.value === option.value && selectedOption.label === option.label;
};

const SingleOptionGroupField: FieldComponent = ({ field, heading, fieldNumber }) => {
  // Action Dispatcher
  const dispatch = useDispatch();

  // Form Correlation Id for events
  const formCorrelationId = useSelector(selectFormCorrelationId);
  // stepContext for for fields events
  const stepContext = useSelector(selectStepContext);

  const { fieldSelected, fieldViewed } = useFieldEvents({
    correlationId: formCorrelationId,
    userInputField: {
      autofilled: false,
      fieldName: field.name,
      fieldLabel: heading,
      fieldType: field.type,
      fieldNumber,
    },
  });

  // Inputs State properties
  const fieldInputObject = useSelector((state) => selectInputObject(state, field.name as string));

  useEffect(() => {
    fieldViewed();
  }, []);

  const handleSelection = useCallback(
    (option: Option) => {
      fieldSelected({
        userInputField: {
          fieldValue: option.label,
        },
      });

      if (isSelected(fieldInputObject as VoyagerInput, option)) {
        dispatch(setInput({ key: field.name as string, value: undefined, options: undefined }));
      } else {
        if (field.dcsInput) {
          dispatch(setDcsValue({ key: field.dcsInput, value: option.value }));
        }
        dispatch(setInput({ key: field.name as string, value: option.value, options: option }));
      }
    },
    [fieldInputObject, field, heading, isSelected, stepContext]
  );
  return (
    <OptionGroupOptions
      isSelected={isSelected}
      field={field}
      handleSelection={handleSelection}
      fieldInputObject={fieldInputObject}
    />
  );
};

export default SingleOptionGroupField;
