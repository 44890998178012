import { IDCSRule, IInputRule, IPublisherRule } from '@core/ts/rules';

export const resolveDCSRule = (
  rule: IDCSRule,
  dcsDegrees: string[],
  dcsSubjects: string[],
  dcsCategories: string[]
): boolean => {
  // If we have an undefined dcsList inside a DCS rule, then this is not a valid config
  if (!rule.dcsList) return false;

  return !!rule.dcsList.find(({ degrees, subjects, categories }) => {
    if (!degrees && !subjects && !categories) return false;
    // Check if degrees array have any matching values in dcs degrees, if empty then set to true, as we consider this a wildcard match
    const degreesMatch = degrees && degrees.length > 0 ? degrees.some((degree) => dcsDegrees.includes(degree)) : true;
    // Check if subjects array have any matching values in dcs subjects, if empty then set to true, as we consider this a wildcard match
    const subjectsMatch =
      subjects && subjects.length > 0 ? subjects.some((subject) => dcsSubjects.includes(subject)) : true;
    // Check if categories array have any matching values in dcs categories, if empty then set to true, as we consider this a wildcard match
    const categoriesMatch =
      categories && categories.length > 0 ? categories.some((category) => dcsCategories.includes(category)) : true;
    return degreesMatch && subjectsMatch && categoriesMatch;
  });
};

export const resolveInputRule = (rule: IInputRule, inputs: any): boolean => {
  if (!rule.field || !rule.operator) return false;
  const input = inputs[rule.field];
  if (!input) return false;

  const ruleRegistry = {
    EQUALS: input.value === rule.value,
    NOT_EQUALS: input.value !== rule.value,
    EXISTS: true,
  };

  // If the `rule.type` isn't a property of the ruleRegistry, we explicitly return `false`
  return ruleRegistry[rule.operator] || false;
};

export const resolvePublisherRule = (rule: IPublisherRule, name: string): boolean => rule.names.indexOf(name) !== -1;
