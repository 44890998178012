import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectInput } from '@core/reducers/inputsSlice';

import Dots from './Dots';
import Icon1 from './Icon1';
import Icon2 from './Icon2';
import Icon3 from './Icon3';
import styles from './Loading.module.css';

const Loading: FC = () => {
  const intent = useSelector((state) => selectInput(state, 'intentLevel'));
  const navigate = useNavigate();

  // redirects to the results page after 4 seconds
  useEffect(() => {
    setTimeout(() => navigate('/results'), 4000);
  }, []);

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.heading}>We’re preparing your matches!</h2>
      <Dots />
      <div className={styles.cardContainer}>
        <div className={styles.card}>
          <Icon1 />
          <div className={styles.cardText}>
            <p>
              Compare <br /> schools
            </p>
          </div>
        </div>
        <div className={styles.card}>
          <Icon2 />
          <div className={styles.cardText}>
            <p>
              Pick your <br /> favorite
            </p>
          </div>
        </div>
        <div className={styles.card}>
          <Icon3 />
          {intent === 'talk-to-an-advisor-directly' ? (
            <div className={styles.cardText}>
              <p>
                Talk with an
                <br /> advisor
              </p>
            </div>
          ) : (
            <div className={styles.cardText}>
              <p>
                Apply <br />
                today
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Loading;
