import { type FC } from 'react';

import ChartPanel from '../components/AdditionalContentFields/ChartPanel';
import InfoPanel from '../components/AdditionalContentFields/InfoPanel';
import StatCta from '../components/AdditionalContentFields/StatCta';
import { type AdditionalContentField } from '../components/AdditionalContentFields/types';

const additionalContentFieldTypeDictionary = {
  infoPanel: InfoPanel,
  statCta: StatCta,
  chart: ChartPanel,
};
export type AdditionalContentFieldType = keyof typeof additionalContentFieldTypeDictionary;
export type AdditionalFieldComponentProps<T extends AdditionalContentField = AdditionalContentField> = {
  field: T;
};

export type AdditionalContentFieldComponent = FC<AdditionalFieldComponentProps>;
export default additionalContentFieldTypeDictionary as Record<
  AdditionalContentFieldType,
  AdditionalContentFieldComponent
>;
