import cn from '@core/utils/classNames';

const SelectField = (props) => {
  const { name, onChange, uiSchema, schema } = props;
  const title = uiSchema?.['ui:title'];
  const id = uiSchema?.['ui:id'];

  const options = uiSchema?.['ui:options']?.options;

  // function that runs whenever something is selected in the dropdown
  const handleSelection = (event) => {
    if (event.target.value === '') {
      onChange(null);
      return;
    }

    onChange(schema.type === 'number' ? Number(event.target.value) : event.target.value);
  };

  return (
    <label htmlFor={name} className="control-label">
      {title}
      <select
        id={id}
        defaultValue=""
        className={cn('form-control', 'prq')}
        data-testid="select-field-select"
        onChange={handleSelection}
      >
        <option disabled value="">
          Select an option
        </option>

        {options?.map((option) => (
          <option key={option.value} label={option.label} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </label>
  );
};

export default SelectField;
