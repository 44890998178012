import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isDevelopment } from '@core/blocks/edu-match/utils/determineEnv';
import schemaConfig from '@core/config/schema.json';
import { DEBUG_PARAMS } from '@core/constants';
import { useFeatureFlags } from '@core/context/FeatureFlagsContext';
// Hooks
import useConfig from '@core/hooks/useConfig';
// Reducers selectors/actions
import { setSchemaName } from '@core/reducers/configSlice';
import { selectDcs, selectDcsDegrees } from '@core/reducers/dcsSlice';
import { selectInput } from '@core/reducers/inputsSlice';
import { selectQueryParam } from '@core/reducers/queryParamsSlice';
import { type BlockPage, type Flow } from '@core/schemas/schema';
import { newRelicCustomAttribute } from '@core/services/newRelic';
import buildAllQuestionsFlow from '@core/utils/buildAllQuestionsFlow';
import generateTestSchema from '@core/utils/generateTestSchema';
import getSchema from '@core/utils/getSchema';

import getInjectionKeys from './getInjectionSlug';
import getModifiedArray from './getPagesForInjection';
import getQuestionInjectionSchema, { InjectionKey } from './getQuestionInjectionSchema';

const orderSchemasByRelevance = (
  { dcsCategories, dcsDegrees, dcsSubjects }: { dcsCategories: string[]; dcsDegrees: string[]; dcsSubjects: string[] },
  schemasNames: string[]
) => {
  const rankedList: { name: string; rank: number }[] = [];
  const schemas = schemaConfig.config.filter(({ value }) => schemasNames.includes(value));

  schemas?.forEach((schema) => {
    if (!schema.ruleSets) rankedList.push({ name: schema.value, rank: 0 });
    return schema.ruleSets?.forEach((rules) =>
      rules?.forEach((rule) => {
        if (rule.type === 'INPUT') {
          rankedList.push({ name: schema.value, rank: 1 });
        }
        if (rule.type === 'DCS') {
          let rank = 0;
          rule.dcsList.forEach(({ degrees, subjects, categories }) => {
            rank +=
              Number((categories || []).includes(dcsCategories?.[0])) +
              Number((subjects || []).includes(dcsSubjects?.[0])) +
              Number((degrees || []).includes(dcsDegrees?.[0]));
          });
          rankedList.push({
            name: schema.value,
            rank: rank + 1,
          });
        }
      })
    );
  });

  return rankedList.sort((a, b) => b.rank - a.rank);
};

/**
 * useSchemaConfig handles the schema configuration for the voyager app
 * should only be used in App.tsx
 * values will be stored in state for use in other components
 * @returns
 */
const useSchemaConfig = () => {
  const [dcsDegree] = useSelector(selectDcsDegrees);
  // test all questions debugger query param
  const testAllQuestionsDebug = useSelector((state) => selectQueryParam(state, DEBUG_PARAMS.TEST_ALL_QUESTIONS));

  const dispatch = useDispatch();
  const dcs = useSelector(selectDcs);
  const [winningSchema, setWinningSchema] = useState<Flow>({} as any);

  const { isCompleted, results } = useConfig(schemaConfig);
  const ipInferredPostalCode = useSelector((state) => selectInput(state, 'ipInferredPostalCode'));
  const flags = useFeatureFlags();
  // Once our config is completed, set the schema name in state
  useEffect(() => {
    if (!isCompleted) return;

    const winner = orderSchemasByRelevance(dcs, results).find((schema) => !!schema)?.name;
    (async () => {
      const winningSchema = testAllQuestionsDebug
        ? buildAllQuestionsFlow()
        : await getSchema(winner).then((schema) =>
            dcsDegree !== 'Bootcamps' ? generateTestSchema<typeof schema>(schema, flags) : schema
          );

      const winningPageArray = winningSchema.blocks[0].pages as BlockPage[];

      const injectionKeys = await getInjectionKeys({
        dcs,
        ipInferredPostalCode,
        flags,
      });
      winningSchema.blocks[0].pages = injectionKeys?.reduce((pagesAcc, currentInjectionKey) => {
        const questionInjectionSchema = getQuestionInjectionSchema(flags);
        return getModifiedArray(pagesAcc, questionInjectionSchema[currentInjectionKey as InjectionKey]);
      }, winningPageArray);

      newRelicCustomAttribute('voyager_flow', winningSchema);
      setWinningSchema(winningSchema);
    })();
    dispatch(setSchemaName(winner));
  }, [isCompleted, dcs, results, dispatch, ipInferredPostalCode, flags]);

  // Log the winning schema in development
  if (isDevelopment()) {
    console.log('winningSchema', winningSchema);
  }

  return { winningSchema };
};

export default useSchemaConfig;
