import { FC, MouseEventHandler, useEffect } from 'react';
import { CgExternal } from 'react-icons/cg';
import { FaAngleDoubleLeft, FaChevronLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import styles from './Cappex.module.css';

/**
 * When user selects High School for Level of Education
 * AND Graduation date indicates they are in their Junior
 * year or lower (in 2023, will be 2024 and up), user should
 * be presented with Cappex landing page, taking user out of
 * Voyager flow - BUT keeping user in the same tab as the
 * voyager flow.  Landing page should NOT open to a new tab.
 */

type Props = {
  onLoad: () => void;
  onClick: () => void;
  onPreviousPage?: MouseEventHandler<HTMLButtonElement>;
};

const EduCappex: FC<Props> = ({ onLoad, onClick, onPreviousPage }) => {
  const navigate = useNavigate();
  const clickHandler = () => {
    navigate(-1);
  };
  useEffect(() => {
    onLoad();
  }, []);
  return (
    <div className={styles.landingPage}>
      <div className={styles.backgroundContainer} data-testid="cappex-background-image">
        <img
          src="https://res.cloudinary.com/highereducation/image/upload/c_scale,w_1440/v1684422028/Cappex/LandingPage.jpg"
          alt=""
        />
      </div>
      <div className={styles.infoContainer}>
        <section className={styles.infoPanel}>
          <button type="button" className={styles.backButton} onClick={clickHandler}>
            <FaAngleDoubleLeft className={styles.buttonChevron} />
            Back to previous question
          </button>
          <h3 className={styles.welcomeHeader}>Continue with Appily to get your best results!</h3>
          <p className={styles.welcomeText}>
            We&apos;re <b>partnering with Appily</b> to help students like you find the right college for your goals and
            future. Finish this quiz on their site to get personalized matches.
          </p>
          <h6 className={styles.findCollegeHeader}>Find a College in 3 Easy Steps</h6>

          <ul className={styles.cappexFeaturesList}>
            <li>Continue this quiz on the Appily website</li>
            <li>Create your FREE account</li>
            <li>Connect with colleges</li>
          </ul>

          <p className={styles.statusText}>
            Once you’ve created your account, you can even view your application status!
          </p>
          <a
            data-testid="cappex-cta-anchor"
            href="http://tracking.appily.com/aff_c?offer_id=181&aff_id=2195&aff_sub=PHASE2"
          >
            <button data-testid="cappex-cta-button" onClick={onClick} type="button" className={styles.ctaButton}>
              Find Your Match <CgExternal />
            </button>
          </a>
        </section>
      </div>
    </div>
  );
};
export default EduCappex;
