import styles from './ProductFlags.module.css';
import ProductFlagsDictionary from './ProductFlagsDictionary';

const ProductFlags = ({ flags }) => (
  <div className={styles.flagsContainer}>
    <span className={styles.flagContent}>
      {Object.entries(flags ?? {})?.map(([key, value]) => {
        const flag = ProductFlagsDictionary[key];

        return !value || !flag ? null : (
          <div className={styles.flag} key={key}>
            {flag?.icon}
            {flag?.value}
          </div>
        );
      })}
    </span>
  </div>
);

export default ProductFlags;
