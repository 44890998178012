// Components
import { FacetButton } from '@edu-match/components';
import { type FC, useState, useRef, ReactNode } from 'react';

// Hooks
import useWindowEvent from '@core/hooks/useWindowEvent';

import styles from './FacetDropdown.module.css';

type Props = {
  initialButtonText: string;
  filteredButtonText?: string;
  filter: string;
  children?: ReactNode;
};

const FacetDropdown: FC<Props> = ({ children, initialButtonText, filteredButtonText, filter }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  // listen for clicks on the window
  // if the element target that was clicked
  // is not the modal, then close the modal
  useWindowEvent('mousedown', (event) => {
    const { target } = event;

    if (!open) return;
    if (ref.current.contains(target)) return;

    setOpen(!open);
  });

  return (
    <div ref={ref} className={styles.facetDropdown}>
      <FacetButton
        initialText={initialButtonText}
        filteredText={filteredButtonText}
        filter={filter}
        handleClick={() => setOpen(!open)}
        isOpen={open}
      />

      <div className={open ? styles.open : undefined}>{children}</div>
    </div>
  );
};

export { FacetDropdown };
