import { FC } from 'react';

import { Cta } from '../../hooks/useGetApplicationCtaData';
import styles from './LeadSubmittedCta.module.css';

type Props = {
  cta: Cta;
};

const LeadSubmittedCta: FC<Props> = ({ cta }) =>
  cta?.label && cta?.url ? (
    <div className={styles.ctaButton}>{cta.label}</div>
  ) : (
    <div className={styles.nextStepsButton}>Next Steps</div>
  );
export default LeadSubmittedCta;
