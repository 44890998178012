const recursivelyCheckForUndefined = (object: object) => {
  // if object is not an object or null return false
  if (typeof object !== 'object' || object === null) {
    return false;
  }

  // loop through all the prop values of the object
  for (const value of Object.values(object)) {
    // if another object is encountered, check that one as well
    if (typeof value === 'object') {
      return recursivelyCheckForUndefined(value);
    }

    // if a prop is found with an undefined value return true
    if (typeof value === 'undefined') {
      return true;
    }
  }

  // if no undefined values are found return false
  return false;
};

export default recursivelyCheckForUndefined;
