import { Dispatch, FC, SetStateAction, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import Card from '@core/blocks/eduResults/components/Card';
import useProductEvents from '@core/hooks/cohesion/useProductEvents';
import {
  setProductCorrelationIdMapEntry,
  setHeclidMapEntry,
  selectRecommenderPopupListId,
  setViewCorrelationIdMapEntry,
} from '@core/reducers/eventingSlice';
import { selectSubmissions, setCurrentProgram } from '@core/reducers/matchesSlice';
import { EnhancedSchoolData } from '@core/services/hub';
import { mobiusRedirect } from '@core/services/mobius';
import { VoyagerResult } from '@core/ts/results';
import getToken from '@core/utils/getToken';

type Props = {
  isSingleResultPopupRecommender?: boolean;
  enhancedSchoolData?: EnhancedSchoolData;
  result: VoyagerResult;
  eventingOverrides: { [key: string]: any };
  position: number;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setRecommenderStep: Dispatch<SetStateAction<string>>;
};
const RecommenderCardRevamp: FC<Props> = ({
  isSingleResultPopupRecommender,
  result,
  eventingOverrides,
  position,
  setLoading,
  setRecommenderStep,
  enhancedSchoolData,
}) => {
  const dispatch = useDispatch();
  // eventing state properties
  const popupListId = useSelector(selectRecommenderPopupListId);

  const newViewCorrelationId = useMemo(() => uuid(), []);

  const submissions = useSelector(selectSubmissions);

  const isSchoolSubmitted = submissions?.some((submission) => submission.school.id === result.school.id);

  // Initialize events
  const { productLoaded, productViewed, productClicked } = useProductEvents({
    listId: popupListId,
    viewCorrelationId: newViewCorrelationId,
    product: {
      sku: String(result.cap.id),
      variant: result.program.degree.slug,
      productId: String(result.program.id),
      name: result.program.subject.slug,
      category: result.program.category.slug,
      brand: result.school.slug,
      position,
      location: eventingOverrides?.location,
      formatType: 'multi-program',
      formatSubtype: 'recommendation',
      positionEngine: 'algolia',
      ...eventingOverrides.product,
    },
    customDimensions: eventingOverrides.customDimensions,
  });

  // Fire productLoaded + productViewed events and adds entry to the viewCorrelationIdMap
  useEffect(() => {
    productLoaded();
    productViewed();
    dispatch(
      setViewCorrelationIdMapEntry({
        key: result.program.id,
        value: newViewCorrelationId,
      })
    );
  }, []);
  const onBtnClickHandler = async () => {
    setLoading(true);
    dispatch(setCurrentProgram(result));

    const newProductCorrelationId = uuid();

    // add correlationId to map for LDS lead submission
    dispatch(
      setProductCorrelationIdMapEntry({
        key: result.program.id,
        value: newProductCorrelationId,
      })
    );

    productClicked({
      correlationId: newProductCorrelationId,
    });

    // Mobius Redirect will fire the OutcomeTracked APP_VIEWED event
    const { heclid } = await mobiusRedirect({
      idToken: getToken(),
      productCorrelationId: newProductCorrelationId,
      link: result.url,
      trackingContextOverride: {
        formatType: 'app',
        formatSubtype: 'recommendation',
      },
    });
    // add heclid to map for Mobius track if API call succeeds
    dispatch(setHeclidMapEntry({ key: result.program.id, value: heclid }));

    setLoading(false);
    setRecommenderStep('PII_CONFIRMATION');
  };

  return (
    <Card
      isSingleResultPopupRecommender={isSingleResultPopupRecommender}
      enhancedSchoolData={enhancedSchoolData}
      isRecommenderPopup
      result={result}
      eventingOverrides={eventingOverrides}
      position={position}
      ctaLabel="Explore Program"
      onClick={isSchoolSubmitted ? undefined : onBtnClickHandler}
    />
  );
};
export default RecommenderCardRevamp;
