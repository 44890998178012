import { Dispatch } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectDcsDegrees } from '@core/reducers/dcsSlice';
import { selectRelatedMatches, selectExactMatches, setUniqueMatches } from '@core/reducers/matchesSlice';
import { Hit } from '@core/ts/algolia';
import { VoyagerResult } from '@core/ts/results';

/**
 * @param {Hit} hits
 * @returns  New hits list without school duplicates
 */
export const removeSchoolDuplicates = <T extends { school: { id: number } } = Hit>(hits: T[]): T[] =>
  hits.filter((hit: T, index: number) => hits?.findIndex((_hit: T) => _hit.school.id === hit.school.id) === index);

/**
 * @param {Hit} hits1
 * @param {Hit} hits2
 * @returns  The difference of two lists of hits based on school.id
 */
export const schoolDifference = <T extends { school: { id: number } } = Hit>(hits1: T[], hits2: T[]): T[] =>
  hits1?.filter((hit1) => !hits2?.some((hit2) => hit1.school.id === hit2.school.id));
/**
 * @param {Hit} hits1
 * @param {Hit} hits2
 * @returns The difference of two lists of hits based on program.id
 */
export const programDifference = <T extends { program: { id: number } } = Hit>(hits1: T[], hits2: T[]): T[] =>
  hits1?.filter((hit1: T) => !hits2?.some((hit2: T) => hit1.program.id === hit2.program.id));

export const computeDcsConsolidation = <T extends { school: { id: number } } = Hit>({
  degree,
  exactMatches,
  relatedMatches,
}: {
  degree: string;
  exactMatches?: T[];
  relatedMatches?: T[];
}): T[] => {
  if (!exactMatches || !relatedMatches) {
    return [];
  }
  let limit = 0;
  // Associate's &  exact matches < 4
  if (degree === "Associate's" && exactMatches.length < 4) {
    // Get 4 unique schools from the related matches and add them to the exact matches
    limit = 4;
  }
  // Bachelors & exact matches < 6
  if (degree === "Bachelor's" && exactMatches.length < 6) {
    // Get 6 unique schools from the related matches and add them to the exact matches
    limit = 6;
  }
  // Find unique matches and only grab the missing amount
  if (limit !== 0) {
    const newUniqueMatches = schoolDifference<T>(removeSchoolDuplicates<T>(relatedMatches), exactMatches)?.slice(
      0,
      limit - exactMatches.length
    );

    return newUniqueMatches;
  }

  return [];
};

/**
 * @description Finds the difference between exact matches and related matches
 * this'll later be added to exact matches
 * @returns Void
 */
const useDCSConsolidation = () => {
  const dispatch: Dispatch = useDispatch();
  const degree: string = useSelector(selectDcsDegrees)?.[0];
  const relatedMatches: VoyagerResult[] = useSelector(selectRelatedMatches);
  const exactMatches: VoyagerResult[] = useSelector(selectExactMatches);
  const [_uniqueMatches, _setUniqueMatches] = useState<VoyagerResult[]>([]);

  useEffect(() => {
    let limit = 0;
    // Associate's &  exact matches < 4
    if (degree === "Associate's" && exactMatches.length < 4) {
      // Get 4 unique schools from the related matches and add them to the exact matches
      limit = 4;
    }
    // Bachelors & exact matches < 6
    if (degree === "Bachelor's" && exactMatches.length < 6) {
      // Get 6 unique schools from the related matches and add them to the exact matches
      limit = 6;
    }
    // Find unique matches and only grab the missing amount
    if (limit !== 0) {
      const newUniqueMatches: VoyagerResult[] = schoolDifference(
        removeSchoolDuplicates(relatedMatches),
        exactMatches
      )?.slice(0, limit - exactMatches.length);

      // Local use
      _setUniqueMatches(newUniqueMatches);

      // App/Global use
      dispatch(setUniqueMatches(newUniqueMatches));
    }
  }, [relatedMatches, exactMatches, degree]);

  return _uniqueMatches;
};

export default useDCSConsolidation;
