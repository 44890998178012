import withFieldInputted from '../withFieldInputted';
import TextField from './TextWidget';
import ZipField from './ZipWidget';

const widgets = (schoolSlug: string, formCorrelationId: string, eventingOverrides?: Record<string, unknown>) => ({
  zipCode: withFieldInputted({ Component: ZipField, schoolSlug, formCorrelationId, eventingOverrides }),
  textField: withFieldInputted({ Component: TextField, schoolSlug, formCorrelationId, eventingOverrides }),
});

export default widgets;
