import { CompactCard } from '@edu-match/components/CompactCard';
import { SchoolConfigData } from '@edu-match/components/ExitStrategy/type';
import findNextDate from '@edu-match/utils/findNextDate';
import Autolinker from 'autolinker';
import { FC, useEffect, useMemo, useState } from 'react';
import { AiOutlineClockCircle, AiOutlineCalendar } from 'react-icons/ai';
import { HiOutlineClipboardList, HiOutlineClipboardCheck } from 'react-icons/hi';
import { useSelector } from 'react-redux';

import useElementEvents from '@core/hooks/cohesion/useElementEvents';
import useSchoolConfig from '@core/hooks/useSchoolConfig';
import {
  selectHeclidMap,
  selectViewCorrelationIdMap,
  selectProductCorrelationIdMap,
} from '@core/reducers/eventingSlice';
import { selectInput } from '@core/reducers/inputsSlice';
import { selectSubmissions } from '@core/reducers/matchesSlice';
import { mobiusGetApplicationCta } from '@core/services/mobius';
import cn from '@core/utils/classNames';
import { applicationCtaBtn, continueBrowsingBtn } from '@core/utils/objects/eventPayloads';

import styles from './RecommenderExitStrategy.module.css';

// Reuse for different locations (mobile vs. desktop)
const NextSteps = ({ applicationCtaData, elementClicked, dataTestId, closePopup, school }) => {
  const nextStepSchools = ['purdue-university-global', 'southern-new-hampshire-university'];

  const handleContinueBtnClick = () => {
    elementClicked(continueBrowsingBtn);
    if (closePopup) closePopup();
  };

  const handleApplication = (e, url) => {
    e.preventDefault();
    elementClicked({ ...applicationCtaBtn, outboundUrl: applicationCtaData?.url });
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <section className={styles.nextSteps}>
      {nextStepSchools.includes(school) ? (
        <div>
          <p className={styles.preampNextSteps}>Apply today with no application fees!</p>
        </div>
      ) : (
        <div>
          <h5>Next Steps</h5>
          <p>Your information will never be shared with a school without your consent</p>
        </div>
      )}
      {applicationCtaData?.url && (
        <button
          className={styles.applicationCta}
          type="button"
          onClick={(e) => handleApplication(e, applicationCtaData?.url)}
        >
          {applicationCtaData?.label}
        </button>
      )}
      <button
        data-testid={dataTestId}
        className={styles.continueToResults}
        onClick={handleContinueBtnClick}
        type="button"
      >
        Continue browsing programs
      </button>
    </section>
  );
};

type Props = {
  closePopup?: () => void;
  eventingOverrides: Record<string, any>;
};

const RecommenderExitStrategy: FC<Props> = ({ closePopup, eventingOverrides }) => {
  // Store
  const firstName = useSelector((state) => selectInput(state, 'firstName'));
  const submissions = useSelector(selectSubmissions);
  const heclidMap = useSelector(selectHeclidMap);
  const productCorrelationIdMap = useSelector(selectProductCorrelationIdMap);
  const viewCorrelationIdMap = useSelector(selectViewCorrelationIdMap);
  const { program, school } = submissions[0];

  const { data }: SchoolConfigData = useSchoolConfig(school.id);

  const programDates = useMemo(
    () => (data?.formDates?.undergrad?.length ? findNextDate(data?.formDates?.undergrad) : {}),
    [data]
  );

  const introText =
    data?.confirmation?.meta.shortIntro ||
    data?.confirmation?.meta.text ||
    'An Admissions Advisor will be in touch soon.';

  const autolinkedIntroText = Autolinker.link(introText);

  // Local state
  const [isIntroTextExpanded, setIsIntroTextExpanded] = useState(false);
  const [applicationCtaData, setApplicationCtaData] = useState(null);
  const showViewMoreBtn = autolinkedIntroText?.length > 110;

  const { elementViewed, elementClicked } = useElementEvents({
    correlationId: viewCorrelationIdMap[program.id],
    webElement: {
      location: eventingOverrides?.location,
    },
  });

  useEffect(() => {
    elementViewed(continueBrowsingBtn);

    const getApplicationCtaData = async () => {
      const response = await mobiusGetApplicationCta({
        programId: `${program.id}`,
        heclid: heclidMap[program.id],
        correlationId: productCorrelationIdMap[program.id],
      });
      setApplicationCtaData({ label: response?.label, url: response?.url });
    };

    getApplicationCtaData();
  }, []);

  useEffect(() => {
    if (applicationCtaData?.url) {
      elementViewed(applicationCtaBtn);
    }
  }, [applicationCtaData]);

  // Reuse for different locations (mobile vs. desktop)
  const collapsibleSection = (
    <>
      <div
        className={cn(
          styles.admissionsText,
          showViewMoreBtn && styles.admissionsCollapsible,
          isIntroTextExpanded && styles.expand
        )}
      >
        <div dangerouslySetInnerHTML={{ __html: autolinkedIntroText }} />
        {!isIntroTextExpanded && showViewMoreBtn && <span className={styles.gradient} />}
      </div>
      {showViewMoreBtn && (
        <button
          type="button"
          className={styles.expandButton}
          onClick={() => setIsIntroTextExpanded(!isIntroTextExpanded)}
        >
          {isIntroTextExpanded ? 'View less -' : 'View more +'}
        </button>
      )}
    </>
  );

  return (
    <div className={styles.RecommenderExitStrategy}>
      <span className={styles.leftColumn}>
        <div className={styles.mobilePersonalization}>
          <h2>
            🎉 Congratulations, <span>{firstName}</span>!
          </h2>
          <p>You&apos;ve taken the first step toward furthering your education</p>
          {collapsibleSection}
        </div>

        <section>
          <div className={styles.columnTitle}>
            <h4>{school.name}</h4>
          </div>
          <p>{program.name}</p>
          <div className={styles.compactSchoolCard}>
            <CompactCard showClose={false} hit={submissions[0]} />
          </div>
          <div className={styles.dateCard}>
            {programDates?.registerDate || programDates?.startDate ? (
              <>
                {programDates.registerDate && (
                  <span>
                    <AiOutlineClockCircle size={20} className={styles.icon} />
                    <p>
                      Register By: <b>{programDates.registerDate}</b>
                    </p>
                  </span>
                )}

                {programDates.startDate && (
                  <span>
                    <AiOutlineCalendar size={20} className={styles.icon} />

                    <p>
                      Classes Start: <b>{programDates.startDate}</b>
                    </p>
                  </span>
                )}
              </>
            ) : (
              <span>
                <p>Now accepting new students.</p>
              </span>
            )}
          </div>
        </section>

        <div className={styles.unstickyActions}>
          <NextSteps
            closePopup={closePopup}
            elementClicked={elementClicked}
            applicationCtaData={applicationCtaData}
            dataTestId="recommender-popup-continue-browsing-desktop"
            school={school.slug}
          />
        </div>
      </span>

      <span className={styles.rightColumn}>
        <div className={styles.contactContainer}>
          <h2>Congratulations, {firstName}!</h2>
          {collapsibleSection}
        </div>

        <hr className={styles.divider} />

        <h3>What&apos;s next?</h3>
        <div className={styles.whatsNextCard}>
          <div>
            <HiOutlineClipboardList size={22} className={styles.icon} />
            <h4>What to expect from the Advisor</h4>
          </div>

          <ul>
            <li>Hands-on assistance with your application process</li>
            <li>Help applying for financial aid</li>
            <li>Answers to any question you have</li>
          </ul>
        </div>

        {data?.valueProps?.length > 0 && (
          <div className={styles.whatsNextCard}>
            <div>
              <HiOutlineClipboardCheck size={22} className={styles.icon} />

              <h4>{school.name} offers</h4>
            </div>

            <ul>
              {data?.valueProps.map((valueProp: { title: string }) => (
                <li>{valueProp.title}</li>
              ))}
            </ul>
          </div>
        )}
      </span>
      <div className={styles.stickyActions}>
        <NextSteps
          closePopup={closePopup}
          elementClicked={elementClicked}
          applicationCtaData={applicationCtaData}
          dataTestId="recommender-popup-continue-browsing-mobile"
          school={school.slug}
        />
      </div>
    </div>
  );
};

export default RecommenderExitStrategy;
