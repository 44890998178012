/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import ProductFlags from '@edu-match/components/ProductFlags';
import { useEffect, useState, useMemo, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import useProductEvents from '@core/hooks/cohesion/useProductEvents';
// selectors, actions
import {
  selectRecommenderPopupListId,
  setHeclidMapEntry,
  setProductCorrelationIdMapEntry,
  setViewCorrelationIdMapEntry,
} from '@core/reducers/eventingSlice';
import {
  addProgramToList,
  removeProgramFromList,
  selectLeadDelivery,
  setCurrentProgram,
} from '@core/reducers/matchesSlice';
import { mobiusRedirect } from '@core/services/mobius';
// hooks & utils
import cn from '@core/utils/classNames';
import getToken from '@core/utils/getToken';

// styles
import styles from './RecommenderCard.module.css';

const RecommenderCard = ({ hit, setRecommenderStep, position, setLoading, eventingOverrides }) => {
  const { currentPrograms } = useSelector(selectLeadDelivery);

  // dispatches actions
  const dispatch = useDispatch();

  // local state
  const [showMore, setShowMore] = useState(false);

  // Eventing Ids
  const popupListId = useSelector(selectRecommenderPopupListId);
  const newViewCorrelationId = useMemo(() => uuid(), []);

  // determine if we are in single submission
  // DEV NOTE: setting this to just be true for the purposes of a desired optimization
  // and we don't want to remove the multi-select code just yet
  const isSingleSubmissionRecommender = true;

  // Initialize events
  const { productLoaded, productViewed, productClicked } = useProductEvents({
    listId: popupListId,
    viewCorrelationId: newViewCorrelationId,
    product: {
      sku: String(hit.cap.id),
      variant: hit.program.degree.slug,
      productId: String(hit.program.id),
      name: hit.program.subject.slug,
      category: hit.program.category.slug,
      brand: hit.school.slug,
      position,
      location: eventingOverrides?.location,
      formatType: isSingleSubmissionRecommender ? 'app' : 'multi-program',
      formatSubtype: 'recommendation',
      positionEngine: 'algolia',
      ...eventingOverrides.product,
    },
    customDimensions: eventingOverrides.customDimensions,
  });

  // Fire productLoaded + productViewed events and adds entry to the viewCorrelationIdMap
  useEffect(() => {
    productLoaded();
    productViewed();
    dispatch(
      setViewCorrelationIdMapEntry({
        key: hit.program.id,
        value: newViewCorrelationId,
      })
    );
  }, []);

  const isSelected = !!currentPrograms.find((current) => hit.program.id === current.program.id);

  const onChangeHandler = () => {
    const action = isSelected ? removeProgramFromList : addProgramToList;
    dispatch(action(hit));
  };

  const onBtnClickHandler = async () => {
    setLoading(true);
    dispatch(setCurrentProgram(hit));

    const newProductCorrelationId = uuid();

    // add correlationId to map for LDS lead submission
    dispatch(
      setProductCorrelationIdMapEntry({
        key: hit.program.id,
        value: newProductCorrelationId,
      })
    );

    productClicked({
      correlationId: newProductCorrelationId,
    });

    // Mobius Redirect will fire the OutcomeTracked APP_VIEWED event
    const { heclid } = await mobiusRedirect({
      idToken: getToken(),
      productCorrelationId: newProductCorrelationId,
      link: hit.url,
      trackingContextOverride: {
        formatType: 'app',
        formatSubtype: 'recommendation',
      },
    });
    // add heclid to map for Mobius track if API call succeeds
    dispatch(setHeclidMapEntry({ key: hit.program.id, value: heclid }));

    setLoading(false);
    setRecommenderStep('PII_CONFIRMATION');
  };

  const handleDescriptionToggle = (e) => {
    e.stopPropagation();
    setShowMore(!showMore);
  };

  return (
    <button
      type="button"
      className={styles.card}
      onClick={isSingleSubmissionRecommender ? onBtnClickHandler : undefined}
    >
      <div className={styles.cardTopBorder} />
      <div className={styles.cardHeader}>
        <div className={styles.logo}>
          <img
            src={`https://simple-storage-server.highereducation.com/${hit.school.slug}.png?w=130&h=80`}
            alt={hit.school.name}
          />
        </div>

        {hit.school.city && hit.school.state && (
          <span className={styles.location}>
            {hit.school.city}, {hit.school.state}
          </span>
        )}
      </div>
      <div className={styles.cardBody}>
        <div>
          <div className={styles.subtitleContainer}>
            <span className={styles.subtitle}>{hit.program.name}</span>
          </div>
          <h4 className={styles.title}>{hit.school.name}</h4>
        </div>
        <ProductFlags flags={hit?.school?.additional} />

        <div className={cn(showMore ? styles.descriptionOpened : styles.descriptionClosed)}>
          <div className={styles.descriptionOverlay} />
          {hit.program.snippet}
        </div>

        <button type="button" className={styles.readMoreBtn} onClick={handleDescriptionToggle}>
          Description {showMore ? '-' : '+'}
        </button>

        {isSingleSubmissionRecommender ? (
          <button data-testid={`recommender-popup-card-button-${position}`} type="button" className={styles.cardBtnCta}>
            Explore Program
          </button>
        ) : (
          <div className={cn(styles.cardCta, isSelected && styles.selectedCardCta)}>
            <p>Select</p>
            <input
              onChange={onChangeHandler}
              checked={isSelected}
              id={hit.program.id}
              type="checkbox"
              data-testid={`recommender-popup-card-checkbox-${position}`}
            />
          </div>
        )}
      </div>
    </button>
  );
};

// We use memo to prevent rerenders when props and state haven't changed
export default memo(RecommenderCard);
