import { FC, MouseEventHandler, RefObject, useState } from 'react';
import { HiOutlineChevronRight } from 'react-icons/hi';

import styles from './SubmitButton.module.css';

type Props = {
  text: string;
  disabled?: boolean | undefined;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  buttonRef?: RefObject<HTMLButtonElement>;
};
const SubmitButton: FC<Props> = ({ text, disabled, buttonRef, onClick = () => {} }) => (
  <>
    <button
      type="button"
      data-testid="continue"
      className={styles.submitButton}
      disabled={disabled}
      onClick={onClick}
      ref={buttonRef}
    >
      {text}
      <HiOutlineChevronRight />
    </button>
  </>
);

export default SubmitButton;
