/* eslint-disable react/jsx-props-no-spreading */
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from '@core/App';
// This is our data store. Anytime a user makes a selection it is recorded here
// and accessible anywhere in the app.
import store from '@core/store';
import determineBaseName from '@core/utils/determineBaseName';

// grab the mount element
// this will be used to pass attributes
// from the element to the app
const mount = document.getElementById('voyager-app') || document.getElementById('voyager');

ReactDOM.createRoot(mount).render(
  <BrowserRouter basename={determineBaseName(mount?.dataset?.basename)}>
    <Provider store={store}>
      <App {...mount?.dataset} />
    </Provider>
  </BrowserRouter>
);

// expose store when run in Cypress
if (window.Cypress) {
  window.disableCohesion = () => {
    window.cohesion = () => {};
  };
  window.ReduxStore = store;
}
