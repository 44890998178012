import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import useSetBaseIndex from '@core/blocks/edu-match/hooks/useSetBaseIndex';
import useAlgoliaClient from '@core/hooks/useAlgoliaClient';
import { selectFilters } from '@core/reducers/configSlice';
import { selectDcs } from '@core/reducers/dcsSlice';
import { setRecommenderPopupListId } from '@core/reducers/eventingSlice';
import { selectAllInputs } from '@core/reducers/inputsSlice';
import { selectBaseIndex } from '@core/reducers/matchesSlice';
import AlgoliaService from '@core/services/AlgoliaService';
import { ValidateZipSuccess, validateZip } from '@core/services/leadDelivery';
import getAllCandidates from '@core/utils/spotlight/getAllCandidates';
import getFacetFilters from '@core/utils/spotlight/getFacetFilters';
import getFilters from '@core/utils/spotlight/getFilters';
import getSpotlightRulesForDcs from '@core/utils/spotlight/getSpotlightRulesForDcs';

const useSpotlightQuery = () => {
  const [state, setState] = useState({
    loading: true,
    data: undefined,
    error: undefined,
  });

  const dcs = useSelector(selectDcs);

  useSetBaseIndex();

  // Filters applied by our config rules engine
  const configFilters = useSelector(selectFilters);
  const inputs = useSelector(selectAllInputs);

  // Algolia + Mobius Data
  const { searchClient } = useAlgoliaClient({ applyQualifications: true });
  const indexName = useSelector(selectBaseIndex);
  const dispatch = useDispatch();

  const filters = getFilters({ dcs, configFilters });
  const facetFilters = getFacetFilters({ dcs, inputs });

  useEffect(() => {
    if (searchClient) {
      const listId = uuid();
      dispatch(setRecommenderPopupListId(listId));

      const algoliaIndex = searchClient.initIndex(indexName);

      const buildAndRunSpotlightQueries = async () => {
        try {
          const rules = getSpotlightRulesForDcs({
            degree: dcs?.dcsDegrees?.[0],
            category: dcs?.dcsCategories?.[0],
            subject: dcs?.dcsSubjects?.[0],
          });

          // We need to know the current state to get geo and geoAndInput candidates
          const { stateAbbr } = (await validateZip(inputs?.zip?.value)) as ValidateZipSuccess;

          const candidates = getAllCandidates({ rules, inputs, currentState: stateAbbr });

          const result = await AlgoliaService.getSpotlightResult({ algoliaIndex, candidates, filters, facetFilters });
          setState({ loading: false, data: result, error: undefined });
        } catch (error) {
          setState({ loading: false, data: undefined, error });
        }
      };

      buildAndRunSpotlightQueries();
    }
  }, [searchClient]);

  return state;
};

export default useSpotlightQuery;
