import { RootState } from '@core/store';
import toKeyValueObject from '@core/utils/objects/toKeyValueObject';

import { ConditionalContinue } from '../types';

// This function will take an input value and run it by a rule
export const validateRule = (rule: { type: string; value?: any }, inputValue: any): boolean => {
  const ruleRegistry: { [key: string]: boolean } = {
    EQUALS: inputValue === rule.value,
    MIN: inputValue?.length >= rule.value,
    EXISTS: inputValue !== undefined,
    IS_FALSE_OR_UNDEFINED: inputValue === false || inputValue === undefined,
    HAS_LENGTH_LESS_THAN: inputValue?.length < rule.value,
    HAS_NO_CURRENT_PROGRAM: inputValue?.currentPrograms?.length === 0,
    CURRENT_PROGRAM_HAS_NO_PRQ_SCHEMA: !inputValue?.currentPrograms?.[0]?.prqSchema,
    NO_SUBMISSIONS: inputValue?.submissions?.length === 0,
  };

  // If the `rule.type` isn't a property of the ruleRegistry, we explicitly return `false`
  return ruleRegistry[rule.type] || false;
};

const getConditionBehavior = (store: RootState, conditions: ConditionalContinue) => {
  const keyValueStoreMap = {
    ...store.inputs?.entities,
    ...toKeyValueObject(store.monarch, store.matches),
  };
  // I no conditions exits, then we explicitly return `true`
  if (!conditions?.length) return true;

  const conditionMet = conditions.find((condition) => {
    // When the operator is 'OR' we use the `Array.some` method, else we use `Array.every` for 'AND'
    const arrayMethodName = condition.operator === 'OR' ? 'some' : 'every';
    return condition.rules?.[arrayMethodName]((rule) => validateRule(rule, keyValueStoreMap[rule.field]?.value));
  });
  // We return the Condition action unless no conditions are met. In that case, we explicitly return `false`
  return conditionMet?.action || false;
};

export default getConditionBehavior;
