import { SearchClient } from 'algoliasearch/lite';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import sortHits from '@core/blocks/edu-match/utils/sortHits';
import { useFeatureFlags } from '@core/context/FeatureFlagsContext';
import useFilterConfig from '@core/hooks/useFilterConfig';
import { DCS } from '@core/reducers/dcsSlice';
import { VoyagerInputs } from '@core/reducers/inputsSlice';
import { setRecommenderMatches } from '@core/reducers/matchesSlice';
// Spotlight
import AlgoliaService from '@core/services/AlgoliaService';
// Recommender, Exact Matches, Related Matches
import AlgoliaServiceV2, { ALGOLIA_BASE_INDEX_NAME, getSearchClient } from '@core/services/AlgoliaService/v2';
import { fetchEnhancedSchoolData } from '@core/services/hub';
import { ValidateZipSuccess, validateZip } from '@core/services/leadDelivery';
import { newRelicNoticeError, nrErrorMap } from '@core/services/newRelic';
import { Hit } from '@core/ts/algolia';
import type { VoyagerRecommenderResult, VoyagerResult, VoyagerResultEnhancedSchoolData } from '@core/ts/results';
import getAllCandidates from '@core/utils/spotlight/getAllCandidates';
import getFilters from '@core/utils/spotlight/getFilters';
import getSpotlightRulesForDcs from '@core/utils/spotlight/getSpotlightRulesForDcs';

import { mapHitsToResults } from '../utils/mapHitstoResults';

enum LoadingValues {
  recommender = 'isRecommenderResultsLoading',
  exact = 'isExactResultsLoading',
  related = 'isRelatedResultsLoading',
}
type UseResults = {
  loading: boolean;
  loadingState: Record<string, boolean>;
  searchClient: any;
  hasDcsConsolidation?: boolean;
  showEmbeddedRecommender: boolean;
  exactResults: VoyagerResult[] | undefined | null;
  relatedResults: VoyagerResult[] | undefined | null;
  enhancedSchoolData: VoyagerResultEnhancedSchoolData;
  setRecommenderResults: Dispatch<
    SetStateAction<
      | {
          spotlight?: VoyagerRecommenderResult | undefined;
          recommender?: VoyagerRecommenderResult | undefined;
        }
      | null
      | undefined
    >
  >;
  recommenderResults:
    | { spotlight?: VoyagerRecommenderResult | null; recommender?: VoyagerRecommenderResult | null }
    | null
    | undefined;
  fetchRecommenderResults: () => Promise<{
    spotlight?: VoyagerRecommenderResult | null;
    recommender?: VoyagerRecommenderResult | null;
  }>;
  fetchExactResults: (recommendedResults: VoyagerRecommenderResult) => Promise<void>;
  fetchRelatedResults: () => Promise<void>;
};

type Params = {
  dcs: DCS;
  inputs: VoyagerInputs;
};

const useResults = ({ dcs, inputs }: Params): UseResults => {
  const dispatch = useDispatch();
  // Results loading state
  const [loadingState, setLoadingState] = useState<Record<string, boolean>>({
    [LoadingValues.recommender]: true,
    [LoadingValues.exact]: true,
    [LoadingValues.related]: true,
  });
  const flags = useFeatureFlags();

  // Results loading flag
  const loading =
    loadingState[LoadingValues.recommender] || loadingState[LoadingValues.exact] || loadingState[LoadingValues.related];
  // DCS values
  const { dcsDegrees, dcsCategories, dcsSubjects } = dcs;

  // Results local state
  const [recommenderResults, setRecommenderResults] = useState<
    | {
        spotlight?: VoyagerRecommenderResult;
        recommender?: VoyagerRecommenderResult;
      }
    | null
    | undefined
  >(null);
  const [exactResults, setExactResults] = useState<VoyagerResult[] | null>(null);
  const [relatedResults, setRelatedResults] = useState<VoyagerResult[] | null>(null);
  const [searchClient, setSearchClient] = useState<SearchClient | null>(null);
  const [enhancedSchoolData, setEnhancedSchoolData] = useState<UseResults['enhancedSchoolData']>({});
  // Flag for showing embedded recommender

  const showEmbeddedRecommender =
    recommenderResults?.recommender?.feature === 'RECOMMENDER' && flags?.voyagerGradResultsPage !== 'test';

  // Filters
  const { configFilters } = useFilterConfig();
  const filters = getFilters({ dcs, configFilters });

  // Max results in recommender query
  const maxResults: number = 2;

  // Bonsai is switching to full traffic so we can remove all Monarch logic that was applied for the test.

  // Name of the Index to query data from
  const indexName: string = ALGOLIA_BASE_INDEX_NAME;

  useEffect(() => {
    (async () => {
      const searchClient = await getSearchClient(inputs);
      setSearchClient(searchClient);
    })();

    if (!enhancedSchoolData?.length) {
      fetchEnhancedSchoolData().then((data) => {
        setEnhancedSchoolData(
          data?.reduce((acc, curr) => {
            acc[curr.sdbId] = curr;
            return acc;
          }, {} as VoyagerResultEnhancedSchoolData)
        );
      });
    }
  }, []);

  // Helper function for activating loading state
  const load = (promise: Promise<unknown>, key: string) => {
    setLoadingState((state) => ({ ...state, [key]: true }));
    promise
      .catch((e) => {
        newRelicNoticeError(nrErrorMap.VOYAGER_RESULTS_LOAD_ERROR, e);
      })
      .finally(() => setLoadingState((state) => ({ ...state, [key]: false })));
  };

  // Function for getting results for recommender popup (recommended & spotlight)
  const fetchRecommenderResults = async () => {
    const searchClient = await getSearchClient(inputs);
    const index = searchClient?.initIndex(ALGOLIA_BASE_INDEX_NAME);
    const rules = getSpotlightRulesForDcs({
      degree: dcs?.dcsDegrees?.[0],
      category: dcs?.dcsCategories?.[0],
      subject: dcs?.dcsSubjects?.[0],
    });

    setLoadingState((state) => ({ ...state, [LoadingValues.recommender]: true }));
    // We need to know the current state to get geo and geoAndInput candidates
    const { stateAbbr } = (await validateZip(inputs?.zip?.value)) as ValidateZipSuccess;
    // Get spotlight results if any
    const spotlightResult = await AlgoliaService.getSpotlightResult({
      algoliaIndex: index,
      candidates: getAllCandidates({ rules, inputs, currentState: stateAbbr }),
      filters,
      facetFilters: [],
    });

    // Get recommender results and apply Bonsai Logic
    const recommendedResults = await AlgoliaServiceV2.getRecommenderResults({
      dcs,
      inputs,
      configFilters,
      hitsPerPage: 51,
      indexName,
    })
      .then(async (data) => {
        let aggregatedResults: Hit[] = [...(data as Hit[])];
        // Filter out TwoU programs
        const twoUPrograms = aggregatedResults.filter(({ program: { providerName } }) => providerName === 'TwoU');

        // Filter out non TwoU programs and sort them based on eRPI
        const nonTwoUPrograms = await sortHits(
          aggregatedResults.filter(({ program: { providerName } }) => providerName !== 'TwoU'),
          inputs?.zip?.value
        );
        // concatentate both arrays once the non TwoU programs have been sorted
        aggregatedResults = [...twoUPrograms, ...nonTwoUPrograms];

        // truncate to limit length
        return aggregatedResults.slice(0, maxResults);
      })
      .then(mapHitsToResults);

    const results: { spotlight?: VoyagerRecommenderResult; recommender?: VoyagerRecommenderResult } = {
      spotlight: spotlightResult
        ? {
            eventing: {
              location: 'voyager-spotlight-pop-up',
              customDimensions: spotlightResult.eventing.customDimensions as any,
            },
            data: [spotlightResult.hit],
            feature: 'SPOTLIGHT',
          }
        : undefined,
      recommender: recommendedResults?.length
        ? {
            eventing: { location: 'voyager-recommender-pop-up' },
            data: recommendedResults,
            feature: 'RECOMMENDER',
          }
        : undefined,
    };
    setRecommenderResults(results);
    dispatch(setRecommenderMatches(results?.recommender?.data));

    setLoadingState((state) => ({ ...state, [LoadingValues.recommender]: false }));

    return results;
  };

  /**
   * Function for getting Exact Matches
   * @param {VoyagerResult[]} excludeResults List of results we want to exclude from the exact matches (e.g. Embedded Recommender)
   * @returns
   */
  const fetchExactResults = async (recommenderResults: VoyagerRecommenderResult) => {
    // Results from embedded recommender that we need to exclude
    const excludeResultsFilter = (
      recommenderResults?.feature === 'RECOMMENDER' ? recommenderResults?.data ?? [] : []
    )?.map((hit) => `NOT program.id:${hit.program.id}`);
    load(
      AlgoliaServiceV2.getExactResults({
        dcs,
        inputs,
        configFilters: [...configFilters, ...excludeResultsFilter],
      })
        /* Sort Hits based on eRPI - higher eRPI should be showed first */
        .then((data) => sortHits(data as Hit[], inputs?.zip?.value))
        .then(mapHitsToResults)
        .then(setExactResults as unknown as (value: VoyagerResult[]) => void | PromiseLike<void>),
      LoadingValues.exact
    );
  };
  // Function for getting related matches
  const fetchRelatedResults = async () =>
    load(
      AlgoliaServiceV2.getRelatedResults({
        degrees: dcsDegrees,
        subjects: dcsSubjects,
        inputs,
        configFilters,
      })
        .then((data) => mapHitsToResults(data as Hit[]))
        .then(setRelatedResults as unknown as (value: VoyagerResult[]) => void | PromiseLike<void>),
      LoadingValues.related
    );
  return {
    loadingState,
    loading,
    searchClient,
    exactResults,
    relatedResults,
    recommenderResults,
    fetchRecommenderResults,
    setRecommenderResults,
    fetchExactResults,
    fetchRelatedResults,
    showEmbeddedRecommender,
    enhancedSchoolData,
  };
};
export default useResults;
