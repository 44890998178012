/**
 * determineBaseName
 *
 * A utility to set the root basename of the app for routing purposes.
 */

const determineBaseName = (basenameAttr: string | null): string => {
  // look on window for voyagerAppExperienceUrl and use that if it's set
  const windowPath = window?.HE?.publisher?.voyagerAppExperienceUrl;
  if (windowPath) {
    return windowPath;
  }

  // if a basenameAttr is passed in, return that
  if (basenameAttr && basenameAttr !== '') {
    return basenameAttr;
  }

  // if nothing is passed, return empty string
  return '';
};

export default determineBaseName;
