import { FC, useRef, useState } from 'react';
import { HiOutlineInformationCircle } from 'react-icons/hi';

import useWindowEvent from '@core/hooks/useWindowEvent';
import AdvertiserDisclosure from '@core/shared/components/Tooltip/advertiserDisclosure';

import styles from './SponsoredResultsPopover.module.css';

type Props = {
  position?: 'below' | 'above';
  spanText?: 'Sponsored' | 'Learn More About Our Partners';
};

const SponsoredResultsPopover: FC<Props> = ({ position = 'below', spanText = 'Sponsored' }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const togglePopover = () => setIsOpen(!isOpen);
  const ref = useRef<HTMLButtonElement>(null);

  // listen for clicks on the window
  // if the element target that was clicked
  // is not the popover, then close the popover
  useWindowEvent('mousedown', (event: MouseEvent) => {
    const { target } = event;
    if (!isOpen || (ref.current && ref.current.contains(target as Node))) return;
    togglePopover();
  });

  return (
    <button type="button" onClick={togglePopover} className={styles.sponsoredResultsPopoverWrapper} ref={ref}>
      <AdvertiserDisclosure isOpen={isOpen} toggleOpen={togglePopover} position={position} />
      <span className={styles.sponsoredResults}>{spanText}</span>
      <HiOutlineInformationCircle className={styles.infoIcon} onClick={togglePopover} />
    </button>
  );
};

export default SponsoredResultsPopover;
