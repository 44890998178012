import { MonarchRequest, MonarchParams } from './monarch';

/**
 * @defaults publisher is always passed to every test (e.g. bestcolleges.com)
 * @param state Voyager state
 * @returns Monarch Requests with custom state values
 */
export default ({
  slug,
  state: {
    dcs: {
      dcsDegrees: [degree],
    },
    inputs: {
      entities: { is18YearsOrOlder },
    },
  },
}: MonarchParams): MonarchRequest[] => [
  {
    condition: slug === '/results',
    type: 'rule',
    id: 'bonsaiABTest',
  },
  {
    condition: true,
    type: 'rule',
    id: 'voyagerEmailConsentBox',
  },
  {
    condition: ["Bachelor's", "Associate's"].includes(degree),
    type: 'rule',
    id: 'voyagerProgramCardCopyCtaTest',
  },
  {
    condition: ["Master's", 'Doctorate'].includes(degree),
    type: 'rule',
    id: 'voyagerRPBannerTest',
  },
];
