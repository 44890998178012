import { type FC } from 'react';

// import Fields from '../index';
import type { FieldComponentProps } from '../types';

const SectionField: FC<FieldComponentProps> = ({ field }) => (
  <div>
    <h2>{field.heading}</h2>

    {/* <Fields fields={field.fields} /> */}
  </div>
);

export default SectionField;
