import { type Dispatch, type FC, type SetStateAction, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { CompactCard } from '@core/blocks/edu-match/components';
import Dots from '@core/blocks/edu-match/components/Loading/Dots';
import { useFeatureFlags } from '@core/context/FeatureFlagsContext';
import useFormEvents from '@core/hooks/cohesion/useFormEvents';
import useSchoolConfig from '@core/hooks/useSchoolConfig';
import { selectDcs } from '@core/reducers/dcsSlice';
import { selectFormContext, selectHeclidMap, selectProductCorrelationIdMap } from '@core/reducers/eventingSlice';
import { selectAllInputs } from '@core/reducers/inputsSlice';
import {
  addSubmission,
  removeProgramFromList,
  selectLeadDelivery,
  selectSubmissions,
} from '@core/reducers/matchesSlice';
import { postGetLeadSchema, submitLead } from '@core/services/leadDelivery';
import { PageActions } from '@core/services/newRelic';
import { getSchoolConfig } from '@core/services/schoolConfig';
import { VoyagerResult } from '@core/ts/results';
import cn from '@core/utils/classNames';
import Diffy from '@core/utils/diffy';
import { phoneValidationFeatureFlagWrapper } from '@core/utils/functionWrappers/phoneValidationFeatureFlagWrapper';
import timer from '@core/utils/timer';

import { selectContactId } from '../../../../reducers/contactSlice';
import PostResultForm from '../../PostResultForm';
import SubmissionStatus from '../../SubmissionStatus';
import TcpaSection from '../../TcpaSection';
import styles from './SubmissionQueue.module.css';

type Props = {
  setRecommenderStep: Dispatch<SetStateAction<string>>;
  closePopup: () => void;
  layout?: string;
  setPiiLeadErrors: Dispatch<SetStateAction<{ field: string }[]>>;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  piiLeadErrors: { field: string }[];
};

const SubmissionQueue: FC<Props> = ({
  setRecommenderStep,
  closePopup,
  layout = 'horizontal',
  setPiiLeadErrors,
  piiLeadErrors,
  setEditMode,
}) => {
  const [tcpa, setTcpa] = useState(undefined);
  const [tcpaLoading, setTcpaLoading] = useState(true);
  const [currentProgram, setCurrentProgram] = useState<VoyagerResult | undefined>(undefined);
  const [showPostResultForm, setShowPostResultForm] = useState(false);
  const [currentSchema, setCurrentSchema] = useState(undefined);
  const [currentPrqSchema, setCurrentPrqSchema] = useState(undefined);
  const [submissionStatus, setSubmissionStatus] = useState<string | undefined>(undefined);

  const dispatch = useDispatch();
  const inputs = useSelector(selectAllInputs);
  const heclidMap = useSelector(selectHeclidMap);
  const submissions = useSelector(selectSubmissions);
  const { currentPrograms } = useSelector(selectLeadDelivery);
  const productCorrelationIdMap = useSelector(selectProductCorrelationIdMap);
  const { dcsDegrees } = useSelector(selectDcs);
  const formContext = useSelector(selectFormContext);
  const isMastersOrDoctorate = ['Doctorate', "Master's"].includes(dcsDegrees?.[0]);
  const flags = useFeatureFlags();
  const formCorrelationId = useMemo(() => uuid(), []);
  const schoolConfig = useSchoolConfig(currentProgram?.school?.id);
  const { formViewed, formStarted, formSubmitted } = useFormEvents({
    correlationId: formCorrelationId,
    formContext: {
      ...formContext,
      formVersion: 'dynamic-post-flow',
      formType: 'post-flow',
      formBrand: currentProgram?.school?.slug,
      formId: '1015',
    },
    stepContext: {},
  });

  const onMount = () => {
    formViewed();
  };

  const onFirstInteraction = () => {
    formStarted();
  };

  const onSubmit = () => {
    formSubmitted();
  };

  const { formErrored } = useFormEvents({
    formContext,
    errorDetails: 'INVALID',
    errorMessage: 'Please provide your valid phone number.',
    errorType: 'Phone Validation',
  });
  const contactId = useSelector(selectContactId);

  const triggerLeadSubmission = async (hit: VoyagerResult) => {
    setPiiLeadErrors([]);
    const actionParams = {
      ...flags,
      anonymousId: window._Cohesion.anonymousId,
      correlationId: productCorrelationIdMap[hit?.program?.id],
    };
    PageActions.LeadAttempt(actionParams);

    const leadInputs = {
      ...inputs,
      tcpaText: { key: 'tcpaText', value: schoolConfig?.data?.tcpa },
    };
    const res = await phoneValidationFeatureFlagWrapper({
      wrappedFunction: () =>
        submitLead(hit, leadInputs, heclidMap[hit.program.id], productCorrelationIdMap[hit.program.id], contactId),
      inputs: leadInputs,
      flag: !!flags?.phoneValidation,
    });
    if (res?.message === 'invalid_phone_line_type_intelligence') {
      setPiiLeadErrors((state) => [...state, { field: 'phone' }]);
      setEditMode(true);
      setSubmissionStatus('');
      formErrored();
      setRecommenderStep('PII_CONFIRMATION');
      return;
    }
    if (res.message) {
      setSubmissionStatus('ERROR');
    } else {
      PageActions.LeadSuccess(actionParams);
      setSubmissionStatus('SUCCESS');
      dispatch(addSubmission(hit));
    }
    // Gives the user some time to see the submission status
    await timer(2000);
    dispatch(removeProgramFromList(hit));
  };

  const diffy = new Diffy(inputs);

  useEffect(() => {
    setSubmissionStatus('LOADING');
    setShowPostResultForm(false);

    const processQueue = async () => {
      const submissionQueue = [];
      const prqQueue = [];

      for (const current of currentPrograms) {
        const schema = await postGetLeadSchema({
          schoolId: current?.school?.id,
          programId: current?.program?.id,
          body: {
            lead: {
              location: {
                postalCode: inputs?.zip?.value,
              },
            },
            trackingContext: {
              correlationId: productCorrelationIdMap[current?.program?.id],
              publisher: window.location.host,
              heclid: heclidMap[current?.program?.id],
              anonymousId: window._Cohesion.anonymousId,
              url: inputs?.sourceUrl?.value,
              experience: 'Voyager',
            },
          },
        });

        const prqSchema = diffy.diffLdsSchema(schema, current?.leadSource);
        setCurrentSchema(schema);
        setCurrentPrqSchema(prqSchema);

        if (!prqSchema) {
          submissionQueue.push(current);
        } else {
          prqQueue.push(current);
        }
      }
      if (submissionQueue?.length > 0) {
        setCurrentProgram(submissionQueue[0]);
        triggerLeadSubmission(submissionQueue[0]);
      } else if (prqQueue?.length > 0 && piiLeadErrors?.length === 0) {
        setCurrentProgram(prqQueue[0]);
        setShowPostResultForm(true);
      } else if (submissions.length === 1) {
        setRecommenderStep('EXIT_STRATEGY');
      } else if (closePopup) closePopup();
    };

    processQueue();
  }, [currentPrograms.length]);

  // when currentProgram is set use getSchoolConfig to get tcpa
  useEffect(() => {
    if (currentProgram) {
      const getTcpa = async () => {
        const schoolConfig = await getSchoolConfig(currentProgram?.school?.id);
        setTcpa({ ...schoolConfig, providerName: currentProgram?.program?.providerName });
        setTcpaLoading(false);
      };
      getTcpa();
    }
  }, [currentProgram]);

  if (!currentProgram || tcpaLoading) return <Dots />;

  return (
    <section className={cn(styles.recommenderPostResultForm, layout === 'vertical' && styles.verticalLayout)}>
      {showPostResultForm ? (
        <>
          <header>
            {!isMastersOrDoctorate && currentPrograms?.length > 1 ? (
              <>Your information was successfully submitted to the other schools.</>
            ) : (
              <></>
            )}
            &nbsp;
            {currentProgram?.school.name} requires some additional info before submitting.
          </header>
          <div className={styles.programInfo}>
            <CompactCard hit={currentProgram} showClose={false} />
          </div>
          <div className={styles.form}>
            <PostResultForm
              originalSchema={currentSchema}
              prqSchema={currentPrqSchema}
              hit={currentProgram}
              setPiiLeadErrors={setPiiLeadErrors}
              setEditMode={setEditMode}
              setStep={() => {
                setRecommenderStep('PII_CONFIRMATION');
              }}
              formCorrelationId={formCorrelationId}
              onFormError={formErrored}
              onMount={onMount}
              onFirstInteraction={onFirstInteraction}
              onSubmit={onSubmit}
            />
          </div>
          <div className={styles.tcpa}>
            <TcpaSection tcpa={tcpa ? [tcpa] : null} />
          </div>
        </>
      ) : (
        <>
          <div className={styles.programInfo}>
            <CompactCard hit={currentProgram} showClose={false} />
          </div>
          <div className={styles.form}>
            <SubmissionStatus status={submissionStatus} schoolName={currentProgram?.school.name} />
          </div>
        </>
      )}
    </section>
  );
};

export default SubmissionQueue;
