import { track } from '@core/services/cohesion';
import type { CohesionEventTypes, UsePageEvents } from '@core/ts/cohesion';

const usePageEvents = (): UsePageEvents => {
  const triggerPageEvent = (eventName: string) => {
    track({ eventName, properties: {} });
  };

  const buildPageEventMethod = (eventName: CohesionEventTypes) => () => triggerPageEvent(eventName);

  return {
    pageViewed: buildPageEventMethod('PageViewed'),
  };
};

export default usePageEvents;
