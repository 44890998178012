/* eslint-disable react/style-prop-object */
const Image = () => (
  <svg viewBox="0 0 201 141" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.7">
      <path
        d="M16.9861 116.699L79.599 114.802C83.8599 114.673 87.2094 111.112 87.0802 106.848L85.0798 40.8172C84.9506 36.5532 81.3917 33.2011 77.1308 33.3302L14.5179 35.2271C10.257 35.3562 6.90746 38.9175 7.03664 43.1815L9.03708 109.212C9.16626 113.476 12.7252 116.828 16.9861 116.699Z"
        fill="#C9C9C7"
        // style="mix-blend-mode:multiply"
      />
    </g>
    <path
      d="M17.1249 114.474L79.3918 107.632C83.6292 107.166 86.6866 103.351 86.2206 99.1108L79.0053 33.4449C78.5394 29.2045 74.7265 26.1443 70.4892 26.6099L8.22228 33.4518C3.98494 33.9174 0.927529 37.7325 1.39346 41.9729L8.60879 107.639C9.07472 111.879 12.8876 114.939 17.1249 114.474Z"
      fill="white"
      stroke="#71717A"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path d="M45.9556 60.9008L70.8535 58.1651L68.4817 36.5791L43.5837 39.3149L45.9556 60.9008Z" fill="#E9E8E5" />
    <path
      d="M46.4011 47.79L57.4458 50.8016L67.572 45.4638L56.5284 42.4521L46.4011 47.79Z"
      fill="#C68C8C"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path d="M66.5583 50.976L66.0791 46.6145" stroke="#52525B" strokeWidth="1.06785" strokeMiterlimit="10" />
    <path
      d="M66.2508 54.1093L67.5372 53.9679L66.5569 50.9758L66.2496 54.1094L66.2508 54.1093Z"
      fill="white"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M62.5859 46.2444C62.5859 46.2444 61.5642 45.3768 56.9048 45.8888C52.2454 46.4008 51.4365 47.4694 51.4365 47.4694L51.9635 52.2658L63.1118 51.0408L62.6763 47.0774L62.5859 46.2444Z"
      fill="#C68C8C"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M57.6692 52.834C60.7481 52.4957 63.1859 51.6927 63.1142 51.0404C63.0425 50.3882 60.4885 50.1337 57.4097 50.472C54.3309 50.8103 51.8931 51.6133 51.9647 52.2655C52.0364 52.9178 54.5904 53.1723 57.6692 52.834Z"
      fill="#C68C8C"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M56.1451 57.9898L41.7382 51.9662L28.9819 60.9745L27.5993 61.1264L27.8705 63.5952L42.8353 61.9508L57.8001 60.3065L57.5289 57.8378L56.1451 57.9898Z"
      fill="#E7D2D2"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M18.4763 80.5413C20.066 80.3666 21.5165 81.6867 21.7147 83.4904L22.834 93.6776L17.0749 94.3104L15.9555 84.1232C15.7573 82.3195 16.8854 80.7161 18.4763 80.5413Z"
      fill="#B7C5D9"
    />
    <path
      d="M26.1149 79.7024C27.7046 79.5277 29.1551 80.8478 29.3532 82.6504L30.4719 92.8319L24.7128 93.4647L23.594 83.2832C23.396 81.4806 24.5241 79.8772 26.1149 79.7024Z"
      fill="#B7C5D9"
    />
    <path
      d="M14.048 94.8581L32.4568 92.8353L30.6588 76.4719L12.25 78.4947L14.048 94.8581Z"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M12.533 74.3237L30.2091 72.3815L30.6586 76.4723L10.2044 78.7198L12.533 74.3237Z"
      fill="#E7D2D2"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M63.0222 75.6467C61.4324 75.8214 60.3032 77.4249 60.5014 79.2287L61.6208 89.4158L67.3799 88.783L66.2606 78.5958C66.0624 76.7921 64.613 75.4719 63.0222 75.6467Z"
      fill="#B7C5D9"
    />
    <path
      d="M70.6608 74.8075C69.0711 74.9822 67.9419 76.5857 68.1401 78.3894L69.2594 88.5766L75.0186 87.9438L73.8992 77.7566C73.701 75.9529 72.2517 74.6327 70.6608 74.8075Z"
      fill="#B7C5D9"
    />
    <path
      d="M76.3395 71.4527L57.9307 73.4754L59.7287 89.8388L78.1375 87.816L76.3395 71.4527Z"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M75.1642 67.4415L57.4814 69.3845L57.9309 73.4753L78.3852 71.2278L75.1642 67.4415Z"
      fill="#E7D2D2"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M32.4568 92.8356L59.7291 89.8389L56.5077 60.5212L29.2354 63.5179L32.4568 92.8356Z"
      fill="white"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M61.5494 87.5687L31.5498 90.865L31.7746 92.9104L61.7741 89.6141L61.5494 87.5687Z"
      fill="white"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path d="M54.0113 63.5542L32.2725 65.9428L33.2548 74.8829L54.9937 72.4943L54.0113 63.5542Z" fill="#B7C5D9" />
    <path
      d="M52.3867 85.8152L55.114 85.5155L54.0651 75.9702L51.3379 76.2698L52.3867 85.8152Z"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M50.6554 76.3445L54.7463 75.895L54.5215 73.8496L50.4307 74.2991L50.6554 76.3445Z"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M55.8025 86.4493L55.6997 85.4512L51.7997 85.8797L51.9093 86.8771L51.1584 87.2027L51.3221 88.6924L56.7766 88.093L56.6129 86.6034L55.8025 86.4493Z"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M44.2051 86.714L46.9323 86.4143L45.8835 76.869L43.1562 77.1686L44.2051 86.714Z"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M42.4747 77.2433L46.5656 76.7938L46.3409 74.7484L42.25 75.1979L42.4747 77.2433Z"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M47.6209 87.3481L47.518 86.35L43.6181 86.7786L43.7276 87.7759L42.9768 88.1015L43.1405 89.5912L48.595 88.9919L48.4313 87.5022L47.6209 87.3481Z"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M36.0215 87.6136L38.7487 87.3139L37.6999 77.7686L34.9727 78.0683L36.0215 87.6136Z"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M34.2912 78.1429L38.382 77.6934L38.1573 75.648L34.0664 76.0975L34.2912 78.1429Z"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M39.4383 88.2475L39.3354 87.2495L35.4354 87.678L35.545 88.6753L34.7942 89.001L34.9579 90.4907L40.4123 89.8913L40.2486 88.4017L39.4383 88.2475Z"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M42.6881 60.6048C44.2322 60.4351 45.3463 59.0449 45.1765 57.4997C45.0067 55.9545 43.6173 54.8394 42.0732 55.009C40.5291 55.1787 39.415 56.5689 39.5848 58.1141C39.7546 59.6593 41.144 60.7744 42.6881 60.6048Z"
      fill="white"
    />
    <path
      d="M55.7352 72.3364L54.7125 72.4488L43.4976 67.9778L33.5765 74.7712L32.5538 74.8836L32.7411 76.5881L33.4229 76.5132L44.3318 75.3145L55.2407 74.1159L55.9225 74.0409L55.7352 72.3364Z"
      fill="#E7D2D2"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M23.3564 97.2856L47.8308 94.5964L48.4301 100.051L23.9557 102.74C22.7875 102.868 21.7053 101.751 21.5399 100.245C21.3745 98.7398 22.1882 97.414 23.3564 97.2856Z"
      fill="#C68C8C"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M47.939 98.7249L34.0697 100.249C33.6345 100.297 33.2459 100.024 33.2038 99.6412L33.0568 98.3028C33.0147 97.9201 33.3348 97.5695 33.77 97.5216L47.6393 95.9977"
      fill="white"
    />
    <path
      d="M47.939 98.7249L34.0697 100.249C33.6345 100.297 33.2459 100.024 33.2038 99.6412L33.0568 98.3028C33.0147 97.9201 33.3348 97.5695 33.77 97.5216L47.6393 95.9977"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M24.121 91.681L48.5954 88.9918L49.1947 94.4462L24.7204 97.1355C23.5522 97.2638 22.47 96.1463 22.3045 94.6407C22.1391 93.1352 22.9528 91.8094 24.121 91.681Z"
      fill="#C68C8C"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M48.7037 93.1204L34.7985 94.6483C34.383 94.694 34.0105 94.4195 33.9685 94.0368L33.8214 92.6983C33.7794 92.3156 34.0834 91.9667 34.4988 91.9211L48.404 90.3932"
      fill="white"
    />
    <path
      d="M48.7037 93.1204L34.7985 94.6483C34.383 94.694 34.0105 94.4195 33.9685 94.0368L33.8214 92.6983C33.7794 92.3156 34.0834 91.9667 34.4988 91.9211L48.404 90.3932"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <g opacity="0.7">
      <path
        d="M55.8161 99.4401L122.442 133.671C126.976 136.001 132.541 134.211 134.872 129.674L170.972 59.4115C173.303 54.8742 171.517 49.3075 166.983 46.978L100.358 12.7467C95.824 10.4172 90.2586 12.207 87.9274 16.7443L51.8275 87.0067C49.4964 91.5439 51.2821 97.1106 55.8161 99.4401Z"
        fill="#C9C9C7"
        // style="mix-blend-mode:multiply"
      />
    </g>
    <path
      d="M57.2482 97.1911L126.372 126.047C131.076 128.011 136.482 125.786 138.447 121.079L168.878 48.1822C170.844 43.4748 168.623 38.0667 163.919 36.1031L94.7957 7.24711C90.0917 5.28342 84.6852 7.50767 82.7201 12.215L52.289 85.1119C50.3239 89.8193 52.5442 95.2274 57.2482 97.1911Z"
      fill="white"
      stroke="#71717A"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path d="M118.409 57.7998L146.049 69.3381L156.052 45.3752L128.413 33.8369L118.409 57.7998Z" fill="#E9E8E5" />
    <path
      d="M126.459 44.3363L136.276 53.8765L149.961 54.1474L140.145 44.6076L126.459 44.3363Z"
      fill="#C68C8C"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path d="M145.712 59.33L147.733 54.4881" stroke="#52525B" strokeWidth="1.06785" strokeMiterlimit="10" />
    <path
      d="M143.578 62.4313L145.006 63.0275L145.711 59.3291L143.577 62.4308L143.578 62.4313Z"
      fill="white"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M144.292 52.0803C144.292 52.0803 143.724 50.5814 138.552 48.4221C133.379 46.2629 131.915 46.9134 131.915 46.9134L129.692 52.2379L142.068 57.4043L143.905 53.0045L144.292 52.0803Z"
      fill="#C68C8C"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M135.334 56.1327C138.752 57.5595 141.768 58.1292 142.07 57.4052C142.372 56.6811 139.847 54.9375 136.429 53.5107C133.011 52.0839 129.995 51.5142 129.693 52.2382C129.391 52.9623 131.916 54.7059 135.334 56.1327Z"
      fill="#C68C8C"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M130.757 60.6471L119.163 46.0097L100.603 48.059L99.0677 47.4183L97.9236 50.1589L114.536 57.0939L131.149 64.0289L132.293 61.2883L130.757 60.6471Z"
      fill="#E7D2D2"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M78.2895 62.4603C80.0542 63.197 80.8087 65.4175 79.9728 67.4199L75.2518 78.7289L68.8585 76.0599L73.5795 64.7509C74.4153 62.7486 76.5234 61.7231 78.2895 62.4603Z"
      fill="#B7C5D9"
    />
    <path
      d="M86.7689 66.0007C88.5337 66.7374 89.2882 68.9579 88.4528 70.959L83.7345 82.2617L77.3411 79.5927L82.0595 68.29C82.8948 66.2889 85.0029 65.2634 86.7689 66.0007Z"
      fill="#B7C5D9"
    />
    <path
      d="M65.3738 74.882L85.8098 83.4131L93.393 65.2478L72.957 56.7167L65.3738 74.882Z"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M75.666 52.5151L95.2887 60.7067L93.3929 65.248L70.6862 55.769L75.666 52.5151Z"
      fill="#E7D2D2"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M127.741 83.1039C125.976 82.3672 123.867 83.3922 123.031 85.3946L118.31 96.7036L124.703 99.3725L129.424 88.0635C130.26 86.0612 129.507 83.8412 127.741 83.1039Z"
      fill="#B7C5D9"
    />
    <path
      d="M136.22 86.6439C134.455 85.9072 132.346 86.9322 131.51 88.9346L126.789 100.244L133.182 102.912L137.903 91.6035C138.739 89.6011 137.986 87.3812 136.22 86.6439Z"
      fill="#B7C5D9"
    />
    <path
      d="M144.105 86.4177L123.669 77.8866L116.086 96.0519L136.522 104.583L144.105 86.4177Z"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M145.194 81.5398L125.564 73.3452L123.669 77.8865L146.375 87.3655L145.194 81.5398Z"
      fill="#E7D2D2"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M85.8099 83.4135L116.085 96.0521L129.672 63.5059L99.3965 50.8673L85.8099 83.4135Z"
      fill="white"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M119.304 94.7292L86.001 80.8267L85.0531 83.0974L118.356 96.9999L119.304 94.7292Z"
      fill="white"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path d="M125.306 65.2365L101.173 55.1622L97.0298 65.0868L121.163 75.1611L125.306 65.2365Z" fill="#B7C5D9" />
    <path
      d="M110.729 87.5941L113.756 88.8579L118.18 78.2615L115.152 76.9976L110.729 87.5941Z"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M114.395 76.6813L118.936 78.5771L119.884 76.3064L115.343 74.4106L114.395 76.6813Z"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M113.937 90.2336L114.407 89.1296L110.078 87.3222L109.615 88.4294L108.641 88.3359L107.951 89.9896L114.006 92.5173L114.696 90.8636L113.937 90.2336Z"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M101.646 83.8022L104.673 85.066L109.097 74.4696L106.069 73.2057L101.646 83.8022Z"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M105.312 72.8895L109.853 74.7853L110.801 72.5146L106.26 70.6188L105.312 72.8895Z"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M104.854 86.4418L105.324 85.3378L100.995 83.5304L100.532 84.6375L99.5583 84.5441L98.868 86.1978L104.923 88.7255L105.613 87.0718L104.854 86.4418Z"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M92.5618 80.0107L95.5894 81.2746L100.013 70.6782L96.9854 69.4143L92.5618 80.0107Z"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M96.2289 69.0981L100.77 70.9939L101.718 68.7232L97.1768 66.8275L96.2289 69.0981Z"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M95.7714 82.6505L96.2411 81.5465L91.9117 79.7392L91.4495 80.8463L90.4753 80.7528L89.785 82.4065L95.8401 84.9342L96.5304 83.2805L95.7714 82.6505Z"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M115.16 55.5998C116.874 56.3154 118.844 55.5049 119.56 53.7895C120.276 52.0741 119.467 50.1035 117.753 49.3879C116.039 48.6723 114.069 49.4828 113.353 51.1982C112.637 52.9136 113.446 54.8843 115.16 55.5998Z"
      fill="white"
    />
    <path
      d="M122.029 75.4246L120.894 74.9506L111.743 63.7846L97.4301 65.1557L96.2947 64.6817L95.5048 66.574L96.2617 66.8899L108.372 71.9454L120.482 77.0008L121.239 77.3168L122.029 75.4246Z"
      fill="#E7D2D2"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M73.7115 82.8069L100.881 94.1489L98.3532 100.204L71.1838 88.862C69.8869 88.3206 69.4007 86.5251 70.0984 84.8538C70.7961 83.1825 72.4147 82.2655 73.7115 82.8069Z"
      fill="#C68C8C"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M98.6069 98.5326L83.2103 92.1052C82.7272 91.9035 82.4782 91.3933 82.6556 90.9684L83.2759 89.4826C83.4532 89.0577 83.991 88.876 84.4742 89.0776L99.8707 95.505"
      fill="white"
    />
    <path
      d="M98.6069 98.5326L83.2103 92.1052C82.7272 91.9035 82.4782 91.3933 82.6556 90.9684L83.2759 89.4826C83.4532 89.0577 83.991 88.876 84.4742 89.0776L99.8707 95.505"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M77.7535 77.3834L104.923 88.7254L102.395 94.7805L75.2258 83.4385C73.9289 82.8971 73.4427 81.1016 74.1404 79.4303C74.8381 77.7589 76.4566 76.842 77.7535 77.3834Z"
      fill="#C68C8C"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      d="M102.648 93.1089L87.2115 86.6649C86.7503 86.4724 86.5192 85.9696 86.6966 85.5448L87.3169 84.0589C87.4942 83.6341 88.0142 83.4448 88.4754 83.6374L103.912 90.0814"
      fill="white"
    />
    <path
      d="M102.648 93.1089L87.2115 86.6649C86.7503 86.4724 86.5192 85.9696 86.6966 85.5448L87.3169 84.0589C87.4942 83.6341 88.0142 83.4448 88.4754 83.6374L103.912 90.0814"
      stroke="#52525B"
      strokeWidth="1.06785"
      strokeMiterlimit="10"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M146.794 10.1365C138.445 9.94632 130.537 13.0294 124.516 18.8227L146.794 10.1365ZM124.516 18.8227C112.091 30.7817 111.698 50.7 123.648 63.22C129.437 69.2864 137.22 72.7289 145.57 72.9191C149.119 73.0007 152.591 72.4891 155.869 71.4304L156.218 71.3176C160.517 69.8722 164.478 67.4801 167.849 64.2351C173.869 58.4418 177.274 50.6358 177.438 42.2477C177.601 33.8594 174.505 25.9043 168.716 19.8377C162.928 13.7713 155.145 10.3288 146.794 10.1365M155.597 73.1848L155.601 73.1963L156.36 72.951C161.025 71.4442 165.32 68.8845 168.955 65.3848C175.282 59.2967 178.862 51.088 179.033 42.2788C179.205 33.4698 175.951 25.1074 169.871 18.736C163.791 12.3643 155.606 8.74315 146.831 8.54109C138.056 8.34117 129.736 11.5847 123.409 17.6728C110.348 30.2445 109.94 51.1696 122.494 64.3218C128.574 70.6934 136.758 74.3146 145.533 74.5145C148.987 74.5939 152.374 74.1384 155.597 73.1848Z"
      fill="#3F3F46"
    />
    <path
      d="M156.05 84.0126C155.372 81.9131 156.551 79.6527 158.684 78.9638C160.817 78.2749 163.096 79.4185 163.774 81.518L172.544 108.672C173.222 110.771 172.043 113.032 169.91 113.72C167.777 114.409 165.498 113.266 164.82 111.166L156.05 84.0126Z"
      fill="#3F3F46"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M160.722 89.6779L155.317 72.9432L157.892 72.1117L163.297 88.8464L160.722 89.6779Z"
      fill="#3F3F46"
    />
  </svg>
);

export default Image;
