import { ChangeEvent, FC, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';

import { getCookies } from '@core/blocks/edu-flow/utils/getCookies';
import { useFeatureFlags } from '@core/context/FeatureFlagsContext';
import useElementEvents from '@core/hooks/cohesion/useElementEvents';
import { setInputs, selectAllInputs } from '@core/reducers/inputsSlice';
import { validateEmail, validatePhone } from '@core/services/leadDelivery';
import ErrorMessage from '@core/shared/components/ErrorMessage';
import cn from '@core/utils/classNames';

import styles from './PiiForm.module.css';
import type { PiiFormSectionTypes } from './type';

const PiiFormSection: FC<PiiFormSectionTypes> = ({
  setEditMode,
  editMode,
  location,
  header = 'Your Information',
  piiLeadErrors = [],
}) => {
  // Action Dispatcher
  const dispatch = useDispatch();

  const formInputs = useSelector(selectAllInputs);

  // Cookies
  const localInput = getCookies();

  // flags
  const flags = useFeatureFlags();

  // First Name State
  const [firstName, setFirstName] = useState(formInputs.firstName?.value);
  // Last Name State
  const [lastName, setLastName] = useState(formInputs.lastName?.value);
  // Email State
  const [email, setEmail] = useState(formInputs.email?.value);
  // Phone State
  const [phone, setPhone] = useState(formInputs.phone?.value);

  // formStatus States
  const [formError, setFormError] = useState([]);

  const hasLeadError = (targetField: string) => piiLeadErrors.findIndex(({ field }) => field === targetField) !== -1;
  const leadPhoneError = hasLeadError('phone');

  const isEmptyString = (str: String) => str === null || str.match(/^ *$/) !== null;

  const handleSubmit = async () => {
    setFormError([]);
    let hasFormError = false;
    const formErrorArray = [];
    const inputArray = [
      { field: 'first name', value: firstName },
      { field: 'last name', value: lastName },
      { field: 'email', value: email },
      { field: 'phone', value: phone },
    ];

    for (const input of inputArray) {
      if (isEmptyString(input.value)) {
        hasFormError = true;
        formErrorArray.push({ field: input.field });
      }
    }

    if (/\d/.test(firstName)) {
      hasFormError = true;
      formErrorArray.push({ field: 'first name' });
    }

    if (/\d/.test(lastName)) {
      hasFormError = true;
      formErrorArray.push({ field: 'last name' });
    }

    if (email && !validateEmail(email)) {
      hasFormError = true;
      formErrorArray.push({ field: 'email' });
    }

    if (phone) {
      const { validPhoneNumber } = await validatePhone(phone);

      if (!validPhoneNumber) {
        hasFormError = true;
        formErrorArray.push({ field: 'phone' });
      }
    }

    if (hasFormError) {
      setFormError(formErrorArray);
    } else {
      dispatch(
        setInputs([
          { key: 'firstName', value: firstName },
          { key: 'lastName', value: lastName },
          { key: 'email', value: email },
          { key: 'phone', value: phone },
        ])
      );

      setEditMode(false);
    }
  };
  const { elementClicked } = useElementEvents({
    webElement: {
      elementType: 'card',
      location: 'drawer',
      name: 'edit-info',
      htmlId: 'voyager-takeover-edit-pii',
    },
  });
  const handleSetEditMode = () => {
    elementClicked();
    setEditMode(true);
  };

  return (
    <>
      <div className={styles.header}>
        <h5>{header}</h5>
        <button
          data-testid={`${location}-popup-edit-review-pii-button`}
          className={cn(formError && styles.buttonDisabled)}
          type="button"
          onClick={() => (editMode ? handleSubmit() : handleSetEditMode())}
        >
          {!editMode ? 'Edit' : 'Done'}
        </button>
      </div>
      {!editMode ? (
        <div className={styles.infoGrid}>
          <b>Name:</b>
          <p>{`${firstName} ${lastName}`}</p>

          <b>Email:</b>
          <p>{email}</p>

          <b>Phone:</b>
          <p>{phone}</p>
        </div>
      ) : (
        <>
          {formError.length !== 0 && (
            <>
              <p className={styles.formErrorText}>
                We were unable to save your information. Please correct the errors listed below.
              </p>
              {formError.map((obj) => (
                <ErrorMessage isFieldGroup={false} message={`Make sure the ${obj.field} field is valid.`} />
              ))}
            </>
          )}

          <form onSubmit={handleSubmit}>
            <input
              id="firstName"
              type="text"
              placeholder="First Name"
              data-testid="pii-form-first-name-input"
              defaultValue={firstName}
              onChange={(event) => setFirstName(event.target.value)}
            />

            <input
              type="text"
              placeholder="Last Name"
              data-testid="pii-form-last-name-input"
              defaultValue={lastName}
              onChange={(event) => setLastName(event.target.value)}
            />

            <input
              type="email"
              placeholder="Email"
              data-testid="pii-form-email-input"
              defaultValue={email}
              onChange={(event) => setEmail(event.target.value)}
            />

            <InputMask
              className={leadPhoneError && styles.inputError}
              mask="(999) 999-9999"
              maskChar=""
              autoComplete="on"
              type="text"
              placeholder="Phone"
              data-testid="pii-form-phone-input"
              defaultValue={phone}
              onChange={(event) => setPhone(event.target.value)}
            />
            {leadPhoneError && flags?.phoneValidation && (
              <div className={styles.piiLeadError}>
                <ErrorMessage isFieldGroup={false} message="Please provide your valid phone number." />
              </div>
            )}
          </form>
        </>
      )}
    </>
  );
};

export { PiiFormSection };
