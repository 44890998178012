import { type FC, useEffect, useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import useElementEvents from '@core/hooks/cohesion/useElementEvents';
import Popup from '@core/shared/components/Popup';

import styles from './EmptyCookiesPopup.module.css';

type Props = {
  displayPopup: boolean;
  popupState: (b: boolean) => void;
};

const EmptyCookiesPopup: FC<Props> = ({ displayPopup, popupState }) => {
  const correlationId = useMemo(() => uuid(), []);
  const { elementViewed } = useElementEvents({
    correlationId,
    webElement: {
      elementType: 'pop-up',
      text: 'email-continue',
      location: 'middle',
      name: 'email-popup',
    },
  });

  const { elementClicked } = useElementEvents({
    correlationId,
    webElement: {
      elementType: 'pop-up',
      text: 'email-exit',
      location: 'middle',
      name: 'email-exit-popup',
    },
  });

  const closePopup = () => {
    elementClicked(popupState(false) as any);
  };

  useEffect(() => {
    elementViewed();
  }, []);

  return (
    <Popup show={displayPopup} onClose={closePopup}>
      <div className={styles.content}>
        <h1>Looks like we haven&apos;t spoken in a while.</h1>
        <p>
          Don&apos;t you worry! <br /> By answering a few quick questions, you can access the same great results that
          match your interests.
        </p>
        <button type="button" onClick={closePopup}>
          Continue
        </button>
      </div>
    </Popup>
  );
};

export default EmptyCookiesPopup;
