import type { GetGeoCandidates } from './types';

const getGeoCandidates: GetGeoCandidates = ({ geoRules, currentState }) => {
  if (!geoRules?.length || !currentState) {
    return [];
  }

  // check if DCS' any of the states in geo match the user's state
  const match = geoRules.find(({ state }) => state.toUpperCase() === currentState.toUpperCase());

  // if one matches, we return the candidates
  // if not, we return an empty array
  return (match?.schoolIds ?? []).map((schoolId) => ({ schoolId, rule: 'geo' }));
};

export default getGeoCandidates;
