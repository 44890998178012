import { FC, useEffect, useMemo } from 'react';
import { FaRegClock } from 'react-icons/fa';
import { HiOutlineChevronDoubleRight } from 'react-icons/hi';
import { TiLocation } from 'react-icons/ti';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { SchoolConfigData } from '@core/blocks/edu-match/components/ExitStrategy/type';
import Application from '@core/blocks/eduResults/components/Application';
import SchoolDetails from '@core/blocks/eduResults/components/SchoolDetails';
import { useGetApplicationCtaData } from '@core/blocks/eduResults/hooks/useGetApplicationCtaData';
import useElementEvents from '@core/hooks/cohesion/useElementEvents';
import useSchoolConfig from '@core/hooks/useSchoolConfig';
import { selectAllInputs } from '@core/reducers/inputsSlice';
import { selectSubmissions } from '@core/reducers/matchesSlice';
import { applicationCtaBtn, continueBrowsingBtn, popupClose } from '@core/utils/objects/eventPayloads';

import NextStepsCopy from '../NextStepsCopy';
import styles from './RecommenderExitStrategyRevamp.module.css';

type Props = {
  closePopup?: () => void;
  eventingOverrides: Record<string, any>;
};
const RecommenderExitStrategyRevamp: FC<Props> = ({ closePopup, eventingOverrides }) => {
  const submissions = useSelector(selectSubmissions);
  const inputs = useSelector(selectAllInputs);
  const { program, school } = submissions[0];
  const schoolConfigData: SchoolConfigData = useSchoolConfig(school.id);
  const cta = useGetApplicationCtaData({
    program,
  });
  const correlationId: string = useMemo(() => uuid(), []);

  const { elementViewed, elementClicked } = useElementEvents({
    correlationId,
    webElement: {
      location: eventingOverrides?.location,
    },
  });
  const onCtaClick = () => {
    elementClicked({ ...applicationCtaBtn, outboundUrl: cta?.url });
    window.open(cta?.url, '_blank', 'noopener,noreferrer');
  };
  const handleContinueBtnClick = () => {
    elementClicked(continueBrowsingBtn);
    if (closePopup) closePopup();
  };

  useEffect(() => {
    elementViewed(popupClose);
  }, []);

  return (
    <>
      <div className={styles.schoolDetailsContainer}>
        <SchoolDetails school={school} />
      </div>
      <section className={styles.nextStepsContainer}>
        <div className={styles.nextSteps}>
          <h2 className={styles.congratsMessage}>Congratulations, {inputs.firstName.value}!</h2>
          <h1 className={styles.successMessage}>Your information was submitted succesfully.</h1>
          {schoolConfigData.showNextStartDate && (
            <p className={styles.acceptingStudentsCta}>
              <FaRegClock size="20px" /> Now Accepting Students
            </p>
          )}
          <div className={styles.appCta}>
            <Application
              program={program}
              isRecommender
              eventingOverrides={{ location: eventingOverrides?.location }}
            />
          </div>
          <NextStepsCopy />
        </div>
      </section>
      <div className={styles.mobileCta}>
        <div className={styles.mobileCtaSchool}>
          <h3>{school.name}</h3>
          {school.state && school.city && (
            <div className={styles.mobileCtaLocation}>
              <TiLocation size={16} />
              <p>
                {school.city}, {school.state}
              </p>
            </div>
          )}
        </div>
        <button type="button" className={styles.ctaButton} onClick={onCtaClick}>
          {cta?.label}
        </button>
        <button className={styles.continueToResults} onClick={handleContinueBtnClick} type="button">
          Continue browsing programs <HiOutlineChevronDoubleRight size={16} />
        </button>
      </div>
    </>
  );
};
export default RecommenderExitStrategyRevamp;
