import { FC, MouseEventHandler } from 'react';

import styles from './BackButton.module.css';

type BackButtonProps = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  text?: string;
};

const BackButton: FC<BackButtonProps> = ({ onClick, text = 'back' }) => (
  <button type="button" onClick={onClick} className={styles.buttonPrevious}>
    <svg width="24" height="47" viewBox="0 0 24 47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.875 45.375L1 23.5L22.875 1.625"
        stroke="#334F6E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>

    <span className={styles.buttonText}>{text}</span>
  </button>
);

BackButton.displayName = 'BackButton';

BackButton.defaultProps = {
  text: 'back',
};

export default BackButton;
