import { useState, useEffect, ChangeEvent } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector, useDispatch } from 'react-redux';

import useFieldEvents from '@core/hooks/cohesion/useFieldEvents';
import { selectFormCorrelationId, selectStepContext } from '@core/reducers/eventingSlice';
// Reducers and Actions
import { selectInput, setInput } from '@core/reducers/inputsSlice';
import debounce from '@core/utils/debounce';

// local
import { FieldComponent } from '../../../utils/fieldTypeDictionary';
// Utils
import styles from './ZipField.module.css';

const ZipField: FieldComponent = ({ errors, heading, fieldNumber }) => {
  // Action Dispatcher
  const dispatch = useDispatch();

  // Form Correlation Id for events
  const formCorrelationId = useSelector(selectFormCorrelationId);

  // Inputs State properties
  const fieldInput = useSelector((state) => selectInput(state, 'zip'));
  const [previousFieldInput, setPreviousFieldInput] = useState('');
  const stepContext = useSelector(selectStepContext);
  const autofilled = !!fieldInput && !previousFieldInput;

  // Eventing
  const { fieldInputted, fieldViewed } = useFieldEvents({
    correlationId: formCorrelationId,
    userInputField: {
      autofilled,
      fieldName: 'zip',
      fieldLabel: heading,
      fieldType: 'zip',
      fieldValue: 'SANITIZED',
      fieldNumber,
    },
  });

  const handleChange = debounce<(event: ChangeEvent<HTMLInputElement>) => void>((event) => {
    setPreviousFieldInput(fieldInput);
    dispatch(setInput({ key: 'zip', value: event.target.value }));
  }, 500);

  useEffect(() => {
    fieldViewed();
  }, []);

  useEffect(() => {
    if (fieldInput?.length >= 5 && stepContext) {
      fieldInputted({
        userInputField: {
          fieldValue: fieldInput,
        },
      });
    }
  }, [fieldInput, stepContext]);

  return (
    <div className={styles.inputContainer}>
      <NumberFormat
        defaultValue={fieldInput}
        format="#####"
        mask=""
        id="zip"
        type="text"
        inputMode="numeric"
        className={`${styles.textField} ${errors && styles.error}`}
        name="zip"
        placeholder="Enter ZIP Code"
        maxLength={5}
        onChange={handleChange}
        data-testid="zip"
        aria-label="ZIP Code"
      />
    </div>
  );
};

export default ZipField;
