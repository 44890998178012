import { ValidateZipSuccess, validateZip } from '@core/services/leadDelivery';
import { PageActions } from '@core/services/newRelic';
import { Hit } from '@core/ts/algolia';

const sortHits = async (hits: Hit[], zip: string): Promise<Hit[]> => {
  // Sort the hits if the test is active for this user 50/50 split

  const { stateAbbr }: { stateAbbr: string } = (await validateZip(zip)) as ValidateZipSuccess;
  if (!stateAbbr?.length) return hits;
  /*
   *  Sort hits in descending order based on the bonsaiERPIState for their location (stateAbbr e.g. CA)
   * Need to make a copy of hits to prevent assinging read only property (Hits appear to be read-only and sorting assigns properties)
   */

  const sortedHits: Hit[] = [...hits].sort((hit1: Hit, hit2: Hit) => {
    const a = hit2?.meta?.bonsaiERPIState[stateAbbr?.toLowerCase()] ?? 0;
    const b = hit1?.meta?.bonsaiERPIState[stateAbbr?.toLowerCase()] ?? 0;
    return a - b;
  });

  // Override __position from algolia to position in bonsai sort
  const positionedHits: Hit[] = sortedHits.map((hit: Hit, index: number) => ({
    ...hit,
    __position: index + 1,
  }));
  // Fire new relic event with a list of sorted id's
  PageActions.BonsaieRPISortEnabled({
    schoolIdList: JSON.stringify(positionedHits.map((hit: Hit) => hit.school.id)),
  });
  // Update the __position given by the position engine
  return positionedHits;
};

export default sortHits;
