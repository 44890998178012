/**
 * Convert to Test Id
 * @param string - the string to convert to the test id format
 * @returns string - the string converted to test id format
 */

export default (originalString: string): string => {
  if (!originalString) return '';
  // Replace all special characters (except spaces and -) with empty string, replace spaces with a dash
  return originalString
    .replace(/[^a-zA-Z0-9\s-]/g, '')
    .replace(/\s+/g, '-')
    .toLocaleLowerCase();
};
