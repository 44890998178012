// build facetFilters
// pulls values from voyager context and builds array
// to provide default filters for the results
import { buildFilter, buildFacetFilter } from '@core/utils/buildFilter';

type Params = {
  dcsDegrees: string[];
  dcsCategories: string[];
  configFilters?: string[];
  additionalFilters?: string[];
  dcsSubjects?: string[];
};

type GetFacetFilters = (params: Params) => string;

const getFacetFilters: GetFacetFilters = ({
  dcsCategories = [],
  dcsDegrees = [],
  dcsSubjects = [],
  configFilters = [],
  additionalFilters = [],
}) => {
  const categoryFilters = dcsCategories.map((category) => buildFacetFilter('program.category.name', category));
  const degreeFilters = dcsDegrees.map((degree) => buildFacetFilter('program.degree.name', degree));
  const subjectFilters =
    dcsSubjects?.length > 0 ? dcsSubjects.map((subject) => buildFacetFilter('program.subject.name', subject)) : [];
  const filters = buildFilter([
    ...categoryFilters,
    ...degreeFilters,
    ...subjectFilters,
    ...configFilters,
    ...additionalFilters,
  ]);
  return filters;
};
export default getFacetFilters;
