import { FC } from 'react';

import styles from './PulsingDotLoader.module.css';

const PulsingDotLoader: FC = () => (
  <div className={styles.pulseLoadContainer}>
    <div className={styles.pulse1} />
    <div className={styles.pulse2} />
    <div className={styles.pulse3} />
  </div>
);

export default PulsingDotLoader;
