import { CollapsibleTrigger } from '@edu-match/components';
import { type FC, useState, type ReactNode } from 'react';

import styles from './CollapsibleSection.module.css';

type Props = {
  triggerLabel: string;
  filter?: string;
  children?: ReactNode;
};

const CollapsibleSection: FC<Props> = ({ triggerLabel, filter, children }) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <section className={styles.collapsibleSection}>
      {/* trigger/button */}
      <CollapsibleTrigger
        isOpen={open}
        triggerLabel={triggerLabel}
        filter={filter}
        handleClick={() => setOpen(!open)}
      />

      <div className={open ? styles.open : undefined}>{children}</div>
    </section>
  );
};

export { CollapsibleSection };
