import { HiCheckCircle, HiExclamationCircle } from 'react-icons/hi';

import Dots from '@core/blocks/edu-match/components/Loading/Dots';

import styles from './SubmissionStatus.module.css';

const SubmissionStatus = ({ status, schoolName }) => {
  if (!status) return null;

  return (
    <div className={styles.submissionStatus}>
      {status === 'LOADING' && (
        <>
          <Dots />
          <h4>Requesting information from {schoolName}...</h4>
        </>
      )}
      {status === 'SUCCESS' && (
        <>
          <HiCheckCircle size="4rem" className={styles.successIcon} />
          Successfully requested information from <b>&nbsp;{schoolName}!</b>
        </>
      )}
      {status === 'ERROR' && (
        <>
          <HiExclamationCircle size="4rem" className={styles.errorIcon} />
          Unable to request information from <b>&nbsp;{schoolName}!</b>
        </>
      )}
    </div>
  );
};

export default SubmissionStatus;
