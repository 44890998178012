import { FormEvent, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector, useDispatch } from 'react-redux';

import { FieldComponent } from '@core/blocks/edu-flow-2/utils/fieldTypeDictionary';
import useFieldEvents from '@core/hooks/cohesion/useFieldEvents';
import { selectFormCorrelationId } from '@core/reducers/eventingSlice';
import { selectInput, setInput } from '@core/reducers/inputsSlice';
import cn from '@core/utils/classNames';
import debounce from '@core/utils/debounce';
import convertToSlug from '@core/utils/strings/convertToSlug';

import styles from './PhoneField.module.css';

const PhoneField: FieldComponent = ({ field, errors, heading, fieldNumber }) => {
  const [canFireFieldInputted, setCanFireFieldInputted] = useState(true);
  // Action Dispatcher
  const dispatch = useDispatch();

  // Form Correlation Id for events
  const formCorrelationId = useSelector(selectFormCorrelationId);

  const { fieldInputted, fieldViewed } = useFieldEvents({
    correlationId: formCorrelationId,
    userInputField: {
      autofilled: false,
      fieldName: field.name,
      fieldLabel: heading,
      fieldType: field.type,
      fieldNumber,
    },
  });

  useEffect(() => {
    fieldViewed();
  }, []);

  const handleChange = debounce<(event: FormEvent<HTMLInputElement>) => void>((event) => {
    const { value } = event.target as HTMLInputElement;
    dispatch(setInput({ key: field.name as string, value }));

    const optionVal = value;

    // only fire this event once
    if (canFireFieldInputted && optionVal && optionVal !== '(') {
      // Field Eventing
      fieldInputted({
        userInputField: {
          fieldValue: 'SANITIZED',
        },
      });

      setCanFireFieldInputted(false);
    }
  }, 500);

  const fieldInput = useSelector((state) => selectInput(state, field.name as string));

  return (
    <div>
      <NumberFormat
        defaultValue={fieldInput}
        format="(###) ###-####"
        mask=""
        id={field.name}
        className={cn(styles.phoneField, errors && styles.error)}
        name={field.name}
        placeholder={field.label}
        min={field.min || undefined}
        max={field.max || undefined}
        onChange={handleChange}
        onBlur={() => setCanFireFieldInputted(true)}
        data-testid={convertToSlug(field.name as string)}
        aria-label={field.label}
        autoComplete={field.autocompleteValue}
      />
    </div>
  );
};

export default PhoneField;
