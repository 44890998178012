import Overlay from '@edu-match/components/Overlay';
import type { SearchClient } from 'algoliasearch';
import { type FC, useEffect } from 'react';
import { HiX } from 'react-icons/hi';
import { Configure, InstantSearch } from 'react-instantsearch-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useGetApplicationCtaData } from '@core/blocks/eduResults/hooks/useGetApplicationCtaData';
import { DEBUG_PARAMS } from '@core/constants';
import useElementEvents from '@core/hooks/cohesion/useElementEvents';
import { selectDcsCategories, selectDcsDegrees } from '@core/reducers/dcsSlice';
import { selectInput } from '@core/reducers/inputsSlice';
import { addSubmission, selectLeadDelivery, setLeadDelivery, setShowDuplicatePopup } from '@core/reducers/matchesSlice';
import type { VoyagerResult } from '@core/ts/results';

import styles from './DuplicatePopup.module.css';
import OtherPrograms from './OtherPrograms';
import {
  RevampEClicked,
  closeBtnEClicked,
  closeBtnEViewed,
  keepBrowsingEClicked,
  keepBrowsingEViewed,
  nextStepsEClicked,
  nextStepsEViewed,
} from './eventPayloads';

type Props = {
  searchClient: SearchClient;
  indexName: string;
  resultsRevamp?: boolean;
};

const DuplicatePopup: FC<Props> = ({ searchClient, indexName, resultsRevamp = false }) => {
  const dispatch = useDispatch();
  const { currentPrograms } = useSelector(selectLeadDelivery);

  // dupes scroll debugger query param
  const dupesScrollDebug = useSelector((state) => selectInput(state, DEBUG_PARAMS.DUPESLIST));

  const { elementViewed, elementClicked } = useElementEvents();

  useEffect(() => {
    elementViewed(closeBtnEViewed);
    elementViewed(keepBrowsingEViewed);
    elementViewed(nextStepsEViewed);
  }, []);

  const dcsDegrees = useSelector(selectDcsDegrees);
  const dcsCategories = useSelector(selectDcsCategories);
  const currentProgram: VoyagerResult = currentPrograms?.[0];
  const resultsRevampCta = useGetApplicationCtaData({
    program: currentProgram?.program,
  });

  // if there are no current programs, don't show the popup
  if (!currentPrograms?.[0]) {
    return null;
  }

  const { program, school } = currentProgram;

  const addToSubmissionsAsDuplicate = () => {
    dispatch(addSubmission({ ...currentProgram, duplicate: true }));
  };
  const handleCtaClick = () => {
    window.open(resultsRevampCta.url, '_blank');
    elementClicked(RevampEClicked);
    addToSubmissionsAsDuplicate();
    dispatch(setShowDuplicatePopup(false));
  };
  const handleOverlayClick = () => {
    elementClicked(closeBtnEClicked);
    addToSubmissionsAsDuplicate();
    dispatch(setShowDuplicatePopup(false));
  };

  const handleCloseBtnClick = () => {
    elementClicked(closeBtnEClicked);
    addToSubmissionsAsDuplicate();
    dispatch(setShowDuplicatePopup(false));
  };

  const handleKeepBrowsingClick = () => {
    elementClicked(keepBrowsingEClicked);
    addToSubmissionsAsDuplicate();
    dispatch(setShowDuplicatePopup(false));
  };

  const handleNextStepsClick = () => {
    elementClicked(nextStepsEClicked);
    addToSubmissionsAsDuplicate();
    dispatch(setShowDuplicatePopup(false));
    dispatch(
      setLeadDelivery({
        show: true,
        currentPrograms,
        showExitStep: true,
      })
    );
  };

  const degreesFilter = dcsDegrees[0] ? `program.degree.name:"${dcsDegrees[0]}" AND ` : '';
  const filters = dupesScrollDebug
    ? undefined
    : `${degreesFilter}program.category.name:"${dcsCategories[0]}" AND school.id = ${school.id} AND NOT program.id = ${program.id}`;

  return (
    <div className={styles.duplicatePopup}>
      <Overlay clickHandler={handleOverlayClick} />
      <div className={styles.card}>
        <button
          data-testid="duplicate-popup-closebtn"
          type="button"
          className={styles.close}
          onClick={handleCloseBtnClick}
        >
          <HiX size="1.5rem" />
        </button>

        <div className={styles.imgContainer}>
          <img
            src={`https://simple-storage-server.highereducation.com/${school.slug}.png?w=130&h=80`}
            alt={school.name}
          />
        </div>

        <section>
          <h2>You&apos;ve already requested info from this school.</h2>
          <p>You requested information about:</p>
          <b>{program.name}</b>
        </section>

        <section>
          <b>{school.name}</b>
          <p className="mt-1">has many programs you might need interested in!</p>
          <p>Chat through your options with your advisors.</p>
        </section>
        <div className={styles.otherProgramsContainer}>
          <section className={styles.otherPrograms}>
            <h4>Other programs available:</h4>

            <InstantSearch searchClient={searchClient} indexName={indexName}>
              <Configure hitsPerPage={51} filters={filters} attributesToRetrieve={['program.name']} />
              <OtherPrograms />
            </InstantSearch>
          </section>
          <div className={styles.hide} />
        </div>

        {!resultsRevamp && (
          <section className={styles.nextSteps}>
            <button type="button" onClick={handleNextStepsClick}>
              View next steps
            </button>
            &nbsp;to learn what to expecting the coming days.
          </section>
        )}

        {resultsRevamp && resultsRevampCta?.url && resultsRevampCta?.label ? (
          <button type="button" className={styles.keepBrowsing} onClick={handleCtaClick}>
            {resultsRevampCta.label}
          </button>
        ) : (
          <button type="button" className={styles.keepBrowsing} onClick={handleKeepBrowsingClick}>
            Keep browsing schools
          </button>
        )}
      </div>
    </div>
  );
};

export default DuplicatePopup;
