/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, MouseEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import useProductEvents from '@core/hooks/cohesion/useProductEvents';
import useSchoolConfig from '@core/hooks/useSchoolConfig';
import { setProductCorrelationIdMapEntry } from '@core/reducers/eventingSlice';
import {
  selectIsRecommenderPopupOpen,
  selectSubmissions,
  setLeadDelivery,
  setShowDuplicatePopup,
} from '@core/reducers/matchesSlice';
import Tags from '@core/shared/features/Recommender/SpotlightProgram/Tags';
import { Hit } from '@core/ts/algolia';
import cn from '@core/utils/classNames';

import styles from './EmbeddedRecommenderCard.module.css';

type Props = {
  hit: Hit;
  listId: string;
  position: number;
  isSingleResult: boolean;
};

const EmbeddedRecommenderCard: FC<Props> = ({ hit, listId, position, isSingleResult = false }) => {
  const dispatch = useDispatch();
  const viewCorrelationId = useMemo(() => uuid(), []);
  const [expandDescription, setExpandDescription] = useState(false);

  const submissions = useSelector(selectSubmissions);

  const showRecommendedResults = useSelector(selectIsRecommenderPopupOpen);

  const isProgramSubmitted = !!submissions.find((submission) => submission.program.id === hit.program.id);

  const schoolConfig = useSchoolConfig(hit?.school?.id);

  const { productLoaded, productViewed, productClicked } = useProductEvents({
    listId,
    viewCorrelationId,
    product: {
      sku: String(hit.cap.id),
      variant: hit.program.degree.slug,
      productId: String(hit.program.id),
      name: hit.program.subject.slug,
      category: hit.program.category.slug,
      brand: hit.school.slug,
      position,
      location: 'voyager-recommender-embedded',
      formatType: 'app',
      formatSubtype: 'recommendation',
      positionEngine: 'algolia',
    },
    customDimensions: [{ key: 'isUserRequested', value: 'true' }],
  });

  useEffect(() => {
    if (!showRecommendedResults) {
      productLoaded();
      productViewed();
    }
  }, [showRecommendedResults]);

  const handleRequestInfoClick = () => {
    const isSchoolSubmitted = !!submissions?.find((submission) => submission.school.id === hit.school.id);

    // If preamp embedded recommender is enabled and program is already submitted, show Exit Strategy
    if (isProgramSubmitted) {
      dispatch(
        setLeadDelivery({
          show: true,
          currentPrograms: [hit],
          showExitStep: true,
        })
      );
      return;
    }

    // If school is already submitted, show duplicate popup
    if (isSchoolSubmitted) {
      dispatch(
        setLeadDelivery({
          show: false,
          currentPrograms: [hit],
          showExitStep: false,
        })
      );
      dispatch(setShowDuplicatePopup(true));
      return;
    }

    // If program hasn't been submitted, show lead delivery slide-out
    const productCorrelationId = uuid();
    dispatch(setProductCorrelationIdMapEntry({ key: hit.program.id, value: productCorrelationId }));
    productClicked({ correlationId: productCorrelationId });

    dispatch(
      setLeadDelivery({
        show: true,
        currentPrograms: [hit],
        showExitStep: false,
      })
    );
  };

  const handleDescriptionClick = (event: MouseEvent) => {
    event.stopPropagation();
    setExpandDescription(!expandDescription);
  };

  return (
    <button
      type="button"
      onClick={handleRequestInfoClick}
      className={cn(styles.embeddedRecommenderCard, isSingleResult && styles.singleResult)}
      data-testid="embeddedRecommenderCard"
    >
      <header>
        <p className={styles.programName}>{hit.program.name}</p>
        <h2 className={styles.schoolName}>{hit.school.name}</h2>
        <Tags tags={schoolConfig?.data?.tags} />
        {/* description */}
        <div className={cn(styles.cardDescription, expandDescription && styles.expand)}>
          <p className={cn(!expandDescription && styles.stretch)}>{hit.program.snippet}</p>
          {/* gradient overlay */}
          <div
            data-testid="descriptionOverlay"
            className={cn(styles.overlayGradient, expandDescription && styles.hide)}
          />
        </div>
        {/* description expand/collapse trigger */}
        <button
          type="button"
          data-testid="descriptionOverlayToggle"
          className={styles.cardDescriptionToggle}
          onClick={handleDescriptionClick}
        >
          Description {expandDescription ? '-' : '+'}
        </button>
      </header>
      <div className={styles.ctaSection}>
        <div className={styles.logoContainer}>
          <img
            src={`https://simple-storage-server.highereducation.com/${hit.school.slug}.png?w=130&h=80`}
            alt={`${hit.school.name} Logo`}
          />
        </div>

        <div className={cn(styles.ctaButton, isProgramSubmitted && styles.nextSteps)}>
          {isProgramSubmitted ? 'Next Steps' : 'Explore Program'}
        </div>
      </div>
    </button>
  );
};

export default EmbeddedRecommenderCard;
