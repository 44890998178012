import cloneDeep from 'lodash/cloneDeep';

import { fetchProgramFormFieldConfig } from '@core/services/hub';

import type { CurrentAcademicAwardKey, UiSchema, UiSchemaProperties } from './types';
import rawUiSchema from './uiSchema.json';

type GetTransformedUiSchemaArgs = {
  programId: number;
  prqSchema: any;
};

type GetTransformedUiSchema = (args: GetTransformedUiSchemaArgs) => Promise<UiSchema>;

const getTransformedUiSchema: GetTransformedUiSchema = async ({ programId, prqSchema }) => {
  const uiSchema: UiSchema = cloneDeep(rawUiSchema);

  // Hack for currentAcademicAward
  if (prqSchema?.properties?.currentAcademicAward) {
    prqSchema.properties.currentAcademicAward?.anyOf?.push({
      type: 'string',
      enum: ['COMPLETED', 'IN_PROGRESS'],
    });

    const key: CurrentAcademicAwardKey = prqSchema.properties.currentAcademicAward?.anyOf?.[0]?.enum?.[0];
    uiSchema.currentAcademicAward = uiSchema?.[`${key}:currentAcademicAward`];
    if (
      prqSchema?.properties?.inProgressAcademicAward &&
      uiSchema.currentAcademicAward?.['ui:options']?.options &&
      !uiSchema.currentAcademicAward['ui:options'].options?.some((option) => option.value === 'IN_PROGRESS')
    ) {
      uiSchema.currentAcademicAward['ui:options'].options = [
        ...uiSchema.currentAcademicAward['ui:options'].options,
        { label: 'In Progress', value: 'IN_PROGRESS' },
      ];
      delete prqSchema.properties.inProgressAcademicAward;
      prqSchema.required = prqSchema.required.filter((required: string) => required !== 'inProgressAcademicAward');
    }
  }

  // Fetch program specific form field configuration from EduHub
  try {
    const programFormFieldsConfig = await fetchProgramFormFieldConfig(programId);
    if (programFormFieldsConfig) {
      Object.entries(programFormFieldsConfig).forEach(([key, { question, options }]) => {
        const uiSchemaKey = key as keyof UiSchema;
        const uiSchemaEntry = uiSchema[uiSchemaKey] as UiSchemaProperties;
        uiSchemaEntry['ui:title'] = question;
        uiSchemaEntry['ui:options'] = {
          ...uiSchemaEntry['ui:options'],
          options,
        };
      });
    }
    return uiSchema;
  } catch (error) {
    return uiSchema;
  }
};

export default getTransformedUiSchema;
