import qs from 'qs';
import { useLocation } from 'react-router-dom';

function decode(str) {
  const txt = new DOMParser().parseFromString(str, 'text/html');

  return txt.documentElement.textContent;
}

// returns the queryParams as a javascript object
// uses the qs library https://github.com/ljharb/qs
const useQueryParams = () => {
  // get search string from the react-router
  const { search } = useLocation();

  // removes the '?' from the querystring
  const queryString = search.substring(1);

  // return parsed queryString using the 'qs' library
  return JSON.parse(decode(JSON.stringify(qs.parse(queryString))).replace(/\+/g, ' '));
};

export default useQueryParams;
