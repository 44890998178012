import type { Field } from '@edu-flow/components/Fields/types';

import { VoyagerInputs } from '@core/reducers/inputsSlice';
import { ValidateZipSuccess, validateEmail, validatePhone, validateZip } from '@core/services/leadDelivery';

type Options = {
  useCarrier: boolean;
};

// This evaluates if all of the inputs provided by the users and checks that they comply with the validation rules found on the schema
const getValidationErrors = async (inputs: VoyagerInputs, fields: Field[], options?: Options) => {
  const errors: Error[] = [];

  for (const field of fields) {
    const fieldInput = inputs[field.name]?.value;

    // Function to push errors to array
    const errorArray = (fieldName: string, fieldError: string) => {
      errors.push({ name: fieldName, message: fieldError });
    };

    switch (true) {
      // Check for required field
      case field.required && (fieldInput === undefined || fieldInput?.length === 0):
        errorArray(field.name, 'This field is required.');
        break;

      // If a field is a group of options check if it has a min
      case field.type === 'optionGroup' && field.min && (!fieldInput || fieldInput.length < field.min):
        errorArray(field.name, 'You must enter a valid email address.');
        break;

      // Validate email field
      case field.format === 'email' && !validateEmail(fieldInput):
        errorArray(field.name, 'You must enter a valid email address.');
        break;

      // Validate phone field
      case field.type === 'phone':
        try {
          const { validPhoneNumber } = await validatePhone(fieldInput, options?.useCarrier);
          // The validPhoneNumber property won't exist if phone is valid.
          // We need to explicitly check for it being === false to know if invalid
          if (validPhoneNumber === false) {
            errorArray(field.name, 'You must enter a valid phone number.');
          }
        } catch (error) {
          // If the API call to LDS fails
          errorArray(field.name, 'Unable to validate phone number.');
        }
        break;

      // Validate Zip Field
      case field.type === 'zip':
        if (fieldInput.length < 5) {
          errorArray(field.name, 'You must enter a valid zip code.');
        } else {
          try {
            const { validZip } = (await validateZip(fieldInput)) as ValidateZipSuccess;
            if (validZip === false) {
              // The validPhoneNumber property won't exist if phone is valid.
              // We need to explicitly check for it being === false to know if invalid
              errorArray(field.name, 'You must enter a valid zip code.');
            }
          } catch (error) {
            // If the API call to LDS fails
            errorArray(field.name, 'Unable to validate zip code.');
          }
        }
        break;
      // Validate Full Name
      case field.type === 'text' && field.name === 'fullName' && fieldInput.trim().split(/\s+/).length < 2:
        errorArray(field.name, 'You must enter a valid first and last name.');
        break;
      default:
        break;
    }
  }
  return errors;
};

export default getValidationErrors;
