import React, { FC, useEffect, useMemo } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { v4 as uuid } from 'uuid';

import useElementEvents from '@core/hooks/cohesion/useElementEvents';

import styles from './ResourcesBanner.module.css';

const ResourcesBanner: FC = () => {
  const correlationId = useMemo(() => uuid(), []);
  const { elementViewed, elementClicked } = useElementEvents({
    correlationId,
    webElement: {
      elementType: 'pop-up',
      text: 'See resources',
      location: 'footer',
      htmlId: 'post-lead-resources-link',
      name: 'post-lead-resources',
    },
  });

  useEffect(() => {
    elementViewed();
  }, []);

  const href = window?.HE?.publisher?.resourcesUrl ?? 'https://www.bestcolleges.com/resources/';

  const handleClick = () => {
    elementClicked({ outboundUrl: href });
  };

  return (
    <div className={styles.resourcesBanner}>
      <div className={styles.content}>
        <span>Before you go!</span>
        <div>Check out some resources that’ll help outline the next steps in your education journey.</div>
      </div>
      <a href={href} target="_BLANK" onClick={handleClick} rel="noreferrer">
        <div>See Resources</div>
        <BsArrowRight />
      </a>
    </div>
  );
};

export { ResourcesBanner };
