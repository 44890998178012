import formStyles from '@edu-flow/styles/Form.module.css';
import { type ChangeEventHandler, type FC, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector, useDispatch } from 'react-redux';

import useFieldEvents from '@core/hooks/cohesion/useFieldEvents';
import { selectFormCorrelationId } from '@core/reducers/eventingSlice';
import { selectInput, setInput } from '@core/reducers/inputsSlice';
import cn from '@core/utils/classNames';
import convertToSlug from '@core/utils/strings/convertToSlug';

import type { FieldComponentProps } from '../types';
import styles from './PhoneField.module.css';

const PhoneField: FC<FieldComponentProps> = ({ field, fieldError, heading, fieldNumber }) => {
  const [canFireFieldInputted, setCanFireFieldInputted] = useState<boolean>(true);
  // Action Dispatcher
  const dispatch = useDispatch();

  // Form Correlation Id for events
  const formCorrelationId = useSelector(selectFormCorrelationId);

  const { fieldInputted, fieldViewed } = useFieldEvents({
    correlationId: formCorrelationId,
    userInputField: {
      autofilled: false,
      fieldName: field.name,
      fieldLabel: heading,
      fieldType: field.type,
      fieldNumber,
    },
  });

  useEffect(() => {
    fieldViewed();
  }, []);

  // Inputs State properties
  const fieldInput = useSelector((state) => selectInput(state, field.name));

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    dispatch(setInput({ key: field.name, value: event.target.value }));

    const optionVal = event?.target?.value;

    // only fire this event once
    if (canFireFieldInputted && optionVal && optionVal !== '(') {
      // Field Eventing
      fieldInputted({
        userInputField: {
          fieldValue: 'SANITIZED',
        },
      });

      setCanFireFieldInputted(false);
    }
  };

  return (
    <div className={formStyles.fieldGroup}>
      <NumberFormat
        format="(###) ###-####"
        mask=""
        id={field.name}
        className={cn(styles.phoneField, fieldError && styles.error)}
        name={field.name}
        placeholder={field.label}
        min={field.min || undefined}
        max={field.max || undefined}
        value={fieldInput}
        onChange={handleChange}
        onBlur={() => setCanFireFieldInputted(true)}
        data-testid={convertToSlug(field.name)}
        aria-label={field.label}
        autoComplete={field.autocompleteValue}
      />
    </div>
  );
};

export default PhoneField;
