import { Dispatch } from '@reduxjs/toolkit';
import { MonarchClient } from '@redventures/monarch-sdk';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { setTestResults } from '@core/reducers/monarchSlice';
import { newRelicNoticeError, nrErrorMap } from '@core/services/newRelic';
import { RootState } from '@core/store';

import { MonarchRequest, MonarchParams } from './monarch';
import getMonarchRequests from './monarch.requests';

// Maps method from monarch config to sdk
const monarchMethodMap = {
  rule: 'evaluateRule',
  ruleset: 'evaluateRuleSet',
};

export default () => {
  // Dispatch events
  const dispatch: Dispatch = useDispatch();
  // Current page of the flow
  const { pathname }: { pathname: string } = useLocation();

  // Ensures we don't evaluate rules on the first render and until cohesion is ready
  const [ready, setReady] = useState<boolean>(false);

  // Store state
  const storeState: RootState = useSelector((state: RootState) => state);

  // Arguments to pass to monarch config
  const monarchParams: MonarchParams = {
    state: storeState,
    slug: pathname,
  };

  // Monarch Requests
  const monarchRequests: MonarchRequest[] = getMonarchRequests(monarchParams);
  // Monarch results to return from hook
  const [results, setResults] = useState<Record<string, unknown>>({});

  // Payload Defaults to pass in each Monarch request
  const payloadDefaults: Record<string, string> = {
    publisher: window.location.hostname.replace('www.', ''), // always include the publisher e.g. bestcolleges.com
  };
  // Param Defualts to pass in each Monarch request
  // https://redventures.atlassian.net/wiki/spaces/COHSN/pages/1427145833/Client+API+-+Monarch
  const paramDefaults: Record<string, string> = {
    sessionId: window._Cohesion?.sessionId,
    anonymousId: window._Cohesion?.anonymousId,
    instanceId: window._Cohesion?.instanceId,
    makeSourceUid: window._Cohesion?.sourceKey,
    makeIdentifier: window._Cohesion?.anonymousId,
    writeKeyOverride: window._Cohesion?.writeKey,
  };

  const handleMonarchRules = async (): Promise<void> => {
    try {
      // Init rule engine
      const rulesEngine: MonarchClient = new MonarchClient(
        import.meta.env.VITE_MONARCH_SRC_KEY,
        import.meta.env.VITE_MONARCH_CLIENT_TOKEN,
        {
          timeout: 1500,
        }
      );
      // local variables for testResults & submittedList
      const rlts: Record<string, unknown> = {};

      // Array of monarch engine calls
      const monarchPromises = monarchRequests.map(
        ({ condition: isConditionMet = false, type, id, payload = {}, params = {}, then: thenFunction }) => {
          if (!isConditionMet || results[id] !== undefined) return undefined;

          return rulesEngine[monarchMethodMap[type]](
            id,
            { ...payloadDefaults, ...payload },
            { ...paramDefaults, ...params }
          )
            .then(thenFunction)
            .catch(() => undefined);
        }
      );

      // Await all monarch requests
      const monarchResults = await Promise.all(monarchPromises);

      // Loop through monarch results
      monarchResults.forEach((result, i) => {
        const { id } = monarchRequests[i];
        // amp the result for later storage
        if (result !== undefined) {
          rlts[id] = result;
        }
      });

      // Prevent unnecessary rerendering from state updates
      if (JSON.stringify(rlts) !== '{}') {
        // merge old results with incoming results
        const allResults = { ...results, ...rlts };
        // local use
        setResults(() => allResults);

        // Keep in store
        dispatch(setTestResults(allResults));
      }
    } catch (err) {
      newRelicNoticeError(nrErrorMap.MONARCH_CLIENT, err as Error);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(handleMonarchRules, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [ready, pathname, storeState.inputs]);

  useEffect(() => {
    window.cohesion('monarch:ready', () => setReady(true));
  }, []);

  return results;
};
