import { useState } from 'react';
import { HiChevronUp } from 'react-icons/hi';
import { useSelector } from 'react-redux';

import { selectInput } from '@core/reducers/inputsSlice';
import { selectSubmissions } from '@core/reducers/matchesSlice';
import cn from '@core/utils/classNames';

import SubmissionsCard from '../SubmissionsCard';
import styles from './Submissions.module.css';

const Submissions = () => {
  const [isOpen, setIsOpen] = useState(true);
  const submissions = useSelector(selectSubmissions);
  const firstName = useSelector((state) => selectInput(state, 'firstName'));

  // We don't render this component if no submissions have been made
  if (!submissions?.length) return null;

  return (
    <section className={styles.submissionsSection}>
      <div className={styles.content}>
        <header className={styles.header}>
          <div className={styles.headerText}>
            <h2>Good news, {firstName}! You&apos;re ready for the next step.</h2>
            <p>
              Select <b>Next Steps</b> to learn about what you can expect from your call with a rep and how to apply.
            </p>
          </div>
          <button className={cn(isOpen && styles.flipped)} type="button" onClick={() => setIsOpen(!isOpen)}>
            <HiChevronUp size="2rem" />
          </button>
        </header>
        {isOpen && (
          <div className={styles.cardContainer}>
            {submissions?.map((submission, index) => (
              <SubmissionsCard key={submission.program.id} data={submission} position={index + 1} />
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default Submissions;
