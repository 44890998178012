import cloneDeep from 'lodash.clonedeep';

import getQuestionById from '@core/blocks/edu-flow/utils/getQuestionById';
import { Flags } from '@core/context/FeatureFlagsContext';

import { EduFlowBlock, Flow } from '../schemas/schema';

export default function generateTestSchema<T = Flow>(schema: T, flags: Flags): T {
  const newSchema = cloneDeep(schema) as Flow;

  const eduFlowIndex = newSchema.blocks.findIndex(({ type }) => type === 'EduFlow');

  const { pages } = newSchema.blocks[eduFlowIndex] as EduFlowBlock;

  // @ts-ignore
  newSchema.blocks[eduFlowIndex].pages = pages.map((page) => {
    const testId = `${page.questionId}-test`;
    const questionId = getQuestionById(testId, flags) ? testId : page.questionId;

    return {
      ...page,
      questionId,
    };
  });

  return newSchema as T;
}
