/**
 * classNames
 *
 * A simple utilty that takes class strings and returns a single evaluated string of classes.
 * Useful for dynamic class names tied to state. Provides a much cleaner way to input the logic.
 *
 * @param {string} classes as many comma separated strings of classes names
 * @returns {string}
 */

const cn = (...classes: string[]): string => classes.filter(Boolean).join(' ');

export default cn;
