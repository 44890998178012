// useWindowEvent allows you to set a global event listener on the
// window object from any component
// this is useful for modal components when you want clicks outside
// the modal to close the modal, without having to set up
// a div that covers the viewport (which is not accessible)
import { useRef, useEffect } from 'react';

const useWindowEvent = (type: string, listener): void => {
  // get a reference to the event being passed in
  const listenerRef = useRef(listener);
  listenerRef.current = listener;

  useEffect(() => {
    // set up the event handler to be triggered
    const handler = (event) => {
      listenerRef.current.call(window, event);
    };

    // attach given event to to proper listener
    window.addEventListener(type, handler);

    // remove event when needed
    return () => {
      window.removeEventListener(type, handler);
    };
  }, []);
};

export default useWindowEvent;
