import { type FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useElementEvents from '@core/hooks/cohesion/useElementEvents';
import { hideLeadDelivery, selectLeadDelivery } from '@core/reducers/matchesSlice';
import SlideOut from '@core/shared/components/SlideOut';
import SingleProgramSubmission from '@core/shared/features/SingleProgramSubmission';

const SubmissionSlideOut: FC = () => {
  const { currentPrograms, show } = useSelector(selectLeadDelivery);
  const currentProgram = currentPrograms?.[0];

  const { elementViewed, elementClicked } = useElementEvents({
    webElement: {
      location: 'drawer',
      name: 'thank-you-confirmation',
      htmlId: 'thank-you-x',
    },
  });

  useEffect(() => {
    if (show) {
      elementViewed();
    }
  }, [show]);

  // Action Dispatcher
  const dispatch = useDispatch();

  // close the modal
  const closeSlideOut = () => {
    dispatch(hideLeadDelivery());
  };

  const handleClose = () => {
    closeSlideOut();
    elementClicked();
  };

  return (
    <SlideOut show={show && Boolean(currentProgram?.program?.id)} onClose={handleClose}>
      <SingleProgramSubmission hit={currentProgram} />
    </SlideOut>
  );
};

export default SubmissionSlideOut;
