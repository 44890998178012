import { type FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import Card from '@core/blocks/eduResults/components/Card';
import { selectDcs } from '@core/reducers/dcsSlice';
import { selectInput } from '@core/reducers/inputsSlice';
import SponsoredResultsPopover from '@core/shared/components/SponsoredResultsPopover';
import type { Hit } from '@core/ts/algolia';
import type { VoyagerResult, VoyagerResultEnhancedSchoolData } from '@core/ts/results';

import styles from './EmbeddedRecommender.module.css';
import EmbeddedRecommenderCard from './EmbeddedRecommenderCard';

type Props = {
  enhancedSchoolData?: VoyagerResultEnhancedSchoolData;
  hits: Hit[];
  newResults?: boolean;
  showAdVerbiageTest?: boolean;
};
const EmbeddedRecommender: FC<Props> = ({ hits, newResults, enhancedSchoolData, showAdVerbiageTest }) => {
  const userFirstName = useSelector((state) => selectInput(state, 'firstName'));
  const dcs = useSelector(selectDcs);
  const {
    dcsSubjects: [subject],
  } = dcs;
  const listId = useMemo(() => uuid(), []);

  if (!hits?.length) {
    return null;
  }

  const cardComponent = (hit: Hit, index: number) => {
    if (!newResults) {
      return (
        <EmbeddedRecommenderCard
          key={hit.program.id}
          hit={hit}
          listId={listId}
          position={index + 1}
          isSingleResult={hits.length === 1}
        />
      );
    }
    return (
      <Card
        ctaLabel="Explore Program"
        isSingleResultRecommender={hits.length === 1}
        isRecommender
        enhancedSchoolData={enhancedSchoolData?.[hit?.school?.sdbSchoolId]}
        result={hit as VoyagerResult}
        position={index + 1}
        eventingOverrides={{
          location: 'voyager-recommender-embedded',
          customDimensions: [{ key: 'isUserRequested', value: 'true' }],
        }}
      />
    );
  };

  return (
    <section className={styles.embeddedRecommender} data-testid="embeddedRecommender">
      <header className={styles.embeddedRecommenderHeader}>
        <h2>
          {!newResults ? (
            <>Great news, {userFirstName}! You&apos;re a strong match for these schools! 🎉</>
          ) : (
            <>Your Top Matches For {subject}</>
          )}
        </h2>
        {showAdVerbiageTest ? (
          <SponsoredResultsPopover position="above" spanText="Learn More About Our Partners" />
        ) : (
          <SponsoredResultsPopover position="above" spanText="Sponsored" />
        )}
      </header>
      <div className={styles.cardContainer}>{hits?.map(cardComponent)}</div>
    </section>
  );
};
EmbeddedRecommender.defaultProps = {
  newResults: false,
};

export default EmbeddedRecommender;
