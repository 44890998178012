import cn from '@core/utils/classNames';

import styles from './RadioField.module.css';

const RadioField = (props) => {
  const { name, onChange, uiSchema, formData } = props;
  const title = uiSchema?.['ui:title'];
  const id = uiSchema?.['ui:id'];

  const options = uiSchema?.['ui:options']?.options;

  const handleClick = (value) => onChange(value);

  return (
    <label id={id} htmlFor={name} className={cn('control-label', 'prq')}>
      {title}
      {options?.map((option) => (
        <button
          key={option.label}
          type="button"
          className={styles.radioField}
          onClick={() => handleClick(option.value)}
        >
          <div className={cn(styles.radio, formData === option.value && styles.checked)} />
          {option.label}
        </button>
      ))}
    </label>
  );
};

export default RadioField;
