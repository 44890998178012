import { type FC } from 'react';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { useSelector } from 'react-redux';

import { selectInput } from '@core/reducers/inputsSlice';

import styles from './FastPassBanner.module.css';
import GroupSVG from './GroupSVG';

const list = (
  <ul>
    <li>
      <IoIosCheckmarkCircle size={20} />
      <p>Select each school to learn more.</p>
    </li>
    <li>
      <IoIosCheckmarkCircle size={20} />
      <p>Pick the one that fits you best.</p>
    </li>
    <li>
      <IoIosCheckmarkCircle size={20} />
      <p>Request more information.</p>
    </li>
  </ul>
);

const FastPassBanner: FC = () => {
  const firstName = useSelector((state) => selectInput(state, 'firstName'));

  return (
    <section className={styles.banner}>
      <span>
        <section>
          <GroupSVG />
          <h3>
            Hi, {firstName}! Explore Your Matches {list}
          </h3>
        </section>
        {list}
      </span>
    </section>
  );
};
export default FastPassBanner;
