/* eslint-disable no-nested-ternary */
// Components
import {
  Search,
  Results,
  FacetDropdown,
  ClearAllButton,
  PaginationControls,
  ResourcesBanner,
  RecommenderPopup,
  RelatedResults,
} from '@edu-match/components';
import BootcampSidebar from '@edu-match/components/BootcampSidebar';
import DuplicatePopup from '@edu-match/components/DuplicatePopup';
import SubmissionSlideOut from '@edu-match/components/SubmissionSlideOut';
import Submissions from '@edu-match/components/Submissions';
import usePopupData from '@edu-match/hooks/usePopupData';
// Hooks
import useSetBaseIndex from '@edu-match/hooks/useSetBaseIndex';
import getFacetFilters from '@edu-match/utils/getFacetFilters';
import getRelatedFilters from '@edu-match/utils/getRelatedFilters';
import { useState, useEffect } from 'react';
import { InstantSearch, Configure, RefinementList, SortBy } from 'react-instantsearch-dom';
import { useDispatch, useSelector } from 'react-redux';

import prospectScriptConfig from '@core/config/prospectScriptSchoolIds.json';
import { useFeatureFlags } from '@core/context/FeatureFlagsContext';
// Utils
import useAlgoliaClient from '@core/hooks/useAlgoliaClient';
import useDCSConsolidation from '@core/hooks/useDCSConsolidation';
import useFacetConfig from '@core/hooks/useFacetConfig';
import useFilterConfig from '@core/hooks/useFilterConfig';
import { setContactId } from '@core/reducers/contactSlice';
import { selectDcsCategories, selectDcsDegrees, selectDcsSubjects } from '@core/reducers/dcsSlice';
import { selectAllInputs } from '@core/reducers/inputsSlice';
// Reducer's Actions/Selectors
import {
  selectBaseIndex,
  selectExactMatches,
  selectRelatedMatches,
  selectSubmissions,
  selectIsRecommenderPopupOpen,
  selectShowDuplicatePopup,
  setIsRecommenderPopupOpen,
  selectLeadDelivery,
} from '@core/reducers/matchesSlice';
import { saveContact } from '@core/services/hub';
import { PageActions } from '@core/services/newRelic';
import SponsoredResultsPopover from '@core/shared/components/SponsoredResultsPopover';
import UserExperienceSurvey from '@core/shared/features/UserExperienceSurvey';
import type { VoyagerResult } from '@core/ts/results';
// Styles & Types
import buildLeadObject, { BuildLeadObjectInputs } from '@core/utils/buildLeadObject';
import cn from '@core/utils/classNames';
import { AddActiveProspectScript } from '@core/utils/scripts/AddActiveProspectScript';

import RevampLoading from '../edu-flow-2/components/Loading/index';
import styles from './EduMatch.module.css';
import Loading from './components/Loading';
import useGradResultsPageBannerTest from './hooks/useGradResultsPageBannerTest';

const EduMatch = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  // Matches State properties
  const baseIndex = useSelector(selectBaseIndex);
  const submissions = useSelector(selectSubmissions);

  const exactMatches = useSelector(selectExactMatches);
  const showDuplicatePopup = useSelector(selectShowDuplicatePopup);
  const showRecommenderPopup = useSelector(selectIsRecommenderPopupOpen);

  const { currentPrograms } = useSelector(selectLeadDelivery);

  // Prospect script schools
  const propsectScriptSchooIdList = prospectScriptConfig;
  const currentProgramsIdList = currentPrograms?.map((cp) => cp?.school?.id);
  const isActiveProspectScriptRequired = currentProgramsIdList?.some((cp) => propsectScriptSchooIdList.includes(cp));

  // DCS State properties
  const dcsDegrees = useSelector(selectDcsDegrees);
  const dcsSubjects = useSelector(selectDcsSubjects);
  const dcsCategories = useSelector(selectDcsCategories);

  // Related results
  const relatedResults: VoyagerResult[] = useSelector(selectRelatedMatches);

  // Check to see if we have spotlight or recommender results here - that way we can hide the UI until those results are ready
  const popupData = usePopupData();
  const inputs: Record<string, any> = useSelector(selectAllInputs);

  const flags = useFeatureFlags();
  const showAdVerbiageTest = flags?.adVerbiageTest === 'test';

  const showPopup = popupData?.data?.length && showRecommenderPopup;

  // Embedded Recommender Preamp Test - END

  useEffect(() => {
    if (popupData?.data?.length > 0) {
      dispatch(setIsRecommenderPopupOpen(true));
    }
  }, [popupData.data]);

  // Run our Filter config hook
  const { configFilters } = useFilterConfig();
  useFacetConfig();

  // Algolia Search Client
  const { searchClient, qualifications } = useAlgoliaClient({
    applyQualifications: true,
    refetchOnInputsChange: true,
  });

  const { searchClient: otherProgramsSearchClient } = useAlgoliaClient({
    applyQualifications: false,
    refetchOnInputsChange: true,
  });

  // fetch algolia credentials and init search client
  useSetBaseIndex();

  /*
   * DCS Consolidation hook that grabs unique hits from related matches and consolidates them into exact matches
   * Returns a list of unique matches
   */
  const hasDcsConsolidation = useDCSConsolidation().length;

  const isBootcamps = dcsDegrees.includes('Bootcamps');

  // Build Algolia Filters for exact matches
  const filters = getFacetFilters({
    dcsCategories,
    dcsDegrees,
    dcsSubjects: isBootcamps ? [] : dcsSubjects,
    configFilters,
    additionalFilters: [],
  });

  // Build facet Filters for related results
  const relatedFacetFilters = getRelatedFilters({
    subjects: dcsSubjects,
    degrees: dcsDegrees,
    configFilters,
  });

  // evaluate if related programs should be shown or not
  const showRelatedPrograms = exactMatches?.length < 52 && dcsDegrees?.length === 1 && dcsSubjects?.length === 1;

  useEffect(() => {
    // after 4 seconds pass and searchClient and recommenderHits are ready, hide loading dots
    const timeout = setTimeout(() => {
      if (searchClient) {
        setLoading(false);
      }
    }, 3000);
    return () => clearTimeout(timeout);
  }, [searchClient]);

  useEffect(() => {
    PageActions.ResultsLoaded({
      resultsPage: 'eduMatch',
      anonymousId: window?._Cohesion.anonymousId,
      sessionId: window?._Cohesion.sessionId,
    });
  }, []);

  useEffect(() => {
    if (!flags?.useContactDb) return;

    saveContact({
      degree: dcsDegrees[0],
      category: dcsCategories[0],
      subject: dcsSubjects[0],
      cohesionSessionId: window?._Cohesion?.sessionId,
      lead: buildLeadObject(inputs as BuildLeadObjectInputs),
    }).then((res) => {
      if (res?.data?.contactId) {
        dispatch(setContactId(res.data.contactId));
      }
    });
  }, [inputs, dcsDegrees, dcsSubjects, dcsCategories, flags]);

  const controlResultsBanner = (
    <header className={styles.resultsHeader}>
      {hasDcsConsolidation ? '' : <p>All results for:</p>}
      <h1>{`${dcsDegrees[0]} in ${dcsCategories[0]} programs`}</h1>
    </header>
  );

  const { TestBanner } = useGradResultsPageBannerTest(controlResultsBanner);

  // if search client or recommender is not ready, show loading dots
  const LoadingScreen = isBootcamps ? Loading : RevampLoading;
  if (loading) return <LoadingScreen />;

  return (
    <>
      {/* Handle Trusted Certificate */}
      {isActiveProspectScriptRequired && <AddActiveProspectScript />}
      {showDuplicatePopup && <DuplicatePopup searchClient={otherProgramsSearchClient} indexName={baseIndex} />}
      {showPopup && (
        <RecommenderPopup
          hits={popupData?.data}
          qualifications={qualifications}
          eventingOverrides={popupData?.eventing}
          showAdVerbiageTest={showAdVerbiageTest}
        />
      )}

      {/* exact match results */}
      <InstantSearch searchClient={searchClient} indexName={baseIndex}>
        {/* configure the results */}
        <Configure hitsPerPage={51} filters={filters} />

        <SubmissionSlideOut />
        {/* app container */}
        <div className={styles.eduMatchContainer}>
          {/* results header */}
          {/* this header should only be shown if there is just one degree and one category selected */}
          {dcsDegrees?.length === 1 && dcsCategories?.length === 1 ? <TestBanner /> : null}

          {/* search box */}
          {isBootcamps && (
            <>
              <Search />

              {/* desktop facets & filters */}
              <section className={styles.facetsFiltersDesktop}>
                <>
                  {/* majors & subjects */}
                  <FacetDropdown initialButtonText="Subject" filter="program.subject.name">
                    <RefinementList
                      attribute="program.subject.name"
                      defaultRefinement={dcsSubjects?.length ? [dcsSubjects[0]] : null}
                      searchable
                    />
                  </FacetDropdown>

                  {/* Location filters are dynamic depending if the user is searching for bootcamps */}
                  <FacetDropdown initialButtonText="Location" filter="program.miscellaneous.location">
                    <RefinementList attribute="program.miscellaneous.location" searchable />
                  </FacetDropdown>

                  {/* clear all refinements */}
                  <ClearAllButton clearsQuery />
                </>
                {/* sponsored results */}
                <div className={styles.resultsInfo}>
                  {showAdVerbiageTest ? (
                    <SponsoredResultsPopover spanText="Learn More About Our Partners" />
                  ) : (
                    <SponsoredResultsPopover spanText="Sponsored" />
                  )}
                </div>
                {/* sorting */}
                <SortBy
                  defaultRefinement={baseIndex}
                  items={[
                    { value: baseIndex, label: 'Best Match' },
                    { value: `${baseIndex}_distance`, label: 'Distance' },
                  ]}
                />
              </section>
            </>
          )}

          {/* mobile facets & filters */}
        </div>

        {/* Not showing this if the 'voyager-embedded-recommender' test is ON */}
        <Submissions />

        <div className={styles.eduMatchContainer}>
          {hasDcsConsolidation ? (
            <div className={styles.consolidationCopy}>
              <b>Here are your results!</b> The results on this page consist of schools that exactly match or are
              closely related to your interests.
            </div>
          ) : (
            ''
          )}

          {/* results/hits */}
          <section className={styles.resultsContainer}>
            {isBootcamps && <BootcampSidebar />}
            <div>
              <Results qualifications={qualifications} />
            </div>
          </section>
          <PaginationControls />
        </div>
      </InstantSearch>

      {/* related results */}
      {showRelatedPrograms && (
        <InstantSearch searchClient={searchClient} indexName={`${baseIndex}_related`}>
          <Configure filters={relatedFacetFilters} hitsPerPage={12} />

          <section className={styles.relatedPrograms}>
            {/* Show related results text when we have some, and conditionally render the copy depending on if we have exact matches */}
            {relatedResults?.length > 0 ? (
              exactMatches?.length > 0 ? (
                <p className={styles.relatedLabel}>
                  Other programs you might be interested in:
                  {showAdVerbiageTest ? (
                    <SponsoredResultsPopover position="below" spanText="Learn More About Our Partners" />
                  ) : (
                    <SponsoredResultsPopover position="below" spanText="Sponsored" />
                  )}
                </p>
              ) : (
                <p className={cn(styles.relatedLabel, styles.relatedLabelMargin)}>
                  Programs you might be interested in:
                  {showAdVerbiageTest ? (
                    <SponsoredResultsPopover position="below" spanText="Learn More About Our Partners" />
                  ) : (
                    <SponsoredResultsPopover position="below" spanText="Sponsored" />
                  )}
                </p>
              )
            ) : (
              <></>
            )}

            {/* added ts-ignore because connectHits has types that come from the algolia library
            his will go away when we change over to hooks
          @ts-ignore */}
            <RelatedResults exactMatchesCount={exactMatches?.length} />
          </section>
        </InstantSearch>
      )}
      <div className={styles.banners}>
        {flags?.userExperienceSurvey === 'test' && !showRecommenderPopup && submissions.length < 3 && (
          <UserExperienceSurvey />
        )}
      </div>

      {/* show banner after 3 or more submissions */}
      {submissions.length >= 3 && (
        <div className={styles.bannerSection}>
          <ResourcesBanner />
        </div>
      )}
    </>
  );
};

export default EduMatch;
