import { useState, useEffect } from 'react';
import { HiAdjustments } from 'react-icons/hi';
import { connectStateResults } from 'react-instantsearch-dom';
import { useDispatch } from 'react-redux';

// Reducers selectors/actions
import { setShowMobileMenu } from '@core/reducers/matchesSlice';

// Utils
import styles from './FiltersButton.module.css';

const FiltersButton = connectStateResults(({ searchState }) => {
  // set up state
  const [numRefinements, setNumRefinements] = useState(0);

  // Action Dispatcher
  const dispatch = useDispatch();

  // update numResults when filters are applied
  useEffect(() => {
    let num = 0;

    for (const item in searchState.refinementList) {
      num += searchState.refinementList[item].length;
    }

    setNumRefinements(num);
  }, [searchState?.refinementList]);

  return (
    <button type="button" className={styles.filtersButton} onClick={() => dispatch(setShowMobileMenu(true))}>
      <HiAdjustments className={styles.icon} /> Filters
      {numRefinements > 0 && <span>({numRefinements})</span>}
    </button>
  );
});

export { FiltersButton };
