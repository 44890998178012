import { FC } from 'react';

import { CardProps } from '@core/blocks/eduResults/components/Card/DefaultCard/DefaultCard';
import LeadSubmittedCta from '@core/blocks/eduResults/components/LeadSubmittedCta';
import { EnhancedSchoolData } from '@core/services/hub';

import styles from './SpotlightCard.module.css';

type SpotlightCardProps = CardProps & { enhancedSchoolData?: EnhancedSchoolData };

const SpotlightCard: FC<SpotlightCardProps> = ({
  handleProductClick,
  program,
  school,
  enhancedSchoolData,
  cardRef,
  isProgramSubmitted,
  ctaLabel,
  cta,
}) => (
  <button type="button" className={styles.card} onClick={handleProductClick}>
    <div ref={cardRef} className={styles.header}>
      <img src={`https://simple-storage-server.highereducation.com/${school?.slug}.png?w=130&h=80`} alt={school.name} />
      <div className={styles.title}>
        <h3>{school?.name}</h3>
        <div className={styles.subtitle}>
          {school?.city}, {school?.state}
        </div>
      </div>
    </div>
    <h2> {program.name} </h2>
    <p className={styles.description}>{program.snippet}</p>
    <div className={styles.content}>
      <div className={styles.data}>
        <div className={styles.dataPoint}>
          <div className={styles.title}>Start Date</div>
          <div className={styles.value}>{enhancedSchoolData?.startDate ?? 'Not provided'}</div>
        </div>
        <div className={styles.dataPoint}>
          <div className={styles.title}>Acceptance Rate</div>
          <div className={styles.value}>{enhancedSchoolData?.acceptanceRate ?? 'Not provided'}</div>
        </div>
      </div>
    </div>
  </button>
);

export { SpotlightCard };
