import getGeoAndInputCandidates from './getGeoAndInputCandidates';
import getGeoCandidates from './getGeoCandidates';

const getAllCandidates = ({ rules, inputs = {}, currentState }) => [
  ...getGeoAndInputCandidates({ geoAndInputRules: rules?.geoAndInput, inputs, currentState }),
  ...getGeoCandidates({ geoRules: rules?.geo, currentState }),
  // uncomment this when we start using DCS fallbacks
  // ...getDcsCandidates(),
];
export default getAllCandidates;
