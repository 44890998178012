import algoliasearch from 'algoliasearch';

import { mobiusGetAlgoliaCredentials } from '@core/services/mobius';
import { AlgoliaCredentials } from '@core/ts/algolia';

import { newRelicNoticeError, nrErrorMap } from './newRelic';

const initSearchClient = async (options) => {
  try {
    const credentials: AlgoliaCredentials = await mobiusGetAlgoliaCredentials(options);

    // initialize client
    return algoliasearch(import.meta.env.VITE_ALGOLIA_APP_ID, credentials?.key);
  } catch (err) {
    newRelicNoticeError(nrErrorMap.ALGOLIA_CLIENT, err);
    return null;
  }
};
export default initSearchClient;
