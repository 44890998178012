const CursorclickSVG = () => (
  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.9998 20L17.3331 26.6666L11.9998 12L26.6664 17.3333L19.9998 20ZM19.9998 20L26.6664 26.6666M9.58408 2.98468L10.6194 6.84838M6.84808 10.6196L2.98438 9.58433M18.5994 5.40037L15.771 8.2288M8.22861 15.7711L5.40019 18.5996"
      stroke="#8B4C4C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CursorclickSVG;
