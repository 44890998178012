import cn from '@core/utils/classNames';
import convertToSlug from '@core/utils/strings/convertToSlug';

// styles
import styles from './OptionGroupField.module.css';

const Design2 = ({ field, handleSelection, isSelected, selection }) => (
  <div className={styles.optionGroup2Container}>
    {field.options?.map((option) => (
      <div key={option.label} data-testid={convertToSlug(`${field.name} ${option.value}`)}>
        <input
          id={option.label}
          type="checkbox"
          onChange={() => handleSelection(option)}
          className={styles.optionCheckmark}
          value={option.value ?? option.label}
          aria-label={option.label}
        />

        <label
          className={cn(
            isSelected(selection, option) ? styles.selected : undefined,
            styles[field.labelWeight ?? 'normal']
          )}
          htmlFor={option.label}
        >
          {option.label}
        </label>
      </div>
    ))}
  </div>
);

export default Design2;
