import type { FC } from 'react';

import styles from './ErrorPage.module.css';

type Props = {
  appEntryURL: string;
};

const ErrorPage: FC<Props> = ({ appEntryURL }) => (
  <div className={styles.errorPageContainer}>
    <h2>Oops! It seems like something unexpected happened.</h2>
    <p>
      We apologize for the inconvenience. Our team has been notified and is working diligently to resolve the issue.
      Please try again later. In the meantime, you can click <a href={appEntryURL}>here</a> to go back to the start
      page. Thank you for your patience and understanding!
    </p>
  </div>
);

export default ErrorPage;
