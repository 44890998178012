import { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { selectTestResultById } from '@core/reducers/monarchSlice';

import FastPassBanner from '../components/GradResultsPageBannerTest/FastPassBanner';
import ShopperBanner from '../components/GradResultsPageBannerTest/ShopperBanner';

/**
 * @param controlBanner - control experience banner
 * @returns TestBanner - the test experience banner based on the monarch test result
 */
const useGradResultsPageBannerTest = (controlBanner: ReactElement) => {
  // Record for mapping test result to the experience test banner
  const bannerTestMap: Record<string, FC> = {
    fast: FastPassBanner,
    shopper: ShopperBanner,
    control: () => controlBanner,
  };
  // Get the test result from the monarch slice
  const testResult: string = useSelector((state) => selectTestResultById(state, 'voyagerRPBannerTest'));

  // Map the test result to the experience test banner - default to control banner
  return { TestBanner: bannerTestMap[testResult] ?? bannerTestMap.control };
};
export default useGradResultsPageBannerTest;
