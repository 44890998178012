import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectInitialInputsAdded } from '@core/reducers/configSlice';
import { selectDcsCategories, selectDcsDegrees, selectDcsSubjects } from '@core/reducers/dcsSlice';
// Reducers selectors/actions
import { selectAllInputs } from '@core/reducers/inputsSlice';
// Types
import { IDCSRule, IInputRule, IPublisherRule, IRule } from '@core/ts/rules';
// Utils
import { resolveDCSRule, resolveInputRule, resolvePublisherRule } from '@core/utils/resolveRule';

import useQueryParams from './useQueryParams';

/**
 * useRuleEngine handles resolving configuration rules
 * @returns
 */
const useRuleEngine = () => {
  const [isReady, setIsReady] = useState(false);

  const inputsInitialized = useSelector(selectInitialInputsAdded);

  const queryParams = useQueryParams();

  // Get all app inputs to resolve input rules
  const inputs = useSelector(selectAllInputs);

  // Get all dcs inputs to resolve dcs rules
  const dcsDegrees = useSelector(selectDcsDegrees);
  const dcsSubjects = useSelector(selectDcsSubjects);
  const dcsCategories = useSelector(selectDcsCategories);

  // DCS must match at least one item in the list for degree, subjects and categories
  const DCSRuleResolver = (rule: IDCSRule): boolean => {
    const result = resolveDCSRule(rule, dcsDegrees, dcsSubjects, dcsCategories);
    return result;
  };

  // We have an input that matches the rule operator and value
  const inputRuleResolver = (rule: IInputRule): boolean => {
    const result = resolveInputRule(rule, inputs);
    return result;
  };

  const publisherRuleResolver = (rule: IPublisherRule): boolean => {
    const name = window?.HE?.publisher?.name || queryParams.publisher;
    const result = resolvePublisherRule(rule, name);
    return result;
  };

  const resolverRegistry = {
    DCS: DCSRuleResolver,
    INPUT: inputRuleResolver,
    PUBLISHER: publisherRuleResolver,
  };

  // Resolves a rule, will use the correct resolver based on the rule type
  const resolveRule = (rule: IRule): boolean => {
    if (!rule) return false;
    const resolver = resolverRegistry[rule.type];
    if (!resolver) return false;
    return resolver(rule);
  };

  // Resolves a list of rules and returns true if all rules are true
  const resolveRules = (rules: Array<IRule>): boolean => {
    // If no rules then return false
    if (!rules || rules.length === 0) return false;
    // All rules must resolve to true
    return rules.every(resolveRule);
  };

  useEffect(() => {
    if (!inputsInitialized) return;
    setIsReady(true);
  }, [inputsInitialized]);

  return { isReady, resolveRule, resolveRules };
};

export default useRuleEngine;
