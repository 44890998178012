import spotlightConfig from '@core/config/spotlight.json';

const emptyRules = {
  input: [],
  geo: [],
  fallback: [],
};

const getSpotlightRulesForDcs = ({ degree, category, subject, spotlightConfigOverride = undefined }) => {
  const dcsKey = `${degree}|${category}|${subject}`;
  const config = spotlightConfigOverride ?? spotlightConfig;
  const rules = config[dcsKey];
  return rules ?? emptyRules;
};

export default getSpotlightRulesForDcs;
