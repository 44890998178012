/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
import { connectHits } from 'react-instantsearch-dom';

import { Hit } from '@core/ts/algolia';

export type ReturnComponentProps = { hits?: Hit[] } & Record<string, unknown>;

export type WithExtraPropsType = (Component: FC<unknown>) => FC<ReturnComponentProps>;

const withExtraProps: WithExtraPropsType = (Component: FC<unknown>) => {
  const Hits = connectHits<Hit>(Component);

  Hits.defaultProps = { hits: [] };

  return (props) => <Hits {...props} />;
};

export default withExtraProps;
