// Parses the page heading to make sure it doesn't have any "PII personalization"
// If it does have PII personalization then it replaces
// ex: {firstName} becomes the value for voyager[firstName]
import { useSelector } from 'react-redux';

import { selectDcs } from '@core/reducers/dcsSlice';
import { selectAllInputs } from '@core/reducers/inputsSlice';

type parsePageHeading = (heading: string | unknown, label?: boolean) => string;

type UsePageHeadingParser = () => parsePageHeading;

const usePageHeadingParser: UsePageHeadingParser = () => {
  const inputs = useSelector(selectAllInputs);
  const dcs = useSelector(selectDcs);

  const parsePageHeading: parsePageHeading = (heading, label = false) => {
    if (typeof heading !== 'string') return '';

    // Safari compatible regex
    const matches = heading.match(/{(.*?)}/g);

    if (!matches) {
      return heading;
    }

    // Since we have to do Safari compatible regex there is extra work to do...
    // Remove the curly brackets
    const voyagerKeys = matches.map((match) => match.replace(/{|}/gm, ''));

    // Replace - example {firstName} replaced with voyager[firstName]

    let parsedHeading = heading;
    voyagerKeys.forEach((voyagerKey, index) => {
      const replacement = label
        ? inputs[voyagerKey]?.options?.label
        : (inputs[voyagerKey]?.value || dcs[voyagerKey]?.[0]) ?? '';
      parsedHeading = parsedHeading.replace(`{${voyagerKey}}`, replacement ?? '');
    });
    return parsedHeading;
  };

  return parsePageHeading;
};

export default usePageHeadingParser;
