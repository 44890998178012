/**
 * Creates a search filter for a given group of filters
 * @param filters list of filters that will go together
 * @param separator 'AND', 'OR' how the filters will relate to eachother
 * @param enclose true if the filter should be wrapped in parentheses
 * @returns filter string
 */
export const buildFilter = (filters: string[], separator = 'AND', enclose = false): string => {
  if (!filters || filters.length === 0) return '';
  // Filter our any empty filters
  const newFilters = filters.filter((value) => value && value !== '');
  if (newFilters.length === 0) return '';
  let filter = newFilters.join(` ${separator} `);
  if (enclose) filter = `(${filter})`;
  return filter;
};

// Creates a search filter for a given facet and value
export const buildFacetFilter = (facet: string | null, value: string | null, isNegation = false): string => {
  if (!facet || !value || facet === '' || value === '') return '';
  const filter = `${facet}:"${value}"`;
  return isNegation ? `NOT ${filter}` : filter;
};

// Creates a search filter for a group of values within a given facet
export const groupFacetFilter = (
  facet: string,
  values: string[],
  isNegation = false,
  separator = 'AND',
  enclose = false
): string => {
  if (!values) return '';
  const filters = values.map((value) => buildFacetFilter(facet, value, isNegation));
  return buildFilter(filters, separator, enclose);
};

export default { buildFilter, buildFacetFilter, groupFacetFilter };
