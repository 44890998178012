// utils
import { type FC } from 'react';

import usePageHeadingParser from '@core/blocks/edu-flow/components/Page/hooks/usePageHeadingParser';
import cn from '@core/utils/classNames';

// styles
import styles from './Heading.module.css';

type Props = {
  preSubheading?: string;
  heading?: string;
  postSubheading?: string;
};

const Heading: FC<Props> = ({ preSubheading, heading, postSubheading }) => {
  const parsePageHeading = usePageHeadingParser();
  return (
    <span className={styles.headingContainer}>
      {preSubheading && <p dangerouslySetInnerHTML={{ __html: parsePageHeading(preSubheading) }} />}
      {heading && (
        <h2
          className={cn(!(preSubheading && postSubheading) ? styles.soloHeading : '')}
          dangerouslySetInnerHTML={{ __html: parsePageHeading(heading) }}
        />
      )}
      {postSubheading && <p dangerouslySetInnerHTML={{ __html: parsePageHeading(postSubheading) }} />}
    </span>
  );
};

export default Heading;
