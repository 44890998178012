import { Flags } from '@core/context/FeatureFlagsContext';
import { type DCS } from '@core/reducers/dcsSlice';
import { EduRequestV2, type QualificationResults } from '@core/ts/leadDelivery';
import { generateEduRequestV2 } from '@core/utils/requests/EduRequestV2';

import { type InjectionKey } from '../getQuestionInjectionSchema';

export type GCPParams = {
  dcs: DCS;
  ipInferredPostalCode: string;
};

const qualificationsMap = {
  isGradDegree: 'E0C4',
  isEabGradBisCategory: 'E3E1',
  hasEABGradBisZipcode: 'B7B6',
  isEABEducationCategory: 'E8F4',
  hasEABGradEduZipcode: 'D1A0',
  isAssociatesDegree: 'B1C0',
  isEmccSubject: 'F0E5',
  hasEMCCZipcode: 'F7E6',
  hasEABGradHealthZipcode: 'E3C1',
  isEABHealthcareCategory: 'F7A4',
  isUndergradDegree: 'A4B7',
  hasEABUndergradZipcode: 'C1E1',
};

const {
  isGradDegree,
  isEabGradBisCategory,
  hasEABGradBisZipcode,
  isEABEducationCategory,
  hasEABGradEduZipcode,
  isAssociatesDegree,
  isEmccSubject,
  hasEMCCZipcode,
  isEABHealthcareCategory,
  hasEABGradHealthZipcode,
  isUndergradDegree,
  hasEABUndergradZipcode,
} = qualificationsMap;

// EAB Grad Tranches
// const isGradBusinessEligible = [isGradDegree, isEabGradBisCategory, hasEABGradBisZipcode];
// The line above was the original code.  For July2024, removed the zip qualification for EAB Grad Business
const isGradBusinessEligible = [isGradDegree, isEabGradBisCategory];
const isGradEducationEligible = [isGradDegree, isEABEducationCategory, hasEABGradEduZipcode];
const isGradHealthEligible = [isGradDegree, isEABHealthcareCategory, hasEABGradHealthZipcode];

// EAB Undergrad Tranch
const isUndergradEligible = [isUndergradDegree, hasEABUndergradZipcode];

// EMCC Tranch
const isEmccEligible = [isAssociatesDegree, isEmccSubject, hasEMCCZipcode];

// Map of partner keys to their respective qualification tranches
const PartnerKeyToQualificationsMap: Record<string, string[][]> = {
  EAB_GRAD: [isGradBusinessEligible, isGradEducationEligible, isGradHealthEligible],
  EMCC: [isEmccEligible],
  EAB_UNDERGRAD: [isUndergradEligible],
};
// Map of partner keys to their respective feature flags
const PartnerKeyToFeatureFlagMap: Record<string, Array<keyof Flags>> = {
  EAB_GRAD: ['useEAB', 'testEAB'],
  EAB_UNDERGRAD: ['useEAB', 'testEAB'],
  EMCC: ['useEMCC', 'testEMCC'],
};

/**
 *
 * @param qualified QualificationResults['qualified']
 * @returns returns all the slugs that the user is qualified for
 */
export const getQualifiedPartnerKeys = (qualified: QualificationResults['qualified'], flags: Flags): InjectionKey[] =>
  Object.entries(PartnerKeyToQualificationsMap).reduce((injectionKeys: InjectionKey[], [slug, tranches]) => {
    // search through each partner and their tranches to verify eligibility
    const isQualified = tranches.some((qualificationIds) =>
      qualificationIds.every((id) => qualified.some((qualification) => qualification.id === id))
    );
    const hasFeatureFlagActive = PartnerKeyToFeatureFlagMap[slug].some((flag: keyof Flags) => flags[flag]);

    if (isQualified && hasFeatureFlagActive) return [...injectionKeys, slug] as InjectionKey[];
    return injectionKeys;
  }, []);

/**
 *
 * @param param0.dcs DCS
 * @param param0.ipInferredPostalCode string
 * @returns generate the payload for the qualify request
 */
export const generateQualifyPayload = ({ dcs, ipInferredPostalCode }: GCPParams): EduRequestV2 =>
  generateEduRequestV2({
    lead: {
      location: {
        ipInferredPostalCode,
      },
    },
    programContext: {
      degree: {
        name: dcs.dcsDegrees[0],
      },
      category: {
        name: dcs.dcsCategories[0],
      },
      subject: {
        name: dcs.dcsSubjects[0],
      },
    },
    trackingContext: {
      publisher: window.HE?.publisher?.name ?? window.location.host,
    },
  });
