import React, { useEffect } from 'react';
import { connectHits } from 'react-instantsearch-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';

import RevampCard from '@core/blocks/eduResults/components/Card';
import { programDifference } from '@core/hooks/useDCSConsolidation';
import { selectDcs } from '@core/reducers/dcsSlice';
import { selectUniqueMatches, setRelatedMatches } from '@core/reducers/matchesSlice';
import { Hit } from '@core/ts/algolia';

import { Card } from '../Card';
import styles from './Results.module.css';

// added ts-ignore because connectHits has types that come from the algolia library
// this will go away when we change over to hooks
// @ts-ignore
const RelatedResults = connectHits<Hit>(({ hits, exactMatchesCount }) => {
  // dispatch to store
  const dispatch: Dispatch = useDispatch();
  const {
    dcsDegrees: [degree],
  } = useSelector(selectDcs);
  const useRevampCard = degree === 'Bootcamps';

  /*
   * Returns a list of unique matches (No duplicate schools) that will be removed from the related results
   * since they're consolidated in the exact matches.
   */
  const borrowedMatches = useSelector(selectUniqueMatches);
  const unborrowedMatches = programDifference(hits, borrowedMatches as Hit[]);

  useEffect(() => {
    // Save the related matches to the store for DCS consolidation use
    dispatch(setRelatedMatches(hits));
  }, [hits]);
  return (
    <ol className={styles.results}>
      {unborrowedMatches.map((hit: Hit, index) => (
        <li>
          {!useRevampCard ? (
            <Card
              dataTestId="related-matches-card"
              key={hit.program.id}
              hit={hit}
              eventingOverrides={{
                customDimensions: [{ key: 'isUserRequested', value: 'false' }],
                product: { location: 'voyager-related-results-page', position: index + 1 + exactMatchesCount },
              }}
            />
          ) : (
            <RevampCard
              ctaLabel="Explore Program"
              key={hit.program.id}
              result={hit}
              eventingOverrides={{
                customDimensions: [{ key: 'isUserRequested', value: 'false' }],
                product: { location: 'voyager-related-results-page', position: index + 1 + exactMatchesCount },
              }}
            />
          )}
        </li>
      ))}
    </ol>
  );
});

export { RelatedResults };
