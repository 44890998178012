export const elementEventPayloads = {
  giveFeedbackButton: {
    webElement: {
      elementType: 'user-survey',
      name: 'give-feedback',
      location: 'voyager-results-page',
      text: 'Give feedback',
    },
  },
  popupXButton: {
    webElement: {
      elementType: 'user-survey',
      name: 'survey-feedback',
      location: 'voyager-results-page',
      text: 'x',
    },
  },
  popupCloseButton: {
    webElement: {
      elementType: 'user-survey',
      name: 'survey-feedback',
      location: 'voyager-results-page',
      text: 'Close',
    },
  },
  popupCancelButton: {
    webElement: {
      elementType: 'user-survey',
      name: 'survey-feedback',
      location: 'voyager-results-page',
      text: 'Cancel',
    },
  },
  popupSubmitButton: {
    webElement: {
      elementType: 'user-survey',
      name: 'survey-feedback',
      location: 'voyager-results-page',
      text: 'Submit',
    },
  },
};

export const userInputFieldPayload = {
  autofilled: false,
  fieldName: 'survey-feedback',
  fieldLabel: 'Give us your feedback',
  fieldType: 'checkbox',
};
