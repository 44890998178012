import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import type { BlockPage } from '@core/schemas/schema';

const RootRouteHandler: FC<{ pages: BlockPage[] }> = ({ pages }) => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(pages[0].slug);
  }, []);
  return null;
};

export default RootRouteHandler;
