import { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectInput } from '@core/reducers/inputsSlice';

import ClipboardSVG from './ClipboardSVG';
import CursorclickSVG from './CursorclickSVG';
import LibrarySVG from './LibrarySVG';
import styles from './ShopperBanner.module.css';

const ShopperBanner: FC = () => {
  const firstName = useSelector((state) => selectInput(state, 'firstName'));
  return (
    <section className={styles.banner}>
      <h3>Next Steps, {firstName}:</h3>
      <ul>
        <li>
          <LibrarySVG />
          <p>Compare your schools.</p>
        </li>
        <li>
          <ClipboardSVG />
          <p>Pick the one that fits you best.</p>
        </li>
        <li>
          <CursorclickSVG />
          <p>Apply online today.</p>
        </li>
      </ul>
    </section>
  );
};
export default ShopperBanner;
