import determineColor from '@core/utils/determineColor';

const Icon3 = () => (
  <svg width="211" height="97" viewBox="0 0 211 97" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_dd_422_18913)">
      <rect x="5.02344" y="3.32617" width="200" height="62" rx="6" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.0234 25.3262C27.7097 25.3262 25.0234 28.0124 25.0234 31.3261C25.0234 34.6398 27.7097 37.3261 31.0234 37.3261C34.3371 37.3261 37.0234 34.6398 37.0234 31.3261C37.0234 28.0124 34.3371 25.3262 31.0234 25.3262ZM22.0234 31.3261C22.0234 26.3556 26.0529 22.3262 31.0234 22.3262C35.994 22.3262 40.0234 26.3556 40.0234 31.3261C40.0234 33.2699 39.4072 35.0697 38.3595 36.5409L45.584 43.7654C46.1698 44.3512 46.1698 45.301 45.584 45.8868C44.9982 46.4725 44.0485 46.4725 43.4627 45.8868L36.2382 38.6622C34.767 39.7099 32.9671 40.3261 31.0234 40.3261C26.0529 40.3261 22.0234 36.2967 22.0234 31.3261Z"
        fill={determineColor('--primary-500')}
      />
      <rect x="5.52344" y="3.82617" width="199" height="61" rx="5.5" stroke="#E6E8E9" />
    </g>
    <rect x="5.86295" y="77.6657" width="68.7778" height="18.3335" rx="3.7346" fill={determineColor('--primary-50')} />
    <path
      d="M21.7523 86.833C21.7523 89.0456 19.9587 90.8392 17.7461 90.8392C15.5335 90.8392 13.7399 89.0456 13.7399 86.833C13.7399 84.6205 15.5335 82.8268 17.7461 82.8268C19.9587 82.8268 21.7523 84.6205 21.7523 86.833ZM19.9999 86.3708C20.3447 86.026 20.3447 85.4671 19.9999 85.1224C19.6552 84.7777 19.0963 84.7777 18.7516 85.1224L17.2029 86.6711L16.7406 86.2088C16.3959 85.8641 15.837 85.8641 15.4923 86.2088C15.1476 86.5536 15.1476 87.1125 15.4923 87.4572L16.5787 88.5436C16.9234 88.8884 17.4823 88.8884 17.8271 88.5436L19.9999 86.3708Z"
      fill={determineColor('--primary-400')}
      stroke={determineColor('--primary-400')}
      strokeWidth="0.679019"
    />
    <rect x="25.8945" y="83.0977" width="40.938" height="7.4692" rx="1.35804" fill={determineColor('--primary-300')} />
    <rect
      x="5.86295"
      y="77.6657"
      width="68.7778"
      height="18.3335"
      rx="3.7346"
      stroke={determineColor('--primary-300')}
      strokeWidth="0.679019"
    />
    <rect x="80.7516" y="77.6657" width="58.6604" height="18.3335" rx="3.7346" fill={determineColor('--primary-50')} />
    <path
      d="M96.641 86.833C96.641 89.0456 94.8473 90.8392 92.6348 90.8392C90.4222 90.8392 88.6286 89.0456 88.6286 86.833C88.6286 84.6205 90.4222 82.8268 92.6348 82.8268C94.8473 82.8268 96.641 84.6205 96.641 86.833ZM94.8886 86.3708C95.2333 86.026 95.2333 85.4671 94.8886 85.1224C94.5439 84.7777 93.985 84.7777 93.6402 85.1224L92.0916 86.6711L91.6293 86.2088C91.2846 85.8641 90.7257 85.8641 90.381 86.2088C90.0362 86.5536 90.0362 87.1125 90.381 87.4572L91.4674 88.5436C91.8121 88.8884 92.371 88.8884 92.7157 88.5436L94.8886 86.3708Z"
      fill={determineColor('--primary-400')}
      stroke={determineColor('--primary-400')}
      strokeWidth="0.679019"
    />
    <rect x="100.783" y="83.0977" width="30.8207" height="7.4692" rx="1.35804" fill={determineColor('--primary-300')} />
    <rect
      x="80.7516"
      y="77.6657"
      width="58.6604"
      height="18.3335"
      rx="3.7346"
      stroke={determineColor('--primary-300')}
      strokeWidth="0.679019"
    />
    <rect x="145.523" y="77.6657" width="58.6604" height="18.3335" rx="3.7346" fill="white" />
    <path
      d="M161.752 86.832C161.752 89.2321 159.806 91.1778 157.406 91.1778C155.006 91.1778 153.061 89.2321 153.061 86.832C153.061 84.432 155.006 82.4863 157.406 82.4863C159.806 82.4863 161.752 84.432 161.752 86.832Z"
      fill="#E6E8E9"
    />
    <rect x="165.555" y="83.0977" width="30.8207" height="7.4692" rx="1.35804" fill="#CDD2D4" />
    <rect
      x="145.523"
      y="77.6657"
      width="58.6604"
      height="18.3335"
      rx="3.7346"
      stroke="#CDD2D4"
      strokeWidth="0.679019"
    />
    <defs>
      <filter
        id="filter0_dd_422_18913"
        x="0.0234375"
        y="0.326172"
        width="210"
        height="72"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology radius="1" operator="erode" in="SourceAlpha" result="effect1_dropShadow_422_18913" />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_422_18913" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_422_18913" result="effect2_dropShadow_422_18913" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_422_18913" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default Icon3;
