import { ClearAllButton } from '@edu-match/components';
import React from 'react';

// components
import NoResultsImage from './Image';
// styles
import styles from './NoResults.module.css';

const NoResults = () => (
  <div className={styles.noResults} data-testid="no-results">
    <div>
      <h2>We couldn&apos;t find a direct match for your search</h2>
      <p>
        Try updating or <ClearAllButton clearsQuery label="clearing all" /> of your selected filters.
      </p>
    </div>
    <div className={styles.noResultsImage}>
      <NoResultsImage />
    </div>
  </div>
);

export { NoResults };
