import determineColor from '@core/utils/determineColor';

const Icon2 = () => (
  <svg width="221" height="136" viewBox="0 0 221 136" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_dd_422_18931)">
      <g clipPath="url(#clip0_422_18931)">
        <rect x="5.03125" y="3.3418" width="171.944" height="86.9454" rx="5.19077" fill="white" />
        <rect x="5.03125" y="3.3418" width="171.944" height="6.48846" fill={determineColor('--primary-100')} />
        <g filter="url(#filter1_dd_422_18931)">
          <g clipPath="url(#clip1_422_18931)">
            <g filter="url(#filter2_d_422_18931)">
              <path
                d="M15.4121 3.3418H99.7621V37.0818C99.7621 39.9486 97.4382 42.2726 94.5714 42.2726H20.6029C17.7361 42.2726 15.4121 39.9486 15.4121 37.0818V3.3418Z"
                fill="white"
              />
            </g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M27.0938 13.8171C27.2336 13.755 27.3932 13.755 27.533 13.8171L32.3994 15.9799C32.6723 16.1012 32.7952 16.4208 32.6739 16.6936C32.5526 16.9665 32.2331 17.0894 31.9602 16.9681L27.3134 14.9029L22.6667 16.9681C22.3938 17.0894 22.0743 16.9665 21.953 16.6936C21.8317 16.4208 21.9546 16.1012 22.2275 15.9799L27.0938 13.8171ZM21.9064 18.0962C21.9064 17.7975 22.1485 17.5554 22.4471 17.5554H32.1798C32.4784 17.5554 32.7205 17.7975 32.7205 18.0962C32.7205 18.3948 32.4784 18.6369 32.1798 18.6369V23.5032C32.4784 23.5032 32.7205 23.7453 32.7205 24.0439C32.7205 24.3425 32.4784 24.5846 32.1798 24.5846H22.4471C22.1485 24.5846 21.9064 24.3425 21.9064 24.0439C21.9064 23.7453 22.1485 23.5032 22.4471 23.5032V18.6369C22.1485 18.6369 21.9064 18.3948 21.9064 18.0962ZM23.5285 18.6369V23.5032H31.0984V18.6369H23.5285ZM25.1506 19.7183C25.4492 19.7183 25.6913 19.9603 25.6913 20.259V21.8811C25.6913 22.1797 25.4492 22.4218 25.1506 22.4218C24.852 22.4218 24.6099 22.1797 24.6099 21.8811V20.259C24.6099 19.9603 24.852 19.7183 25.1506 19.7183ZM27.3134 19.7183C27.6121 19.7183 27.8541 19.9603 27.8541 20.259V21.8811C27.8541 22.1797 27.6121 22.4218 27.3134 22.4218C27.0148 22.4218 26.7727 22.1797 26.7727 21.8811V20.259C26.7727 19.9603 27.0148 19.7183 27.3134 19.7183ZM29.4763 19.7183C29.7749 19.7183 30.017 19.9603 30.017 20.259V21.8811C30.017 22.1797 29.7749 22.4218 29.4763 22.4218C29.1776 22.4218 28.9356 22.1797 28.9356 21.8811V20.259C28.9356 19.9603 29.1776 19.7183 29.4763 19.7183Z"
              fill={determineColor('--primary-300')}
            />
            <rect
              x="36.3438"
              y="14.748"
              width="30.1923"
              height="8.85745"
              rx="1.29769"
              fill={determineColor('--primary-100')}
            />
          </g>
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M114.394 21.139C115.458 20.0748 117.184 20.0748 118.248 21.139C119.312 22.2032 119.312 23.9287 118.248 24.993L116.321 26.9199L114.394 24.993C113.33 23.9287 113.33 22.2032 114.394 21.139ZM116.321 23.8446C116.751 23.8446 117.099 23.496 117.099 23.066C117.099 22.636 116.751 22.2874 116.321 22.2874C115.891 22.2874 115.542 22.636 115.542 23.066C115.542 23.496 115.891 23.8446 116.321 23.8446Z"
          fill={determineColor('--primary-400')}
        />
        <rect x="121.512" y="20.2363" width="45.0817" height="6.43877" rx="1.29769" fill="#CDD2D4" />
        <rect x="18.0078" y="46.166" width="115.028" height="8.39857" rx="1.29769" fill="#CDD2D4" />
        <rect x="18.0078" y="59.7549" width="139.201" height="17.6431" rx="1.29769" fill="#CDD2D4" />
      </g>
      <rect x="5.53125" y="3.8418" width="170.944" height="85.9454" rx="4.69077" stroke="#E6E8E9" />
    </g>
    <g filter="url(#filter3_dd_422_18931)">
      <g clipPath="url(#clip2_422_18931)">
        <rect x="43.0684" y="41.3799" width="171.944" height="86.9454" rx="5.19077" fill="white" />
        <rect x="43.0684" y="41.3799" width="171.944" height="6.48846" fill={determineColor('--primary-100')} />
        <g filter="url(#filter4_dd_422_18931)">
          <g clipPath="url(#clip3_422_18931)">
            <g filter="url(#filter5_d_422_18931)">
              <path
                d="M53.4473 41.3799H137.797V75.1199C137.797 77.9867 135.473 80.3107 132.607 80.3107H58.638C55.7713 80.3107 53.4473 77.9867 53.4473 75.1199V41.3799Z"
                fill="white"
              />
            </g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M65.127 51.8542C65.2668 51.7921 65.4264 51.7921 65.5662 51.8542L70.4326 54.017C70.7055 54.1383 70.8284 54.4579 70.7071 54.7307C70.5858 55.0036 70.2663 55.1265 69.9934 55.0052L65.3466 52.94L60.6999 55.0052C60.427 55.1265 60.1075 55.0036 59.9862 54.7307C59.8649 54.4579 59.9878 54.1383 60.2607 54.017L65.127 51.8542ZM59.9396 56.1333C59.9396 55.8346 60.1817 55.5926 60.4803 55.5926H70.213C70.5116 55.5926 70.7537 55.8346 70.7537 56.1333C70.7537 56.4319 70.5116 56.674 70.213 56.674V61.5403C70.5116 61.5403 70.7537 61.7824 70.7537 62.081C70.7537 62.3796 70.5116 62.6217 70.213 62.6217H60.4803C60.1817 62.6217 59.9396 62.3796 59.9396 62.081C59.9396 61.7824 60.1817 61.5403 60.4803 61.5403V56.674C60.1817 56.674 59.9396 56.4319 59.9396 56.1333ZM61.5617 56.674V61.5403H69.1316V56.674H61.5617ZM63.1838 57.7554C63.4824 57.7554 63.7245 57.9975 63.7245 58.2961V59.9182C63.7245 60.2168 63.4824 60.4589 63.1838 60.4589C62.8852 60.4589 62.6431 60.2168 62.6431 59.9182V58.2961C62.6431 57.9975 62.8852 57.7554 63.1838 57.7554ZM65.3466 57.7554C65.6453 57.7554 65.8873 57.9975 65.8873 58.2961V59.9182C65.8873 60.2168 65.6453 60.4589 65.3466 60.4589C65.048 60.4589 64.8059 60.2168 64.8059 59.9182V58.2961C64.8059 57.9975 65.048 57.7554 65.3466 57.7554ZM67.5095 57.7554C67.8081 57.7554 68.0502 57.9975 68.0502 58.2961V59.9182C68.0502 60.2168 67.8081 60.4589 67.5095 60.4589C67.2108 60.4589 66.9688 60.2168 66.9688 59.9182V58.2961C66.9688 57.9975 67.2108 57.7554 67.5095 57.7554Z"
              fill={determineColor('--primary-600')}
            />
            <rect
              x="74.377"
              y="52.7861"
              width="30.1923"
              height="8.85745"
              rx="1.29769"
              fill={determineColor('--primary-100')}
            />
          </g>
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M152.429 59.1771C153.493 58.1128 155.219 58.1128 156.283 59.1771C157.347 60.2413 157.347 61.9668 156.283 63.031L154.356 64.958L152.429 63.031C151.365 61.9668 151.365 60.2413 152.429 59.1771ZM154.356 61.8827C154.786 61.8827 155.135 61.5341 155.135 61.1041C155.135 60.674 154.786 60.3254 154.356 60.3254C153.926 60.3254 153.577 60.674 153.577 61.1041C153.577 61.5341 153.926 61.8827 154.356 61.8827Z"
          fill={determineColor('--primary-400')}
        />
        <rect x="159.547" y="58.2744" width="45.0817" height="6.43877" rx="1.29769" fill="#CDD2D4" />
        <rect x="56.0449" y="84.2041" width="115.028" height="8.39857" rx="1.29769" fill="#CDD2D4" />
        <rect x="56.0449" y="97.793" width="139.201" height="17.6431" rx="1.29769" fill="#CDD2D4" />
      </g>
      <rect
        x="42.7439"
        y="41.0555"
        width="172.593"
        height="87.5943"
        rx="5.51519"
        stroke="#E6E8E9"
        strokeWidth="0.648846"
      />
    </g>
    <defs>
      <filter
        id="filter0_dd_422_18931"
        x="0.03125"
        y="0.341797"
        width="181.943"
        height="96.9453"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology radius="1" operator="erode" in="SourceAlpha" result="effect1_dropShadow_422_18931" />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_422_18931" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_422_18931" result="effect2_dropShadow_422_18931" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_422_18931" result="shape" />
      </filter>
      <filter
        id="filter1_dd_422_18931"
        x="7.62595"
        y="-1.84897"
        width="75.9141"
        height="47.3663"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology radius="1.29769" operator="erode" in="SourceAlpha" result="effect1_dropShadow_422_18931" />
        <feOffset dy="1.29769" />
        <feGaussianBlur stdDeviation="1.94654" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_422_18931" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology radius="1.94654" operator="erode" in="SourceAlpha" result="effect2_dropShadow_422_18931" />
        <feOffset dy="2.59539" />
        <feGaussianBlur stdDeviation="4.86635" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_422_18931" result="effect2_dropShadow_422_18931" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_422_18931" result="shape" />
      </filter>
      <filter
        id="filter2_d_422_18931"
        x="7.62595"
        y="-4.44436"
        width="99.9219"
        height="54.503"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology radius="1.94654" operator="erode" in="SourceAlpha" result="effect1_dropShadow_422_18931" />
        <feOffset />
        <feGaussianBlur stdDeviation="4.86635" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_422_18931" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_422_18931" result="shape" />
      </filter>
      <filter
        id="filter3_dd_422_18931"
        x="37.4199"
        y="37.7314"
        width="183.242"
        height="98.2432"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology radius="1" operator="erode" in="SourceAlpha" result="effect1_dropShadow_422_18931" />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_422_18931" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_422_18931" result="effect2_dropShadow_422_18931" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_422_18931" result="shape" />
      </filter>
      <filter
        id="filter4_dd_422_18931"
        x="45.6611"
        y="36.1891"
        width="75.9141"
        height="47.3663"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology radius="1.29769" operator="erode" in="SourceAlpha" result="effect1_dropShadow_422_18931" />
        <feOffset dy="1.29769" />
        <feGaussianBlur stdDeviation="1.94654" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_422_18931" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology radius="1.94654" operator="erode" in="SourceAlpha" result="effect2_dropShadow_422_18931" />
        <feOffset dy="2.59539" />
        <feGaussianBlur stdDeviation="4.86635" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_422_18931" result="effect2_dropShadow_422_18931" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_422_18931" result="shape" />
      </filter>
      <filter
        id="filter5_d_422_18931"
        x="45.6611"
        y="33.5937"
        width="99.9219"
        height="54.503"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology radius="1.94654" operator="erode" in="SourceAlpha" result="effect1_dropShadow_422_18931" />
        <feOffset />
        <feGaussianBlur stdDeviation="4.86635" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_422_18931" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_422_18931" result="shape" />
      </filter>
      <clipPath id="clip0_422_18931">
        <rect x="5.03125" y="3.3418" width="171.944" height="86.9454" rx="5.19077" fill="white" />
      </clipPath>
      <clipPath id="clip1_422_18931">
        <path
          d="M15.4121 3.3418H75.7548V31.2422C75.7548 33.3923 74.0118 35.1353 71.8617 35.1353H19.3052C17.1551 35.1353 15.4121 33.3923 15.4121 31.2422V3.3418Z"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip2_422_18931">
        <rect x="43.0684" y="41.3799" width="171.944" height="86.9454" rx="5.19077" fill="white" />
      </clipPath>
      <clipPath id="clip3_422_18931">
        <path
          d="M53.4473 41.3799H113.79V69.2803C113.79 71.4304 112.047 73.1734 109.897 73.1734H57.3403C55.1903 73.1734 53.4473 71.4304 53.4473 69.2803V41.3799Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Icon2;
