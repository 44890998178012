import { FC } from 'react';
import { TiLocation } from 'react-icons/ti';

import { School } from '@core/ts/results';

import styles from './SchoolDetails.module.css';

type Props = {
  school: School;
};

const SchoolDetails: FC<Props> = ({ school }) => (
  <div className={styles.header}>
    <div className={styles.programDetails}>
      <img
        className={styles.drawerSchoolLogo}
        src={`https://simple-storage-server.highereducation.com/${school.slug}.png?w=130&h=80`}
        alt={school.name}
      />
      <div className={styles.drawerSchool}>
        <h3>{school.name}</h3>
        {school.state && school.city && (
          <div className={styles.drawerLocation}>
            <TiLocation size={16} />
            <p>
              {school.city}, {school.state}
            </p>
          </div>
        )}
      </div>
    </div>
  </div>
);
export default SchoolDetails;
