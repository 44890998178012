/* eslint-disable @typescript-eslint/ban-ts-comment */
import { track } from '@core/services/cohesion';
import type { ElementEventProperties, UseElementEvents } from '@core/ts/cohesion';
import deepMerge from '@core/utils/deepMerge';

const useElementEvents = (baseProperties?: ElementEventProperties | {}): UseElementEvents => {
  const buildElementEventMethod =
    <T>(eventName: string) =>
    (overrideProperties?: ElementEventProperties) => {
      const properties = deepMerge({}, baseProperties, overrideProperties);
      track({ eventName, properties });
    };
  return {
    elementClicked: buildElementEventMethod('ElementClicked'),
    elementViewed: buildElementEventMethod('ElementViewed'),
  };
};

export default useElementEvents;
