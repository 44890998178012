// Components
import { FiltersButton, CollapsibleSection, ClearAllButton } from '@edu-match/components';
import Overlay from '@edu-match/components/Overlay';
import { useEffect } from 'react';
import { HiAdjustments, HiX } from 'react-icons/hi';
import { RefinementList, connectStateResults, SortBy } from 'react-instantsearch-dom';
import { useSelector, useDispatch } from 'react-redux';

import { selectDcsDegrees } from '@core/reducers/dcsSlice';
// Store Actions and Selectors
import { selectBaseIndex, selectShowMobileMenu, setShowMobileMenu } from '@core/reducers/matchesSlice';
import SponsoredResultsPopover from '@core/shared/components/SponsoredResultsPopover';
// Utils
import cn from '@core/utils/classNames';

// Styles
import styles from './MobileDrawer.module.css';

const MobileMenu = ({ searchResults }) => {
  // DCS State properties
  const dcsDegrees = useSelector(selectDcsDegrees);

  // Action Dispatcher
  const dispatch = useDispatch();

  const closeMobileMenu = () => {
    dispatch(setShowMobileMenu(false));
  };

  return (
    <Overlay transparent>
      <div className={cn(styles.mobileDrawer, styles.open)}>
        {/* header */}
        <header className={styles.mobileDrawerHeader}>
          <p>
            <HiAdjustments className={styles.icon} />
            Filters
          </p>
          <button type="button" onClick={closeMobileMenu}>
            <HiX className={styles.icon} />
          </button>
        </header>

        {/* content */}
        {dcsDegrees.includes('Bootcamps') ? (
          <>
            {/* Bootcamps Filters */}
            {/* Course Filter */}
            <CollapsibleSection triggerLabel="Course" filter="program.subject.name">
              <RefinementList attribute="program.subject.name" />
            </CollapsibleSection>
            {/* Time Commitment Filter */}
            <CollapsibleSection triggerLabel="Time Commitment" filter="program.miscellaneous.timeCommitment">
              <RefinementList attribute="program.miscellaneous.timeCommitment" />
            </CollapsibleSection>
            {/* Learning Format Filter */}
            <CollapsibleSection triggerLabel="Learning Format" filter="program.miscellaneous.learningFormat">
              <RefinementList attribute="program.miscellaneous.learningFormat" />
            </CollapsibleSection>
            {/* Duration Filter */}
            <CollapsibleSection triggerLabel="Duration" filter="program.miscellaneous.programLength">
              <RefinementList attribute="program.miscellaneous.programLength" />
            </CollapsibleSection>
            {/* Price Filter */}
            <CollapsibleSection triggerLabel="Price" filter="program.miscellaneous.price">
              <RefinementList attribute="program.miscellaneous.price" />
            </CollapsibleSection>
            {/* School Filter */}
            <CollapsibleSection triggerLabel="School" filter="school.name">
              <RefinementList attribute="school.name" />
            </CollapsibleSection>
          </>
        ) : (
          <>
            {/* Voyager Filters */}
            {/* Major filter */}
            <CollapsibleSection triggerLabel="Subject" filter="program.subject.name">
              <RefinementList attribute="program.subject.name" searchable />
            </CollapsibleSection>
            {/* location filter */}
            <CollapsibleSection triggerLabel="Location" filter="school.additional.stateCodeFull">
              <RefinementList attribute="school.additional.stateCodeFull" searchable />
            </CollapsibleSection>
          </>
        )}
        <div className={styles.mobileDrawerContent} />

        {/* footer */}
        <footer className={styles.mobileDrawerFooter}>
          <ClearAllButton type="button" />
          <button type="button" className={styles.viewSchoolsButton} onClick={closeMobileMenu}>
            {searchResults?.nbHits ? `View ${searchResults.nbHits} schools` : 'No Results'}
          </button>
        </footer>
      </div>
    </Overlay>
  );
};

const MobileDrawer = connectStateResults(({ searchResults }) => {
  // Matches State properties
  const baseIndex = useSelector(selectBaseIndex);

  const showMobileMenu = useSelector(selectShowMobileMenu);

  return (
    <>
      {/* filters button/menu trigger */}
      <FiltersButton />

      {/* mobile menu */}

      {showMobileMenu && <MobileMenu searchResults={searchResults} />}

      <div className={styles.mobileDrawerResultsAndSort}>
        {/* sponsored results for mobile */}
        <SponsoredResultsPopover />
        {/* sorting for mobile
            ensure our state is set before we try to render, otherwise we generate an error
        */}
        {baseIndex && (
          <SortBy
            defaultRefinement={baseIndex}
            items={[
              { value: baseIndex, label: 'Best Match' },
              { value: `${baseIndex}_distance`, label: 'Distance' },
            ]}
          />
        )}
      </div>
    </>
  );
});

export { MobileDrawer };
