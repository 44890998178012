/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useEffect } from 'react';

import cn from '@core/utils/classNames';

import styles from './Overlay.module.css';

type TOverlay = {
  clickHandler?: () => any;
  transparent?: boolean;
  children?: any;
  zIndex?: number;
};

/**
 * @param {function} props.clickHandler Function to fire when the component is clicked
 * @param {boolean} props.transparent Flag for having a clear or tinted background overlay
 * @param {React.Children} props.children Elements wrapped by
 * @param {number} props.zIndex Priority on stacked elements
 * @returns {React.FunctionComponent} Function Component
 */
const Overlay: FC<TOverlay> = ({ clickHandler, transparent, children, zIndex }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);
  return (
    <>
      <button
        type="button"
        className={cn(styles.overlay, !transparent && styles.dark)}
        style={{ zIndex }}
        onClick={clickHandler}
      />
      {children}
    </>
  );
};

Overlay.defaultProps = {
  clickHandler: () => {},
  transparent: false,
  children: null,
  zIndex: 0,
};

export default Overlay;
