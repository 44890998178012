import { type FC, useMemo } from 'react';

import type { FieldComponentProps } from '../types';
import CurrAcademicAwardOptionGroup from './CurrAcademicAwardOptionGroup';
import Design from './Design1';
import Design2 from './Design2';
import MultiOptionGroupField from './MultiOptionGroupField';
import SingleOptionGroupField from './SingleOptionGroupField';

const optionGroupMap = {
  1: Design,
  2: Design2,
};

const OptionGroupField: FC<FieldComponentProps> = ({ field, heading, design = 1, fieldNumber }) => {
  const OptionFieldComponent = useMemo(() => optionGroupMap[design], []);
  const componentMap = {
    radio: (
      <SingleOptionGroupField
        field={field}
        heading={heading}
        InputComponent={OptionFieldComponent}
        fieldNumber={fieldNumber}
      />
    ),
    yesorno: (
      <SingleOptionGroupField
        field={field}
        heading={heading}
        InputComponent={OptionFieldComponent}
        fieldNumber={fieldNumber}
      />
    ),
    checkbox: (
      <MultiOptionGroupField
        field={field}
        heading={heading}
        InputComponent={OptionFieldComponent}
        fieldNumber={fieldNumber}
      />
    ),
    currAcaAward: (
      <CurrAcademicAwardOptionGroup
        field={field}
        heading={heading}
        InputComponent={OptionFieldComponent}
        fieldNumber={fieldNumber}
      />
    ),
  };
  return componentMap[field.mode];
};

export default OptionGroupField;
