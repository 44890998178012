import { FC, SyntheticEvent, useState } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';

import useFieldEvents from '@core/hooks/cohesion/useFieldEvents';
import { selectFormCorrelationId } from '@core/reducers/eventingSlice';
import { selectInput, setInput } from '@core/reducers/inputsSlice';
import { selectPrefetchedLocations } from '@core/reducers/matchesSlice';
import cn from '@core/utils/classNames';

import type { FieldComponentProps } from '../types';
import styles from './Location.module.css';

const LocationField: FC<FieldComponentProps> = ({ field, heading }) => {
  // Prefetched locations
  const locations = useSelector(selectPrefetchedLocations);

  // Action Dispatcher
  const dispatch = useDispatch();

  // Form Correlation Id for events
  const formCorrelationId = useSelector(selectFormCorrelationId);

  const { fieldSelected } = useFieldEvents({
    correlationId: formCorrelationId,
  });

  // Inputs State properties
  const fieldInput = useSelector((state) => selectInput(state, field.name));
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  // function that opens/closes dropDown options when dropDown is clicked
  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  // function that runs whenever something is selected in the dropdown
  const handleSelection = (event: SyntheticEvent) => {
    const { value } = event.target as HTMLSelectElement;

    dispatch(setInput({ key: field.name, value }));
    setIsOptionsOpen(false);

    fieldSelected({
      userInputField: {
        autofilled: false,
        fieldName: field.name,
        fieldLabel: heading,
        fieldType: field.type,
        fieldValue: `${value}`,
      },
    });
  };

  return (
    <>
      <div data-testid="select-field-select" className={styles.container}>
        <select className={styles.selectOverlay} onChange={handleSelection}>
          <option selected disabled value={undefined}>
            Select
          </option>
          {locations?.map(({ value }) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </select>
        <button
          type="button"
          className={cn(styles.dropDownContainer, isOptionsOpen && styles.activeDropDownContainer)}
          id={`select-${field?.name}-input`}
          onClick={toggleOptions}
        >
          <div className={cn(styles.contentText, fieldInput && styles.fieldInputText)}>
            {fieldInput ?? field?.placeholder}
          </div>
          <div className={styles.iconContainer}>
            <BsChevronDown
              size="12px"
              className={cn(styles.chevronIcon, isOptionsOpen && styles.activeChevronIcon)}
              strokeWidth="2"
            />
          </div>
        </button>
      </div>

      <div className={styles.dropDownOptionsContainer}>
        <div className={cn(styles.dropDownOptionsWrapper, isOptionsOpen && styles.expanded)}>
          {locations?.map(({ value }, optionIndex) => (
            <button
              className={cn(styles.dropDownOption, fieldInput === value && styles.optionSelected)}
              onClick={handleSelection}
              value={value}
              key={value}
              type="button"
              id={`select-${field?.name}-option-${optionIndex}`}
            >
              {value}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

export default LocationField;
