import type { FC } from 'react';
import { HiMapPin } from 'react-icons/hi2';

import styles from './styles.module.css';

type TagsProps = {
  tags: {
    city?: string;
    state?: string;
    notForProfit?: boolean;
    isPrivate?: boolean;
    online?: boolean;
  };
};

const Tags: FC<TagsProps> = ({ tags }) => {
  if (!tags) return null;

  return (
    <span className={styles.tags}>
      {tags.city && tags.state && (
        <>
          <HiMapPin size="1rem" /> {`${tags.city}, ${tags.state}`}
        </>
      )}
      {tags.notForProfit && <>&nbsp;&bull;&nbsp;Non-Profit</>}
      {tags.isPrivate && <>&nbsp;&bull;&nbsp;Private</>}
      {tags.online && <>&nbsp;&bull;&nbsp;Online</>}
    </span>
  );
};

export default Tags;
