import { useEffect, useState } from 'react';

// Hooks
import useRuleEngine from '@core/hooks/useRuleEngine';
// Types
import { IConfigFile } from '@core/ts/config';

/**
 * useConfig accepts a configuration and resolves it
 * @returns
 */
const useConfig = (configFile: IConfigFile) => {
  // Rule engine used to resolve config rules
  const { isReady: isRuleEngineReady, resolveRules } = useRuleEngine();

  const [isCompleted, setIsCompleted] = useState(false);
  const [results, setResults] = useState([]);

  // Resolves Schema rules to set the correct Schema Name
  const resolveConfig = (): void => {
    const newResults = [];
    // Go through each schema configuration to find one that matches
    configFile.config.forEach((config) => {
      // If we don't have rules then it resolves to true and we add the value
      if (!config.ruleSets) {
        newResults.push(config.value);
        return;
      }

      // If we have a rule set, then evaluate them
      if (config.ruleSets.find((ruleSet) => resolveRules(ruleSet))) {
        newResults.push(config.value);
      }
    });
    // Set our results
    setResults(newResults);
  };

  // If our rule engine is ready to go, then run our config rules
  useEffect(() => {
    if (!isRuleEngineReady || isCompleted) return;
    resolveConfig();
    setIsCompleted(true);
  }, [isRuleEngineReady]);

  return { isCompleted, results };
};

export default useConfig;
