import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import useSetBaseIndex from '@core/blocks/edu-match/hooks/useSetBaseIndex';
import sortHits from '@core/blocks/edu-match/utils/sortHits';
import useAlgoliaClient from '@core/hooks/useAlgoliaClient';
import { selectFilters } from '@core/reducers/configSlice';
import { selectDcs } from '@core/reducers/dcsSlice';
import { setRecommenderPopupListId } from '@core/reducers/eventingSlice';
import { selectAllInputs } from '@core/reducers/inputsSlice';
import { selectBaseIndex } from '@core/reducers/matchesSlice';
import type { Query } from '@core/ts/algolia';
import { buildFacetFilter, buildFilter } from '@core/utils/buildFilter';

const useRecommenderQuery = () => {
  const [state, setState] = useState({
    loading: true,
    data: undefined,
    error: undefined,
  });
  // Bonsai A/B Test

  const { dcsDegrees, dcsCategories, dcsSubjects } = useSelector(selectDcs);

  // Filters applied by our config rules engine
  const configFilters = useSelector(selectFilters);
  const inputs = useSelector(selectAllInputs);

  const maxResults = 2;

  // Algolia + Mobius Data
  const { searchClient } = useAlgoliaClient({ applyQualifications: true });
  const baseIndex = useSelector(selectBaseIndex);
  useSetBaseIndex();

  const dispatch = useDispatch();

  useEffect(() => {
    if (searchClient) {
      const listId = uuid();
      dispatch(setRecommenderPopupListId(listId));
      const getFilters = (additionalFilters): string => {
        const categoryFilters = dcsCategories.map((category) => buildFacetFilter('program.category.name', category));
        const degreeFilters = dcsDegrees.map((degree) => buildFacetFilter('program.degree.name', degree));
        const subjectFilters = dcsSubjects.map((subject) => buildFacetFilter('program.subject.name', subject));
        const filters = buildFilter([
          ...categoryFilters,
          ...degreeFilters,
          ...subjectFilters,
          ...configFilters,
          additionalFilters,
        ]);
        return filters;
      };

      const indexName = `${baseIndex}_recommended`;

      const facetFilters = dcsDegrees.includes('Bootcamps')
        ? [
            inputs['program.miscellaneous.learningFormat']?.value?.map(
              (facet) => `program.miscellaneous.learningFormat:${facet}`
            ),
            inputs['program.miscellaneous.timeCommitment']?.value?.map(
              (facet) => `program.miscellaneous.timeCommitment:${facet}`
            ),
            // removes any null or undefined values (we get nulls if there aren't any selections)
          ].filter((_filters) => !!_filters)
        : undefined;

      // define all the possible queries
      const queries: Query[] = [
        {
          indexName,
          params: {
            hitsPerPage: 1,
            filters: getFilters("program.providerName:'TwoU'"),
            facetFilters,
          },
        },
        // gets two non-twoU schools
        {
          // If the bonsai test is active pull from the same index as the results page
          indexName: baseIndex,
          params: {
            /*
             * We're adding 1 to use in case we get a duplicate school
             * For the bonsasi test we need to have as many programs as possible to sort by eRPI to match the results page
             */
            hitsPerPage: 51,
            filters: getFilters("NOT program.providerName:'TwoU'"),
            facetFilters,
          },
        },
      ];

      // async function to make one or more queries and return the aggregated results
      const fetchResults = async (queries: Query[]): Promise<void> => {
        try {
          // make fetch the results from passed queries
          const queryResults = await searchClient?.multipleQueries(queries);

          // Combine query results hits
          let aggregatedResults = queryResults?.results.reduce(
            (accumulator, resultSet) => [...accumulator, ...resultSet.hits],
            []
          );
          const twoUPrograms = aggregatedResults.filter(({ program: { providerName } }) => providerName === 'TwoU');

          // Filter out non TwoU programs and sort them based on eRPI
          const nonTwoUPrograms = await sortHits(
            aggregatedResults.filter(({ program: { providerName } }) => providerName !== 'TwoU'),
            inputs?.zip?.value
          );
          // concatentate both arrays once the non TwoU programs have been sorted
          aggregatedResults = [...twoUPrograms, ...nonTwoUPrograms];

          // truncate to limit length
          aggregatedResults = aggregatedResults.slice(0, maxResults);

          setState({ loading: false, data: aggregatedResults, error: undefined });
        } catch (error) {
          setState({ loading: false, data: undefined, error });
        }
      };

      // call the function
      fetchResults(queries);
    }
  }, [searchClient]);

  return state;
};

export default useRecommenderQuery;
