/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useFieldEvents from '@core/hooks/cohesion/useFieldEvents';
import { selectFormContext } from '@core/reducers/eventingSlice';
import deepMerge from '@core/utils/deepMerge';

import type { TEventingHOC } from './types';

// This is a Higher-Order component that fires the FieldInputted events for the inputs

const withFieldInputted: TEventingHOC =
  ({ Component, schoolSlug, formCorrelationId, eventingOverrides = {} }) =>
  (props) => {
    const { value, label, schema, uiSchema } = props;
    const [hasFiredEvent, setHasFiredEvent] = useState(false);
    const formContext = useSelector(selectFormContext);

    const getFieldNumber = () =>
      Array.from(document.getElementsByClassName('prq')).findIndex((field) => field.id === uiSchema?.['ui:id']) + 1;

    const userInputField = {
      autofilled: false,
      fieldName: label,
      fieldLabel: uiSchema?.['ui:title'],
      fieldType: schema.type,
    };

    const { fieldViewed, fieldInputted } = useFieldEvents(
      deepMerge(
        {
          correlationId: formCorrelationId,
          formContext: {
            ...formContext,
            formVersion: 'dynamic-post-flow',
            formType: 'post-flow',
            formId: '1015',
            formBrand: schoolSlug,
          },
          stepContext: {
            stepNumber: 1,
            stepId: undefined,
            stepName: undefined,
            stepType: undefined,
          },
          userInputField,
        },
        eventingOverrides
      )
    );

    useEffect(() => {
      fieldViewed({
        userInputField: {
          ...userInputField,
          fieldNumber: getFieldNumber(),
        },
      });
    }, []);

    useEffect(() => {
      if (!hasFiredEvent && value !== undefined && value !== '') {
        fieldInputted({
          userInputField: {
            fieldValue: label === 'address' || label === 'city' ? 'SANITIZED' : String(value),
            fieldNumber: getFieldNumber(),
          },
        });
        setHasFiredEvent(true);
      }
    }, [value]);

    return <Component {...props} />;
  };

export default withFieldInputted;
