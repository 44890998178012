// build Related facet Filters
// pulls values from voyager context and builds array
// to provide default filters for the results
import { buildFilter, buildFacetFilter } from '@core/utils/buildFilter';

// prop types
interface RelatedFilterProps {
  degrees: string[];
  categories?: string[];
  subjects: string[];
  configFilters: string[];
}

// function export
export default ({ degrees, categories, subjects, configFilters }: RelatedFilterProps): string => {
  // build subject filters
  const relatedSubjectFilters = subjects.map((subject) => buildFacetFilter('relatedSubject.name', subject));

  // build degree filters
  const degreeFilters = degrees.map((degree) => buildFacetFilter('program.degree.name', degree));

  // try and build category filters
  const categoryFilters: string[] | [] =
    categories?.map((category) => buildFacetFilter('program.category.name', category)) || [];

  // build full filters array
  const filters = buildFilter([...relatedSubjectFilters, ...degreeFilters, ...categoryFilters, ...configFilters]);

  // return filters
  return filters;
};
