import formStyles from '@edu-flow/styles/Form.module.css';
import { FC } from 'react';

import styles from './ErrorMessage.module.css';

type Props = {
  message: string;
  isFieldGroup?: boolean;
  flowRevamp?: boolean;
};

const ErrorMessage: FC<Props> = ({ message, isFieldGroup = true, flowRevamp = false }) => (
  <div
    className={`${styles.error} ${isFieldGroup ? formStyles.fieldGroup : ''} ${flowRevamp ? styles.flowRevamp : ''}`}
  >
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4001 7.00001C13.4001 10.5346 10.5347 13.4 7.0001 13.4C3.46548 13.4 0.600098 10.5346 0.600098 7.00001C0.600098 3.46538 3.46548 0.600006 7.0001 0.600006C10.5347 0.600006 13.4001 3.46538 13.4001 7.00001ZM7.8001 10.2C7.8001 10.6418 7.44193 11 7.0001 11C6.55827 11 6.2001 10.6418 6.2001 10.2C6.2001 9.75818 6.55827 9.40001 7.0001 9.40001C7.44193 9.40001 7.8001 9.75818 7.8001 10.2ZM7.0001 3.00001C6.55827 3.00001 6.2001 3.35818 6.2001 3.80001V7.00001C6.2001 7.44183 6.55827 7.80001 7.0001 7.80001C7.44193 7.80001 7.8001 7.44183 7.8001 7.00001V3.80001C7.8001 3.35818 7.44193 3.00001 7.0001 3.00001Z"
        fill="#E15F60"
      />
    </svg>
    {message}
  </div>
);
export default ErrorMessage;
