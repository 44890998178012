/* eslint-disable react/destructuring-assignment */
import { FieldTemplateProps } from '@rjsf/utils';

import SelectField from '../SelectField';

const GraduationYearField = (props: FieldTemplateProps) => {
  const start = 1980;
  const end = new Date().getFullYear() + 3;
  const options = Array.from({ length: end - start }, (_, i) => {
    const value = end - i;
    return {
      label: value,
      value,
    };
  });

  const newProps = {
    ...props,
    uiSchema: {
      ...props.uiSchema,
      'ui:options': {
        ...props.uiSchema?.['ui:options'],
        options,
      },
    },
  };
  return <SelectField {...newProps} />;
};

export default GraduationYearField;
