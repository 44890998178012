import { ConfigureStoreOptions, combineReducers, configureStore } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import configReducer from '@core/reducers/configSlice';
// eslint-disable-next-line import/no-named-as-default
import contactSlice from '@core/reducers/contactSlice';
import dcsReducer from '@core/reducers/dcsSlice';
import eventingReducer from '@core/reducers/eventingSlice';
import inputsReducer from '@core/reducers/inputsSlice';
import matchesReducer from '@core/reducers/matchesSlice';
import monarchReducer from '@core/reducers/monarchSlice';
import queryParamsReducer from '@core/reducers/queryParamsSlice';
import cookieMiddleware from '@core/store/cookieMiddleware';

const rootReducer = combineReducers({
  eventing: eventingReducer,
  matches: matchesReducer,
  inputs: inputsReducer,
  dcs: dcsReducer,
  config: configReducer,
  queryParams: queryParamsReducer,
  monarch: monarchReducer,
  contact: contactSlice,
});

export const makeStore = (configureStoreOptions?: Partial<ConfigureStoreOptions>) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), cookieMiddleware],
    preloadedState: shouldLoadStateFromCookies ? JSON.parse(decodeURIComponent(voyagerAppStateCookie)) : undefined,
    ...configureStoreOptions,
  });

// We would only load state from cookies if there are no query params (ie. user coming in from DCS) and if the cookie exists.
const voyagerAppStateCookie = Cookies.get('voyagerAppState');
const shouldLoadStateFromCookies =
  voyagerAppStateCookie && new URLSearchParams(window.location.search).toString() === '';

export type RootState = ReturnType<typeof rootReducer>;

export default makeStore();
