import { FC } from 'react';

import Tooltip from '.';

type Props = {
  isOpen: boolean;
  toggleOpen: () => void;
  position?: string;
};

const AdvertiserDisclosure: FC<Props> = ({ isOpen, toggleOpen, position }) => {
  // Get publisher name from window object, if it exists
  const publisherName = window.HE?.publisher?.displayName || 'This website';
  const disclosureText = `${publisherName} is an advertising-supported site. Featured
              or sponsored partner programs and all school search, finder, or
              match results are for schools that compensate us. This compensation
              does not influence our school rankings, resource guides, or other
              editorially-independent information published on this site.`;
  return (
    <Tooltip text={disclosureText} isOpen={isOpen} toggleOpen={toggleOpen} buttonText="GOT IT!" position={position} />
  );
};

export default AdvertiserDisclosure;
