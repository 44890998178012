// Edu Match App
import EduMatch from '@edu-match/EduMatch';
import { useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';

import { useFeatureFlags } from '@core/context/FeatureFlagsContext';
import { selectDcsDegrees } from '@core/reducers/dcsSlice';

import EduResults from '../eduResults/EduResults';

const EduMatchRouter = () => {
  // eslint-disable-next-line camelcase
  const dcsDegrees = useSelector(selectDcsDegrees);
  const flags = useFeatureFlags();

  const ResultsComponent =
    (dcsDegrees.includes("Associate's") ||
      dcsDegrees.includes("Bachelor's") ||
      // Test EDU Results page on Grad results page
      flags?.voyagerGradResultsPage === 'test') &&
    !flags?.cypressTest
      ? EduResults
      : EduMatch;
  return (
    <Routes>
      <Route path="/results" element={<ResultsComponent />} />
    </Routes>
  );
};
export default EduMatchRouter;
