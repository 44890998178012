import React, { FC } from 'react';

import styles from './Loading.module.css';

const dots = [
  { id: 1, delay: 0, opacity: '1' },
  { id: 2, delay: 0.33, opacity: '0.5' },
  { id: 3, delay: 0.66, opacity: '0.25' },
];

const Dots: FC = () => (
  <div className={styles.dotContainer}>
    {dots.map(({ id, delay, opacity }) => (
      <div key={id} className={styles.dot} style={{ animationDelay: `${delay}s`, opacity }} />
    ))}
  </div>
);

export default Dots;
