import { createSlice } from '@reduxjs/toolkit';

import type { ConfigContext } from '@core/context/types';

// State with default values
const configDefaults: ConfigContext = {
  schemaName: '',
  filters: [],
  facets: [],
  initialInputsAdded: false,
};

export const configSlice = createSlice({
  name: 'config',
  initialState: configDefaults, // set our initial state with default values
  reducers: {
    setSchemaName: (state: ConfigContext, action) => {
      state.schemaName = action.payload;
    },
    setFilters: (state: ConfigContext, action) => {
      state.filters = action.payload;
    },
    setFacets: (state: ConfigContext, action) => {
      state.facets = action.payload;
    },
    setInitialInputsAdded: (state: ConfigContext) => {
      state.initialInputsAdded = true;
    },
  },
});

// Actions
export const { setSchemaName, setFilters, setFacets, setInitialInputsAdded } = configSlice.actions;

// Selectors
export const selectSchemaName = (state) => state.config.schemaName;
export const selectFilters = (state): string[] => state.config.filters;
export const selectFacets = (state): string[] => state.config.facets;
export const selectInitialInputsAdded = (state): boolean => state.config.initialInputsAdded;

// Reducer
export default configSlice.reducer;
