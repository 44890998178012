import { getPreampPlacement } from '@core/reducers/preampSlice';
import Icon from '@core/shared/components/Icon';
import cn from '@core/utils/classNames';
import convertToSlug from '@core/utils/strings/convertToSlug';

// styles
import styles from './OptionGroupField.module.css';

const OptionInput = ({ field, isSelected, handleSelection, selection }) => (
  <div
    className={cn(
      styles.optionGroup,
      field.fullWidth && styles.optionGroupFullWidth,
      field.style === 'button' && styles.buttonGroup,
      field.cols ? styles[`optionGroupCols${field.cols}`] : styles.optionGroupCols4
    )}
  >
    {field.options?.map((option) => (
      <div key={option.label} className={cn(styles.option, isSelected(selection, option) && styles.selected)}>
        <div className={styles.optionInner}>
          <input
            id={option.label}
            type="checkbox"
            onChange={() => handleSelection(option)}
            className={styles.optionCheckmark}
            value={option.value ?? option.label}
            data-testid={convertToSlug(`${field.name} ${option.value}`)}
            aria-label={option.label}
          />
          <label htmlFor={option.label} className={styles.optionBody}>
            {option.icon && (
              <span className={styles.optionIcon}>
                <Icon name={option.icon} color={isSelected(selection, option) ? '#fff' : '--primary-700'} size={6} />
              </span>
            )}

            <div>
              <span className={cn(styles.optionLabel, styles[field.labelWeight ?? 'normal'])}>{option.label}</span>
              {option.description && <span className={styles.optionDescription}>{option.description}</span>}
            </div>
          </label>
        </div>
      </div>
    ))}
  </div>
);
export default OptionInput;
