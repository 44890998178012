import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectHeclidMap, selectProductCorrelationIdMap } from '@core/reducers/eventingSlice';
import { mobiusGetApplicationCta } from '@core/services/mobius';
import { Program } from '@core/ts/results';

type Params = {
  program: Program;
};

export type Cta = {
  label: string;
  url: string;
};

export const useGetApplicationCtaData = ({ program }: Params): Cta => {
  const [applicationCtaData, setApplicationCtaData] = useState<Cta>(null);
  const heclidMap = useSelector(selectHeclidMap);
  const productCorrelationIdMap = useSelector(selectProductCorrelationIdMap);

  useEffect(() => {
    const getApplicationCtaData = async () => {
      const response = await mobiusGetApplicationCta({
        programId: `${program.id}`,
        heclid: heclidMap[program.id],
        correlationId: productCorrelationIdMap[program.id],
      });
      setApplicationCtaData({ label: response?.label, url: response?.url });
    };

    getApplicationCtaData();
  }, []);

  return applicationCtaData;
};
