import type { FC } from 'react';

import { type FieldComponentProps } from '@core/blocks/edu-flow/components/Fields/types';

import SideChart from './svg/SideChart';
import StraightChart from './svg/StraightChart';

const svgMap = { 'side-chart': <SideChart />, 'straight-chart': <StraightChart /> };

const ChartPanel: FC<FieldComponentProps> = ({ field }) => svgMap[field.content as keyof typeof svgMap] ?? null;

export default ChartPanel;
