const debounce = <T extends Function = (...args: unknown[]) => void>(callback: T, delay: number = 100) => {
  let pid: number | undefined;
  return (...args: unknown[]) => {
    clearTimeout(pid);
    pid = setTimeout(() => {
      callback(...args);
    }, delay) as unknown as number;
  };
};

export default debounce;
