/**
 * determineColor
 *
 * A function that takes in a string, and determines if it is a valid CSS color.
 *
 * If a CSS custom prop is passed (i.e. --primary-500), it attempts to fetch
 * the computed value of that variable, and tests that as well.
 *
 * If {color} is determined invalid, #000 is returned and an error is thrown.
 *
 * @param {string} color the color to test
 * @param {string} el the element using the function
 * @returns {string}
 */

const determineColor = (color = '', el = '') => {
  // initialize var that will get returned
  const computedColor: string = color.startsWith('--')
    ? getComputedStyle(document.documentElement).getPropertyValue(color)
    : color;

  // test computed color
  // if valid, return it, else throw an error
  if (CSS.supports('color', computedColor)) {
    return computedColor;
  }

  console.warn(
    el,
    computedColor === '' ? `${color} was not found on the root element.` : `${computedColor} is not a valid CSS color.`
  );
  return '#000';
};

export default determineColor;
