import algoliasearch from 'algoliasearch/lite';

import getRelatedFilters from '@core/blocks/edu-match/utils/getRelatedFilters';
import { AlgoliaCredentials } from '@core/ts/algolia';
import getLdsStartDate from '@core/utils/getLdsStartDate';
import getFilters from '@core/utils/spotlight/getFilters';

import { getLeadQualifications } from '../leadDelivery';
import { mobiusGetAlgoliaCredentials } from '../mobius';

export const ALGOLIA_BASE_INDEX_NAME = import.meta.env.VITE_ALGOLIA_BASE_INDEX;
export const ALGOLIA_RECOMMENDED_INDEX_NAME = `${ALGOLIA_BASE_INDEX_NAME}_recommended`;
export const ALGOLIA_RELATED_INDEX_NAME = `${ALGOLIA_BASE_INDEX_NAME}_related`;

type GetHitsParams = {
  search?: string;
  filters?: string;
  facetFilters?: string[];
  hitsPerPage?: number;
  inputs: {
    [key: string]: { value: unknown };
  };
  applyQualifications?: boolean;
  indexName?: string;
};
export const getSearchClient = async (inputs, applyQualifications = true) => {
  const qualifications =
    applyQualifications && inputs?.firstName?.value && inputs?.lastName?.value && inputs?.email?.value
      ? await getLeadQualifications(inputs)
      : undefined;

  const mobiusOptions = {
    criteriaOverride: {
      unqualified: qualifications?.unqualified?.map(({ id }) => id),
      qualified: qualifications?.qualified?.map(({ id }) => id),
      startDate: getLdsStartDate(inputs?.startTime?.value),
    },
    zip: inputs?.zip?.value,
  };

  const credentials: AlgoliaCredentials = await mobiusGetAlgoliaCredentials(mobiusOptions);

  return algoliasearch(import.meta.env.VITE_ALGOLIA_APP_ID, credentials?.key);
};

const AlgoliaServiceV2 = {
  async getHits({
    search = '',
    filters = '',
    facetFilters = [],
    hitsPerPage = 51,
    inputs,
    applyQualifications = true,
    indexName = ALGOLIA_BASE_INDEX_NAME,
  }: GetHitsParams) {
    const algoliaClient = await getSearchClient(inputs, applyQualifications);

    const algoliaIndex = algoliaClient.initIndex(indexName);

    return algoliaIndex.search(search, {
      filters,
      facetFilters,
      hitsPerPage,
    });
  },

  async getRecommenderResults({ dcs, inputs, configFilters, hitsPerPage, indexName }) {
    const twoUFilters = getFilters({ dcs, configFilters, additionalFilters: "program.providerName:'TwoU'" });
    const nonTwoUFilters = getFilters({ dcs, configFilters, additionalFilters: "NOT program.providerName:'TwoU'" });

    const TwoU = await AlgoliaServiceV2.getHits({
      inputs,
      filters: twoUFilters,
      hitsPerPage: 1,
      indexName: ALGOLIA_RECOMMENDED_INDEX_NAME,
    });
    const NonTwoU = await AlgoliaServiceV2.getHits({
      inputs,
      filters: nonTwoUFilters,
      hitsPerPage,
      indexName,
    });
    return [...TwoU.hits, ...NonTwoU.hits];
  },

  async getExactResults({ dcs, inputs, configFilters, hitsPerPage = 51 }) {
    const filters = getFilters({ dcs, configFilters });
    const algoliaHits = await AlgoliaServiceV2.getHits({ inputs, filters, hitsPerPage });
    return algoliaHits.hits;
  },

  async getRelatedResults({ degrees, subjects, inputs, configFilters, hitsPerPage = 12 }) {
    const filters = getRelatedFilters({ degrees, subjects, configFilters });
    const algoliaHits = await AlgoliaServiceV2.getHits({
      inputs,
      filters,
      hitsPerPage,
      indexName: `${ALGOLIA_BASE_INDEX_NAME}_related`,
    });
    return algoliaHits.hits;
  },
};

export default AlgoliaServiceV2;
