/* eslint-disable @typescript-eslint/ban-ts-comment */
import { track } from '@core/services/cohesion';
import { PageActions, newRelicAddPageAction } from '@core/services/newRelic';
import type {
  UseFormEvents,
  FormContinued,
  FormStarted,
  FormSubmitted,
  FormViewed,
  FormContext,
  StepContext,
  FormErrored,
} from '@core/ts/cohesion';
import deepMerge from '@core/utils/deepMerge';

type Field = {
  fieldType: string;
  fieldName: string;
  fieldValue: string;
  fieldSource: string;
};

export type UseFormEventsProps = {
  sourceCorrelationId?: string;
  correlationId?: string;
  formContext?: FormContext;
  stepContext?: StepContext;
  field?: Field[];
  errorDetails?: string;
  errorMessage?: string;
  errorType?: string;
};
const useFormEvents = (baseProperties: UseFormEventsProps = {}): UseFormEvents => {
  const defaultProperties = {
    formContext: { formType: 'flow' },
  };

  const buildFormEventMethod =
    <T>(eventName: string) =>
    (overrideProperties: Partial<T>) => {
      const properties = deepMerge({}, defaultProperties, baseProperties, overrideProperties);
      // custom NR page action
      PageActions[eventName]?.({
        correlationId: baseProperties?.correlationId,
        anonymousId: window?._Cohesion?.anonymousId,
        ...properties?.formContext,
        ...properties?.stepContext,
      });
      track({ eventName, properties });
    };

  return {
    formContinued: buildFormEventMethod<FormContinued>('FormContinued'),
    formStarted: buildFormEventMethod<FormStarted>('FormStarted'),
    formSubmitted: buildFormEventMethod<FormSubmitted>('FormSubmitted'),
    formViewed: buildFormEventMethod<FormViewed>('FormViewed'),
    formErrored: buildFormEventMethod<FormErrored>('FormErrored'),
  };
};

export default useFormEvents;
