import { merge } from 'lodash';

import { type QuestionType } from '@core/blocks/edu-flow/components/Page/types';
import { type MonarchEditableAsset } from '@core/context/types';
import { type DeepPartial } from '@core/ts/types';

const genMultiOptionGroupQuestion = (
  question: QuestionType,
  {
    questionHeading,
    questionMaximumSelections,
    questionMinimumSelections,
    questionSubHeading,
    questionOptions,
    questionFieldName,
    questionStepId,
    questionStepName,
  }: MonarchEditableAsset
): QuestionType => {
  const questionFieldOptions = questionOptions.map((option) => ({ value: option, ...option }));

  const populatedQuestion: DeepPartial<QuestionType> = {
    heading: questionHeading,
    postSubheading: questionSubHeading,
    stepId: questionStepId,
    stepName: questionStepName,
    conditionalContinue: [
      {
        action: 'CONTINUE',
        rules: [
          {
            type: 'MIN',
            field: questionFieldName,
            value: questionMaximumSelections,
          },
        ],
      },
      {
        action: 'SHOW',
        rules: [
          {
            type: 'MIN',
            field: questionFieldName,
            value: questionMinimumSelections,
          },
        ],
      },
    ],
    fields: [
      {
        name: questionFieldName,
        mode: 'checkbox',
        options: questionFieldOptions,
        max: questionMaximumSelections,
        min: questionMinimumSelections,
      },
    ],
  };

  return merge(populatedQuestion, question);
};

export default genMultiOptionGroupQuestion;
