import { useMemo } from 'react';

import { type FieldComponent } from '@core/blocks/edu-flow-2/utils/fieldTypeDictionary';

import MultiOptionGroupField from './MultiOptionGroupField';
import SingleOptionGroupField from './SingleOptionGroupField';

const componentMap = {
  radio: SingleOptionGroupField,
  yesorno: SingleOptionGroupField,
  checkbox: MultiOptionGroupField,
  currAcaAward: SingleOptionGroupField,
};

const OptionGroupField: FieldComponent = ({ field, heading, fieldNumber }) => {
  const Component = useMemo(() => componentMap[field?.mode as keyof typeof componentMap], [field.mode]);

  return <Component field={field} heading={heading} fieldNumber={fieldNumber} />;
};

export default OptionGroupField;
