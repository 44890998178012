import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// import function to register Swiper custom elements
// Blocks - the building blocks of Voyager
import EduFlow from '@core/blocks/edu-flow';
import EduMatch from '@core/blocks/edu-match';
import { DEBUG_PARAMS } from '@core/constants';
import useSchemaConfig from '@core/hooks/schema/useSchemaConfig';
// Hooks, services, and utils
import useQueryParams from '@core/hooks/useQueryParams';
import { selectInitialInputsAdded, setInitialInputsAdded } from '@core/reducers/configSlice';
import { selectDcsDegrees, setInitialDCSValuesFromQueryParams } from '@core/reducers/dcsSlice';
import { initFormContext, selectFormCorrelationId } from '@core/reducers/eventingSlice';
// Reducers selectors/actions
import { selectInput, setInput, setInputs } from '@core/reducers/inputsSlice';
import { PageActions, newRelicCustomAttribute, newRelicInit } from '@core/services/newRelic';
// Global styles
import '@core/styles/global.module.css';
// App level props
import type { AppProps } from '@core/ts/types';

// utils & services
import EduCappex from './blocks/edu-cappex';
import TestPage from './blocks/edu-flow-2/components/Page/Page';
import DefaultPage from './blocks/edu-flow/components/Page/DefaultPage';
import { getCookies } from './blocks/edu-flow/utils/getCookies';
import determineEnv from './blocks/edu-match/utils/determineEnv';
import { FeatureFlagsProvider, useFeatureFlags } from './context/FeatureFlagsContext';
import useMonarch from './hooks/monarch/useMonarch';
import useNewrelic from './hooks/useNewrelic';
import { selectQueryParam, setQueryParams } from './reducers/queryParamsSlice';
import { EduFlowBlock, EduMatchBlock } from './schemas/schema';
import ErrorBoundary from './shared/components/ErrorBoundary';
import ErrorPage from './shared/components/ErrorPage';
import toKeyValueObjectArray from './utils/objects/toKeyValueObjectArray';

const FLOW_REVAMP_FORM_ID = '3002';
const FLOW_REVAMP_FORM_VERSION = '5eb6ead24f8a2a9bcfeeb6eb3e9a494053fb8019';

const App: FC<AppProps> = () => {
  useNewrelic();
  const [formContextReady, setFormContextReady] = useState(false);

  /* If we redirect our user out of voyager for an external action (e.g. email) we store their selections
  in a cookie that we can later access to persist the session */
  const localInput = toKeyValueObjectArray(getCookies());

  // Get our monarch values and store them in state
  useMonarch();

  // Run our schema name configuration to get our schema name
  const { winningSchema } = useSchemaConfig();

  const queryParams = useQueryParams();

  const inputsInitialized = useSelector(selectInitialInputsAdded);

  // Action Dispatcher
  const dispatch = useDispatch();

  const formCorrelationId = useSelector(selectFormCorrelationId);

  // Result debugger query param
  const resultsDebug = useSelector((state) => selectQueryParam(state, DEBUG_PARAMS.RESULTS));
  const dcsDegrees = useSelector(selectDcsDegrees)[0];
  const isBootcamps = dcsDegrees === 'Bootcamps';

  // Set up navigation for handling going directly to results
  const navigate = useNavigate();

  const flags = useFeatureFlags();

  const featureFlagSingleScreenPII = flags?.singleScreenPIITest === 'test';

  // Add initial inputs to the store
  useEffect(() => {
    if (!inputsInitialized) {
      // Set DCS combo in store
      dispatch(setInitialDCSValuesFromQueryParams(queryParams));

      // Set store inputs from cookies
      if (queryParams?.returning && localInput?.length) dispatch(setInputs(localInput));

      dispatch(setQueryParams(toKeyValueObjectArray(queryParams)));

      dispatch(setInitialInputsAdded());
    }
  }, [queryParams, dispatch, featureFlagSingleScreenPII]);

  useEffect(() => {
    if (flags.loading) return;
    dispatch(setInputs([{ key: 'featureFlagSingleScreenPII', value: featureFlagSingleScreenPII }]));
  }, [flags]);

  // Initialize the Form Context in eventing based on schema
  useEffect(() => {
    if (winningSchema) {
      // this is only needed while the revamp test is on, but won't fail if it's not
      // when we are done testing we can just remove this and replace the initFormContext call with the one below
      // dispatch(initFormContext(schema));
      dispatch(initFormContext(winningSchema));
      setFormContextReady(true);
    }
  }, [winningSchema, dispatch]);

  // Adds custom attributes to newRelic to provide more context to our error logs
  useEffect(() => {
    newRelicInit(formCorrelationId, import.meta.env.VITE_VERSION_NUMBER);
    console.log(
      `%cVoyager (${determineEnv()}) version: ${import.meta.env.VITE_VERSION_NUMBER}`,
      'background: papayawhip; color: #333; padding: 2px 4px; font-weight: 700; border-radius: 4px'
    );
    // This is added in the lead object
    dispatch(setInput({ key: 'sourceUrl', value: window.location.href.replace(/https?:\/\//, '') }));
  }, []);

  // Check for the debug query param for filling the form and going to results
  useEffect(() => {
    if (formContextReady && resultsDebug && resultsDebug !== 'false') {
      dispatch(
        setInputs([
          { key: 'firstName', value: 'Test' },
          { key: 'lastName', value: 'Test' },
          { key: 'email', value: 'test@redventures.com' },
          { key: 'zip', value: '90411' },
          {
            key: 'educationLevel',
            value: 'bachelors',
            options: { label: "Bachelor's", value: 'bachelors', icon: 'signal3' },
          },
          { key: 'graduationYear', value: '2026' },
          { key: 'hsGradYear', value: '2026' },
          { key: 'militaryAffiliation', value: false },
          { key: 'country', value: 'us' },
          { key: 'phone', value: '(407) 555-5555' },
          { key: 'startTime', value: '3' },
        ])
      );

      navigate('/results');
    }
  }, [formContextReady, resultsDebug]);

  // Cookies redirect handler
  useEffect(() => {
    // if the user is returning from email link and the
    // cookies aren't empty it should be redirected to results
    if (
      (queryParams.returning && localInput?.length) ||
      (formContextReady && resultsDebug && resultsDebug !== 'false')
    ) {
      navigate('/results');
    }
  }, [formContextReady, resultsDebug]);

  const FlowPage = isBootcamps ? DefaultPage : TestPage;
  return (
    <section id="voyager-blocks">
      {/** Cappex Landing Page */}
      <EduCappex />
      {formContextReady &&
        winningSchema?.blocks?.map((block: EduFlowBlock | EduMatchBlock, index: number) => {
          if (block.type === 'EduFlow') {
            // We are only using the level of intent page for the flow revamp test
            // Once we are done with the test we can revert back to this:
            // return <EduFlow key={index} pages={(block as EduFlowBlock).pages} PageComponent={DefaultPage} />;
            const baseSchemaPages = (block as EduFlowBlock).pages;
            const pages = baseSchemaPages;

            return <EduFlow key={index} pages={pages} PageComponent={FlowPage} />;
          }
          if (block.type === 'EduMatch') return <EduMatch key={index} />;
          return null;
        })}
    </section>
  );
};

export const WrappedApp: FC<AppProps> = (props) => {
  const appEntryURL = useMemo(() => window.location.href, []);

  return (
    <ErrorBoundary fallback={<ErrorPage appEntryURL={appEntryURL} />}>
      <FeatureFlagsProvider>
        <App {...props} />
      </FeatureFlagsProvider>
    </ErrorBoundary>
  );
};

export default WrappedApp;
