import { useEffect, useRef, useState } from 'react';

import { newRelicNoticeError, nrErrorMap } from '@core/services/newRelic';
import { getSchoolConfig } from '@core/services/schoolConfig';

type Spotlight = {
  header?: string;
  blocks: SpotlightBlock[];
};

type SpotlightBlock = {
  title: string;
  image: string;
  list: { text: string }[];
};

type FormDates = {
  startDate: string;
  showDateOn: string;
  registerDate: string;
};

type SchoolConfigResponse = {
  schoolName: string;
  schoolId: number;
  slug: string;
  mobius: {
    idToken: string;
  };
  logo: string;
  tags: {
    online: boolean;
    notForProfit: boolean;
    isPrivate: boolean;
    city: string;
    state: string;
  };
  themeColors: {
    primary: string;
    secondary: string;
    cta: string;
    formBackground: string;
    heroGradient: string;
  };
  description: string;
  formDatesLabel: string;
  formDates: {
    undergrad: FormDates[];
    grad: FormDates[];
  };
  valueProps: [];
  flow: string;
  programFlow: [];
  confirmation: {
    meta: {
      text: string;
    };
  };
  environment: string;
  userEmail: string;
  sdbSchoolId: string;
  showNextStartDate: true;
  tcpa: string;
  formName: string;
  spotlight?: Spotlight;
};

type UseSchoolConfig = {
  loading: boolean;
  data?: SchoolConfigResponse;
};

const useSchoolConfig = (schoolId: number): UseSchoolConfig => {
  const cache = useRef<Record<number, SchoolConfigResponse>>({});
  const [{ loading, data }, setState] = useState<UseSchoolConfig>({ loading: true, data: undefined });

  useEffect(() => {
    if (cache.current[schoolId]) {
      setState({ loading: false, data: cache.current[schoolId] });
      return;
    }

    const fetchSchoolConfig = async (schoolId: number) => {
      try {
        const data = await getSchoolConfig(schoolId);
        cache.current[schoolId] = data;
        setState({ loading: false, data });
      } catch (error) {
        setState({ loading: false, data: undefined });
        newRelicNoticeError(nrErrorMap.SCHOOL_CONFIG, error as Error, { schoolId });
        console.error('Unable to find school config!');
      }
    };

    fetchSchoolConfig(schoolId);
  }, []);

  return { loading, data };
};

export default useSchoolConfig;
