import Autolinker from 'autolinker';
import { useEffect, useState, useMemo } from 'react';
import { HiOutlineClock, HiOutlineCalendar, HiOutlineCheck, HiExternalLink } from 'react-icons/hi';
import { useSelector, useDispatch } from 'react-redux';

import findNextDate from '@core/blocks/edu-match/utils/findNextDate';
import useElementEvents from '@core/hooks/cohesion/useElementEvents';
import useSchoolConfig from '@core/hooks/useSchoolConfig';
import {
  selectHeclidMap,
  selectProductCorrelationIdMap,
  selectViewCorrelationIdMap,
} from '@core/reducers/eventingSlice';
import { selectInput } from '@core/reducers/inputsSlice';
import { selectLeadDelivery, hideLeadDelivery } from '@core/reducers/matchesSlice';
import { getPreampPlacement } from '@core/reducers/preampSlice';
import { mobiusGetApplicationCta } from '@core/services/mobius';
import cn from '@core/utils/classNames';

import Dots from '../Loading/Dots';
import styles from './ExitStrategyV2.module.css';
import { applicationCtaBtn, continueBrowsingBtn, slideOutClose, slideOutDismiss } from './eventPayloads';
import type { ExitStrategyComponent, SchoolConfigData } from './type';

const ExitStrategy: ExitStrategyComponent = ({ setSlideOutActions }) => {
  // Store
  const firstName = useSelector((state) => selectInput(state, 'firstName'));
  const { currentPrograms } = useSelector(selectLeadDelivery);
  const heclidMap = useSelector(selectHeclidMap);
  const productCorrelationIdMap = useSelector(selectProductCorrelationIdMap);
  const viewCorrelationIdMap = useSelector(selectViewCorrelationIdMap);
  const { program, school } = currentPrograms[0];

  // Action Dispatcher
  const dispatch = useDispatch();

  const { data, loading }: SchoolConfigData = useSchoolConfig(school.id);

  const programDates = useMemo(
    () => (data?.formDates?.undergrad?.length ? findNextDate(data?.formDates?.undergrad) : {}),
    [data]
  );

  // Local state
  const [applicationCtaData, setApplicationCtaData] = useState<{ label: string; url: string } | null>(null);
  const [isIntroTextExpanded, setIsIntroTextExpanded] = useState(false);

  const introText =
    data?.confirmation?.meta.shortIntro ||
    data?.confirmation?.meta.text ||
    'An Admissions Advisor will be in touch soon.';

  const autolinkedIntroText = Autolinker.link(introText);

  const showViewMoreBtn = autolinkedIntroText?.length > 110;

  const { elementViewed, elementClicked } = useElementEvents({
    correlationId: viewCorrelationIdMap[program.id],
  });

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (setSlideOutActions) {
      setSlideOutActions({
        close: () => elementClicked(slideOutClose),
        dismiss: () => elementClicked(slideOutDismiss),
      });

      // This makes sure to set the callbacks back to undefined
      //  after the child component is unmounted
      return () => {
        setSlideOutActions(undefined);
      };
    }
  }, []);

  useEffect(() => {
    elementViewed(continueBrowsingBtn);

    const getApplicationCtaData = async () => {
      const response = await mobiusGetApplicationCta({
        programId: `${program.id}`,
        heclid: heclidMap[program.id],
        correlationId: productCorrelationIdMap[program.id],
      });
      setApplicationCtaData({ label: response?.label, url: response?.url });
    };

    getApplicationCtaData();
  }, []);

  useEffect(() => {
    if (applicationCtaData?.url) {
      elementViewed(applicationCtaBtn);
    }
  }, [applicationCtaData]);

  const handleContinueBtnClick = () => {
    elementClicked(continueBrowsingBtn);
    dispatch(hideLeadDelivery());
  };

  const handleApplication = (e, url: string) => {
    e.preventDefault();
    elementClicked({ ...applicationCtaBtn, outboundUrl: applicationCtaData?.url });
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const nextStepSchools = ['purdue-university-global', 'southern-new-hampshire-university'];

  return (
    <>
      {loading ? (
        <section className={styles.loadingContainer}>
          <p className={styles.loadingText}>Submitting your info</p>
          <Dots />
        </section>
      ) : (
        <section className={styles.exitStrategyContainer}>
          <div className={styles.scrollContainer}>
            <div className={styles.header}>
              <div className={styles.defaultText}>
                <h2>🎉 Congratulations{firstName ? `, ${firstName}` : ''}!</h2>
              </div>
              <div
                className={cn(
                  styles.admissionsText,
                  showViewMoreBtn && styles.admissionsCollapsible,
                  isIntroTextExpanded && styles.expand
                )}
              >
                <div dangerouslySetInnerHTML={{ __html: autolinkedIntroText }} />
                {!isIntroTextExpanded && showViewMoreBtn && <span className={styles.gradient} />}
              </div>
              {showViewMoreBtn && (
                <button
                  type="button"
                  className={styles.expandButton}
                  onClick={() => setIsIntroTextExpanded(!isIntroTextExpanded)}
                >
                  {isIntroTextExpanded ? 'View less -' : 'View more +'}
                </button>
              )}

              <div className={styles.schoolCard}>
                <img
                  className={styles.logo}
                  src={`https://simple-storage-server.highereducation.com/${school.slug}.png?w=80&h=50`}
                  alt={school.name}
                />
                {programDates?.registerDate || programDates?.startDate ? (
                  <div
                    className={cn(
                      styles.datesContainer,
                      programDates?.registerDate && programDates?.startDate && styles.spaceDates
                    )}
                  >
                    {programDates?.registerDate && (
                      <div className={styles.dates}>
                        <HiOutlineClock className={styles.icon} size="19px" />
                        <p>Register By:{'\u00a0'}</p>
                        <span>{programDates?.registerDate}</span>
                      </div>
                    )}
                    {programDates?.startDate && (
                      <div className={styles.dates}>
                        <HiOutlineCalendar className={styles.icon} size="19px" />
                        <p>Classes Start:{'\u00a0'}</p>
                        <span>{programDates?.startDate}</span>
                      </div>
                    )}
                  </div>
                ) : (
                  <p className={styles.noDate}>Now accepting new students.</p>
                )}
              </div>
            </div>

            {/* We only show this section if Mobius returns us a valid URL */}
            {applicationCtaData?.url && (
              <section className={styles.applicationContainer}>
                {nextStepSchools.includes(data.slug) ? (
                  <p className={styles.preampNextSteps}>Apply today with no application fees!</p>
                ) : (
                  <h4>Ready for the next step?</h4>
                )}
                <button type="button" onClick={(e) => handleApplication(e, applicationCtaData?.url)}>
                  {applicationCtaData?.label}
                  <HiExternalLink className={styles.applicationCtaIcon} size="18px" />
                </button>
              </section>
            )}

            <section className={styles.cardsContainer}>
              <div className={styles.card}>
                <h4>What to expect from the Advisor?</h4>
                <ul>
                  <li className={styles.iconContainer}>
                    <HiOutlineCheck className={styles.icon} size="14px" />A 20-30 minute call
                  </li>
                  <li className={styles.iconContainer}>
                    <HiOutlineCheck className={styles.icon} size="14px" />
                    Help finding the right program for you
                  </li>
                  <li className={styles.iconContainer}>
                    <HiOutlineCheck className={styles.icon} size="14px" />
                    Hands-on assistance with your application process
                  </li>
                  <li className={styles.iconContainer}>
                    <HiOutlineCheck className={styles.icon} size="14px" />
                    Answers to any of your questions
                  </li>
                </ul>
              </div>

              {!!data?.valueProps?.length && (
                <div className={styles.card}>
                  <h4>{data.schoolName} offers:</h4>
                  <ul>
                    {data.valueProps.map((valueProp) => (
                      <li key={valueProp.title} className={styles.iconContainer}>
                        <HiOutlineCheck className={styles.icon} size="14px" />
                        {valueProp.title}
                      </li>
                    ))}
                  </ul>
                  <p>Visit the school&apos;s website for more details!</p>
                </div>
              )}
            </section>
          </div>
          <div className={styles.conclusion}>
            <h4>There are more schools waiting for you!</h4>

            <p>Easily request more details from other schools you like.</p>
            <button data-testid="continue-browsing" type="button" onClick={handleContinueBtnClick}>
              Continue browsing schools
            </button>
          </div>
        </section>
      )}
    </>
  );
};

export { ExitStrategy };
