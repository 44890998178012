/* eslint-disable jsx-a11y/label-has-associated-control */
import { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';

import useFieldEvents from '@core/hooks/cohesion/useFieldEvents';
import { selectAllInputs } from '@core/reducers/inputsSlice';
import ErrorMessage from '@core/shared/components/ErrorMessage';

import InputFieldWithFieldEventing from './InputFieldWithFieldEventing';
import styles from './PiiFormSectionRevamp.module.css';

type Props = {
  header?: string;
  piiLeadErrors?: { field: string }[];
  setFirstName: Dispatch<SetStateAction<string>>;
  setLastName: Dispatch<SetStateAction<string>>;
  setEmail: Dispatch<SetStateAction<string>>;
  setPhone: Dispatch<SetStateAction<string>>;
};
const PiiFormSectionRevamp: FC<Props> = ({
  header,
  piiLeadErrors = [],
  setFirstName,
  setLastName,
  setEmail,
  setPhone,
}) => {
  const dispatch = useDispatch();
  const formInputs = useSelector(selectAllInputs);

  // Form Errors
  const nameError =
    piiLeadErrors.findIndex(({ field }) => field === 'first name') !== -1 ||
    piiLeadErrors.findIndex(({ field }) => field === 'last name') !== -1;
  const emailError = piiLeadErrors.findIndex(({ field }) => field === 'email') !== -1;
  const phoneError = piiLeadErrors.findIndex(({ field }) => field === 'phone') !== -1;

  return (
    <div className={styles.formContainer}>
      <h2 className={styles.formHeader}>{header}</h2>
      <div className={styles.formName}>
        <div className={styles.inputContainer}>
          <InputFieldWithFieldEventing inputName="firstName" setState={setFirstName} />
          <label htmlFor="firstName" className={styles.formLabel}>
            First Name
          </label>
        </div>
        <div className={styles.inputContainer}>
          <InputFieldWithFieldEventing inputName="lastName" setState={setLastName} />
          <label htmlFor="lastName" className={styles.formLabel}>
            Last Name
          </label>
        </div>
      </div>
      {nameError && <ErrorMessage isFieldGroup={false} message="Please enter a valid first and last name" />}
      <div className={styles.inputContainer}>
        <InputFieldWithFieldEventing inputName="email" setState={setEmail} />
        <label htmlFor="email" className={styles.formLabel}>
          Email
        </label>
        {emailError && <ErrorMessage isFieldGroup={false} message="Please enter a valid email address." />}
      </div>
      <div className={styles.inputContainer}>
        <InputFieldWithFieldEventing inputName="phone" setState={setPhone} />
        <label htmlFor="phone" className={styles.formLabel}>
          Phone
        </label>
        {phoneError && <ErrorMessage isFieldGroup={false} message="Please enter a valid phone number." />}
      </div>
    </div>
  );
};
export default PiiFormSectionRevamp;
