/* eslint-disable react/jsx-props-no-spreading */
import { EntityId } from '@reduxjs/toolkit';
import { useEffect, useMemo, useState, useRef, MutableRefObject, MouseEventHandler, FC } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { useSelector, useDispatch } from 'react-redux';

import { FieldComponentProps } from '@core/blocks/edu-flow-2/utils/fieldTypeDictionary';
import useFieldEvents from '@core/hooks/cohesion/useFieldEvents';
import { selectFormCorrelationId } from '@core/reducers/eventingSlice';
import { selectInput, setInput } from '@core/reducers/inputsSlice';
import cn from '@core/utils/classNames';

import { Option, Field } from '../types.d';
import styles from './SelectField.module.css';

const SelectField: FC<FieldComponentProps<Field>> = ({ field, heading, fieldNumber }) => {
  // Action Dispatcher
  const dispatch = useDispatch();

  // Ref to Scroll to the year 2020 on the graduationYear Select
  const yearRef = useRef<HTMLButtonElement>(null);

  // Form Correlation Id for events
  const formCorrelationId = useSelector(selectFormCorrelationId);

  const { fieldSelected, fieldViewed } = useFieldEvents({
    correlationId: formCorrelationId,
    userInputField: {
      autofilled: false,
      fieldName: field.name,
      fieldLabel: heading,
      fieldType: field.type,
      fieldNumber,
    },
  });

  // Inputs State properties
  const fieldInput = useSelector((state) => selectInput(state, field.name as EntityId));
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  // function that opens/closes dropDown options when dropDown is clicked
  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };
  useEffect(() => {
    if (isOptionsOpen && yearRef.current != null) {
      yearRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isOptionsOpen]);

  useEffect(() => {
    fieldViewed();
  }, []);

  // function that runs whenever something is selected in the dropdown
  const handleSelection: MouseEventHandler<HTMLButtonElement> = ({ target: option }) => {
    // @ts-expect-error
    const { value } = option;

    dispatch(setInput({ key: field.name as string, value }));
    setIsOptionsOpen(false);

    fieldSelected({
      userInputField: {
        fieldValue: `${value}`,
      },
    });
  };

  // converts the field options into valid react-select options
  // useMemo to make sure that we only run this once unless the field.options change
  const selectOptions = useMemo(() => field?.options as Option[], [field?.options]);

  // Generate graduation year dropdown options
  let valueLabelArray: Option[];
  if (field?.min && field?.max) {
    valueLabelArray = Array.from(Array(field.max - field.min).keys())
      .reverse()
      .map((opt) => {
        const val = opt + Number(field.min) + 1;
        return {
          value: `${val}`,
          label: `${val}`,
        };
      });
  } else {
    valueLabelArray = selectOptions;
  }

  return (
    <>
      <div data-testid="select-field-select" className={cn(styles.customSelect, isOptionsOpen && styles.selectOpen)}>
        <button
          data-testid="select-field-select-button"
          id={`select-${field?.name}-input`}
          className={cn(styles.selectButton, isOptionsOpen && styles.selectButtonOpen)}
          onClick={toggleOptions}
          type="button"
        >
          <span className={styles.fieldText}>{fieldInput ?? field?.placeholder}</span>
          <BsChevronDown
            size="12px"
            className={cn(styles.chevronIcon, isOptionsOpen && styles.activeChevronIcon)}
            strokeWidth="2"
          />
        </button>
        {isOptionsOpen && (
          <div data-testid="select-field-dropdown-wrapper" className={styles.dropDownOptionsContainer}>
            {valueLabelArray?.map(({ label, value }, optionIndex) => {
              const props = {
                ref:
                  field.name === 'graduationYear' && value === '2020'
                    ? (yearRef as MutableRefObject<HTMLButtonElement>)
                    : undefined,
              };
              return (
                <button
                  className={cn(styles.dropDownOption, fieldInput === value && styles.optionSelected)}
                  onClick={handleSelection}
                  value={value}
                  key={label}
                  type="button"
                  id={`select-${field?.name}-option-${optionIndex}`}
                  data-testid={label}
                  {...props}
                >
                  {label}
                </button>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default SelectField;
