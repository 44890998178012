import { HiX } from 'react-icons/hi';

import Overlay from '@core/blocks/edu-match/components/Overlay';
import cn from '@core/utils/classNames';

import styles from './SlideOut.module.css';
import type { Width, SlideOutComponent } from './types';

const SlideOut: SlideOutComponent = ({ show, children, onClose, width }) => {
  if (!show) return null;

  return (
    <Overlay clickHandler={onClose} zIndex={40}>
      <div
        className={cn(
          styles.slideOut,
          width === 'extended' && styles.extended,
          width === 'fullyExtended' && styles.fullyExtended
        )}
      >
        <button data-testid="slideout-close-btn" type="button" className={styles.closeBtn} onClick={onClose}>
          <HiX size={20} />
        </button>
        <div
          className={cn(
            styles.content,
            width === 'extended' && styles.extended,
            width === 'fullyExtended' && styles.fullyExtended
          )}
        >
          {children}
        </div>
      </div>
    </Overlay>
  );
};
SlideOut.defaultProps = {
  width: 'default',
};

export default SlideOut;
