import { FC, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { FieldComponentProps } from '@core/blocks/edu-flow-2/utils/fieldTypeDictionary';
// Utils
import usePageHeadingParser from '@core/blocks/edu-flow/components/Page/hooks/usePageHeadingParser';
import { useFeatureFlags } from '@core/context/FeatureFlagsContext';
// Reducers selectors/actions
import { selectAllInputs } from '@core/reducers/inputsSlice';

import Fields from '..';
// Styles
import styles from './Conditional.module.css';
import { OperatorToMethodMap } from './operatorToMethodMap';
import type { ConditionalType, ConditionalTypeContent, ConditionalTypeLogic } from './types';

const Conditional: FC<FieldComponentProps<ConditionalType>> = (props) => {
  const { field, errors = [], fieldNumber, heading } = props;
  const logic = field.conditional?.logic as ConditionalTypeLogic;
  const content = field.conditional?.content as ConditionalTypeContent;

  const flags = useFeatureFlags();

  const { scroll = true } = logic;
  const parsePageHeading = usePageHeadingParser();

  // Context
  // set up state to show/hide conditional
  const [conditionsMet, setConditionsMet] = useState<boolean>(false);
  // Set up the ref we will use to target this to scroll it into view
  const ref = useRef<HTMLSpanElement>(null);
  // Inputs State properties
  const inputs = useSelector(selectAllInputs);
  // when voyager state changes, check if conditions are met
  useEffect(() => {
    // or operator
    // penciling in the = operator too. this will likely need to be expanded on.
    if (Object.keys(OperatorToMethodMap).includes(logic.operator as string)) {
      // Get the array method for the operator
      const arrayMethodName: string = OperatorToMethodMap[logic.operator as keyof typeof OperatorToMethodMap];
      // find if conditions are met
      const found = logic.fields?.[arrayMethodName as keyof typeof logic.fields](
        (condition: { name: string; value: unknown }) => {
          const inputValue = inputs[condition.name]?.value || flags[condition.name as keyof typeof flags];

          if (condition.value === null && !inputValue) return true;
          // if value is an array
          if (Array.isArray(inputValue)) {
            return inputValue.includes(condition.value);
          }
          // if value just a primitive type
          return inputValue === condition.value;
        }
      );
      setConditionsMet(Boolean(found));
    }
  }, [field, inputs]);
  // When conditionals are met automatically scroll them down to the conditional field
  useEffect(() => {
    if (conditionsMet && scroll) {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [conditionsMet]);

  return (
    <>
      {conditionsMet ? (
        <span className={styles.conditional} ref={ref}>
          {content?.heading && <h3 dangerouslySetInnerHTML={{ __html: parsePageHeading(content?.heading, true) }} />}
          <Fields
            fieldNumber={fieldNumber}
            errors={errors}
            fields={content?.fields}
            heading={(content?.heading || heading) as string}
          />
        </span>
      ) : null}
    </>
  );
};

export default Conditional;
