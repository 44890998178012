import { VoyagerInputs } from '@core/reducers/inputsSlice';
import { validatePhone } from '@core/services/leadDelivery';
import { PageActions } from '@core/services/newRelic';
import { LeadResponse } from '@core/ts/leadDelivery';

type ReturnValue = Promise<Partial<LeadResponse & Error>>;

type FunctionConfig = {
  wrappedFunction: () => ReturnValue;
  inputs: VoyagerInputs;
  flag: boolean;
};

/**
 * @param param0.wrappedFunction function to call with the phone validation
 * @param param0.inputs stored user inputs
 * @param param0.useCarrier whether or not use the paid version of phone validation
 * @returns A lead response or an error
 */
export const phoneValidationFeatureFlagWrapper = async ({
  wrappedFunction,
  inputs,
  flag = false,
}: FunctionConfig): ReturnValue => {
  try {
    const { validPhoneNumber } = await validatePhone(inputs?.phone?.value, flag);
    if (!validPhoneNumber) {
      PageActions.InvalidPhoneLineTypeIntelligence();
      return { message: 'invalid_phone_line_type_intelligence' };
    }
  } catch (err) {
    return err as Error;
  }

  return wrappedFunction();
};
