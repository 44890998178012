import { Dispatch, SetStateAction, useMemo } from 'react';
import { HiExclamationCircle } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';

import { CompactCard } from '@core/blocks/edu-match/components';
import { LEAD_SUBMISSION_STEPS } from '@core/constants';
import useFormEvents from '@core/hooks/cohesion/useFormEvents';
import { selectFormContext } from '@core/reducers/eventingSlice';
import PostResultForm from '@core/shared/features/PostResultForm';
import { VoyagerResult } from '@core/ts/results';

import styles from './AdditionalQuestions.module.css';

type AdditionalQuestionsProps = {
  setPiiLeadErrors: Dispatch<SetStateAction<{ field: string }[]>>;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  setStep: Dispatch<SetStateAction<string>>;
  currentProgram: VoyagerResult;
  prqSchema: any;
  formErrored: () => void;
  originalSchema: any;
  isResultsRevamp?: boolean;
};

const { EXIT_PAGE, EXIT_STRATEGY } = LEAD_SUBMISSION_STEPS;
const AdditionalQuestions = ({
  currentProgram,
  originalSchema,
  prqSchema,
  setStep,
  isResultsRevamp = false,
  setPiiLeadErrors,
  setEditMode,
  formErrored,
}: AdditionalQuestionsProps) => {
  const formCorrelationId = useMemo(() => v4(), []);
  const formContext = useSelector(selectFormContext);

  const { formViewed, formStarted, formSubmitted } = useFormEvents({
    correlationId: formCorrelationId,
    formContext: {
      ...formContext,
      formVersion: 'dynamic-post-flow',
      formType: 'post-flow',
      formBrand: currentProgram?.school?.slug,
      formId: '1015',
    },
    stepContext: {},
  });

  const onMount = () => {
    formViewed();
  };

  const onFirstInteraction = () => {
    formStarted();
  };

  const onSubmit = () => {
    setStep(isResultsRevamp ? EXIT_PAGE : EXIT_STRATEGY);
    formSubmitted();
  };

  return (
    <section className={styles.postResultForm}>
      <h4>
        This school requires some additional info. <HiExclamationCircle size="1.8rem" className={styles.icon} />
      </h4>

      <CompactCard hit={currentProgram} showClose={false} />

      <div className={styles.form}>
        <PostResultForm
          formCorrelationId={formCorrelationId}
          originalSchema={originalSchema}
          hit={currentProgram}
          prqSchema={prqSchema}
          setPiiLeadErrors={setPiiLeadErrors}
          setEditMode={setEditMode}
          setStep={setStep}
          onFormError={formErrored}
          onMount={onMount}
          onFirstInteraction={onFirstInteraction}
          onSubmit={onSubmit}
        >
          <div className={styles.spacer} />
          <div className={styles.cta}>
            <b>Double check your information before submitting 👆</b>
            <p>Your information will never be shared with a school without your consent.</p>
            <button type="submit" className={styles.submitBtn}>
              Submit
            </button>
          </div>
        </PostResultForm>
      </div>
    </section>
  );
};

export default AdditionalQuestions;
