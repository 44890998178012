import { track } from '@core/services/cohesion';
import type { CohesionEventTypes, ProductEventProperties, UseProductEvents } from '@core/ts/cohesion';
import deepMerge from '@core/utils/deepMerge';

/**
 * Custom hook that exposes the following Cohesion Product Events:
 * - productLoaded
 * - productViewed
 * - productClicked
 * You can instantiate the values of the event object py passing a ProductEventProperties object.
 * Example:
 * const { productLoaded, productViewed, productClicked } = useProductEvents({ viewCorrelationId: '123 '})
 *
 * You can also override those at the individual event call by also passing a ProductEventProperties object.
 * Example:
 * productClicked({ correlationId: '234' })
 *
 * @param {ProductEventProperties} baseEventProps
 * @return {UseProductEvents}
 */

const useProductEvents = (baseProperties?: ProductEventProperties): UseProductEvents => {
  const triggerProductEvent = (eventName: string, overrideEventProps: ProductEventProperties = { product: {} }) => {
    const properties = deepMerge({}, baseProperties, overrideEventProps);
    track({ eventName, properties });
  };

  const buildProductEventMethod = (eventName: CohesionEventTypes) => (propertiesOverride?: ProductEventProperties) =>
    triggerProductEvent(eventName, propertiesOverride);

  return {
    productLoaded: buildProductEventMethod('ProductLoaded'),
    productClicked: buildProductEventMethod('ProductClicked'),
    productViewed: buildProductEventMethod('ProductViewed'),
  };
};

export default useProductEvents;
