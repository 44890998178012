import { useState, useEffect, type FC, type ChangeEventHandler } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import useFieldEvents from '@core/hooks/cohesion/useFieldEvents';
import { selectFormCorrelationId } from '@core/reducers/eventingSlice';
// Reducers selectors/actions
import { setInput } from '@core/reducers/inputsSlice';
import cn from '@core/utils/classNames';
// Utils
import convertToSlug from '@core/utils/strings/convertToSlug';

import type { FieldComponentProps, YesOrNoOptions } from '../types';
import styles from './RadioField.module.css';

type CurrentOption = keyof YesOrNoOptions;

const RadioField: FC<FieldComponentProps> = ({ field, heading, fieldNumber, flowRevamp = false }) => {
  // Action Dispatcher
  const dispatch = useDispatch();

  const [currentOption, setCurrentOption] = useState<CurrentOption>(field.default as CurrentOption);

  // Form Correlation Id for events
  const formCorrelationId = useSelector(selectFormCorrelationId);

  const { fieldSelected, fieldViewed } = useFieldEvents({
    correlationId: formCorrelationId,
    userInputField: {
      autofilled: false,
      fieldName: field.name,
      fieldLabel: heading,
      fieldType: field.type,
      fieldNumber,
    },
  });

  useEffect(() => {
    fieldViewed();
  }, []);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const optionVal = event?.target?.value;

    fieldSelected({
      userInputField: {
        fieldValue: optionVal,
      },
    });

    setCurrentOption(event.target.id as CurrentOption);
  };

  useEffect(() => {
    if (field.options) dispatch(setInput({ key: field.name, value: (field.options as YesOrNoOptions)[currentOption] }));
  }, [currentOption]);

  return (
    <div className={cn(styles.radioField, flowRevamp && styles.flowRevamp)}>
      <span className={styles.radioFieldHeading}>{field.label}</span>

      <div className={styles.radioOptions}>
        {Object.entries(field.options as YesOrNoOptions)?.map(([option, value]) => (
          <div key={option} className={styles.radioOption}>
            <input
              onChange={handleChange}
              type="radio"
              value={value as string}
              name={field.name}
              id={option}
              checked={option === currentOption}
              data-testid={convertToSlug(`${field.name}-${option}`)}
              aria-label={field.label}
            />
            <label htmlFor={option}>{option}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RadioField;
