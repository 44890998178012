import EduCappex from '@edu-cappex/EduCappex';
import { useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import usePageEvents from '@core/hooks/cohesion/usePageEvents';
import useProductEvents from '@core/hooks/cohesion/useProductEvents';
import { selectAllInputs } from '@core/reducers/inputsSlice';
import { PageActions } from '@core/services/newRelic';

const EduCappexRouter = () => {
  // User inputs
  const inputs = useSelector(selectAllInputs);

  // Eventing params
  const viewCorrelationId = uuid();
  const correlationId = uuid();

  // Tracking/Eventing
  const { pageViewed } = usePageEvents();

  const { productClicked } = useProductEvents({
    viewCorrelationId,
    correlationId,
    product: {
      name: 'cappex',
      location: 'voyager-flow-bailout',
      formatType: 'app',
      formatSubtype: 'landing-page',
    },
  });

  // Handle when Cappex page loads
  const onCappexLoad = () => {
    // Fire page viewed event when user hits the landing page
    pageViewed();

    // NR Page Action
    PageActions.CappexPageTriggered({
      educationLevel: inputs?.educationLevel?.value,
      graduationYear: inputs?.graduationYear?.value,
    });
  };

  return (
    <Routes>
      {/** Need to set the click like this if not we'll get an error */}
      <Route path="/cappex" element={<EduCappex onLoad={onCappexLoad} onClick={() => productClicked()} />} />
    </Routes>
  );
};

export default EduCappexRouter;
