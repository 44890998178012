import { EducationLevel, GraduationYear } from '@core/ts/inputs';

type SendToCappexParams = {
  educationLevel: EducationLevel;
  graduationYear: GraduationYear;
};
/**
 *
 * @param {SendToCappexParams} cappexInputs Cappex related input values
 * @param {string} cappexInputs.educationLevel User's highest level of education (e.g. highschool)
 * @param {string} cappexInputs.graduationYear User's graduation year for highest level of education
 * @returns {boolean} Flag for whether or not to show the Cappex landing page
 */
const sendToCappex = ({ educationLevel, graduationYear }: SendToCappexParams): Boolean => {
  const today: Date = new Date();
  const curMonth: number = today.getMonth();
  const curYear: number = today.getFullYear();

  // Handles both number, string types
  const gradYear: number = parseInt(`${graduationYear}`, 10);

  // Edge case we get a non-numeric string
  if (Number.isNaN(gradYear)) return false;

  // IF user selects highschool for level of education and gradudation date indicates they are in their junior or lower
  return (
    educationLevel === 'highschool' &&
    // Juniors and below
    ((curMonth < 5 && gradYear > curYear) || (curMonth >= 5 && gradYear > curYear + 1))
  );
};

export default sendToCappex;
