// icons
import { HiRss, HiHeart, HiLocationMarker, HiEye, HiEyeOff } from 'react-icons/hi';
import { RiMoneyDollarCircleFill } from 'react-icons/ri';

export default {
  isPrivate: { value: 'Private', icon: <HiEyeOff /> },
  isOnline: { value: 'Online', icon: <HiRss /> },
  isPublic: { value: 'Public', icon: <HiEye /> },
  forProfit: { value: 'Forprofit', icon: <RiMoneyDollarCircleFill /> },
  notForProfit: { value: 'Nonprofit', icon: <HiHeart /> },
  notOnline: { value: 'On Campus', icon: <HiLocationMarker /> },
};
