import dayjs from 'dayjs';

/**
 * Returns a date in YYYY-MM-DD format based on the number of months to add to today's date
 * Link to DayJs docs: https://day.js.org/docs/en/manipulate/add
 * @param {number | null | undefined} userInputValue - The number of months to add to the today's date | null | undefined
 * @returns {string | null | undefined} - The date in YYYY-MM-DD format | null | undefined
 */

const getLdsStartDate = (userInputValue: number | null | undefined): string | null | undefined => {
  if (userInputValue === undefined || userInputValue === null) {
    return userInputValue as null | undefined;
  }

  return dayjs().add(userInputValue, 'month').format('YYYY-MM-DD');
};

export default getLdsStartDate;
