import formStyles from '@edu-flow/styles/Form.module.css';
import { type FC, useState, useEffect, type ChangeEventHandler } from 'react';
// Icons
import { TiLocation } from 'react-icons/ti';
import NumberFormat from 'react-number-format';
import { useSelector, useDispatch } from 'react-redux';

import useFieldEvents from '@core/hooks/cohesion/useFieldEvents';
import { selectFormCorrelationId, selectStepContext } from '@core/reducers/eventingSlice';
// Reducers and Actions
import { selectInput, setInput } from '@core/reducers/inputsSlice';
// local
import cn from '@core/utils/classNames';

import type { FieldComponentProps } from '../types';
// Utils
import styles from './ZipField.module.css';

const ZipField: FC<FieldComponentProps> = ({ field, fieldError, heading, fieldNumber }) => {
  // Action Dispatcher
  const dispatch = useDispatch();

  // Form Correlation Id for events
  const formCorrelationId = useSelector(selectFormCorrelationId);

  // Inputs State properties
  const fieldInput = useSelector((state) => selectInput(state, 'zip'));
  const [previousFieldInput, setPreviousFieldInput] = useState('');
  const stepContext = useSelector(selectStepContext);
  const autofilled = !!fieldInput && !previousFieldInput;

  // Eventing
  const { fieldInputted, fieldViewed } = useFieldEvents({
    correlationId: formCorrelationId,
    userInputField: {
      autofilled,
      fieldName: field.name,
      fieldLabel: heading,
      fieldType: field.type,
      fieldValue: 'SANITIZED',
      fieldNumber,
    },
  });

  useEffect(() => {
    fieldViewed();
  }, []);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setPreviousFieldInput(fieldInput);
    dispatch(setInput({ key: 'zip', value: event.target.value }));
  };

  useEffect(() => {
    if (fieldInput?.length >= 5 && stepContext) {
      fieldInputted({
        userInputField: {
          fieldValue: fieldInput,
        },
      });
    }
  }, [fieldInput, stepContext]);

  return (
    <div className={cn(formStyles.fieldGroup, styles.inputContainer)}>
      <TiLocation data-testid="zip-icon" size={30} />

      <NumberFormat
        format="#####"
        mask=""
        id={field.type}
        type="text"
        inputMode="numeric"
        className={`${styles.textField} ${fieldError && styles.error}`}
        name={field.name}
        placeholder="ZIP Code"
        value={fieldInput}
        maxLength={5}
        onChange={handleChange}
        data-testid="zip"
        aria-label="ZIP Code"
      />
    </div>
  );
};

export default ZipField;
