import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { type Option } from '@core/blocks/edu-flow-2/components/Fields/types';
import { type FieldComponent } from '@core/blocks/edu-flow-2/utils/fieldTypeDictionary';
import useFieldEvents from '@core/hooks/cohesion/useFieldEvents';
import { selectFormCorrelationId, selectStepContext } from '@core/reducers/eventingSlice';
import { type VoyagerInput, selectInputObject, setInput } from '@core/reducers/inputsSlice';
import removeDuplicates from '@core/utils/arrays/removeDuplicates';

import OptionGroupOptions from './OptionGroupOptions';

const MultiOptionGroupField: FieldComponent = ({ field, heading, fieldNumber }) => {
  // Action Dispatcher
  const dispatch = useDispatch();

  // Form Correlation Id for events
  const formCorrelationId = useSelector(selectFormCorrelationId);
  // stepContext for for fields events
  const stepContext = useSelector(selectStepContext);

  const { fieldSelected, fieldViewed } = useFieldEvents({
    correlationId: formCorrelationId,
    userInputField: {
      autofilled: false,
      fieldName: field.name,
      fieldLabel: heading,
      fieldType: 'multi-select',
      fieldNumber,
    },
  });

  // Inputs State properties
  const fieldInputObject = useSelector((state) => selectInputObject(state, field.name as string));

  const isSelected = useCallback(
    (selection?: VoyagerInput, option?: Option) =>
      selection?.options?.some((selectedOption: Option) => selectedOption.label === option?.label),
    []
  );
  useEffect(() => {
    fieldViewed();
  }, []);

  const handleSelection = useCallback(
    (option: Option) => {
      fieldSelected({
        userInputField: {
          fieldValue: option.label,
        },
      });

      // If the option was previously selected, we remove it from the selection
      if (isSelected(fieldInputObject, option)) {
        // We need this to support options with the same value
        const newOptions = fieldInputObject?.options?.filter(
          (selectedOption: Option) => selectedOption.label !== option.label
        );
        const newValue = newOptions.map((selectedOption: Option) => selectedOption.value);

        // If the array is empty, we set the value and options to undefined
        // to indicate that the user hasn't made a selection
        const payload = !newValue?.length
          ? { key: field.name as string, value: undefined, options: undefined }
          : { key: field.name as string, value: newValue, options: newOptions };

        dispatch(setInput(payload));
        return;
      }

      // If user already has selected the max amount of options allowed, we don't allow more selections
      if (field.max && fieldInputObject?.options?.length >= field.max) {
        return;
      }

      // If option wasn't previously selected, we add it as long as we aren't over the max
      const newValue = fieldInputObject?.value
        ? removeDuplicates([...fieldInputObject.value, option.value])
        : [option.value];
      const newOptions = fieldInputObject?.options ? [...fieldInputObject.options, option] : [option];

      dispatch(
        setInput({
          key: field.name as string,
          value: newValue,
          options: newOptions,
        })
      );
    },
    [fieldInputObject, field, heading, isSelected, stepContext]
  );

  return (
    <OptionGroupOptions
      isSelected={isSelected}
      field={field}
      handleSelection={handleSelection}
      fieldInputObject={fieldInputObject}
    />
  );
};

export default MultiOptionGroupField;
