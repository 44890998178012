const getFacetFilters = ({ dcs, inputs }) =>
  dcs.dcsDegrees.includes('Bootcamps')
    ? [
        inputs['program.miscellaneous.learningFormat']?.value?.map(
          (facet) => `program.miscellaneous.learningFormat:${facet}`
        ),
        inputs['program.miscellaneous.timeCommitment']?.value?.map(
          (facet) => `program.miscellaneous.timeCommitment:${facet}`
        ),
        // removes any null or undefined values (we get nulls if there aren't any selections)
      ].filter(Boolean)
    : undefined;

export default getFacetFilters;
