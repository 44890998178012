import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setBaseIndex } from '@core/reducers/matchesSlice';

import determineEnv, { Environments } from '../utils/determineEnv';

const useSetBaseIndex = () => {
  const env = determineEnv();

  const dispatch = useDispatch();

  useEffect(() => {
    // set base index to context
    const baseIndex =
      env === Environments.DEVELOPMENT ? 'staging_edudirect_programs_voyager' : import.meta.env.VITE_ALGOLIA_BASE_INDEX;
    dispatch(setBaseIndex(baseIndex));
  }, []);
};

export default useSetBaseIndex;
