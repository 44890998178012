import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useRecommenderQuery from '@core/hooks/useRecommenderQuery';
import useSpotlightQuery from '@core/hooks/useSpotlightQuery';
import { selectDcs } from '@core/reducers/dcsSlice';
import { Hit } from '@core/ts/algolia';

type State = {
  eventing?: {
    location: string;
    customDimensions?: { key: string; value: string }[];
  };
  data?: Hit[];
  feature?: 'RECOMMENDER' | 'SPOTLIGHT';
};

const usePopupData = () => {
  const [state, setState] = useState<State>({
    eventing: undefined,
    data: undefined,
    feature: undefined,
  });

  const dispatch = useDispatch();

  // try to get data for spotlight and recommender
  const spotlight = useSpotlightQuery();
  const recommender = useRecommenderQuery();

  const dcs = useSelector(selectDcs);

  useEffect(() => {
    // When both queries are done loading
    if (!spotlight.loading && !recommender.loading) {
      //  if we have spotlight data, we prioritize that over recommender
      if (spotlight?.data?.hit) {
        setState({
          eventing: {
            location: 'voyager-spotlight-pop-up',
            customDimensions: spotlight?.data?.eventing?.customDimensions,
          },
          data: [spotlight.data.hit],
          feature: 'SPOTLIGHT',
        });

        const winningRule = spotlight?.data?.eventing.customDimensions.find(
          ({ key }) => key === 'spotlight-winner'
        ).value;

        window.newrelic?.addPageAction?.('SPOTLIGHT_RESULTS_FOUND', {
          programId: spotlight?.data?.hit.program.id,
          schoolId: spotlight?.data?.hit.program.id,
          winningRule,
          ...dcs,
        });

        return;
      }

      //  if we have recommender data, we fallback to recommender
      if (recommender?.data?.length > 0) {
        setState({
          eventing: { location: 'voyager-recommender-pop-up' },
          data: recommender.data,
          feature: 'RECOMMENDER',
        });

        window.newrelic?.addPageAction?.('RECOMMENDER_RESULTS_FOUND', {
          programId: recommender?.data?.map((hit) => hit.program.id).join(','),
          schoolId: recommender?.data?.map((hit) => hit.school.id).join(','),
          ...dcs,
        });

        return;
      }

      // if no results are found and we skip to full results
      window.newrelic?.addPageAction?.('NO_SPOTLIGHT_OR_RECOMMENDER_RESULTS', { ...dcs });
    }
  }, [spotlight.loading, recommender.loading]);

  return { ...state, recommender };
};

export default usePopupData;
