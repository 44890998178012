export const ENVIRONMENTS = {
  LOCALHOST: 'localhost',
  DEV: 'dev',
  STAGING: 'staging',
  PRODUCTION: 'production',
  TEST: 'test',
};

// Url Parameters used to debug the application
export const DEBUG_PARAMS = {
  RESULTS: 'resultsDebug', // Takes you straight to results and fills your form,
  DUPESLIST: 'dupesScrollTest', // Deactivates filters in dupes popup to show long list of items for testing the scroll behaviour
  TEST_ALL_QUESTIONS: 'testAllQuestions',
};

// List of DCS Categories that can be sent to the application
export const DCS_CATEGORIES = {
  COMPUTERS_TECHNOLOGY: 'Computers & Technology',
  PSYCHOLOGY_COUNSELING: 'Psychology & Counseling',
  BUSINESS_MANAGEMENT: 'Business & Management',
};

export const LEAD_SUBMISSION_STEPS = {
  PII_CONFIRMATION: 'PII_CONFIRMATION',
  EXIT_PAGE: 'EXIT_PAGE',
  EXIT_STRATEGY: 'EXIT_STRATEGY',
  PRQ_FORM: 'PRQ_FORM',
  ADDITIONAL_QUESTIONS: 'ADDITIONAL_QUESTIONS',
};

export default { LEAD_SUBMISSION_STEPS, ENVIRONMENTS, DEBUG_PARAMS, DCS_CATEGORIES };
