import NumberFormat from 'react-number-format';

import cn from '@core/utils/classNames';

import styles from './ZipWidget.module.css';

const ZipWidget = (props) => {
  const { placeholder, value, onChange, uiSchema } = props;
  const id = uiSchema?.['ui:id'];
  return (
    <NumberFormat
      autoComplete="postal-code"
      format="#####"
      mask=""
      id={id}
      type="text"
      inputMode="numeric"
      className={cn(styles.zipWidget, 'prq')}
      name="zip"
      placeholder={placeholder}
      defaultValue={value}
      value={value}
      onChange={(event) => onChange(event.target.value)}
      maxLength={5}
      data-testid={id}
    />
  );
};

export default ZipWidget;
