import { FC } from 'react';
import { TiLocation } from 'react-icons/ti';

import { VoyagerResult } from '@core/ts/results';

import styles from './CompactCard.module.css';

type Props = {
  result: VoyagerResult;
};

const CompactCard: FC<Props> = ({ result: { school, program } }) => {
  if (!school || !program) return null;

  return (
    <div className={styles.card}>
      <div className={styles.logo}>
        <img
          src={`https://simple-storage-server.highereducation.com/${school?.slug}.png?w=130&h=80`}
          alt={school?.name}
        />
      </div>
      <div className={styles.infoContainer}>
        <span className={styles.subtitle}>{program?.name}</span>
        {school.state && school.city && (
          <div className={styles.mobileCtaLocation}>
            <TiLocation size={16} />
            <p>
              {school.city}, {school.state}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export { CompactCard };
