import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectAllInputs } from '@core/reducers/inputsSlice';
import initSearchClient from '@core/services/algolia';
import { getLeadQualifications } from '@core/services/leadDelivery';
import type { Qualifications } from '@core/ts/results';
import getLdsStartDate from '@core/utils/getLdsStartDate';

const useAlgoliaClient = ({ applyQualifications = false, refetchOnInputsChange = false }) => {
  // Search client run queries against
  const [searchClient, setSearchClient] = useState(null);
  const [qualifications, setQualifications] = useState<Qualifications | undefined>(undefined);
  // Store inputs
  const inputs = useSelector(selectAllInputs);

  // Flag for ensuring at least one call to the search client
  const [hasInit, setHasInit] = useState(false);

  const initHook = useCallback(async () => {
    // Build the qualifications object that we will send to Mobius
    if (!hasInit || refetchOnInputsChange) {
      // Get qualifications only if the `applyQualifications` flag was set to true and the input requirements are met
      const qualifications =
        applyQualifications && inputs?.firstName?.value && inputs?.lastName?.value && inputs?.email?.value
          ? await getLeadQualifications(inputs)
          : undefined;

      // options to pass when initializing the search client
      const searchClientOptions = {
        criteriaOverride: {
          unqualified: qualifications?.unqualified?.map(({ id }) => id),
          qualified: qualifications?.qualified?.map(({ id }) => id),
          startDate: getLdsStartDate(inputs?.startTime?.value),
        },
        zip: inputs?.zip?.value,
      };

      setQualifications(qualifications);
      setSearchClient(await initSearchClient(searchClientOptions));
    }

    if (searchClient && !hasInit) setHasInit(true);
  }, [applyQualifications, hasInit, inputs, refetchOnInputsChange, searchClient]);

  useEffect(() => {
    initHook();
  }, [inputs]);

  return { searchClient, qualifications };
};

export default useAlgoliaClient;
