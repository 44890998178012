import { type BlockPage } from '@core/schemas/schema';

import { type QuestionInjectionConfig } from './getQuestionInjectionSchema';

const getModifiedArray = (winningPageArray: BlockPage[], questionInjectionConfig?: QuestionInjectionConfig[]) => {
  if (!questionInjectionConfig) return winningPageArray;
  const pagesWithNonConvertingPartner: BlockPage[] = winningPageArray?.reduce(
    (newPageArray: BlockPage[], page: BlockPage) => {
      const target = questionInjectionConfig?.find((pageTarget) =>
        [pageTarget.insertPosition, pageTarget.replacePosition].includes(page.slug)
      );

      if (target?.insertPosition) return [...newPageArray, page, ...target.pages];

      if (target?.replacePosition) return [...newPageArray, ...target.pages];

      return [...newPageArray, page];
    },
    []
  );
  return pagesWithNonConvertingPartner;
};
export default getModifiedArray;
