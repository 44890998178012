const whitelistedQualifiers = [
  {
    id: 'D4C1',
    field: 'gpa',
    rank: 3,
    label: 'GPA: 2.0+',
  },
  {
    id: 'B4D4',
    field: 'gpa',
    rank: 2,
    label: 'GPA: 2.5+',
  },
  {
    id: 'A1F9',
    field: 'gpa',
    rank: 1,
    label: 'GPA: 3.0+',
  },
  {
    id: 'F7B2',
    field: 'currentAcademicAward',
    label: 'BSN',
  },
  {
    id: 'B6E8',
    field: 'currentAcademicAward',
    label: 'BSW',
  },
  {
    id: 'D0D6',
    field: 'currentAcademicAward',
    label: 'Law Degree',
  },
  {
    id: 'E3A3',
    field: 'currentAcademicAward',
    label: 'MSW',
  },
  {
    id: 'A4C6',
    field: 'currentAcademicAward',
    label: 'Teaching Certificate',
  },
  {
    id: 'D1F1',
    field: 'currentLicense',
    label: 'RN License',
  },
  {
    id: 'F3B8',
    field: 'yearsOfProgramRelatedExperience',
    rank: 2,
    label: 'Program Related Experience',
  },
  {
    id: 'B4C2',
    field: 'yearsOfProgramRelatedExperience',
    rank: 1,
    label: 'Program Related Experience',
  },
  {
    id: 'B1F5',
    field: 'yearsOfWorkExperience',
    rank: 7,
    label: 'Experience: 1+ years',
  },
  {
    id: 'D8B0',
    field: 'yearsOfWorkExperience',
    rank: 6,
    label: 'Experience: 2+ years',
  },
  {
    id: 'F4E1',
    field: 'yearsOfWorkExperience',
    rank: 5,
    label: 'Experience: 3+ years',
  },
  {
    id: 'A8C0',
    field: 'yearsOfWorkExperience',
    rank: 4,
    label: 'Experience: 4+ years',
  },
  {
    id: 'A3C8',
    field: 'yearsOfWorkExperience',
    rank: 3,
    label: 'Experience: 5+ years',
  },
  {
    id: 'F6F6',
    field: 'yearsOfWorkExperience',
    rank: 2,
    label: 'Experience: 6+ years',
  },
  {
    id: 'F1E4',
    field: 'yearsOfWorkExperience',
    rank: 1,
    label: 'Experience: 7+ years',
  },
];

export default whitelistedQualifiers;
