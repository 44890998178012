/** Recommender specific utility functions */

/**
 * isSingleSubmission
 *
 * uses school id + degree to determine if recommender is single submission
 */

export const isSingleSubmission = (degree: string, schoolId?: number) => {
  // check if degree is single submission
  const isSingleSubmissionDegree = ['Doctorate', "Master's", 'Bootcamps'].includes(degree);

  // check if school id is a bumped school
  const isBumpedSchool = [142, 451].includes(schoolId);

  // if degree is a single submission degree, return true
  if (isSingleSubmissionDegree) return true;

  // if not a single submission degree & is bumped school
  // return true
  if (isBumpedSchool && !isSingleSubmissionDegree) return true;

  return false;
};

/**
 * isGradLvl
 *
 * uses dcs degree to determine if recommender shows qualifier matching language
 */

export const isGradLvl = (degree: string) =>
  // check if degree is Grad
  ['Doctorate', "Master's"].includes(degree);

/**
 * schoolBumpData
 *
 * an object containing various school specific data
 * used to highlight certain school programs in
 * various situations
 */

export const schoolBumpData = {
  // snhu
  '142': {
    title: "You're a strong match for SNHU! 🎉",
    subtitle:
      "SNHU offers some of the lowest tuition rates in the nation. Because paying for college shouldn't stop you from reaching your goals.",
  },

  // uagc
  '787': {
    title: "You're a strong match for The University of Arizona - Global Campus! 🎉",
    subtitle:
      "At UAGC, online college classes are offered in continuous 5 or 6-week terms so you can begin your program as soon as you're accepted!",
  },
};
