/* eslint-disable react/no-danger */
import { useState, useEffect, useMemo, Dispatch, SetStateAction, FC } from 'react';
import { HiOutlineChevronDoubleLeft } from 'react-icons/hi';
import { TiLocation } from 'react-icons/ti';
import { useDispatch, useSelector } from 'react-redux';

import { SchoolConfigData } from '@core/blocks/edu-match/components/ExitStrategy/type';
import Dots from '@core/blocks/edu-match/components/Loading/Dots';
import SchoolDetails from '@core/blocks/eduResults/components/SchoolDetails';
import { useFeatureFlags } from '@core/context/FeatureFlagsContext';
import { selectAllInputs, setInputs } from '@core/reducers/inputsSlice';
import { selectLeadDelivery } from '@core/reducers/matchesSlice';
import { validateEmail, validatePhone } from '@core/services/leadDelivery';
import { getSchoolConfig } from '@core/services/schoolConfig';
import TcpaSection from '@core/shared/features/TcpaSection';
import { VoyagerResult } from '@core/ts/results';
import cn from '@core/utils/classNames';

import PiiFormSectionRevamp from '../PiiFormSectionRevamp';
import styles from './PiiConfirmationRevamp.module.css';

type Props = {
  setRecommenderStep: Dispatch<SetStateAction<string>>;
  piiLeadErrors: { field: string }[];
};

const PiiConfirmationRevamp: FC<Props> = ({ setRecommenderStep, piiLeadErrors }) => {
  const dispatch = useDispatch();
  const formInputs = useSelector(selectAllInputs);

  const flags = useFeatureFlags();

  // First Name State
  const [firstName, setFirstName] = useState(formInputs.firstName?.value);
  // Last Name State
  const [lastName, setLastName] = useState(formInputs.lastName?.value);
  // Email State
  const [email, setEmail] = useState(formInputs.email?.value);
  // Phone State
  const [phone, setPhone] = useState(formInputs.phone?.value);

  // FormStatus States
  const [formError, setFormError] = useState<{ field: string }[]>([]);

  const { currentPrograms } = useSelector(selectLeadDelivery);
  const { school } = currentPrograms[0];

  // local state
  const [tcpa, setTcpa] = useState<{ schoolConfig: SchoolConfigData; hit: VoyagerResult }[]>([]);
  const [loadingTcpa, setLoadingTcpa] = useState(true);

  // UG to Grad RP Test
  const isUGToGradResultsPageABTest = flags?.voyagerGradResultsPage === 'test';

  const isEmptyString = (str: String) => str === null || str?.match(/^ *$/) !== null;
  // Build the TCPA data here to prevent the popup from jumping around after TCPA loads in
  const uniqueSchools: { schoolId: number; hit: VoyagerResult }[] = useMemo(
    () =>
      currentPrograms.reduce(
        (accumulator: { schoolId: number; hit: VoyagerResult }[], current) => {
          const existingSchool = accumulator.find((school) => school.schoolId === current.school.id);
          if (!existingSchool) {
            return [...accumulator, { schoolId: current.school.id, hit: current }];
          }
          return accumulator;
        },
        [] // accumulator initial value
      ),
    [currentPrograms]
  );

  useEffect(() => {
    if (currentPrograms?.length === 0) {
      setRecommenderStep('PROGRAM_SELECT');
    }
  }, [currentPrograms?.length]);

  // useEffect for when uniqueSchoolIds is ready to loop through and call useSchoolConfig hook and store the data in tcpa state
  useEffect(() => {
    if (uniqueSchools.length > 0) {
      // build an array by looping through the uniqueSchoolIds and calling the async getSchoolConfig function
      const tcpaArray = uniqueSchools.map(async ({ schoolId, hit }) => {
        const schoolConfig = await getSchoolConfig(schoolId);
        return { ...schoolConfig, hit };
      });
      // once the array is built, set the tcpa state
      Promise.all(tcpaArray).then((data: any[]) => {
        setTcpa(data);
        setLoadingTcpa(false);
      });
    }
  }, [uniqueSchools]);

  const BackToTopMatches = (
    <button
      onClick={() => {
        setRecommenderStep('PROGRAM_SELECT');
      }}
      className={styles.backTopMatchesBtn}
      type="button"
    >
      <HiOutlineChevronDoubleLeft size={16} />
      Back to Top Matches
    </button>
  );

  if (loadingTcpa)
    return (
      <section className={styles.loadingContainer}>
        <h2>Loading...</h2>
        <Dots />
      </section>
    );

  const onSubmit = async () => {
    setFormError([]);
    let hasFormError = false;
    const formErrorArray = [];
    const inputArray = [
      { field: 'first name', value: firstName },
      { field: 'last name', value: lastName },
      { field: 'email', value: email },
      { field: 'phone', value: phone },
    ];
    for (const input of inputArray) {
      if (isEmptyString(input.value)) {
        hasFormError = true;
        formErrorArray.push({ field: input.field });
      }
    }

    if (/\d/.test(firstName)) {
      hasFormError = true;
      formErrorArray.push({ field: 'first name' });
    }

    if (/\d/.test(lastName)) {
      hasFormError = true;
      formErrorArray.push({ field: 'last name' });
    }

    if (email && !validateEmail(email)) {
      hasFormError = true;
      formErrorArray.push({ field: 'email' });
    }

    if (phone) {
      const { validPhoneNumber } = await validatePhone(phone);

      if (!validPhoneNumber) {
        hasFormError = true;
        formErrorArray.push({ field: 'phone' });
      }
    }

    if (hasFormError) {
      setFormError(formErrorArray);
    } else {
      dispatch(
        setInputs([
          { key: 'firstName', value: firstName },
          { key: 'lastName', value: lastName },
          { key: 'email', value: email },
          { key: 'phone', value: phone },
        ])
      );
      setRecommenderStep('SUBMISSIONS');
    }
  };

  return (
    <>
      <div className={styles.piiSchoolDetails}>
        <SchoolDetails school={school} />
      </div>

      <section className={cn(styles.piiConfirmation, isUGToGradResultsPageABTest && styles.piiConfirmationRPTest)}>
        <div className={styles.piiSection}>
          <div className={styles.piiInfo}>
            <p className={styles.piiInfoReassurance}>Almost done!</p>
            <h2 className={styles.piiInfoInstructions}>
              Submit your contact info to start a conversation with a school advisor.
            </h2>
            <p className={styles.piiInfoDescription}>
              Let’s make sure your information is correct. Then, after you submit, you can start your application.
            </p>
          </div>
          <div className={styles.piiPanel}>
            <PiiFormSectionRevamp
              piiLeadErrors={[...formError, ...piiLeadErrors]}
              header="Confirm your contact details:"
              setFirstName={setFirstName}
              setLastName={setLastName}
              setEmail={setEmail}
              setPhone={setPhone}
            />
          </div>
        </div>
        <button
          className={styles.submitInfoBtn}
          id="reward_click_popup"
          data-testid="edumatch-leaddelivery-submit"
          onClick={onSubmit}
          type="button"
        >
          Confirm and Submit
        </button>
        <div className={styles.tcpa}>
          <TcpaSection tcpa={tcpa} />
        </div>
        <div className={styles.piiSection}>{BackToTopMatches}</div>
      </section>
      <div className={styles.mobileCta}>
        <div className={styles.mobileCtaSchool}>
          <h3>{school.name}</h3>
          {school.state && school.city && (
            <div className={styles.mobileCtaLocation}>
              <TiLocation size={16} />
              <p>
                {school.city}, {school.state}
              </p>
            </div>
          )}
        </div>
        <button
          data-testid="recommender-popup-submission-request-info-btn"
          className={styles.mobileSubmitInfoBtn}
          id="reward_click_popup"
          onClick={onSubmit}
          type="button"
        >
          Confirm and Submit
        </button>
        {BackToTopMatches}
      </div>
    </>
  );
};

export default PiiConfirmationRevamp;
