import { isDevelopment } from '@core/blocks/edu-match/utils/determineEnv';
import { type Flags } from '@core/context/FeatureFlagsContext';
import { qualifyEduRequest } from '@core/services/leadDelivery';

import { type InjectionKey } from './getQuestionInjectionSchema';
import { GCPParams, generateQualifyPayload, getQualifiedPartnerKeys } from './utils/qualifications';

type GNCPSParams = {
  flags: Flags;
} & GCPParams;

const getInjectionKeys = async ({ dcs, ipInferredPostalCode, flags }: GNCPSParams): Promise<InjectionKey[]> => {
  const qualifyPayload = generateQualifyPayload({ dcs, ipInferredPostalCode });

  const res = await qualifyEduRequest(qualifyPayload);

  if (res instanceof Error) {
    return [];
  }

  let injectionKeys = getQualifiedPartnerKeys(res.qualified, flags);

  // Editable Assets
  if (
    flags?.personalizationQuestionPursuingHigherEducationUndergrad &&
    flags?.personalizationQuestionImportantFactorsUndergrad
  )
    injectionKeys.push('PERSONALIZATION_TEST_UNDERGRAD');
  if (flags?.testPersonalizationGrad) injectionKeys.push('PERSONALIZATION_TEST_GRAD');

  // Hardcoded rules
  if (injectionKeys.includes('EMCC')) {
    // Remove EAB keys if EMCC is present
    injectionKeys = injectionKeys.filter((key) => !key.includes('EAB'));
  }

  if (isDevelopment()) {
    console.log('Flags:', flags);
    console.log('Injection Keys:', injectionKeys);
  }
  return injectionKeys;
};

export default getInjectionKeys;
