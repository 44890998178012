/* eslint-disable @typescript-eslint/ban-ts-comment */
import { track } from '@core/services/cohesion';
import type { IdentifyEventProperties, UseIdentifyEvents } from '@core/ts/cohesion';
import deepMerge from '@core/utils/deepMerge';

const useIdentifyEvents = (baseProperties?: IdentifyEventProperties | {}): UseIdentifyEvents => {
  const buildIdentifyEventMethod =
    <T>(eventName: string) =>
    (overrideProperties?: IdentifyEventProperties) => {
      const properties = deepMerge({}, baseProperties, overrideProperties);
      track({ eventName, properties });
    };

  return {
    identify: buildIdentifyEventMethod('Identify'),
  };
};

export default useIdentifyEvents;
