import { buildFacetFilter, buildFilter } from '@core/utils/buildFilter';

const getFilters = ({ dcs, configFilters, additionalFilters = '' }): string => {
  const { dcsDegrees, dcsCategories, dcsSubjects } = dcs;

  const degreeFilters = dcsDegrees.map((degree) => buildFacetFilter('program.degree.name', degree));
  const categoryFilters = dcsCategories.map((category) => buildFacetFilter('program.category.name', category));
  const subjectFilters = dcsSubjects.map((subject) => buildFacetFilter('program.subject.name', subject));

  const filters = buildFilter([
    ...categoryFilters,
    ...degreeFilters,
    ...subjectFilters,
    ...configFilters,
    additionalFilters,
  ]);
  return filters;
};

export default getFilters;
