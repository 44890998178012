const Icon3 = () => (
  <svg width="73" height="72" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="36.75" cy="36" r="36" fill="white" />
    <g clipPath="url(#clip0_1368_3315)">
      <circle cx="29.75" cy="25" r="12" fill="#FDABB7" />
      <path
        d="M28.2002 28.0402L25.2402 25.0802L25.2602 25.0702L28.2002 28.0102L34.2402 21.9702L34.2602 21.9802L28.2002 28.0402Z"
        stroke="#FB2E4B"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.75 43.76V41.93C50.75 40.55 49.63 39.43 48.25 39.43C46.87 39.43 45.75 40.55 45.75 41.93V40.85C45.75 39.47 44.63 38.35 43.25 38.35C41.87 38.35 40.75 39.47 40.75 40.85V29.5C40.75 28.12 39.63 27 38.25 27C36.87 27 35.75 28.12 35.75 29.5V46.05C32.21 40.95 29.04 40.74 27.1 41.78C25.74 42.51 25.75 44.18 25.75 44.18C25.75 44.18 29.37 47.39 31.58 50.95C33.01 53.25 34.37 54.98 36.18 56.79C37.99 58.6 40.23 60.34 43.88 60.34C50.76 60.34 55.76 55.24 55.76 49.72V42.85C55.76 41.47 54.64 40.35 53.26 40.35C51.88 40.35 50.76 41.47 50.76 42.85V43.76H50.75Z"
        fill="white"
        stroke="#FB2E4B"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.25 27C39.63 27 40.75 28.12 40.75 29.5V29.79C41.39 28.32 41.75 26.7 41.75 25C41.75 18.37 36.38 13 29.75 13C23.12 13 17.75 18.37 17.75 25C17.75 31.63 23.12 37 29.75 37C31.94 37 33.98 36.4 35.75 35.38V29.5C35.75 28.12 36.87 27 38.25 27Z"
        stroke="#FB2E4B"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1368_3315">
        <rect width="40" height="49.33" fill="white" transform="translate(16.75 12)" />
      </clipPath>
    </defs>
  </svg>
);

export default Icon3;
