import { createSlice } from '@reduxjs/toolkit';

import type { EduMatch, LeadDelivery } from '@core/blocks/edu-match/context/types';
import { RootState } from '@core/store';
import { VoyagerResult } from '@core/ts/results';

// Matches State with default values
const matchesDefaults: EduMatch = {
  showMobileMenu: false,
  leadDelivery: {
    show: false,
    currentPrograms: [],
  },
  isRecommenderPopupOpen: false, // defaulting to true since this is the rolled out feature
  relatedMatches: [],
  recommenderMatches: [],
  exactMatches: [],
  uniqueMatches: [],
  initialLoad: true,
  submissions: [],
  baseIndex: '',
  showDuplicatePopup: false,
  prefetchedLocations: [],
};

export const matchesSlice = createSlice({
  name: 'matches',
  initialState: matchesDefaults, // set our initial state with default values
  reducers: {
    didInitialLoad: (state) => {
      if (state.initialLoad) {
        state.initialLoad = false;
      }
    },
    setRecommenderMatches: (state, action) => {
      state.recommenderMatches = action.payload;
    },
    setRelatedMatches: (state, action) => {
      state.relatedMatches = action.payload;
    },
    setExactMatches: (state, action) => {
      state.exactMatches = action.payload;
    },
    setUniqueMatches: (state, action) => {
      state.uniqueMatches = action.payload;
    },
    addSubmission: (state, action) => {
      state.submissions.push(action.payload);
    },
    setLeadDelivery: (state, action) => {
      state.leadDelivery = action.payload;
    },
    hideLeadDelivery: (state) => {
      state.leadDelivery.show = false;
    },
    setBaseIndex: (state, action) => {
      state.baseIndex = action.payload;
    },
    setShowMobileMenu: (state, action) => {
      state.showMobileMenu = action.payload;
    },
    setShowDuplicatePopup: (state, action) => {
      state.showDuplicatePopup = action.payload;
    },
    setIsRecommenderPopupOpen: (state, action) => {
      state.isRecommenderPopupOpen = action.payload;
    },
    setPrefetchedLocations: (state, action) => {
      state.prefetchedLocations = action.payload;
    },
    setCurrentProgram: (state, action) => {
      state.leadDelivery.currentPrograms = [action.payload];
    },
    addProgramToList: (state, action) => {
      state.leadDelivery.currentPrograms?.push(action.payload);
    },
    removeProgramFromList: (state, action) => {
      state.leadDelivery.currentPrograms = state.leadDelivery.currentPrograms.filter(
        (p: any) => p.program.id !== action.payload.program.id
      );
    },
  },
});

// Actions
export const {
  didInitialLoad,
  setRecommenderMatches,
  setExactMatches,
  setRelatedMatches,
  setUniqueMatches,
  addSubmission,
  setLeadDelivery,
  hideLeadDelivery,
  setShowMobileMenu,
  setBaseIndex,
  addProgramToList,
  setCurrentProgram,
  setShowDuplicatePopup,
  removeProgramFromList,
  setIsRecommenderPopupOpen,
  setPrefetchedLocations,
} = matchesSlice.actions;
// Selectors
export const selectInitialLoad = (state: RootState): boolean => state.matches.initialLoad;
export const selectShowMobileMenu = (state: RootState): boolean => state.matches.showMobileMenu;
export const selectIsRecommenderPopupOpen = (state: RootState): boolean => state.matches.isRecommenderPopupOpen;
export const selectLeadDelivery = (state: RootState): LeadDelivery => state.matches.leadDelivery;
export const selectRelatedMatches = (state: RootState): VoyagerResult[] => state.matches.relatedMatches;
export const selectUniqueMatches = (state: RootState): VoyagerResult[] => state.matches.uniqueMatches;
export const selectExactMatches = (state: RootState): VoyagerResult[] => state.matches.exactMatches;
export const selectRecommenderMatches = (state: RootState): VoyagerResult[] => state.matches.recommenderMatches;
export const selectSubmissions = (state: RootState): VoyagerResult[] => state.matches.submissions;
export const selectBaseIndex = (state: RootState): string => state.matches.baseIndex;
export const selectShowDuplicatePopup = (state: RootState): boolean => state.matches.showDuplicatePopup;
export const selectPrefetchedLocations = (state: RootState): EduMatch['prefetchedLocations'] =>
  state.matches.prefetchedLocations;

// Reducer
export default matchesSlice.reducer;
