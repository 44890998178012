import { CombinedState } from '@reduxjs/toolkit';

import getQuestionById from '@core/blocks/edu-flow/utils/getQuestionById';
import { Flags } from '@core/context/FeatureFlagsContext';
import { RootState } from '@core/store';

import { PageType } from '../types';
import getConditionBehavior from './getConditionBehavior';

/**
 *
 * @param store App State
 * @param pages array of page objects
 * @param targetIndex check pages before this index
 * @returns number of skipped questions
 */
const getNumOfSkippedQuestion = (
  store: CombinedState<RootState>,
  pages: PageType[],
  targetIndex: number,
  flags: Flags
): number =>
  pages.reduce((acc, page, index) => {
    const question = getQuestionById(page.questionId, flags);
    const isSkippedQuestion = getConditionBehavior(store, question?.conditionalContinue) === 'SKIP';
    return isSkippedQuestion && index < targetIndex ? acc + 1 : acc;
  }, 0);
export default getNumOfSkippedQuestion;
