import { FC, useMemo } from 'react';

// Styles
import Card from '@core/blocks/eduResults/components/Card';
// Types
import { Hit } from '@core/ts/algolia';
// Utils
import cn from '@core/utils/classNames';

import styles from './Results.module.css';

export type DefaultExactMatchesProps = {
  listId?: string;
  borrowedMatches?: Hit[];
  exactMatches?: Hit[];
  sideBarDisplayed?: boolean;
};
const RevampExactMatches: FC<DefaultExactMatchesProps> = ({
  listId,
  borrowedMatches,
  exactMatches,
  sideBarDisplayed,
}) => (
  <ol className={cn(styles.results, sideBarDisplayed && styles.resultsWithSideBar)}>
    {/* Exact Matches */}
    {useMemo(
      () =>
        exactMatches?.map((exactMatch) => (
          <li key={`${listId}:${exactMatch.objectID}`}>
            <Card
              ctaLabel="Explore Program"
              result={exactMatch}
              eventingOverrides={{
                customDimensions: [{ key: 'isUserRequested', value: 'true' }],
              }}
            />
          </li>
        )),
      [listId, exactMatches]
    )}

    {/* Consolidated unique matches */}
    {useMemo(
      () =>
        borrowedMatches?.map((hit, index: number) => (
          <li key={`${listId}:${hit.objectID}`}>
            <Card
              ctaLabel="Explore Program"
              key={hit.program.id}
              result={hit}
              eventingOverrides={{
                customDimensions: [{ key: 'isUserRequested', value: 'false' }],
                product: {
                  location: 'voyager-results-page',
                  position: index + 1 + (exactMatches?.length ? exactMatches.length : 0),
                },
              }}
            />
          </li>
        )),
      [borrowedMatches, listId, exactMatches?.length]
    )}
  </ol>
);

RevampExactMatches.defaultProps = {
  listId: '',
  borrowedMatches: [],
  exactMatches: [],
  sideBarDisplayed: false,
};
export default RevampExactMatches;
