import { type FC } from 'react';

import { type CardProps } from '@core/blocks/eduResults/components/Card/DefaultCard/DefaultCard';
import LeadSubmittedCta from '@core/blocks/eduResults/components/LeadSubmittedCta';
import { type EnhancedSchoolData } from '@core/services/hub';
import cn from '@core/utils/classNames';

import styles from './EmbeddedRecommenderCardTest.module.css';

type Props = CardProps & { enhancedSchoolData?: EnhancedSchoolData };

const EmbeddedRecommenderCardTest: FC<Props> = ({
  handleProductClick,
  program,
  school,
  enhancedSchoolData,
  cardRef,
  isProgramSubmitted,
  ctaLabel,
  cta,
  isRecommender,
}) => (
  <button type="button" className={cn(styles.card, isRecommender && styles.rec)} onClick={handleProductClick}>
    <div ref={cardRef} className={styles.header}>
      <img src={`https://simple-storage-server.highereducation.com/${school?.slug}.png?w=130&h=80`} alt={school.name} />
      <div className={styles.title}>
        <h3>{school?.name}</h3>
        <div className={styles.subtitle}>
          {school?.city}, {school?.state}
        </div>
      </div>
    </div>
    <h2 className={styles.cardProgram}> {program.name} </h2>
    <p className={styles.description}>{program.snippet}</p>
    <div className={styles.content}>
      <div className={styles.data}>
        <div className={styles.dataPoint}>
          <div className={styles.title}>Start Date</div>
          <div className={styles.value}>{enhancedSchoolData?.startDate ?? 'Not provided'}</div>
        </div>
        <div className={styles.dataPoint}>
          <div className={styles.title}>Acceptance Rate</div>
          <div className={styles.value}>{enhancedSchoolData?.acceptanceRate ?? 'Not provided'}</div>
        </div>
      </div>
      {!isProgramSubmitted ? (
        <div data-testid="learn-more-btn" className={styles.btn}>
          {ctaLabel || 'Learn More'}
        </div>
      ) : (
        <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
          <LeadSubmittedCta cta={cta} />
        </div>
      )}
    </div>
  </button>
);

export { EmbeddedRecommenderCardTest };
