import getQuestionById from '@core/blocks/edu-flow/utils/getQuestionById';
import { Flags } from '@core/context/FeatureFlagsContext';

/**
 *
 * @param fieldType field type name used in the schema for a particular field
 * @param pages array of pages for current schema
 * @returns the field if the field type is in the current schema pages, null otherwise
 */
const getFieldType = (fieldType: string, pages: any, flags: Flags): any => {
  let fieldLocated = null;
  if (pages) {
    pages.forEach((page) => {
      if (fieldLocated) return;
      const question = getQuestionById(page.questionId, flags);
      // Look into the page's fields
      if (question.fields) {
        question.fields.forEach((field) => {
          if (fieldLocated) return;
          if (field.type === fieldType) {
            fieldLocated = field;
          }
        });
      }
      // Also check the conditional fields
      if (question.conditional?.content?.fields) {
        question.conditional.content.fields.forEach((field) => {
          if (fieldLocated) return;
          if (field.type === fieldType) {
            fieldLocated = field;
          }
        });
      }
    });
  }
  return fieldLocated;
};

export default getFieldType;
