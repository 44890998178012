import { FC } from 'react';
import { useSelector } from 'react-redux';

import usePageHeadingParser from '@core/blocks/edu-flow/components/Page/hooks/usePageHeadingParser';
import { selectDcsCategories } from '@core/reducers/dcsSlice';

import styles from './QuestionHeader.module.css';

type Props = {
  heading: string;
  preSubheading?: string;
  postSubheading?: string;
};
const PageHeader: FC<Props> = ({ heading, preSubheading = '', postSubheading = '' }) => {
  const parseHeader = usePageHeadingParser();
  const dcsCategory = useSelector(selectDcsCategories)[0];
  return (
    <>
      {preSubheading && (
        <p className={styles.pagePreSubheading} dangerouslySetInnerHTML={{ __html: parseHeader(preSubheading) }} />
      )}
      <h2
        className={styles.pageHeading}
        dangerouslySetInnerHTML={{
          __html: dcsCategory === 'General' ? parseHeader(heading).replace('General', '') : parseHeader(heading),
        }}
      />
      {postSubheading && (
        <p className={styles.pagePostSubheading} dangerouslySetInnerHTML={{ __html: parseHeader(postSubheading) }} />
      )}
    </>
  );
};
export default PageHeader;
