import cloneDeep from 'lodash.clonedeep';
import merge from 'lodash.merge';

import { type QuestionType } from '@core/blocks/edu-flow/components/Page/types';
import { type Flags } from '@core/context/FeatureFlagsContext';
import generateAssetQuestion from '@core/editableAssets/generateAssetQuestion';
import questionBank from '@core/schemas/question_bank.json';

// Gets a question from the question bank by id
const getQuestionById = (questionId: string, flags: Flags): QuestionType => {
  let question: QuestionType = cloneDeep(questionBank[questionId as keyof typeof questionBank]);

  if (question?.extends) {
    question = merge(getQuestionById(question.extends, flags), question);
    delete question.extends;
    question.fields =
      (questionBank[questionId as keyof typeof questionBank] as unknown as QuestionType).fields ?? question.fields;
  }
  if (question?.monarchEditableAssetFlag && flags) question = merge(generateAssetQuestion(question, flags), question);

  return question;
};

export default getQuestionById;
