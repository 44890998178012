/**
 * determineEnv
 *
 * A function that uses the URL to determine which env the app is on.
 */

export enum Environments {
  PRODUCTION = 'production',
  DEVELOPMENT = 'development',
}

export const devUrls = [
  'dev.bestcolleges.com',
  'localhost',
  '127.0.0.1',
  'dev-',
  '.pantheonsite.io',
  'voyager-experiences',
];

function determineEnv(): Environments {
  // get url
  const url: string = window.location.hostname;

  // if url hostname includes one of the devUrls then  env is development
  if (devUrls.some((path) => url.includes(path))) {
    return Environments.DEVELOPMENT;
  }

  // return env based on evaluation of url hostname
  return Environments.PRODUCTION;
}

export const isProduction = () => determineEnv() === Environments.PRODUCTION;
export const isDevelopment = () => determineEnv() === Environments.DEVELOPMENT;

export default determineEnv;
