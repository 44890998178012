import { VoyagerInputs } from '@core/reducers/inputsSlice';
import QUESTION_BANK from '@core/schemas/question_bank.json';
import { RootState } from '@core/store';

import { PageType, QuestionType } from '../types';
import getConditionBehavior from './getConditionBehavior';

type GetNextStepParams = {
  flow: PageType[];
  index: number;
  store: RootState;
};

const getNextStep = ({ store, flow, index }: GetNextStepParams) => {
  const questions: any = QUESTION_BANK;
  // Handle step skip in the question before the skip question
  // const nextQuestion = QUESTION_BANK[flow[index + 1]?.questionId];
  // const skip = getConditionBehavior(inputs, nextQuestion?.conditionalContinue) === 'SKIP';
  let i = 1;
  let nextQuestion;
  let skip = true;
  while (skip) {
    nextQuestion = questions[flow[(index as number) + i]?.questionId as string] as QuestionType;
    skip = getConditionBehavior(store, nextQuestion?.conditionalContinue) === 'SKIP';
    if (skip) {
      i += 1;
    }
  }
  return { i, nextQuestion };
};

export default getNextStep;
