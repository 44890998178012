import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Config files
import facetConfig from '@core/config/facet.json';
// Hooks
import useConfig from '@core/hooks/useConfig';
// Reducers selectors/actions
import { setFacets } from '@core/reducers/configSlice';
import { selectAllInputs } from '@core/reducers/inputsSlice';

/*
  Dynamic Qualifiers
    Loops through each of the filters that useConfig returns.
    It checks to see if curly brackets are found in the string.
    If found, it will replace that with the value of the inputs[key].
    If no brackets are found, we return the value as stated in the config.
*/

const processFacets = (results, inputs) => {
  const initialFacets = results.reduce((accumulator, { key }) => {
    accumulator[key] = [];
    return accumulator;
  }, {});

  return results.reduce((accumulator, { key, value }) => {
    if (value.includes('{')) {
      accumulator[key] = inputs[key].value;
      return accumulator;
    }

    accumulator[key].push(value);
    return accumulator;
  }, initialFacets);
};

/**
 * useFacetConfig handles filter config files for the voyager app
 * values will be stored in state for use in other components
 * @returns
 */

const useFacetConfig = () => {
  const dispatch = useDispatch();

  const inputs = useSelector(selectAllInputs);

  const { isCompleted, results } = useConfig(facetConfig);

  const [configFacets, setConfigFacets] = useState(results);

  // Once our config is completed, set the filters in state
  useEffect(() => {
    if (!isCompleted) return;

    const processedFacets = processFacets(results, inputs);
    // Add filters to local state
    setConfigFacets(processedFacets);
    // Add filters to global state
    dispatch(setFacets(processedFacets));
  }, [isCompleted, inputs]);

  return { isCompleted, configFacets };
};

export default useFacetConfig;
