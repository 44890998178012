import { Middleware } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import { RootState } from '.';

const cookieMiddleware: Middleware = (store) => (next) => (action) => {
  // Call the next middleware in the chain
  const result = next(action);

  // Get the updated state after the action has been dispatched
  const voyagerAppState: RootState = store.getState();

  // Store the state in a cookie
  Cookies.set('voyagerAppState', JSON.stringify(voyagerAppState), { expires: 7 });

  return result;
};

export default cookieMiddleware;
