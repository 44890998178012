import { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectDcsDegrees } from '@core/reducers/dcsSlice';
import { selectListId } from '@core/reducers/eventingSlice';
import { selectInput } from '@core/reducers/inputsSlice';
import { selectUniqueMatches, setExactMatches } from '@core/reducers/matchesSlice';
import { Hit } from '@core/ts/algolia';

import sortHits from '../../utils/sortHits';
import withExtraProps from '../../utils/withExtraProps';
import DefaultExactMatches from './DefaultExactMatches';
import { ExactMatchesProps } from './types';

const ExactMatches: FC<ExactMatchesProps> = withExtraProps(
  ({ searchState, hits, ExactMatchesComponent = DefaultExactMatches }) => {
    // Redux store dispatch function hook
    const dispatch = useDispatch();

    // Returns a list of unique matches (No duplicate schools) to be consolidated in exact matches
    const borrowedMatches = useSelector(selectUniqueMatches);

    // Sidebar state, flag, and trigger
    const [sideBarDisplayed, setSideBarDisplayed] = useState<boolean>(false);
    const dcsDegrees: string[] = useSelector(selectDcsDegrees);
    const zip = useSelector((state) => selectInput(state, 'zip'));

    // New state for sorted hits
    const [_hits, setHits] = useState<Hit[]>([]);

    // Handle bootcamps side bar
    useEffect(() => {
      if (dcsDegrees.includes('Bootcamps')) setSideBarDisplayed(true);
    }, []);

    // Store properties
    const listId = useSelector(selectListId);

    // Bonsai A/B Test

    useEffect(() => {
      // Sort Hits based on eRPI  only if we are not applying another sorting method (e.g. distance)
      // - higher eRPI should be showed first.
      if (searchState?.sortBy?.length) {
        setHits(hits);
      } else {
        sortHits(hits, zip).then((sortedHits) => setHits(sortedHits));
      }
    }, [hits, searchState, zip]);

    useEffect(() => {
      // Save the exact matches to the store for DCS consolidation use
      dispatch(setExactMatches(hits));
    }, [hits]);

    return (
      <ExactMatchesComponent
        listId={listId}
        borrowedMatches={borrowedMatches as Hit[]}
        exactMatches={_hits}
        sideBarDisplayed={sideBarDisplayed}
      />
    );
  }
);

ExactMatches.defaultProps = {
  hits: [],
  searchState: {},
  ExactMatchesComponent: DefaultExactMatches,
};

export default ExactMatches;
