import NoResultsImage from './Image';
import styles from './NoResults.module.css';

const NoResults = () => (
  <div className={styles.noResults} data-testid="no-results">
    <div>
      <h2>We couldn&apos;t find a direct match for your search</h2>
    </div>
    <div className={styles.noResultsImage}>
      <NoResultsImage />
    </div>
  </div>
);

export { NoResults };
