import { connectHits } from 'react-instantsearch-dom';

const OtherPrograms = connectHits(({ hits }) => (
  <ul>
    {hits?.map((hit: any) => (
      <li key={hit.program.name}>{hit.program.name}</li>
    ))}
  </ul>
));

export default OtherPrograms;
