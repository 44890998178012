import { FC, useEffect, useState } from 'react';

import { PageType } from '@core/blocks/edu-flow/components/Page/types';

import styles from './ProgressBar.module.css';

type ProgressBarProps = {
  flow: PageType[];
  page: PageType;
};

const ProgressBar: FC<ProgressBarProps> = ({ flow, page }) => {
  const [progress, setProgress] = useState(0);

  // Figure out what page we are on and use that to determine where we are percent wise
  useEffect(() => {
    // Get total number of pages in the flow
    const totalPages = flow.length;

    // Determine the index of the current page for updating the progress bar
    const curPage = flow.findIndex((p) => p.slug === page.slug);

    // If we're on the first page default to 10% so we show _something_
    const percent = curPage > 0 ? (curPage / totalPages) * 100 : 10;

    setProgress(percent);
  }, [page]);

  return (
    <div data-testid="progress-bar" className={styles.progressBar}>
      <div className={styles.progress} style={{ width: `${progress}%` }} />
    </div>
  );
};
export default ProgressBar;
