import { useRef, useState, useEffect, MutableRefObject } from 'react';

const handleObserver =
  (element, cb) =>
  ([entry], observer) => {
    if (entry.isIntersecting) {
      cb();
      // Only observe one time if intersecting.
      observer.unobserve(element);
    }
  };

const makeObserver = (element, cb) => {
  if (!('IntersectionObserver' in window) || !element) return null;
  const observer = new IntersectionObserver(handleObserver(element, cb), {
    threshold: 0.3,
  });
  observer.observe(element);
  return observer;
};

export default function useIntersectionObserver(elementRef: MutableRefObject<any>): boolean | null {
  const [isInView, setInView] = useState(false);
  const observer = useRef(null);

  useEffect(() => {
    if (!elementRef || !elementRef.current) return;
    if (observer.current) observer.current.disconnect();

    observer.current = makeObserver(elementRef.current, () => setInView(true));

    // eslint-disable-next-line consistent-return
    return () => observer.current && observer.current.disconnect();
  }, [elementRef]);

  return isInView;
}
