// Default fields
import CheckboxField from '@edu-flow/components/Fields/CheckboxField';
// Custom fields
import InfoPanelField from '@edu-flow/components/Fields/InfoPanelField';
import LocationField from '@edu-flow/components/Fields/LocationField';
import RadioField from '@edu-flow/components/Fields/RadioField';
import SectionField from '@edu-flow/components/Fields/SectionField';
import SelectField from '@edu-flow/components/Fields/SelectField';
import TextField from '@edu-flow/components/Fields/TextField';

import OptionGroupField from '@core/blocks/edu-flow/components/Fields/OptionGroupField';

import CalloutField from '../components/Fields/CalloutField';
import Conditional from '../components/Fields/ConditionalField';
import DisciplineDescriptionPanelField from '../components/Fields/DisciplineDescriptionPanelField';
import PhoneField from '../components/Fields/PhoneField';
import ZipField from '../components/Fields/ZipField';

const fieldTypeDictionary = {
  zip: ZipField,
  phone: PhoneField,
  text: TextField,
  number: TextField,
  radio: RadioField,
  select: SelectField,
  checkbox: CheckboxField,
  section: SectionField,
  location: LocationField,
  infoPanel: InfoPanelField,
  callout: CalloutField,
  conditional: Conditional,
  optionGroup: OptionGroupField,
  disciplineDescriptionPanel: DisciplineDescriptionPanelField,
};

export default fieldTypeDictionary;
