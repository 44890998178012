import determineColor from '@core/utils/determineColor';

const Icon = ({ name, color, size }) => {
  // If `name` isn't provided, we don't show any icon
  if (!name) {
    return null;
  }

  // format the color prop to be able to pass to the url
  // determineColor will return a hex value, and we need to
  // strip the '#' from the string
  const formattedColor = determineColor(color).trim().substring(1);

  // The size will follow the same sizing scale as TailwindCSS which is `size / 4` rem
  // https://tailwindcss.com/docs/customizing-spacing#default-spacing-scale
  const sizeInRem = `${size / 4}rem`;
  const styleObj = { height: sizeInRem, width: sizeInRem };

  return (
    <div style={styleObj}>
      <img
        loading="lazy"
        src={`https://res.cloudinary.com/highereducation/image/upload/co_rgb:${formattedColor},e_colorize:100,f_auto,fl_lossy,q_auto,h_${
          size * 8
        },w_${size * 8}/v1/Voyager/${name}`}
        alt={name}
      />
    </div>
  );
};

export default Icon;
