import { FC, useMemo } from 'react';

// Types
import { Hit } from '@core/ts/algolia';
// Utils
import cn from '@core/utils/classNames';

// Components
import { Card } from '../Card';
// Styles
import styles from './Results.module.css';

export type DefaultExactMatchesProps = {
  listId?: string;
  borrowedMatches: Hit[];
  exactMatches?: Hit[];
  sideBarDisplayed?: boolean;
};
const DefaultExactMatches: FC<DefaultExactMatchesProps> = ({
  listId = '',
  borrowedMatches = [],
  exactMatches = [],
  sideBarDisplayed = false,
}) => (
  <ol className={cn(styles.results, sideBarDisplayed && styles.resultsWithSideBar)}>
    {/* Exact Matches */}
    {useMemo(
      () =>
        exactMatches?.map((exactMatch) => (
          <li key={`${listId}:${exactMatch.objectID}`}>
            <Card
              dataTestId="exact-matches-card"
              hit={exactMatch}
              eventingOverrides={{
                customDimensions: [{ key: 'isUserRequested', value: 'true' }],
              }}
            />
          </li>
        )),
      [listId, exactMatches]
    )}

    {/* Consolidated unique matches */}
    {useMemo(
      () =>
        borrowedMatches.map((hit, index: number) => (
          <li key={`${listId}:${hit.objectID}`}>
            <Card
              dataTestId="borrowed-exact-matches-card"
              key={hit.program.id}
              hit={hit}
              eventingOverrides={{
                customDimensions: [{ key: 'isUserRequested', value: 'false' }],
                product: { location: 'voyager-results-page', position: index + 1 + exactMatches.length },
              }}
            />
          </li>
        )),
      [borrowedMatches, listId, exactMatches.length]
    )}
  </ol>
);

export default DefaultExactMatches;
