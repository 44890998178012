import { newRelicNoticeError, nrErrorMap } from '@core/services/newRelic';

// Abstracted out retrieving school config for use outside of hooks
export const getSchoolConfig = async (schoolId: number) => {
  try {
    const res = await fetch(`https://schoolconfig.highereducation.com/${schoolId}/live/latest.json`, {
      mode: 'cors',
    });
    return await res.json();
  } catch (error) {
    newRelicNoticeError(nrErrorMap.SCHOOL_CONFIG, error);
    return { error: "Couldn't retrieve schema" };
  }
};
