const LibrarySVG = () => (
  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.6667 18.6667V22.6667M16 18.6667V22.6667M21.3333 18.6667V22.6667M4 28H28M4 13.3333H28M4 9.33333L16 4L28 9.33333M5.33333 13.3333H26.6667V28H5.33333V13.3333Z"
      stroke="#8B4C4C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default LibrarySVG;
