import { type FC, useEffect, useState, useRef } from 'react';

import cn from '@core/utils/classNames';
import determineColor from '@core/utils/determineColor';

import type { FieldComponentProps } from '../types';
import styles from './InfoPanelField.module.css';

const InfoPanelField: FC<FieldComponentProps> = ({ field }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    document.onclick = ({ target }) => {
      if (typeof ref?.current === 'undefined' || !ref.current?.contains(target as unknown as Node))
        setIsCollapsed(false);
    };
  }, []);

  return (
    <button
      type="button"
      data-testid="info-panel"
      ref={ref}
      onClick={() => setIsCollapsed(!isCollapsed)}
      className={cn(styles.infoPanel, isCollapsed ? styles.collapsed : '')}
    >
      <h2 className={styles.heading}>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.6209 10.0004C19.6209 15.3023 15.3228 19.6004 10.0209 19.6004C4.71897 19.6004 0.420898 15.3023 0.420898 10.0004C0.420898 4.69846 4.71897 0.400391 10.0209 0.400391C15.3228 0.400391 19.6209 4.69846 19.6209 10.0004ZM10.0209 6.40039C9.57826 6.40039 9.19002 6.63945 8.98072 7.00126C8.64887 7.57493 7.91479 7.77097 7.34112 7.43912C6.76745 7.10727 6.57141 6.37319 6.90326 5.79952C7.52391 4.72661 8.6869 4.00039 10.0209 4.00039C12.0091 4.00039 13.6209 5.61217 13.6209 7.60039C13.6209 9.16785 12.6191 10.5013 11.2209 10.9955V11.2004C11.2209 11.8631 10.6837 12.4004 10.0209 12.4004C9.35817 12.4004 8.82091 11.8631 8.82091 11.2004V10.0004C8.82091 9.33765 9.35817 8.80039 10.0209 8.80039C10.6837 8.80039 11.2209 8.26313 11.2209 7.60039C11.2209 6.93765 10.6837 6.40039 10.0209 6.40039ZM10.0209 16.0004C10.6836 16.0004 11.2209 15.4631 11.2209 14.8004C11.2209 14.1376 10.6836 13.6004 10.0209 13.6004C9.35816 13.6004 8.8209 14.1376 8.8209 14.8004C8.8209 15.4631 9.35816 16.0004 10.0209 16.0004Z"
            fill={determineColor('--primary-700')}
          />
        </svg>
        <span>{field.heading}</span>
      </h2>
      {isCollapsed && field.content && (
        <p className={styles.content} dangerouslySetInnerHTML={{ __html: field.content }} />
      )}
    </button>
  );
};

export default InfoPanelField;
