import { type FC, type PropsWithChildren } from 'react';
import { HiCheckCircle } from 'react-icons/hi2';
import { useSelector } from 'react-redux';

import { selectDcs } from '@core/reducers/dcsSlice';
import type { Qualifier, VoyagerResult } from '@core/ts/results';
import cn from '@core/utils/classNames';

import styles from './MatchContextPills.module.css';
import getLabelsForMatchContextPills from './getLabelsForMatchContextPills';

type Props = {
  hits: VoyagerResult[];
  qualifiers?: Qualifier[];
  showPillsOnly?: boolean;
  centered?: boolean;
};

const Pill: FC<PropsWithChildren> = ({ children }) => (
  <div className={styles.pill}>
    <HiCheckCircle size={18} />
    {children}
  </div>
);

const MatchContextPills: FC<Props> = ({ hits, qualifiers, showPillsOnly, centered }) => {
  const { dcsDegrees, dcsCategories, dcsSubjects } = useSelector(selectDcs);
  const [degree] = dcsDegrees;
  const [category] = dcsCategories;
  const [subject] = dcsSubjects;

  const labels = getLabelsForMatchContextPills(hits, qualifiers);

  return (
    <div className={cn(styles.matchContextPills, centered && styles.centered)}>
      {!showPillsOnly && <span>Your results are based on your answers:</span>}
      {degree && <Pill>{degree}</Pill>}
      {category && <Pill>{category}</Pill>}
      {subject && <Pill>{subject}</Pill>}
      {labels?.slice(0, 2)?.map((qualifier) => (
        <Pill key={qualifier}>{qualifier}</Pill>
      ))}
    </div>
  );
};

export default MatchContextPills;
