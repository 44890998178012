import { createSlice } from '@reduxjs/toolkit';

export type DCS = {
  dcsDegrees: string[];
  dcsSubjects: string[];
  dcsCategories: string[];
};

// State with default values
const initialState: DCS = {
  dcsDegrees: [] as string[],
  dcsSubjects: [] as string[],
  dcsCategories: ['General'] as string[],
};

export const dcsSlice = createSlice({
  name: 'dcs',
  initialState, // set our initial state with default values
  reducers: {
    // set our initial values from any query params
    setInitialDCSValuesFromQueryParams: (state, action) => {
      if (!action.payload) return;
      const { dcs } = action.payload;

      if (dcs) {
        if (dcs.degrees?.includes('Bootcamps')) state.dcsCategories = [];
        if (dcs.degrees?.length) state.dcsDegrees = dcs.degrees;
        if (dcs.subjects?.length) state.dcsSubjects = dcs.subjects;
        if (dcs.categories?.length) state.dcsCategories = dcs.categories;
      }
    },
    setDcsValue: (state, action) => {
      state[action.payload.key] = [action.payload.value];
    },
    setDcsSubjects: (state, action) => {
      state.dcsSubjects = action.payload;
    },
    // If value already in category, remove it, otherwise add it
    toggleDcsCategory: (state, action) => {
      const category = action.payload;
      const index = state.dcsCategories.indexOf(category);
      if (index > -1) {
        state.dcsCategories.splice(index, 1);
      } else {
        state.dcsCategories.push(category);
      }
    },

    toggleDcsSubject: (state, action) => {
      const subject = action.payload;
      const index = state.dcsSubjects.indexOf(subject);
      if (index > -1) {
        state.dcsSubjects.splice(index, 1);
      } else {
        state.dcsSubjects.push(subject);
      }
    },
  },
});

// Actions
export const { setDcsSubjects, toggleDcsCategory, toggleDcsSubject, setInitialDCSValuesFromQueryParams, setDcsValue } =
  dcsSlice.actions;

// Selectors
export const selectDcs = (state): DCS => state.dcs;
export const selectDcsDegrees = (state): string[] => state.dcs.dcsDegrees;
export const selectDcsSubjects = (state): string[] => state.dcs.dcsSubjects;
export const selectDcsCategories = (state): string[] => state.dcs.dcsCategories;

// Reducer
export default dcsSlice.reducer;
