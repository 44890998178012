import { newRelicNoticeError, nrErrorMap } from '@core/services/newRelic';

/**
 * getToken
 *
 * A function that attempts to fetch the publishers mobius idToken.
 *
 * There are three places a token can be found.
 * - As an attribute on the mounting element
 * - On the window object within HE.publisher or HE.mobius
 * - In a local .env file
 *
 * @param {string} token - will have a value if token is passed on mounting element
 * @returns {string}
 */

const getToken = (token?: string): string | void => {
  // check if param contains token, and return if so
  if (token) {
    return token;
  }

  // attempt to fetch token from window, and return if found
  const windowToken = window?.HE?.publisher?.identityToken || window?.HE?.mobius?.token || null;

  if (windowToken) {
    return windowToken;
  }

  const envToken = import.meta.env.VITE_MOBIUS_ID_TOKEN;

  // fallback to env variable
  if (envToken) {
    console.error('Mobius id token is required.');
    newRelicNoticeError(nrErrorMap.MOBIUS_TOKEN, window.HE);
    return envToken;
  }

  // if none found, log error in New Relic with current window object
  console.error('Mobius id token is required.');
  newRelicNoticeError(nrErrorMap.MOBIUS_TOKEN, window.HE);
  return null;
};

export default getToken;
