const Icon2 = () => (
  <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="36" cy="36" r="36" fill="white" />
    <g clipPath="url(#clip0_1368_3291)">
      <path
        d="M49.5 15.5L20 16L17.5 16.5L16.5 19V52L17 58L19.5 59.5H51.5L54.5 58.5L55 55V19L53.5 16L49.5 15.5Z"
        fill="white"
      />
      <path
        d="M39.5 12L37.5 10L35.5 9.5L33.5 10L32.5 12L31.5 14L28.5 15.5L26.5 17.5V19H45L45.5 18.5L43.5 16L40 14L39.5 12Z"
        fill="#FDABB7"
      />
      <path
        d="M51.27 60.3H20.5C18.02 60.3 16 58.28 16 55.8V19.27C16 16.79 18.02 14.77 20.5 14.77H28.72C29.01 14.77 29.26 14.96 29.34 15.23C29.42 15.5 29.32 15.8 29.08 15.96C27.94 16.72 27.21 17.65 26.99 18.62H44.77C44.55 17.65 43.82 16.73 42.68 15.96C42.44 15.8 42.34 15.5 42.42 15.23C42.5 14.96 42.76 14.77 43.04 14.77H51.26C53.74 14.77 55.76 16.79 55.76 19.27V55.8C55.76 58.28 53.74 60.3 51.26 60.3H51.27ZM20.5 16.07C18.74 16.07 17.3 17.5 17.3 19.27V55.8C17.3 57.56 18.73 59 20.5 59H51.27C53.03 59 54.47 57.57 54.47 55.8V19.27C54.47 17.51 53.04 16.07 51.27 16.07H44.81C45.68 17.03 46.15 18.13 46.15 19.27C46.15 19.63 45.86 19.92 45.5 19.92H26.27C25.91 19.92 25.62 19.63 25.62 19.27C25.62 18.13 26.09 17.03 26.96 16.07H20.5Z"
        fill="#FB2E4B"
      />
      <path
        d="M45.4991 19.92H26.2691C25.9091 19.92 25.6191 19.63 25.6191 19.27C25.6191 16.78 27.8691 14.55 31.3891 13.49C31.3891 11.02 33.4091 9 35.8891 9C38.3691 9 40.3791 11.01 40.3891 13.49C43.9091 14.55 46.1591 16.78 46.1591 19.27C46.1591 19.63 45.8691 19.92 45.5091 19.92H45.4991ZM26.9891 18.62H44.7691C44.3691 16.88 42.3991 15.34 39.5191 14.6C39.1991 14.52 38.9991 14.21 39.0391 13.89L39.0591 13.77C39.0691 13.68 39.0891 13.59 39.0891 13.5C39.0891 11.74 37.6591 10.3 35.8891 10.3C34.1191 10.3 32.6891 11.73 32.6891 13.5C32.6891 13.59 32.6991 13.68 32.7191 13.76L32.7391 13.89C32.7791 14.21 32.5691 14.51 32.2591 14.6C29.3791 15.35 27.3991 16.88 26.9991 18.62H26.9891Z"
        fill="#FB2E4B"
      />
      <path
        d="M30.3807 36.6999H24.3807C23.1907 36.6999 22.2207 35.7299 22.2207 34.5399V28.5399C22.2207 27.3499 23.1907 26.3799 24.3807 26.3799H26.1007C26.4607 26.3799 26.7507 26.6699 26.7507 27.0299C26.7507 27.3899 26.4607 27.6999 26.1007 27.6999H24.3807C23.9207 27.6999 23.5407 28.0799 23.5407 28.5399V34.5399C23.5407 34.9999 23.9207 35.3799 24.3807 35.3799H30.3807C30.8407 35.3799 31.2207 34.9999 31.2207 34.5399V32.8099C31.2207 32.4499 31.5107 32.1599 31.8707 32.1599C32.2307 32.1599 32.5407 32.4499 32.5407 32.8099V34.5399C32.5407 35.7299 31.5707 36.6999 30.3807 36.6999Z"
        fill="#FB2E4B"
      />
      <path
        d="M27.2305 32.9096C27.0605 32.9096 26.9005 32.8496 26.7705 32.7196L25.5605 31.5096C25.3105 31.2596 25.3105 30.8396 25.5605 30.5896C25.8105 30.3396 26.2405 30.3196 26.4905 30.5796L27.2305 31.3196L31.4105 27.1396C31.6605 26.8896 32.0805 26.8896 32.3305 27.1396C32.5805 27.3896 32.6005 27.8196 32.3405 28.0696L27.6805 32.7296C27.5505 32.8596 27.3905 32.9196 27.2205 32.9196L27.2305 32.9096Z"
        fill="#E22944"
      />
      <path
        d="M48.8805 29.6999H37.8805C37.5205 29.6999 37.2305 29.4099 37.2305 29.0499C37.2305 28.6899 37.5205 28.3799 37.8805 28.3799H48.8805C49.2405 28.3799 49.5305 28.6699 49.5305 29.0299C49.5305 29.3899 49.2405 29.6999 48.8805 29.6999Z"
        fill="#FB2E4B"
      />
      <path
        d="M48.8805 34.6999H37.8805C37.5205 34.6999 37.2305 34.4099 37.2305 34.0499C37.2305 33.6899 37.5205 33.3799 37.8805 33.3799H48.8805C49.2405 33.3799 49.5305 33.6699 49.5305 34.0299C49.5305 34.3899 49.2405 34.6999 48.8805 34.6999Z"
        fill="#FB2E4B"
      />
      <path
        d="M30.3807 52.6999H24.3807C23.1907 52.6999 22.2207 51.7299 22.2207 50.5399V44.5399C22.2207 43.3499 23.1907 42.3799 24.3807 42.3799H26.1007C26.4607 42.3799 26.7507 42.6699 26.7507 43.0299C26.7507 43.3899 26.4607 43.6999 26.1007 43.6999H24.3807C23.9207 43.6999 23.5407 44.0799 23.5407 44.5399V50.5399C23.5407 50.9999 23.9207 51.3799 24.3807 51.3799H30.3807C30.8407 51.3799 31.2207 50.9999 31.2207 50.5399V48.8099C31.2207 48.4499 31.5107 48.1599 31.8707 48.1599C32.2307 48.1599 32.5407 48.4499 32.5407 48.8099V50.5399C32.5407 51.7299 31.5707 52.6999 30.3807 52.6999Z"
        fill="#FB2E4B"
      />
      <path
        d="M27.2305 48.9096C27.0605 48.9096 26.9005 48.8497 26.7705 48.7197L25.5605 47.5096C25.3105 47.2596 25.3105 46.8396 25.5605 46.5896C25.8105 46.3396 26.2405 46.3197 26.4905 46.5797L27.2305 47.3196L31.4105 43.1396C31.6605 42.8896 32.0805 42.8896 32.3305 43.1396C32.5805 43.3896 32.6005 43.8196 32.3405 44.0696L27.6805 48.7296C27.5505 48.8596 27.3905 48.9196 27.2205 48.9196L27.2305 48.9096Z"
        fill="#E22944"
      />
      <path
        d="M48.8805 45.6999H37.8805C37.5205 45.6999 37.2305 45.4099 37.2305 45.0499C37.2305 44.6899 37.5205 44.3799 37.8805 44.3799H48.8805C49.2405 44.3799 49.5305 44.6699 49.5305 45.0299C49.5305 45.3899 49.2405 45.6999 48.8805 45.6999Z"
        fill="#FB2E4B"
      />
      <path
        d="M48.8805 50.6999H37.8805C37.5205 50.6999 37.2305 50.4099 37.2305 50.0499C37.2305 49.6899 37.5205 49.3799 37.8805 49.3799H48.8805C49.2405 49.3799 49.5305 49.6699 49.5305 50.0299C49.5305 50.3899 49.2405 50.6999 48.8805 50.6999Z"
        fill="#FB2E4B"
      />
    </g>
    <defs>
      <clipPath id="clip0_1368_3291">
        <rect width="39.76" height="51.3" fill="white" transform="translate(16 9)" />
      </clipPath>
    </defs>
  </svg>
);

export default Icon2;
