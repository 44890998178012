import { type DeepPartial } from '@reduxjs/toolkit';
import { merge } from 'lodash';

import { type AdditionalContentFieldType } from '@core/blocks/edu-flow-2/components/AdditionalContentFields/types';
import { type QuestionType } from '@core/blocks/edu-flow/components/Page/types';
import { type MonarchEditableAsset } from '@core/context/types';

const addAdditionalContent = (
  question: QuestionType,
  { sidePanelContentType, sidePanelContentValue }: MonarchEditableAsset
): QuestionType => {
  const hasAdditionalContent = sidePanelContentType && sidePanelContentValue;

  if (!hasAdditionalContent) return question;

  const partialQuestion: DeepPartial<QuestionType> = {
    additionalContent: [
      {
        content: sidePanelContentValue,
        type: sidePanelContentType as AdditionalContentFieldType,
      },
    ],
  };

  return merge(question, partialQuestion);
};

export default addAdditionalContent;
