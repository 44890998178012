import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ExitStrategy } from '@core/blocks/edu-match/components';
import { LEAD_SUBMISSION_STEPS } from '@core/constants';
import useFormEvents from '@core/hooks/cohesion/useFormEvents';
import { selectFormContext, selectHeclidMap, selectProductCorrelationIdMap } from '@core/reducers/eventingSlice';
import { selectAllInputs } from '@core/reducers/inputsSlice';
import { selectSubmissions } from '@core/reducers/matchesSlice';
import { postGetLeadSchema } from '@core/services/leadDelivery';
import Diffy from '@core/utils/diffy';

import AdditionalQuestions from './AdditionalQuestions';
import PiiConfirmation from './PiiConfirmation';

const { PII_CONFIRMATION, EXIT_STRATEGY } = LEAD_SUBMISSION_STEPS;

const SingleProgramSubmission = ({ hit }) => {
  const [step, setStep] = useState<string>(undefined);
  const submissions = useSelector(selectSubmissions);
  const productCorrelationIdMap = useSelector(selectProductCorrelationIdMap);
  const heclidMap = useSelector(selectHeclidMap);
  const inputs = useSelector(selectAllInputs);
  const [currentSchema, setCurrentSchema] = useState(undefined);
  const [prqSchema, setPrqSchema] = useState(undefined);
  const formContext = useSelector(selectFormContext);
  const [editMode, setEditMode] = useState(false);
  const [piiLeadErrors, setPiiLeadErrors] = useState<Array<{ field: string }>>([]);
  const diffy = new Diffy(inputs);

  const { formErrored } = useFormEvents({
    formContext,
    errorDetails: 'INVALID',
    errorMessage: 'Please provide your valid phone number.',
    errorType: 'Phone Validation',
  });

  useEffect(() => {
    // we only need to get the schema if a lead hasn't been submitted for this program
    if (!step) {
      const getSchema = async () => {
        const schema = await postGetLeadSchema({
          schoolId: hit?.school?.id,
          programId: hit?.program?.id,
          body: {
            lead: {
              location: {
                postalCode: inputs?.zip?.value,
              },
            },
            trackingContext: {
              correlationId: productCorrelationIdMap[hit?.program?.id],
              publisher: window.location.host,
              heclid: heclidMap[hit?.program?.id],
              anonymousId: window._Cohesion.anonymousId,
              url: inputs?.sourceUrl?.value,
              experience: 'Voyager',
            },
          },
        });
        setCurrentSchema(schema);
        setPrqSchema(diffy.diffLdsSchema(schema, hit?.leadSource));
      };
      getSchema();
    }

    const programHasBeenSubmitted = submissions.find((submission) => submission.program.id === hit.program.id);
    if (programHasBeenSubmitted) {
      setStep(EXIT_STRATEGY);
    } else {
      setStep(PII_CONFIRMATION);
    }
  }, [hit.program.id]);

  if (!hit?.program?.id) return null;

  const renderMap = {
    PII_CONFIRMATION: (
      <PiiConfirmation
        step={step}
        setStep={setStep}
        currentProgram={hit}
        prqSchema={prqSchema}
        setPiiLeadErrors={setPiiLeadErrors}
        piiLeadErrors={piiLeadErrors}
        setEditMode={setEditMode}
        editMode={editMode}
        formErrored={formErrored}
      />
    ),
    ADDITIONAL_QUESTIONS: (
      <AdditionalQuestions
        currentProgram={hit}
        originalSchema={currentSchema}
        prqSchema={prqSchema}
        setStep={setStep}
        setPiiLeadErrors={setPiiLeadErrors}
        setEditMode={setEditMode}
        formErrored={formErrored}
      />
    ),
    EXIT_STRATEGY: <ExitStrategy />,
  };

  return <>{renderMap[step]}</>;
};

export default SingleProgramSubmission;
