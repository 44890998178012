import { FC } from 'react';

import styles from './StatCta.module.css';

const StatCta: FC = () => (
  <div className={styles.statCta}>
    <p>We have over</p>
    <h2 className={styles.statNumber}>300</h2>
    <p>potential matches for you.</p>
    <p>Let&apos;s personalize that list some more.</p>
  </div>
);
export default StatCta;
