import { FC } from 'react';

import { FieldComponent } from '@core/blocks/edu-flow-2/utils/fieldTypeDictionary';

import { Field } from '../types';
import styles from './CalloutField.module.css';

const CalloutField: FieldComponent = ({ field: { heading, content } }) => (
  <div data-testid="callout-field" className={content ? styles.callout : styles.calloutEmpty}>
    {heading && <b dangerouslySetInnerHTML={{ __html: heading }} />}
    {/* if there is content and no value from preamp */}
    {content && <p dangerouslySetInnerHTML={{ __html: content }} />}
  </div>
);

export default CalloutField;
