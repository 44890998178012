import { PageActions, newRelicNoticeError, nrErrorMap } from '../newRelic';
import type { SpotlightWinner } from './types';

const AlgoliaService = {
  async getSpotlightResult({ algoliaIndex, candidates, filters, facetFilters }) {
    let spotlightWinner: SpotlightWinner;
    try {
      for (const { schoolId, rule } of candidates) {
        const { hits } = await algoliaIndex.search('', {
          hitsPerPage: 1,
          filters: `${filters} AND school.id:${schoolId}`,
          facetFilters,
        });

        if (!hits?.length) {
          PageActions.SpotlightResultNotFound({
            anonymousId: window?._Cohesion?.anonymousId,
            schoolId,
            spotlightRule: rule,
          });
        } else {
          spotlightWinner = {
            hit: hits?.[0],
            eventing: {
              customDimensions: [{ key: 'spotlight-winner', value: rule }],
            },
          };
          break;
        }
      }

      if (!spotlightWinner) {
        return undefined;
      }

      return spotlightWinner;
    } catch (error) {
      newRelicNoticeError(nrErrorMap.SPOTLIGHT_ALGOLIA, error);
      return undefined;
    }
  },
};

export default AlgoliaService;
