import { FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Field } from '@core/blocks/edu-flow-2/components/Fields/types';
import { FieldComponent } from '@core/blocks/edu-flow-2/utils/fieldTypeDictionary';
import useFieldEvents from '@core/hooks/cohesion/useFieldEvents';
import { selectFormCorrelationId } from '@core/reducers/eventingSlice';
import { selectInput, setInput, setInputs } from '@core/reducers/inputsSlice';
import cn from '@core/utils/classNames';
import debounce from '@core/utils/debounce';
import convertToSlug from '@core/utils/strings/convertToSlug';

import styles from './TextField.module.css';

const TextField: FieldComponent = ({ field, errors, heading, fieldNumber }) => {
  // Action Dispatcher
  const dispatch = useDispatch();

  const [canFireFieldInputted, setCanFireFieldInputted] = useState(true);

  // Form Correlation Id for events
  const formCorrelationId = useSelector(selectFormCorrelationId);

  const { fieldInputted, fieldViewed } = useFieldEvents({
    correlationId: formCorrelationId,
    userInputField: {
      autofilled: false,
      fieldName: field.name,
      fieldLabel: heading,
      fieldType: field.type,
      fieldNumber,
    },
  });

  useEffect(() => {
    fieldViewed();
  }, []);

  const handleChange = debounce<(event: FormEvent<HTMLInputElement>) => void>((event) => {
    const { value } = event.target as HTMLInputElement;
    if (field.name !== 'fullName') dispatch(setInput({ key: field.name as string, value }));
    else {
      const splitFirstName = value.split(' ')[0];
      const splitLastName = value.split(' ')[1];
      dispatch(
        setInputs([
          { key: 'fullName', value },
          { key: 'firstName', value: splitFirstName },
          { key: 'lastName', value: splitLastName },
        ])
      );
    }

    if (canFireFieldInputted) {
      // Field Eventing
      fieldInputted({
        userInputField: {
          fieldValue: 'SANITIZED',
        },
      });
      setCanFireFieldInputted(false);
    }
  }, 500);

  const handleFocus = () => {
    setCanFireFieldInputted(true);
  };

  const fieldInput = useSelector((state) => selectInput(state, field.name as string));

  return (
    <div>
      <input
        defaultValue={fieldInput}
        id={field.name}
        type={field.format}
        className={cn(styles.textField, errors && styles.error)}
        name={field.name}
        placeholder={field.label}
        min={field.min || undefined}
        max={field.max || undefined}
        onChange={handleChange}
        onFocus={handleFocus}
        data-testid={convertToSlug(field.name as string)}
        aria-label={field.label}
        autoComplete={field.autocompleteValue}
      />
    </div>
  );
};

export default TextField;
