import styles from './NextStepsCopy.module.css';

const NextStepsCopy = () => (
  <div className={styles.nextStepsInfo}>
    <div className={styles.nextStepsItem}>
      <h3>What to expect from your application:</h3>
      <ul>
        <li>Most applications don’t take long to complete.</li>
        <li>
          Be prepared to highlight your strengths, achievements, and individuality to show the school who you are.
        </li>
        <li>Depending on the school and degree level, you may be asked to provide your GPA and test scores.</li>
        <li>After submitting your application, track its status online. The school should have a decision soon.</li>
      </ul>
    </div>
    <div className={styles.nextStepsItem}>
      <h3>Preparing for a chat with your advisor</h3>
      <ul>
        <li>
          Generally, you can expect to be called immediately or within 2 business days, depending on the availability of
          the school&apos;s counselors. If you miss your call, the school will try again.
        </li>
        <li>Calls average 20-30 minutes, and you’ll typically receive a follow-up email afterwords.</li>
        <li>Be prepared to talk about your work and school history.</li>
        <li>Write down a few questions before the call.</li>
      </ul>
    </div>
    <div className={styles.nextStepsItem}>
      <h3>Here&apos;s what other students are asking:</h3>
      <ul>
        <li>What types of courses will I be taking for my degree?</li>
        <li>What scholarships and financial aid are available?</li>
        <li>How can I estimate my tuition cost, including books and fees?</li>
      </ul>
    </div>
  </div>
);
export default NextStepsCopy;
