export const applicationCtaBtn = {
  webElement: {
    elementType: 'linkout-cta',
    text: 'Start your application',
    htmlId: 'voyager-application-cta',
    name: 'application-cta',
  },
};

export const topMatchesApplicationCtaBtn = {
  webElement: {
    elementType: 'linkout-cta',
    text: 'Start Your Application',
    htmlId: 'voyager-application-cta',
    name: 'application-cta',
  },
};
export const topMatchesExitClose = {
  webElement: {
    elementType: 'exit-element',
    text: 'not populated',
    htmlId: 'exit-x',
    name: 'exit-application-step',
  },
};

export const topMatchesViewAllMatches = {
  webElement: {
    elementType: 'exit-element',
    text: 'View all matches',
    htmlId: 'exit-back',
    name: 'exit-application-step',
  },
};

export const continueBrowsingBtn = {
  webElement: {
    elementType: 'exit-element',
    text: 'Continue Browsing Schools',
    htmlId: 'thank-you-button',
    name: 'thank-you-confirmation',
  },
};

export const popupClose = {
  webElement: {
    elementType: 'exit-element',
    name: 'thank-you-confirmation',
    htmlId: 'thank-you-x',
  },
};
