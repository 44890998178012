import { ChangeEvent, Dispatch, FC, SetStateAction, useState } from 'react';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';

import useFieldEvents from '@core/hooks/cohesion/useFieldEvents';
import { selectFormCorrelationId } from '@core/reducers/eventingSlice';
import { selectAllInputs } from '@core/reducers/inputsSlice';

type Props = {
  inputName: string;
  setState: Dispatch<SetStateAction<string>>;
};
const InputFieldWithFieldEventing: FC<Props> = ({ inputName, setState }) => {
  const [canFireFieldInputted, setCanFireFieldInputted] = useState(true);
  const formCorrelationId = useSelector(selectFormCorrelationId);
  const formInputs = useSelector(selectAllInputs);

  type fieldEventsType = {
    defaultVal: string;
    fieldName: string;
    fieldLabel: string;
  };
  const fieldEventsParams: Record<string, fieldEventsType> = {
    firstName: {
      defaultVal: formInputs.firstName?.value,
      fieldName: 'first-name',
      fieldLabel: 'First Name',
    },
    lastName: {
      defaultVal: formInputs.lastName?.value,
      fieldName: 'last-name',
      fieldLabel: 'Last Name',
    },
    email: {
      defaultVal: formInputs.email?.value,
      fieldName: 'email',
      fieldLabel: 'Email',
    },
    phone: {
      defaultVal: formInputs.phone?.value,
      fieldName: 'phone-number',
      fieldLabel: 'Phone Number',
    },
  };

  const { fieldInputted } = useFieldEvents({
    correlationId: formCorrelationId,
    userInputField: {
      autofilled: true,
      fieldType: 'contact',
      fieldValue: 'SANITIZED',
    },
  });

  const onFormChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setState(value);
    if (canFireFieldInputted) {
      fieldInputted({
        userInputField: {
          fieldName: fieldEventsParams[`${inputName}`].fieldName,
          fieldLabel: fieldEventsParams[`${inputName}`].fieldLabel,
        },
      });
      setCanFireFieldInputted(false);
    }
  };
  const handleFocus = () => {
    setCanFireFieldInputted(true);
  };

  return inputName !== 'phone' ? (
    <input
      type="text"
      id={inputName}
      data-testid={`pii-form-${inputName}-input`}
      onChange={onFormChange}
      onFocus={handleFocus}
      aria-label={inputName}
      defaultValue={fieldEventsParams[`${inputName}`].defaultVal}
    />
  ) : (
    <InputMask
      id="phone"
      mask="(999) 999-9999"
      maskChar=""
      autoComplete="on"
      type="text"
      placeholder="Phone"
      data-testid="pii-form-phone-input"
      defaultValue={fieldEventsParams[`${inputName}`].defaultVal}
      onChange={onFormChange}
      onFocus={handleFocus}
      aria-label="phone"
    />
  );
};
export default InputFieldWithFieldEventing;
