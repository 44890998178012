/* eslint-disable no-nested-ternary */
import React, { FC, useState, useEffect } from 'react';
import { HiCheck } from 'react-icons/hi';
import { connectCurrentRefinements } from 'react-instantsearch-dom';

// Utils
import cn from '@core/utils/classNames';

import styles from './FacetButton.module.css';
// Styles & Types
import type { FacetButtonProps } from './types';

const Button: FC<FacetButtonProps> = ({ items, initialText, filteredText, handleClick, filter, isOpen }) => {
  // set up button active state
  const [active, setActive] = useState(isOpen);

  // grab the current filter that is being used in this dropdown
  const currentFilter = items.find((item) => item.attribute === filter);

  // handle the button active state
  useEffect(() => {
    // if one or more refinements are selected, keep active state active
    if (currentFilter?.currentRefinement) {
      setActive(true);
    } else {
      setActive(isOpen);
    }
  }, [isOpen, currentFilter]);

  return (
    <button type="button" onClick={handleClick} className={cn(styles.facetButton, active && styles.active)}>
      {/* check icon */}
      <HiCheck className={cn(styles.icon, active && styles.active)} />

      {/* 
          button text
          if one value is selected, show its name in the button
          if more than one is selected show (numSelected)
          if nothing selected, show the label being passed via `initialText` prop
      */}
      {currentFilter?.currentRefinement
        ? currentFilter.currentRefinement.length === 1
          ? `${filteredText || initialText}: ${currentFilter.currentRefinement[0]}`
          : `${filteredText || initialText} (${currentFilter.currentRefinement.length})`
        : initialText}
    </button>
  );
};

const FacetButton = connectCurrentRefinements(Button);

export { FacetButton };
