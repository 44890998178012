import fieldTypeDictionary from '@edu-flow/utils/fieldTypeDictionary';
import getFieldError from '@edu-flow/utils/getFieldError';
import { type FC } from 'react';

import ErrorMessage from '@core/shared/components/ErrorMessage';

import type { Field } from './types';

/**
 *
 * @param field field to traverse
 * @returns the number of fields that are relevant to fieldNumber
 */
const findFieldNumberRelevantField = (field: Field) => {
  if (!['infoPanel', 'callout', 'conditional'].includes(field.type)) return 1;
  let results = 0;
  field.conditional?.content?.fields?.forEach((field) => {
    results += findFieldNumberRelevantField(field);
  });

  return results;
};

/**
 *  Recursively go through all the fields before the provided index and include all relevant children in the fieldNumber
 * @param {Field[]} fields list of fields
 * @param {number} index index of the current field
 * @returns {number} fieldNumber
 */
const determineFieldNumber = (fields: any[], index: number): number => {
  if (!fields[index]) return 0;

  return findFieldNumberRelevantField(fields[index]) + determineFieldNumber(fields, index - 1);
};

type Props = {
  fields?: Field[];
  errors?: Error[];
  heading?: string;
  fieldNumber?: number;
};

const Fields: FC<Props> = ({ fields = [], errors = [], heading, fieldNumber }) => (
  <>
    {fields?.map((field: Field, index: number) => {
      // Parse preamp values in the fields
      if (!fieldTypeDictionary[field.type as keyof typeof fieldTypeDictionary]) {
        console.log(`Field type ${field.type} does not exist.`);
        return null;
      }
      const FieldComponent = fieldTypeDictionary[field.type as keyof typeof fieldTypeDictionary];
      const fieldError = getFieldError(field, errors);
      return (
        <div key={field.name}>
          <FieldComponent
            heading={heading}
            key={field.name}
            field={field}
            fieldError={fieldError}
            design={field.design}
            preampValue={undefined}
            fieldNumber={fieldNumber ? fieldNumber + index : determineFieldNumber(fields, index)}
          />
          {!field.hideError && fieldError && <ErrorMessage message={fieldError.message} />}
        </div>
      );
    })}
  </>
);

export default Fields;
