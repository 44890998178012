import { useState, useEffect, ChangeEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { FieldComponent } from '@core/blocks/edu-flow-2/utils/fieldTypeDictionary';
import useFieldEvents from '@core/hooks/cohesion/useFieldEvents';
import { selectFormCorrelationId } from '@core/reducers/eventingSlice';
// Reducers selectors/actions
import { setInput } from '@core/reducers/inputsSlice';
// Utils
import convertToSlug from '@core/utils/strings/convertToSlug';

import { RadioOption } from '../types';
import styles from './RadioField.module.css';

const RadioField: FieldComponent = ({ field, heading, fieldNumber }) => {
  // Action Dispatcher
  const dispatch = useDispatch();

  const [currentOption, setCurrentOption] = useState<string>(field.default as string);

  // Form Correlation Id for events
  const formCorrelationId = useSelector(selectFormCorrelationId);

  const { fieldSelected, fieldViewed } = useFieldEvents({
    correlationId: formCorrelationId,
    userInputField: {
      autofilled: false,
      fieldName: field.name,
      fieldLabel: heading,
      fieldType: field.type,
      fieldNumber,
    },
  });

  useEffect(() => {
    fieldViewed();
  }, []);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const optionVal = event?.target?.value;

    fieldSelected({
      userInputField: {
        fieldValue: optionVal,
      },
    });

    setCurrentOption(event.target.id);
  };

  useEffect(() => {
    if (field.options)
      dispatch(
        setInput({
          key: field.name as string,
          value: (field.options as Record<string, string>)[currentOption as string],
        })
      );
  }, [currentOption]);

  return (
    <div data-testid="field-option-container" className={styles.radioField}>
      <span data-testid="field-option-heading" className={styles.radioFieldHeading}>
        {field.label}
      </span>

      <div className={styles.radioOptions}>
        {Object.entries(field.options as RadioOption)?.map(([option, value]) => (
          <div key={option} className={styles.radioOption}>
            <input
              onChange={handleChange}
              className={styles.radioInput}
              type="radio"
              value={value as string}
              name={field.name}
              id={option}
              checked={option === currentOption}
              data-testid={convertToSlug(`${field.name}-${option}`)}
            />
            <label htmlFor={option}>{option}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RadioField;
