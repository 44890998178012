import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import Dots from '@core/blocks/edu-match/components/Loading/Dots';
import MatchContextPills from '@core/blocks/edu-match/components/MatchContextPills';
import { useFeatureFlags } from '@core/context/FeatureFlagsContext';
import useElementEvents from '@core/hooks/cohesion/useElementEvents';
import useProductEvents from '@core/hooks/cohesion/useProductEvents';
import { selectDcsDegrees } from '@core/reducers/dcsSlice';
import {
  selectRecommenderPopupListId,
  selectViewCorrelationIdMap,
  setHeclidMapEntry,
  setProductCorrelationIdMapEntry,
} from '@core/reducers/eventingSlice';
import { selectAllInputs } from '@core/reducers/inputsSlice';
import { removeProgramFromList, selectLeadDelivery } from '@core/reducers/matchesSlice';
import { mobiusRedirect } from '@core/services/mobius';
import SponsoredResultsPopover from '@core/shared/components/SponsoredResultsPopover';
import type { Qualifications, VoyagerResult } from '@core/ts/results';
import customPropertyOverride from '@core/utils/customPropertyOverride';
import getToken from '@core/utils/getToken';

import Icon from '../../../components/Icon';
import { isGradLvl, schoolBumpData } from '../utils';
import styles from './ProgramSelect.module.css';
import RecommenderCard from './RecommenderCard';

type Props = {
  hits: VoyagerResult[];
  setRecommenderStep: (step: string) => void;
  closePopup: () => void;
  eventingOverrides: { [key: string]: any };
  qualifications?: Qualifications;
};

const ProgramSelect: FC<Props> = ({ hits, setRecommenderStep, closePopup, eventingOverrides, qualifications }) => {
  const [loading, setLoading] = useState(false);

  const flags = useFeatureFlags();
  const showAdVerbiageTest = flags?.adVerbiageTest === 'test';

  const dcsDegrees = useSelector(selectDcsDegrees);

  // determine if recommender should be single submission
  // DEV NOTE: setting this to just be true for the purposes of a desired optimization
  // and we don't want to remove the multi-select code just yet
  const isSingleSubmissionRecommender = true;

  // dispatch action
  const dispatch = useDispatch();

  // current programs from store
  const { currentPrograms } = useSelector(selectLeadDelivery);

  // eventing state properties
  const popupListId = useSelector(selectRecommenderPopupListId);

  const viewCorrelationIdMap = useSelector(selectViewCorrelationIdMap);

  // inputs state properties
  const inputs = useSelector(selectAllInputs);

  const isGradDegree = isGradLvl(dcsDegrees?.[0]);

  // grabs product event
  const { productClicked } = useProductEvents({
    listId: popupListId,
    customDimensions: eventingOverrides?.customDimensions,
  });

  const { elementClicked } = useElementEvents({
    webElement: {
      elementType: 'exit-element',
      text: 'Skip to all results',
      location: eventingOverrides?.location,
      htmlId: 'thank-you-button',
      name: 'skip-recommender',
    },
  });

  const onClose = () => {
    // if there are any selections and user closes the modal, we remove them from the currentPrograms list
    for (const current of currentPrograms) {
      dispatch(removeProgramFromList(current));
    }
    if (closePopup) closePopup();
    elementClicked();
  };

  const requestInfo = async () => {
    setLoading(true);
    for (const hit of currentPrograms) {
      const newProductCorrelationId = uuid();

      // add correlationId to map for LDS lead submission
      dispatch(
        setProductCorrelationIdMapEntry({
          key: hit.program.id,
          value: newProductCorrelationId,
        })
      );

      productClicked({
        correlationId: newProductCorrelationId,
        product: {
          sku: String(hit.cap.id),
          variant: hit.program.degree.slug,
          productId: String(hit.program.id),
          name: hit.program.subject.slug,
          category: hit.program.category.slug,
          brand: hit.school.slug,
          position: hit.__position,
          formatType: 'multi-program',
          formatSubtype: 'recommendation',
          positionEngine: 'algolia',
        },
        viewCorrelationId: viewCorrelationIdMap[hit.program.id],
      });

      // Mobius Redirect will fire the OutcomeTracked APP_VIEWED event
      const { heclid } = await mobiusRedirect({
        idToken: getToken(),
        productCorrelationId: newProductCorrelationId,
        link: hit.url,
        trackingContextOverride: {
          formatType: 'multi-program',
          formatSubtype: 'grid',
        },
      });

      // add heclid to map for Mobius track
      dispatch(setHeclidMapEntry({ key: hit.program.id, value: heclid }));
    }
    setLoading(false);
    setRecommenderStep('PII_CONFIRMATION');
  };

  const showBestFitSection =
    isGradDegree &&
    (inputs?.educationLevel || inputs?.gpa || inputs?.hasWorkExperience || inputs?.hasProgramRelatedExperience) &&
    flags.matchContextTest !== 'test';

  const singleHit = hits.length === 1;

  if (loading) {
    return (
      <section className={styles.loadingContainer}>
        <h2>Loading...</h2>
        <Dots />
      </section>
    );
  }

  return (
    <section className={styles.programSelect}>
      <div className={styles.content}>
        <header>
          <p className={styles.personalization}>
            {inputs.firstName?.value ? `Great news, ${inputs.firstName.value}!` : 'Great news!'}
          </p>
          {isSingleSubmissionRecommender && singleHit ? (
            <>
              <h2 id="recommender_title" className={styles.title}>
                {schoolBumpData[hits[0]?.school.id]?.title || 'You’re a strong match for this school! 🎉'}
              </h2>
              {schoolBumpData[hits[0]?.school.id] && (
                <p className={styles.subtitle}>{schoolBumpData[hits[0].school.id].subtitle}</p>
              )}
            </>
          ) : (
            <h2 id="recommender_title" className={styles.title}>
              You’re a strong match for these schools! 🎉
            </h2>
          )}

          {showBestFitSection && (
            <div className={styles.bestFitSection}>
              <h3>{singleHit ? 'This school' : 'These schools'} fit your answers best: </h3>
              {inputs?.educationLevel && (
                <span>
                  <Icon name="yes" color={customPropertyOverride('--publisher-checkmark', '--primary-700')} size="5" />
                  {inputs.educationLevel?.options?.label}{' '}
                </span>
              )}
              {inputs?.gpa && (
                <span>
                  <Icon name="yes" color={customPropertyOverride('--publisher-checkmark', '--primary-700')} size="5" />{' '}
                  {`${inputs.gpa.value.toFixed(2)}+ GPA`}{' '}
                </span>
              )}
              {inputs.yearsOfWorkExperience && (
                <span>
                  <Icon name="yes" color={customPropertyOverride('--publisher-checkmark', '--primary-700')} size="5" />

                  <>
                    {`${inputs.yearsOfWorkExperience?.value} year`}
                    {`${inputs.yearsOfWorkExperience?.value > 1 ? 's' : ''} of work experience`}
                  </>
                </span>
              )}
              {!inputs.yearsOfWorkExperience && inputs.yearsOfProgramRelatedExperience && (
                <span>
                  <Icon name="yes" color={customPropertyOverride('--publisher-checkmark', '--primary-700')} size="5" />

                  <>
                    {`${inputs.yearsOfProgramRelatedExperience?.value} year`}
                    {`${inputs.yearsOfProgramRelatedExperience?.value > 1 ? 's' : ''} of related experience`}
                  </>
                </span>
              )}
            </div>
          )}

          {!showBestFitSection && (
            <h4 className={styles.selectSchoolText}>
              Select {singleHit ? 'the' : 'a'} school to request more information.
            </h4>
          )}

          {flags.matchContextTest === 'test' && (
            <div className={styles.matchContextSection}>
              <MatchContextPills hits={hits} qualifiers={qualifications?.qualified} showPillsOnly centered />
            </div>
          )}
        </header>

        <div id="recommender_results" className={styles.cards}>
          {hits?.map((hit: any, index: number) => (
            <RecommenderCard
              setRecommenderStep={setRecommenderStep}
              key={hit.program.id}
              position={index + 1}
              hit={hit}
              setLoading={setLoading}
              eventingOverrides={{
                location: eventingOverrides?.location,
                customDimensions: [
                  { key: 'isUserRequested', value: 'true' },
                  ...(eventingOverrides.customDimensions || []),
                ],
              }}
            />
          ))}
        </div>
      </div>

      <div className={styles.actions}>
        <div className={styles.sponsoredResultsPopover}>
          {showAdVerbiageTest ? (
            <SponsoredResultsPopover position="above" spanText="Learn More About Our Partners" />
          ) : (
            <SponsoredResultsPopover position="above" spanText="Sponsored" />
          )}
        </div>

        {!isSingleSubmissionRecommender && (
          <button
            type="button"
            disabled={!currentPrograms.length}
            className={styles.submitBtn}
            onClick={requestInfo}
            data-testid="recommender-popup-request-info-btn"
          >
            Explore Program
          </button>
        )}
        <button type="button" onClick={onClose} className={styles.skipToResults}>
          Skip to all results &gt;&gt;
        </button>
      </div>
    </section>
  );
};

export default ProgramSelect;
