import cn from '@core/utils/classNames';

import styles from './EnumRadioField.module.css';

const EnumRadioField = (props) => {
  const { name, onChange, uiSchema, formData, schema } = props;
  const title = uiSchema?.['ui:title'];
  const id = uiSchema?.['ui:id'];

  const enumOptions = schema?.enum || schema?.anyOf?.[0]?.enum || schema?.oneOf?.[0]?.enum || schema?.allOf?.[0]?.enum;

  const options = enumOptions
    ? uiSchema?.['ui:options']?.options?.filter(({ value }) => enumOptions.includes(value))
    : uiSchema?.['ui:options']?.options;

  return (
    <label id={id} htmlFor={name} className={cn('control-label', 'prq')}>
      {title}
      {options?.map((option) => (
        <button key={option.label} type="button" className={styles.radioField} onClick={() => onChange(option.value)}>
          <div className={cn(styles.radio, formData === option.value && styles.checked)} />
          {option.label}
        </button>
      ))}
    </label>
  );
};

export default EnumRadioField;
