import React, { FC, useState, useEffect } from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';

// Utils
import determineColor from '@core/utils/determineColor';

import styles from './SearchBox.module.css';
// Styles & Props
import type { SearchBoxProps } from './types';

const SearchBox: FC<SearchBoxProps> = (props) => {
  // get props
  const { refine, delay = 200, currentRefinement } = props;

  // set up value state
  const [searchTerm, setSearchTerm] = useState(currentRefinement);
  // keep track of debounced search
  const [debouncedValue, setDebouncedValue] = useState(searchTerm);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(searchTerm);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  // when debounced search term gets updated, refine the results
  useEffect(() => {
    if (debouncedValue) {
      refine(searchTerm);
    } else {
      refine('');
    }
  }, [debouncedValue]);

  // if a clear refinements button was clicked, reset the search term
  useEffect(() => {
    if (currentRefinement === '') {
      setSearchTerm(currentRefinement);
    }
  }, [currentRefinement]);

  return (
    <form className={styles.searchBoxContainer} noValidate onSubmit={(e) => e.preventDefault()}>
      <button type="submit" className={styles.searchBoxSubmit}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path
            fill={determineColor('--primary-500', 'Magnifying Glass Icon')}
            fillRule="evenodd"
            d="M9 3a6 6 0 100 12A6 6 0 009 3zM0 9a9 9 0 1116.336 5.215l7.225 7.224a1.5 1.5 0 11-2.122 2.122l-7.224-7.225A9 9 0 010 9z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <input
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        placeholder="Search subject, locations, etc."
        className={styles.searchBoxInput}
        type="search"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        data-testid="edumatch-search-box"
      />
    </form>
  );
};

// Hook up to algolia
const Search = connectSearchBox(SearchBox);

export { Search };
