import { type QuestionType } from '@core/blocks/edu-flow/components/Page/types';
import { type MonarchEditableAsset } from '@core/context/types';

import genMultiOptionGroup from './genMultiOptionGroupQuestion';
import genSingleOptionGroup from './genSingleOptionGroupQuestion';

const generateOptionGroupAssetQuestion = (question: QuestionType, asset: MonarchEditableAsset): QuestionType => {
  const { questionMaximumSelections, questionMinimumSelections } = asset;

  const isSingleSelect = questionMaximumSelections === 1 && questionMinimumSelections === 1;

  const generateAssetQuestion = isSingleSelect ? genSingleOptionGroup : genMultiOptionGroup;

  return generateAssetQuestion(question, asset);
};

export default generateOptionGroupAssetQuestion;
