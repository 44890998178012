import { Dispatch, FC, SetStateAction, useEffect } from 'react';

import { SchoolConfigData } from '@core/blocks/edu-match/components/ExitStrategy/type';
import { LEAD_SUBMISSION_STEPS } from '@core/constants';
import { useFeatureFlags } from '@core/context/FeatureFlagsContext';
import useElementEvents from '@core/hooks/cohesion/useElementEvents';
import useSchoolConfig from '@core/hooks/useSchoolConfig';
import SlideOut from '@core/shared/components/SlideOut';
import { VoyagerResult } from '@core/ts/results';

import SingleProgramSubmissionInfoRevamp from '../Revamp/SingleProgramSubmissionInfoRevamp';

const { PII_CONFIRMATION, EXIT_PAGE } = LEAD_SUBMISSION_STEPS;
type Props = {
  drawerStep: string;
  setDrawerStep: Dispatch<SetStateAction<string>>;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  result: VoyagerResult;
};

const Drawer: FC<Props> = ({ drawerStep, setDrawerStep, open, setOpen, result }) => {
  const flags = useFeatureFlags();
  const { data, loading }: SchoolConfigData = useSchoolConfig(result.school.id);
  const { elementViewed, elementClicked } = useElementEvents({
    webElement: {
      location: 'drawer',
      name: 'exit-button',
      htmlId: 'voyager-takeover-exit',
    },
  });

  const handleCloseDrawer = (): void => {
    elementClicked();
    setDrawerStep(PII_CONFIRMATION);
    setOpen(false);
  };

  useEffect(() => {
    elementViewed();
  }, []);

  return (
    !loading && (
      <SlideOut show={open} onClose={handleCloseDrawer} width="extended">
        <SingleProgramSubmissionInfoRevamp
          result={result}
          schoolConfigData={data}
          slideOutStep={drawerStep}
          setSlideOutStep={setDrawerStep}
          setOpen={setOpen}
        />
      </SlideOut>
    )
  );
};
export default Drawer;
