import { FC } from 'react';

import determineColor from '@core/utils/determineColor';

const Image: FC = () => (
  <svg width="100%" height="100%" viewBox="0 0 500 295" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M451.734 183.797C468.005 265.879 423.724 294.868 341.44 294.868C259.155 294.868 179.262 228.324 162.984 146.236C146.707 64.1483 240.996 0.62915 323.28 0.62915C405.565 0.62915 435.456 101.709 451.734 183.797Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M85.5207 291.044C85.5207 291.044 20.3081 294.581 16.7104 278.44C13.1127 262.298 24.9074 268.696 23.0906 261.074C21.2738 253.451 -5.11008 244.845 0.884065 214.902C6.87821 184.96 16.2242 192.397 23.0909 177.508C29.9575 162.618 12.9895 154.238 37.2888 126.177C61.5881 98.1153 84.9794 118.501 103.182 113.071C121.384 107.641 98.379 87.4905 127.937 86.4954C157.496 85.5002 159.763 117.004 177.829 117.901C195.895 118.797 188.838 106.478 215.988 109.764C243.137 113.05 240.197 126.004 267.275 119.902C294.354 113.799 323.506 92.3761 354.193 119.142C384.881 145.907 343.628 173.63 347.99 189.132C352.353 204.633 362.961 196.907 371.662 214.902C380.363 232.897 351.433 243.68 355.646 261.074C359.859 278.467 330.713 295.46 276.072 290.896C231.051 287.135 85.5207 291.044 85.5207 291.044Z"
      fill={determineColor('--primary-100')}
    />
    <path
      d="M225.828 56.9767C225.656 56.6201 225.143 56.4489 224.409 56.4352C224.958 55.7835 225.184 55.2209 224.992 54.8299C224.423 53.6432 220.283 54.4596 215.76 56.6546C211.237 58.8495 208.023 61.5863 208.598 62.7729C208.77 63.1296 209.284 63.308 210.017 63.3149C209.469 63.9665 209.242 64.5288 209.427 64.9198C209.612 65.3108 210.113 65.4549 210.846 65.4617C210.298 66.1476 210.072 66.6827 210.264 67.0737C210.456 67.4647 210.949 67.6019 211.682 67.6156C211.134 68.2672 210.908 68.8296 211.1 69.2206C211.354 69.7487 212.299 69.9066 213.677 69.6734C213.831 70.1205 214.012 70.5581 214.218 70.9836C215.274 73.1648 218.495 73.9467 221.846 72.6435C225.197 71.3402 227.157 67.8075 226.314 65.5371C226.134 65.0387 225.922 64.5532 225.677 64.0832C227.048 62.924 227.795 61.8948 227.493 61.2775C227.322 60.9208 226.808 60.7425 226.074 60.7356C226.623 60.084 226.849 59.5216 226.664 59.1306C226.479 58.7397 225.979 58.5956 225.245 58.5887C225.793 57.9302 226.04 57.3677 225.828 56.9767Z"
      fill={determineColor('--primary-600')}
    />
    <path
      d="M217.899 68.5896C216.662 69.0867 215.382 69.468 214.074 69.7284C214.043 69.7345 214.01 69.7276 213.983 69.7096C213.956 69.6917 213.937 69.6639 213.93 69.6323C213.926 69.6006 213.933 69.5687 213.951 69.5422C213.969 69.5156 213.996 69.4963 214.027 69.4882C216.198 69.0257 218.301 68.2858 220.284 67.2864C221.975 66.4778 223.582 65.5035 225.081 64.378C225.093 64.3636 225.108 64.3522 225.125 64.3445C225.142 64.3367 225.16 64.333 225.179 64.3334C225.198 64.3339 225.216 64.3386 225.233 64.3471C225.249 64.3557 225.264 64.3677 225.275 64.3826C225.286 64.3976 225.294 64.4149 225.297 64.4332C225.301 64.4515 225.3 64.4704 225.295 64.4885C225.29 64.5065 225.282 64.5232 225.269 64.5374C225.257 64.5515 225.242 64.5628 225.225 64.5702C223.703 65.7099 222.071 66.6932 220.352 67.5058C219.544 67.9105 218.707 68.2741 217.899 68.5896Z"
      fill="white"
    />
    <path
      d="M217.09 66.4291C215.569 67.0588 213.972 67.4876 212.34 67.7048C212.314 67.6983 212.29 67.683 212.273 67.6613C212.255 67.6395 212.246 67.6126 212.246 67.5849C212.246 67.5572 212.255 67.5303 212.273 67.5085C212.29 67.4868 212.314 67.4712 212.34 67.4647C214.826 67.0811 217.234 66.296 219.468 65.1396C221.743 64.1052 223.838 62.7161 225.678 61.0241C225.687 61.0087 225.7 60.9954 225.715 60.9856C225.73 60.9757 225.748 60.9695 225.766 60.9671C225.784 60.9647 225.802 60.9664 225.819 60.9718C225.836 60.9773 225.852 60.9867 225.866 60.999C225.879 61.0113 225.889 61.0264 225.896 61.0432C225.903 61.06 225.906 61.078 225.905 61.0961C225.904 61.1142 225.899 61.132 225.891 61.148C225.882 61.164 225.87 61.1777 225.856 61.1885C223.997 62.9048 221.875 64.3108 219.571 65.3523C218.721 65.757 217.892 66.1204 217.09 66.4291Z"
      fill="white"
    />
    <path
      d="M216.233 64.2887C214.632 64.9661 212.939 65.4006 211.21 65.5781C211.177 65.5783 211.146 65.5665 211.122 65.545C211.098 65.5234 211.083 65.4937 211.079 65.4616C211.079 65.43 211.091 65.3998 211.113 65.3769C211.135 65.3539 211.164 65.3401 211.196 65.3383C212.998 65.2423 215.767 64.3642 218.611 62.9855C220.89 61.9552 222.989 60.5657 224.827 58.87C224.851 58.8538 224.88 58.8465 224.908 58.8492C224.937 58.8519 224.964 58.8644 224.984 58.8847C225.004 58.905 225.017 58.9318 225.019 58.9604C225.022 58.989 225.015 59.0177 224.999 59.0414C223.14 60.7533 221.021 62.1585 218.721 63.2049C217.878 63.6096 217.042 63.9869 216.233 64.2887Z"
      fill="white"
    />
    <path
      d="M215.411 62.1347C214.063 62.6806 212.662 63.083 211.23 63.335C211.214 63.338 211.197 63.3377 211.181 63.334C211.165 63.3304 211.15 63.3239 211.137 63.3143C211.123 63.3047 211.112 63.2925 211.103 63.2784C211.094 63.2644 211.089 63.2485 211.086 63.2322C211.084 63.2008 211.093 63.1697 211.112 63.1445C211.131 63.1193 211.158 63.1018 211.189 63.0952C213.482 62.6573 215.702 61.8975 217.782 60.8385C220.041 59.799 222.123 58.4102 223.95 56.723C223.974 56.7069 224.002 56.6995 224.031 56.7022C224.059 56.7049 224.086 56.7174 224.106 56.7377C224.127 56.758 224.139 56.7848 224.142 56.8134C224.145 56.842 224.138 56.8707 224.122 56.8945C222.265 58.5842 220.156 59.9728 217.871 61.01C217.056 61.4559 216.22 61.8192 215.411 62.1347Z"
      fill="white"
    />
    <path
      d="M201.553 53.9245C200.203 52.1334 198.512 50.6275 196.577 49.4934C189.683 45.4563 184.183 39.4091 180.814 32.1601C174.502 18.3044 184.31 6.33491 196.146 2.84355C207.981 -0.647802 222.593 3.52973 226.472 17.1659C228.857 25.5753 226.705 33.4908 224.697 38.3334C223.434 41.4123 222.951 44.7559 223.292 48.0667C223.703 52.1273 223.059 57.6283 217.432 60.132C210.127 63.3764 204.363 57.6491 201.553 53.9245Z"
      fill={determineColor('--primary-500')}
    />
    <path
      d="M218.207 56.5723L212.094 28.916C211.963 28.3398 212.093 27.8802 212.368 27.8734C212.642 27.8665 212.978 28.3329 213.108 28.9023C213.108 28.9023 213.108 28.9023 213.108 28.9572L218.591 56.5861C218.632 56.7918 218.591 56.9565 218.474 56.9428C218.358 56.929 218.248 56.7713 218.207 56.5723Z"
      fill="white"
    />
    <path
      d="M214.122 58.3968L199.997 33.8542C199.703 33.3466 199.312 33.0862 199.093 33.2714C198.874 33.4566 198.949 34.0258 199.244 34.5334V34.5746L213.835 58.6573C213.944 58.8425 214.095 58.9247 214.163 58.8492C214.232 58.7738 214.225 58.5751 214.122 58.3968Z"
      fill="white"
    />
    <path
      d="M211.189 26.6865C211.101 26.6264 210.995 26.5995 210.889 26.6108C210.784 26.6221 210.686 26.6709 210.613 26.7481L208.783 28.6619L207.152 26.7551C207.105 26.7013 207.047 26.6581 206.982 26.6285C206.917 26.599 206.846 26.5837 206.775 26.5837C206.634 26.5793 206.496 26.625 206.385 26.7129C206.275 26.8009 206.199 26.9251 206.172 27.0636L205.631 31.1313L202.08 27.0157C201.985 26.9077 201.853 26.839 201.71 26.8235C201.543 26.8023 201.374 26.8402 201.231 26.9303C201.088 27.0204 200.982 27.1572 200.929 27.3175L199.737 31.8513C199.725 31.8955 199.723 31.9417 199.731 31.9866C199.738 32.0316 199.754 32.0748 199.778 32.1133C199.803 32.1517 199.835 32.1846 199.872 32.2104C199.91 32.2361 199.952 32.2541 199.997 32.263C200.103 32.2859 200.214 32.2712 200.31 32.2211C200.406 32.171 200.482 32.0888 200.525 31.9887L201.806 28.5932L205.596 32.3383C205.662 32.4041 205.75 32.4433 205.843 32.4482C205.951 32.4548 206.058 32.4233 206.146 32.3594C206.233 32.2956 206.296 32.2032 206.323 32.0982L207.063 28.1816L208.433 29.4918C208.523 29.5502 208.631 29.5753 208.737 29.5628C208.843 29.5503 208.942 29.5008 209.016 29.4231L211.182 27.1527C211.213 27.1226 211.238 27.0866 211.255 27.0469C211.272 27.0071 211.281 26.9643 211.282 26.9209C211.283 26.8776 211.275 26.8346 211.259 26.7943C211.243 26.7541 211.219 26.7175 211.189 26.6865Z"
      fill="white"
    />
    <path
      d="M223.594 35.2945C223.405 35.3563 223.201 35.3563 223.011 35.2945C222.893 35.2628 222.782 35.208 222.685 35.1331C222.588 35.0581 222.507 34.9647 222.446 34.8581C222.386 34.7515 222.347 34.6338 222.332 34.5121C222.317 34.3904 222.327 34.2671 222.36 34.1491C222.679 32.9964 222.931 31.8261 223.114 30.6441C223.124 30.5163 223.161 30.392 223.222 30.279C223.283 30.1661 223.366 30.0666 223.467 29.9873C223.567 29.908 223.683 29.8506 223.807 29.8182C223.931 29.7857 224.061 29.7792 224.187 29.7991C224.314 29.8189 224.435 29.8646 224.543 29.9334C224.651 30.0022 224.744 30.0926 224.816 30.199C224.887 30.3053 224.936 30.4252 224.96 30.5513C224.983 30.6774 224.98 30.8072 224.951 30.9321C224.757 32.1834 224.492 33.4225 224.156 34.6431C224.121 34.7889 224.052 34.9242 223.954 35.0376C223.856 35.151 223.733 35.239 223.594 35.2945Z"
      fill="white"
    />
    <path
      d="M224.67 28.3188C224.55 28.3668 224.422 28.3878 224.293 28.3804C224.171 28.376 224.051 28.3474 223.94 28.2963C223.829 28.2452 223.729 28.1726 223.646 28.0827C223.563 27.9927 223.499 27.8874 223.457 27.7725C223.415 27.6576 223.396 27.5357 223.402 27.4135C223.718 20.8259 221.442 14.3767 217.063 9.44915C216.901 9.2739 216.813 9.04333 216.816 8.80509C216.819 8.56686 216.913 8.33881 217.079 8.16773C217.245 7.99665 217.469 7.89554 217.707 7.88506C217.945 7.87458 218.178 7.95562 218.358 8.11147C218.659 8.40641 225.732 15.4234 225.259 27.4888C225.252 27.6695 225.193 27.8439 225.088 27.9912C224.983 28.1386 224.838 28.2525 224.67 28.3188Z"
      fill="white"
    />
    <path
      d="M59.8048 221.104L58.9021 220.326C58.8613 220.292 58.8274 220.251 58.8024 220.204C58.7775 220.158 58.7621 220.107 58.7569 220.054C58.7517 220.001 58.7567 219.948 58.7721 219.898C58.7874 219.847 58.8127 219.8 58.8462 219.759C58.8797 219.718 58.9209 219.684 58.9675 219.659C59.0141 219.634 59.065 219.619 59.1176 219.614C59.1702 219.608 59.2235 219.613 59.2741 219.629C59.3247 219.644 59.3715 219.669 59.4124 219.703L60.3211 220.475C60.362 220.509 60.396 220.551 60.4208 220.598C60.4456 220.645 60.4609 220.696 60.4658 220.749C60.4707 220.802 60.4651 220.856 60.4494 220.906C60.4337 220.957 60.4079 221.004 60.374 221.045C60.3401 221.086 60.2985 221.12 60.2515 221.145C60.2045 221.17 60.1534 221.185 60.1005 221.19C60.0476 221.195 59.9941 221.189 59.9434 221.173C59.8926 221.158 59.8456 221.132 59.8048 221.098V221.104Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M56.8664 218.37L56.051 217.497C55.9838 217.421 55.9493 217.321 55.9543 217.219C55.9594 217.118 56.0036 217.022 56.078 216.952C56.1524 216.882 56.2509 216.845 56.3528 216.846C56.4546 216.848 56.5517 216.89 56.6236 216.962L57.439 217.834C57.5108 217.908 57.5511 218.008 57.5511 218.111C57.5511 218.215 57.5108 218.314 57.439 218.388C57.3655 218.462 57.266 218.503 57.1621 218.503C57.0583 218.503 56.9584 218.462 56.8849 218.388L56.8664 218.37Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M54.2394 215.336L53.5237 214.383C53.4931 214.343 53.4708 214.297 53.458 214.249C53.4452 214.2 53.4423 214.149 53.4492 214.099C53.4562 214.049 53.4728 214.002 53.4984 213.958C53.5241 213.915 53.5581 213.877 53.5984 213.847C53.6797 213.787 53.7813 213.761 53.8814 213.775C53.9815 213.789 54.072 213.842 54.1336 213.922L54.8557 214.869C54.8864 214.91 54.9087 214.956 54.9214 215.006C54.934 215.055 54.9368 215.107 54.9296 215.157C54.9224 215.208 54.9052 215.257 54.8791 215.301C54.8531 215.345 54.8188 215.383 54.7779 215.414C54.7371 215.445 54.6906 215.467 54.6412 215.48C54.5917 215.492 54.5401 215.495 54.4895 215.488C54.4389 215.481 54.3903 215.463 54.3464 215.437C54.3024 215.411 54.264 215.377 54.2333 215.336H54.2394Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M51.9304 212.059L51.308 211.05C51.2818 211.008 51.2642 210.96 51.2563 210.911C51.2484 210.862 51.2502 210.811 51.2618 210.763C51.2733 210.714 51.2945 210.668 51.3238 210.628C51.353 210.587 51.39 210.553 51.4326 210.527C51.4746 210.501 51.5214 210.483 51.5702 210.475C51.619 210.468 51.6687 210.47 51.7167 210.481C51.7648 210.493 51.8103 210.514 51.8501 210.543C51.89 210.572 51.9237 210.609 51.9492 210.652L52.5716 211.661C52.599 211.703 52.6175 211.75 52.6264 211.799C52.6352 211.848 52.634 211.898 52.623 211.947C52.612 211.996 52.5914 212.042 52.5622 212.083C52.5331 212.124 52.4961 212.158 52.4534 212.184C52.3684 212.237 52.2654 212.255 52.1674 212.233C52.0694 212.211 51.9841 212.151 51.9304 212.066V212.059Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M49.9201 208.602L49.3724 207.543C49.338 207.457 49.3368 207.361 49.3691 207.274C49.4014 207.187 49.4649 207.115 49.5472 207.072C49.6295 207.03 49.7247 207.019 49.8143 207.043C49.904 207.066 49.9817 207.122 50.0322 207.2L50.5799 208.259C50.6083 208.303 50.6275 208.352 50.6358 208.404C50.6441 208.455 50.6415 208.508 50.6282 208.558C50.6149 208.609 50.5912 208.656 50.5586 208.697C50.526 208.738 50.4852 208.771 50.4389 208.795C50.3926 208.819 50.342 208.833 50.29 208.836C50.2379 208.839 50.1856 208.832 50.1368 208.814C50.0879 208.795 50.0434 208.767 50.0061 208.731C49.9688 208.694 49.9394 208.65 49.9201 208.602Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M48.177 205.032L47.7038 203.936C47.668 203.847 47.6681 203.747 47.7041 203.657C47.74 203.568 47.809 203.496 47.8967 203.456C47.9408 203.437 47.9884 203.426 48.0366 203.426C48.0847 203.425 48.1326 203.434 48.1773 203.452C48.2219 203.47 48.2625 203.496 48.2967 203.53C48.3309 203.564 48.3577 203.605 48.376 203.649L48.8492 204.739C48.872 204.784 48.8853 204.833 48.8884 204.883C48.8916 204.933 48.8844 204.983 48.8675 205.031C48.8505 205.078 48.8242 205.121 48.79 205.158C48.7557 205.194 48.7143 205.223 48.6684 205.243C48.6225 205.263 48.5729 205.274 48.5228 205.274C48.4727 205.274 48.4233 205.264 48.3772 205.244C48.3312 205.224 48.2895 205.195 48.2551 205.159C48.2206 205.122 48.1941 205.079 48.177 205.032Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M46.6771 201.295L46.2726 200.173C46.2566 200.129 46.2494 200.082 46.2516 200.035C46.2538 199.988 46.2654 199.942 46.2853 199.9C46.3053 199.857 46.3333 199.819 46.368 199.787C46.4027 199.756 46.4433 199.731 46.4874 199.715C46.5316 199.699 46.5782 199.692 46.6251 199.695C46.672 199.697 46.7182 199.708 46.7607 199.728C46.8031 199.748 46.8412 199.776 46.8728 199.811C46.9044 199.846 46.9289 199.886 46.9448 199.93L47.3557 201.052C47.3887 201.142 47.3844 201.241 47.3442 201.328C47.3039 201.415 47.2308 201.483 47.1409 201.516C47.0509 201.549 46.9516 201.545 46.8646 201.505C46.7776 201.464 46.7101 201.391 46.6771 201.301V201.295Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M45.3884 197.52L45.0398 196.379C45.0127 196.289 45.0222 196.192 45.0666 196.109C45.1109 196.026 45.1864 195.965 45.2763 195.937C45.3204 195.924 45.3669 195.919 45.4127 195.924C45.4586 195.929 45.5032 195.943 45.5437 195.965C45.5842 195.987 45.6199 196.017 45.6489 196.052C45.6778 196.088 45.6992 196.13 45.7121 196.174L46.0668 197.308C46.0924 197.399 46.0822 197.496 46.0382 197.579C45.9942 197.663 45.9198 197.726 45.8303 197.756C45.7403 197.783 45.6433 197.774 45.5604 197.729C45.4776 197.685 45.4157 197.609 45.3884 197.52Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M44.2805 193.688L43.9818 192.53C43.9622 192.441 43.9772 192.348 44.0234 192.269C44.0696 192.191 44.1437 192.132 44.231 192.106C44.3201 192.083 44.4146 192.097 44.4939 192.144C44.5731 192.19 44.6306 192.266 44.654 192.355L44.9592 193.508C44.9821 193.598 44.9686 193.693 44.9221 193.774C44.8755 193.854 44.7997 193.913 44.7102 193.938C44.6211 193.96 44.5263 193.947 44.4471 193.9C44.3678 193.853 44.3103 193.777 44.2869 193.688H44.2805Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M43.3285 189.814L43.0672 188.649C43.055 188.564 43.0754 188.477 43.1243 188.406C43.1732 188.335 43.2469 188.285 43.331 188.266C43.415 188.247 43.5032 188.261 43.5777 188.304C43.6523 188.347 43.7076 188.417 43.733 188.499L43.9944 189.664C44.0083 189.709 44.0128 189.757 44.0075 189.804C44.0022 189.851 43.9874 189.896 43.9637 189.937C43.94 189.978 43.9079 190.013 43.8698 190.041C43.8317 190.069 43.7885 190.089 43.7425 190.1C43.6964 190.11 43.6484 190.111 43.6021 190.102C43.5557 190.093 43.5118 190.074 43.4729 190.047C43.434 190.02 43.4011 189.986 43.3762 189.946C43.3514 189.905 43.3352 189.86 43.3285 189.814Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M42.5132 185.914L42.2828 184.743C42.2664 184.655 42.2855 184.565 42.3357 184.491C42.3859 184.418 42.4631 184.367 42.5506 184.35C42.5932 184.341 42.6371 184.341 42.6798 184.349C42.7225 184.358 42.7632 184.375 42.7992 184.399C42.8352 184.424 42.8659 184.455 42.8895 184.492C42.9131 184.528 42.9291 184.569 42.9366 184.612L43.1669 185.783C43.1724 185.865 43.1478 185.945 43.0983 186.01C43.0487 186.075 42.9773 186.119 42.8974 186.135C42.8174 186.151 42.7343 186.138 42.6636 186.097C42.593 186.056 42.5395 185.991 42.5132 185.914Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M41.791 181.983L41.5984 180.8C41.5904 180.758 41.5909 180.714 41.6002 180.672C41.6094 180.63 41.6271 180.591 41.6522 180.556C41.6772 180.521 41.709 180.491 41.7458 180.469C41.7825 180.447 41.8233 180.432 41.8658 180.426C41.9084 180.418 41.9523 180.419 41.9947 180.428C42.037 180.437 42.0769 180.455 42.1123 180.48C42.1477 180.505 42.1778 180.536 42.2007 180.573C42.2236 180.61 42.2387 180.651 42.2454 180.694L42.4384 181.871C42.4615 181.917 42.4739 181.968 42.474 182.019C42.4741 182.071 42.4619 182.121 42.439 182.167C42.4161 182.213 42.3829 182.253 42.3418 182.284C42.3007 182.315 42.2528 182.336 42.2023 182.345C42.1517 182.354 42.0999 182.351 42.0506 182.337C42.0013 182.322 41.9562 182.296 41.9187 182.261C41.8812 182.226 41.8523 182.182 41.8345 182.134C41.8167 182.086 41.8103 182.034 41.816 181.983H41.791Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M41.1752 178.052L41.0132 176.875C41.0195 176.802 41.05 176.734 41.0998 176.681C41.1496 176.628 41.2157 176.594 41.2876 176.583C41.3595 176.572 41.433 176.586 41.4961 176.622C41.5592 176.658 41.6083 176.714 41.6356 176.781L41.7976 177.965C41.8038 178.007 41.8016 178.05 41.7912 178.092C41.7808 178.133 41.7623 178.171 41.7368 178.206C41.7114 178.24 41.6795 178.269 41.6429 178.29C41.6063 178.312 41.5659 178.327 41.5238 178.333C41.4381 178.343 41.3514 178.32 41.2828 178.268C41.2141 178.215 41.1687 178.138 41.1563 178.052H41.1752Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M40.671 174.103L40.5464 172.92C40.5365 172.837 40.5598 172.754 40.6112 172.688C40.6625 172.623 40.738 172.581 40.8206 172.571C40.9031 172.561 40.9862 172.584 41.0515 172.636C41.1169 172.687 41.1589 172.762 41.1689 172.845L41.3059 174.035C41.3147 174.118 41.29 174.201 41.2375 174.267C41.1851 174.332 41.1089 174.374 41.0257 174.383C40.9417 174.392 40.8577 174.368 40.7914 174.315C40.7251 174.263 40.6819 174.187 40.671 174.103Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M40.279 170.147L40.1857 168.964C40.1823 168.923 40.1868 168.881 40.1993 168.842C40.2119 168.803 40.2323 168.767 40.2589 168.736C40.2855 168.705 40.3178 168.679 40.3543 168.66C40.3909 168.641 40.431 168.63 40.472 168.627C40.5125 168.624 40.5533 168.629 40.592 168.641C40.6307 168.654 40.6664 168.674 40.6972 168.701C40.7279 168.728 40.7531 168.76 40.771 168.797C40.789 168.833 40.7994 168.873 40.8017 168.914L40.9014 170.097C40.9048 170.138 40.9 170.18 40.8874 170.219C40.8748 170.258 40.8548 170.294 40.8281 170.325C40.8015 170.356 40.769 170.382 40.7324 170.401C40.6958 170.419 40.656 170.431 40.6151 170.434C40.5338 170.439 40.4537 170.413 40.3911 170.361C40.3286 170.308 40.2884 170.234 40.279 170.153V170.147Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M40.0108 166.161L39.9485 164.971C39.9451 164.891 39.9734 164.813 40.0269 164.753C40.0805 164.694 40.155 164.658 40.2348 164.653C40.2746 164.651 40.3145 164.656 40.3521 164.669C40.3897 164.682 40.4244 164.703 40.4539 164.729C40.4835 164.756 40.5075 164.789 40.5244 164.825C40.5413 164.861 40.5508 164.9 40.5524 164.94L40.6147 166.129C40.6168 166.17 40.6105 166.211 40.5968 166.25C40.583 166.288 40.5619 166.324 40.5345 166.354C40.507 166.384 40.4739 166.409 40.4369 166.427C40.3999 166.444 40.3599 166.454 40.319 166.457C40.2781 166.459 40.2372 166.452 40.1986 166.439C40.1601 166.425 40.1247 166.404 40.0944 166.376C40.0641 166.349 40.0393 166.316 40.0218 166.279C40.0042 166.242 39.9943 166.201 39.9923 166.161H40.0108Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M39.8616 162.18V160.984C39.8616 160.901 39.8944 160.822 39.9527 160.764C40.0111 160.705 40.0902 160.672 40.1728 160.672C40.2553 160.672 40.3345 160.705 40.3928 160.764C40.4512 160.822 40.484 160.901 40.484 160.984V162.174C40.4902 162.216 40.4869 162.259 40.4749 162.301C40.4629 162.342 40.4421 162.38 40.4141 162.412C40.3861 162.445 40.3516 162.471 40.3126 162.489C40.2736 162.506 40.2312 162.516 40.1883 162.516C40.1454 162.516 40.103 162.506 40.064 162.489C40.025 162.471 39.9902 162.445 39.9622 162.412C39.9341 162.38 39.9134 162.342 39.9014 162.301C39.8894 162.259 39.8864 162.216 39.8926 162.174L39.8616 162.18Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M39.8369 158.193V156.997C39.8401 156.92 39.873 156.847 39.9287 156.793C39.9844 156.74 40.0585 156.71 40.1357 156.71C40.2127 156.714 40.2856 156.746 40.339 156.802C40.3924 156.858 40.422 156.932 40.422 157.009V158.199C40.4204 158.276 40.3889 158.35 40.3344 158.404C40.2799 158.459 40.2064 158.49 40.1293 158.492C40.0511 158.49 39.9767 158.458 39.922 158.402C39.8673 158.346 39.8369 158.271 39.8369 158.193Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M39.9488 154.206L40.0111 153.016C40.0051 152.976 40.0079 152.934 40.0193 152.894C40.0308 152.855 40.0508 152.818 40.0777 152.787C40.1046 152.756 40.1378 152.731 40.1752 152.714C40.2127 152.696 40.2532 152.687 40.2944 152.687C40.3355 152.687 40.3761 152.696 40.4135 152.714C40.4509 152.731 40.4842 152.756 40.5111 152.787C40.5379 152.818 40.558 152.855 40.5694 152.894C40.5809 152.934 40.5837 152.976 40.5776 153.016L40.5217 154.206C40.5201 154.244 40.5108 154.281 40.495 154.315C40.4791 154.349 40.4568 154.38 40.429 154.405C40.4013 154.431 40.3687 154.45 40.3333 154.463C40.2979 154.476 40.2603 154.482 40.2226 154.48C40.1473 154.475 40.0768 154.442 40.0257 154.386C39.9747 154.33 39.947 154.257 39.9488 154.181V154.206Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M40.2162 150.213L40.3219 149.023C40.3251 148.987 40.3353 148.952 40.352 148.92C40.3688 148.888 40.3917 148.859 40.4195 148.836C40.4473 148.813 40.4795 148.796 40.514 148.785C40.5486 148.774 40.5847 148.77 40.6207 148.774C40.6939 148.78 40.7617 148.815 40.8094 148.871C40.8572 148.927 40.8812 148.999 40.876 149.073L40.7763 150.263C40.773 150.3 40.7627 150.335 40.7456 150.368C40.7285 150.401 40.7049 150.43 40.6766 150.454C40.6483 150.478 40.6158 150.495 40.5806 150.506C40.5453 150.518 40.5083 150.522 40.4715 150.518C40.4347 150.515 40.3987 150.504 40.366 150.487C40.3333 150.47 40.3043 150.447 40.2806 150.419C40.2569 150.39 40.2391 150.357 40.228 150.322C40.217 150.287 40.2129 150.25 40.2162 150.213Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M40.6025 146.264L40.7457 145.08C40.7473 145.042 40.7567 145.005 40.7733 144.971C40.79 144.937 40.8137 144.906 40.8426 144.882C40.8716 144.858 40.9051 144.839 40.9414 144.828C40.9777 144.818 41.016 144.815 41.0536 144.819C41.0911 144.824 41.1274 144.837 41.1599 144.856C41.1924 144.876 41.2206 144.902 41.2426 144.933C41.2645 144.963 41.2799 144.999 41.2876 145.036C41.2953 145.073 41.2951 145.111 41.2873 145.148L41.1441 146.332C41.1298 146.399 41.0907 146.459 41.0347 146.498C40.9787 146.538 40.9099 146.556 40.8417 146.547C40.7736 146.538 40.7113 146.504 40.667 146.452C40.6227 146.399 40.5996 146.332 40.6025 146.264Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M41.1441 142.32L41.3368 141.143C41.3428 141.108 41.3559 141.075 41.3748 141.045C41.3937 141.015 41.4182 140.989 41.4471 140.969C41.4761 140.949 41.5087 140.934 41.5432 140.927C41.5777 140.919 41.6133 140.918 41.648 140.925C41.6826 140.93 41.7158 140.942 41.7456 140.96C41.7753 140.979 41.8012 141.003 41.8216 141.031C41.8419 141.06 41.8562 141.092 41.8638 141.126C41.8714 141.16 41.8722 141.196 41.8659 141.23L41.6729 142.401C41.6684 142.436 41.6569 142.47 41.6389 142.501C41.6209 142.531 41.5969 142.558 41.5684 142.579C41.5399 142.6 41.5074 142.614 41.473 142.623C41.4385 142.631 41.403 142.631 41.3681 142.625C41.2987 142.613 41.2366 142.574 41.1949 142.518C41.1532 142.461 41.1351 142.39 41.1441 142.32Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M41.8476 138.377L42.084 137.212C42.099 137.144 42.1399 137.085 42.198 137.046C42.2561 137.008 42.3268 136.994 42.3952 137.006C42.4629 137.021 42.522 137.063 42.5593 137.121C42.5967 137.18 42.6091 137.25 42.5943 137.318L42.3578 138.483C42.3519 138.517 42.3391 138.549 42.3204 138.578C42.3018 138.607 42.2775 138.632 42.249 138.651C42.2206 138.67 42.1885 138.683 42.1548 138.69C42.1211 138.696 42.0866 138.696 42.053 138.689C42.0189 138.682 41.9864 138.668 41.9576 138.649C41.9287 138.63 41.9041 138.605 41.8849 138.576C41.8658 138.547 41.8528 138.514 41.8463 138.48C41.8399 138.446 41.8403 138.411 41.8476 138.377Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M42.7059 134.508L42.9922 133.35C43.0098 133.284 43.052 133.228 43.1101 133.193C43.1681 133.159 43.2374 133.148 43.3034 133.163C43.3685 133.18 43.424 133.223 43.4578 133.281C43.4916 133.34 43.501 133.409 43.4839 133.474L43.2037 134.627C43.1874 134.693 43.1458 134.751 43.0876 134.787C43.0294 134.823 42.9593 134.835 42.8925 134.82C42.8597 134.812 42.8288 134.797 42.8016 134.777C42.7744 134.757 42.7515 134.732 42.7341 134.703C42.7168 134.674 42.7056 134.642 42.7007 134.608C42.6958 134.575 42.6975 134.541 42.7059 134.508Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M43.7266 130.653L44.0688 129.506C44.0876 129.444 44.13 129.391 44.187 129.36C44.244 129.328 44.3112 129.321 44.3739 129.338C44.4367 129.358 44.4891 129.402 44.5204 129.46C44.5517 129.518 44.5596 129.586 44.542 129.65L44.2059 130.796C44.1966 130.827 44.181 130.857 44.1603 130.882C44.1396 130.908 44.1141 130.929 44.0852 130.945C44.0563 130.96 44.0246 130.97 43.9919 130.973C43.9592 130.977 43.9261 130.974 43.8947 130.964C43.8319 130.944 43.7792 130.9 43.7479 130.842C43.7165 130.784 43.709 130.716 43.7266 130.653Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M44.9222 126.846L45.3082 125.719C45.3307 125.658 45.3757 125.609 45.4337 125.581C45.4917 125.553 45.5583 125.549 45.6194 125.569C45.6497 125.58 45.6775 125.596 45.7014 125.618C45.7254 125.639 45.745 125.665 45.7589 125.694C45.7728 125.723 45.7809 125.754 45.7826 125.786C45.7843 125.818 45.7794 125.85 45.7686 125.881L45.3826 127.008C45.3612 127.069 45.3164 127.119 45.258 127.148C45.1997 127.176 45.1325 127.179 45.0714 127.158C45.0104 127.136 44.9606 127.091 44.9325 127.033C44.9045 126.974 44.9008 126.908 44.9222 126.846Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M46.2853 123.101L46.7207 121.993C46.7438 121.935 46.789 121.887 46.8467 121.862C46.9044 121.837 46.9697 121.836 47.0283 121.859C47.0868 121.882 47.1338 121.928 47.1589 121.986C47.1839 122.044 47.1853 122.109 47.1622 122.168L46.7268 123.275C46.7032 123.334 46.6574 123.382 46.5993 123.407C46.5411 123.433 46.4753 123.435 46.4159 123.412C46.358 123.388 46.3121 123.341 46.2877 123.283C46.2634 123.225 46.2625 123.16 46.2853 123.101Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M47.8162 119.419L48.3075 118.337C48.3189 118.309 48.3358 118.284 48.3573 118.263C48.3788 118.242 48.4043 118.226 48.4323 118.215C48.4603 118.205 48.4902 118.2 48.5201 118.202C48.5499 118.204 48.5792 118.211 48.606 118.224C48.6336 118.236 48.6588 118.253 48.6798 118.274C48.7008 118.296 48.717 118.321 48.7281 118.349C48.7391 118.377 48.7445 118.407 48.7439 118.437C48.7432 118.467 48.7364 118.496 48.7241 118.523L48.2328 119.612C48.2207 119.64 48.2031 119.665 48.1812 119.686C48.1593 119.708 48.1336 119.724 48.1053 119.735C48.077 119.746 48.0467 119.751 48.0163 119.751C47.986 119.75 47.956 119.743 47.9283 119.731C47.8727 119.704 47.8299 119.656 47.8089 119.598C47.788 119.54 47.7906 119.476 47.8162 119.419Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M49.5154 115.819L50.0501 114.755C50.0791 114.703 50.1269 114.664 50.1837 114.646C50.2405 114.629 50.302 114.635 50.355 114.661C50.4064 114.689 50.445 114.736 50.4625 114.792C50.4799 114.848 50.4747 114.908 50.4482 114.96L49.9135 116.018C49.9003 116.045 49.8817 116.069 49.8591 116.088C49.8366 116.108 49.8104 116.123 49.782 116.132C49.7536 116.142 49.7237 116.145 49.694 116.143C49.6642 116.14 49.6351 116.132 49.6086 116.118C49.5572 116.09 49.5189 116.043 49.5015 115.987C49.484 115.931 49.4889 115.871 49.5154 115.819Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M51.3765 112.299L51.967 111.26C51.9979 111.212 52.0455 111.177 52.1006 111.162C52.1558 111.147 52.2146 111.153 52.2655 111.179C52.3148 111.209 52.3507 111.256 52.3657 111.312C52.3808 111.368 52.3741 111.427 52.3466 111.478L51.7557 112.511C51.7281 112.561 51.6819 112.598 51.6273 112.614C51.5726 112.63 51.5137 112.625 51.4633 112.598C51.413 112.569 51.3757 112.522 51.3595 112.466C51.3432 112.41 51.3495 112.351 51.3765 112.299Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M53.4119 108.873L54.0337 107.865C54.0666 107.825 54.1124 107.799 54.1631 107.791C54.2138 107.783 54.2658 107.794 54.3091 107.821C54.3525 107.849 54.3842 107.891 54.3987 107.94C54.4133 107.989 54.4096 108.042 54.3881 108.089L53.7662 109.097C53.7515 109.121 53.7324 109.141 53.7098 109.158C53.6871 109.174 53.6613 109.186 53.6342 109.192C53.607 109.198 53.5791 109.199 53.5516 109.195C53.5241 109.19 53.4975 109.18 53.4738 109.166C53.4501 109.151 53.4297 109.132 53.4134 109.109C53.3971 109.086 53.3854 109.061 53.3791 109.034C53.3727 109.006 53.3718 108.978 53.3764 108.951C53.3809 108.923 53.3908 108.897 53.4055 108.873H53.4119Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M55.6088 105.547L56.299 104.576C56.3313 104.533 56.3791 104.503 56.4323 104.494C56.4855 104.485 56.54 104.496 56.585 104.526C56.6071 104.542 56.6259 104.561 56.6403 104.584C56.6547 104.607 56.6648 104.632 56.6695 104.659C56.6741 104.686 56.6732 104.713 56.6673 104.739C56.6614 104.766 56.6504 104.79 56.6348 104.812L55.9447 105.789C55.9123 105.833 55.8645 105.862 55.8114 105.871C55.7582 105.88 55.7036 105.869 55.6586 105.839C55.6149 105.806 55.5856 105.757 55.5763 105.703C55.5671 105.649 55.5787 105.593 55.6088 105.547Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M57.9805 102.338L58.7144 101.405C58.7303 101.384 58.75 101.367 58.7727 101.354C58.7954 101.34 58.8205 101.332 58.8465 101.328C58.8725 101.325 58.8989 101.326 58.9242 101.333C58.9496 101.34 58.9732 101.352 58.9941 101.368C59.0149 101.383 59.0325 101.403 59.0457 101.426C59.0588 101.449 59.0671 101.474 59.0706 101.5C59.074 101.526 59.0725 101.552 59.0657 101.578C59.059 101.603 59.0473 101.627 59.0314 101.648L58.2915 102.587C58.2751 102.608 58.2548 102.625 58.2319 102.638C58.209 102.65 58.1838 102.658 58.1579 102.661C58.1319 102.664 58.1055 102.662 58.0804 102.655C58.0553 102.647 58.0319 102.635 58.0115 102.619C57.9707 102.586 57.944 102.538 57.9371 102.486C57.9302 102.434 57.9434 102.381 57.9742 102.338H57.9805Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M60.5073 99.2552L61.2907 98.3651C61.3265 98.3303 61.3744 98.3104 61.4243 98.3091C61.4742 98.3079 61.5227 98.3255 61.5603 98.3584C61.5979 98.3913 61.6217 98.4369 61.6271 98.4866C61.6325 98.5363 61.6188 98.5862 61.5891 98.6264L60.7994 99.5165C60.7826 99.5357 60.7624 99.5514 60.7396 99.5627C60.7168 99.574 60.6921 99.5807 60.6667 99.5825C60.6413 99.5842 60.6155 99.5809 60.5914 99.5727C60.5673 99.5646 60.545 99.5518 60.5258 99.535C60.5066 99.5183 60.491 99.498 60.4797 99.4752C60.4683 99.4523 60.4617 99.4274 60.4599 99.4019C60.4582 99.3765 60.4615 99.351 60.4696 99.3269C60.4778 99.3027 60.4905 99.2804 60.5073 99.2612V99.2552Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M63.1962 96.321L64.0294 95.4683C64.0657 95.432 64.1151 95.4115 64.1664 95.4115C64.2177 95.4115 64.267 95.432 64.3033 95.4683C64.3396 95.5046 64.3598 95.5537 64.3598 95.6051C64.3598 95.6564 64.3396 95.7058 64.3033 95.7421L63.4698 96.5949C63.4335 96.632 63.3842 96.6532 63.3323 96.6538C63.2804 96.6544 63.2303 96.6342 63.1932 96.5979C63.1561 96.5616 63.1349 96.5119 63.1343 96.4599C63.1337 96.408 63.1539 96.3582 63.1902 96.321H63.1962Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M66.0408 93.524L66.9238 92.7211C66.9597 92.6865 67.0075 92.6674 67.0574 92.6674C67.1072 92.6674 67.155 92.6865 67.191 92.7211C67.2098 92.7386 67.225 92.7599 67.2353 92.7834C67.2456 92.807 67.2508 92.8325 67.2508 92.8582C67.2508 92.8839 67.2456 92.9091 67.2353 92.9327C67.225 92.9562 67.2098 92.9775 67.191 92.995L66.3144 93.7978C66.2774 93.8329 66.2283 93.8525 66.1774 93.8525C66.1265 93.8525 66.0777 93.8329 66.0408 93.7978C66.0219 93.7804 66.0067 93.7591 65.9964 93.7355C65.9862 93.712 65.981 93.6868 65.981 93.6611C65.981 93.6354 65.9862 93.6099 65.9964 93.5863C66.0067 93.5628 66.0219 93.5415 66.0408 93.524Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M69.0411 90.92L69.9675 90.1733C69.9849 90.1555 70.0059 90.1411 70.0288 90.1314C70.0518 90.1217 70.0765 90.1168 70.1014 90.1168C70.1263 90.1168 70.151 90.1217 70.174 90.1314C70.197 90.1411 70.2176 90.1555 70.235 90.1733C70.2529 90.1907 70.2672 90.2114 70.2769 90.2344C70.2866 90.2574 70.2915 90.2821 70.2915 90.3071C70.2915 90.332 70.2866 90.3567 70.2769 90.3797C70.2672 90.4027 70.2529 90.4234 70.235 90.4408L69.3147 91.1877C69.2965 91.2092 69.2738 91.2265 69.2482 91.2382C69.2226 91.2499 69.1949 91.2557 69.1668 91.2555C69.1387 91.2553 69.1109 91.249 69.0854 91.237C69.06 91.2249 69.0375 91.2076 69.0196 91.1859C69.0016 91.1643 68.9884 91.139 68.9813 91.1118C68.9742 91.0845 68.9732 91.0559 68.9783 91.0282C68.9833 91.0005 68.9942 90.9743 69.0104 90.9513C69.0267 90.9283 69.0477 90.9091 69.0721 90.8951L69.0411 90.92Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M72.1904 88.4846L73.1666 87.7936C73.1866 87.7792 73.2092 87.7691 73.2331 87.7635C73.2571 87.7579 73.282 87.757 73.3063 87.7611C73.3306 87.7651 73.3538 87.7738 73.3746 87.7869C73.3955 87.8 73.4135 87.8172 73.4278 87.8373C73.4552 87.8782 73.4662 87.9279 73.4581 87.9765C73.45 88.0251 73.4236 88.0689 73.3843 88.0987L72.4142 88.7894C72.3729 88.8188 72.3216 88.8307 72.2715 88.8226C72.2215 88.8144 72.1766 88.787 72.1467 88.746C72.1192 88.7051 72.1086 88.6551 72.1167 88.6065C72.1247 88.5579 72.1512 88.5144 72.1904 88.4846Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M75.4829 86.2419L76.4965 85.6195C76.5377 85.5939 76.5874 85.5856 76.6346 85.5967C76.6819 85.6078 76.7226 85.6372 76.7482 85.6785C76.7737 85.7197 76.782 85.7695 76.771 85.8167C76.7599 85.864 76.7305 85.9051 76.6893 85.9307L75.6818 86.5531C75.6397 86.5795 75.589 86.5882 75.5406 86.5771C75.4922 86.566 75.4501 86.5359 75.4237 86.4938C75.3973 86.4517 75.3886 86.401 75.3997 86.3525C75.4108 86.3041 75.4409 86.2619 75.4829 86.2355V86.2419Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M78.9064 84.1801L79.9509 83.6136C79.9718 83.6022 79.9947 83.5949 80.0183 83.5923C80.042 83.5897 80.066 83.5921 80.0888 83.5987C80.1116 83.6054 80.1328 83.6164 80.1513 83.6312C80.1699 83.6461 80.1851 83.6645 80.1966 83.6853C80.208 83.7062 80.2152 83.7292 80.2178 83.7528C80.2204 83.7764 80.2184 83.8002 80.2118 83.823C80.2051 83.8458 80.1941 83.8671 80.1793 83.8856C80.1644 83.9042 80.1461 83.9197 80.1252 83.9312L79.0804 84.5037C79.0597 84.5159 79.0368 84.5237 79.013 84.5268C78.9892 84.5299 78.9648 84.5283 78.9416 84.522C78.9185 84.5156 78.8968 84.5046 78.8779 84.4898C78.859 84.4749 78.8434 84.4564 78.8317 84.4354C78.8197 84.4137 78.812 84.3897 78.8093 84.3652C78.8065 84.3406 78.8087 84.3157 78.8156 84.2919C78.8226 84.2682 78.8341 84.2461 78.8496 84.2269C78.8652 84.2077 78.8847 84.1918 78.9064 84.1801Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M82.4351 82.3613L83.5109 81.8447C83.5549 81.8267 83.604 81.8255 83.6488 81.8416C83.6935 81.8577 83.7309 81.89 83.7535 81.9319C83.773 81.9758 83.7746 82.0256 83.7584 82.0708C83.7422 82.116 83.7093 82.1529 83.6664 82.1744L82.5906 82.691C82.5467 82.7105 82.497 82.7124 82.4518 82.6962C82.4066 82.68 82.3694 82.6471 82.3479 82.6041C82.3375 82.5825 82.3316 82.5588 82.3303 82.5348C82.3291 82.5108 82.3326 82.4869 82.3407 82.4643C82.3488 82.4417 82.3612 82.4209 82.3774 82.4032C82.3936 82.3855 82.4133 82.3713 82.4351 82.3613Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M86.0639 80.7165L87.1646 80.2622C87.2066 80.2516 87.2507 80.2565 87.2894 80.2759C87.3281 80.2953 87.3588 80.328 87.3754 80.368C87.392 80.408 87.3935 80.4526 87.3799 80.4938C87.3663 80.5349 87.3384 80.57 87.3013 80.5922L86.2006 81.0466C86.1791 81.0567 86.1558 81.0622 86.132 81.063C86.1082 81.0638 86.0846 81.0599 86.0624 81.0511C86.0403 81.0424 86.0201 81.0291 86.0032 81.0122C85.9864 80.9954 85.9731 80.9751 85.9643 80.953C85.9552 80.9309 85.9506 80.9072 85.9507 80.8834C85.9508 80.8595 85.9554 80.8361 85.9647 80.8141C85.9739 80.7921 85.9874 80.7722 86.0044 80.7554C86.0214 80.7387 86.0418 80.7254 86.0639 80.7165Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M89.7674 79.2715L90.893 78.8731C90.915 78.8653 90.9383 78.8622 90.9616 78.8636C90.9849 78.8651 91.0078 78.8711 91.0287 78.8816C91.0496 78.892 91.0682 78.9066 91.0834 78.9244C91.0985 78.9422 91.1098 78.9629 91.1168 78.9852C91.1256 79.0068 91.1298 79.03 91.1292 79.0533C91.1286 79.0766 91.1234 79.0994 91.1134 79.1204C91.1035 79.1415 91.0891 79.1601 91.0715 79.1754C91.054 79.1907 91.0333 79.2023 91.0111 79.2092L89.8855 79.6076C89.8434 79.6151 89.8001 79.6073 89.7632 79.5857C89.7262 79.5641 89.6981 79.5301 89.6839 79.4897C89.6697 79.4492 89.6701 79.4051 89.6854 79.3651C89.7008 79.3251 89.7298 79.292 89.7674 79.2715Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M93.5453 78.0132L94.6894 77.6771C94.7115 77.6704 94.7345 77.6681 94.7574 77.6704C94.7803 77.6728 94.8028 77.6798 94.823 77.6908C94.8433 77.7018 94.8611 77.7166 94.8755 77.7346C94.89 77.7525 94.9007 77.7732 94.9071 77.7953C94.9189 77.8399 94.9133 77.8873 94.8913 77.9278C94.8694 77.9683 94.8328 77.9989 94.789 78.0132L93.6446 78.3493C93.6225 78.356 93.5995 78.3581 93.5766 78.3557C93.5537 78.3534 93.5315 78.3467 93.5113 78.3357C93.4911 78.3247 93.4732 78.3096 93.4588 78.2916C93.4443 78.2736 93.4336 78.2533 93.4272 78.2311C93.4154 78.1866 93.421 78.1391 93.443 78.0986C93.4649 78.0581 93.5015 78.0276 93.5453 78.0132Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M97.3795 76.9478L98.5363 76.6616C98.5809 76.6502 98.6279 76.6568 98.6675 76.6801C98.7071 76.7034 98.736 76.7414 98.7477 76.7859C98.7574 76.8303 98.7501 76.877 98.727 76.9162C98.704 76.9555 98.6668 76.9846 98.6232 76.9977L97.4666 77.2839C97.4214 77.2934 97.3742 77.2852 97.3349 77.2608C97.2955 77.2365 97.2671 77.198 97.2553 77.1533C97.2488 77.1316 97.247 77.1086 97.2498 77.0861C97.2527 77.0637 97.26 77.0423 97.2717 77.0229C97.2834 77.0035 97.2993 76.9869 97.3179 76.974C97.3364 76.961 97.3573 76.9521 97.3795 76.9478Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M101.263 76.0821L102.426 75.8518C102.448 75.8473 102.471 75.8473 102.493 75.8518C102.515 75.8563 102.536 75.8651 102.555 75.8779C102.573 75.8907 102.589 75.9072 102.601 75.9262C102.613 75.9452 102.621 75.9666 102.625 75.9888C102.63 76.0105 102.63 76.0327 102.626 76.0545C102.622 76.0762 102.613 76.097 102.601 76.1155C102.589 76.134 102.573 76.1499 102.555 76.1623C102.537 76.1748 102.516 76.1834 102.495 76.1879L101.326 76.4182C101.304 76.4228 101.281 76.4229 101.26 76.4188C101.238 76.4147 101.217 76.4064 101.199 76.3942C101.18 76.3821 101.164 76.3663 101.152 76.348C101.139 76.3297 101.131 76.3092 101.126 76.2875C101.122 76.2651 101.122 76.242 101.126 76.2195C101.131 76.197 101.14 76.1753 101.152 76.1563C101.165 76.1372 101.181 76.121 101.2 76.1082C101.22 76.0955 101.241 76.0866 101.263 76.0821Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M105.172 75.3468L106.347 75.1663C106.369 75.1628 106.392 75.1637 106.414 75.169C106.436 75.1743 106.456 75.1839 106.474 75.1973C106.492 75.2106 106.507 75.2275 106.518 75.2468C106.53 75.2661 106.537 75.2872 106.54 75.3094C106.547 75.3531 106.537 75.3982 106.511 75.4343C106.486 75.4704 106.447 75.4948 106.403 75.5024L105.222 75.6829C105.177 75.6883 105.133 75.6767 105.097 75.6501C105.061 75.6234 105.037 75.5838 105.029 75.5398C105.025 75.5177 105.026 75.4952 105.032 75.4735C105.037 75.4518 105.047 75.4313 105.06 75.4133C105.073 75.3954 105.09 75.3801 105.109 75.3687C105.129 75.3573 105.15 75.3498 105.172 75.3468Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M109.119 74.8362L110.3 74.7116C110.344 74.7062 110.388 74.7182 110.423 74.745C110.458 74.7718 110.481 74.8111 110.487 74.8547C110.49 74.899 110.477 74.9431 110.449 74.9778C110.421 75.0125 110.382 75.0354 110.338 75.0416L109.156 75.1723C109.134 75.1747 109.112 75.1727 109.09 75.1665C109.069 75.1603 109.049 75.15 109.032 75.1361C109.014 75.1222 109 75.1051 108.99 75.0857C108.979 75.0662 108.972 75.0448 108.97 75.0228C108.967 75.0007 108.969 74.9784 108.975 74.9571C108.981 74.9358 108.992 74.9158 109.006 74.8985C109.02 74.8811 109.037 74.8669 109.056 74.8562C109.075 74.8455 109.097 74.8386 109.119 74.8362Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M113.077 74.4312L114.271 74.3565C114.313 74.3546 114.355 74.3692 114.388 74.3969C114.42 74.4246 114.44 74.4636 114.445 74.506C114.447 74.5277 114.444 74.5494 114.437 74.5701C114.43 74.5908 114.419 74.6097 114.405 74.626C114.39 74.6423 114.372 74.6557 114.353 74.6649C114.333 74.6742 114.311 74.6793 114.289 74.6801L113.102 74.761C113.081 74.7636 113.059 74.7617 113.038 74.7555C113.018 74.7492 112.998 74.7388 112.982 74.7248C112.966 74.7107 112.952 74.6932 112.943 74.6737C112.933 74.6543 112.928 74.6333 112.928 74.6117C112.925 74.59 112.927 74.5677 112.933 74.5467C112.939 74.5257 112.95 74.5062 112.964 74.4893C112.978 74.4724 112.995 74.4586 113.014 74.4486C113.034 74.4385 113.055 74.4328 113.077 74.4312Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M117.054 74.2135L118.242 74.1825C118.264 74.1817 118.285 74.1851 118.306 74.1928C118.326 74.2006 118.345 74.2124 118.36 74.2275C118.376 74.2426 118.389 74.2606 118.397 74.2807C118.406 74.3007 118.41 74.3224 118.41 74.3442C118.411 74.3651 118.407 74.386 118.4 74.4056C118.392 74.4252 118.381 74.443 118.367 74.4581C118.352 74.4732 118.335 74.4855 118.315 74.4937C118.296 74.5019 118.275 74.5062 118.254 74.5062L117.06 74.5432C117.036 74.548 117.011 74.5475 116.987 74.5414C116.963 74.5353 116.941 74.524 116.922 74.5083C116.903 74.4925 116.887 74.4727 116.876 74.4502C116.866 74.4278 116.86 74.4033 116.86 74.3785C116.86 74.3537 116.866 74.3292 116.876 74.3068C116.887 74.2844 116.903 74.2645 116.922 74.2487C116.941 74.233 116.963 74.2217 116.987 74.2156C117.011 74.2095 117.036 74.2087 117.06 74.2135H117.054Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M121.031 74.1699H122.225C122.267 74.1715 122.306 74.1893 122.335 74.2195C122.364 74.2496 122.38 74.2897 122.38 74.3316C122.38 74.3525 122.376 74.3734 122.368 74.3927C122.36 74.412 122.348 74.4295 122.333 74.444C122.318 74.4585 122.3 74.4697 122.28 74.4771C122.261 74.4846 122.24 74.488 122.219 74.4872H121.025C121.004 74.4872 120.983 74.4829 120.964 74.4747C120.945 74.4665 120.927 74.4546 120.913 74.4395C120.898 74.4243 120.887 74.4065 120.88 74.3869C120.872 74.3673 120.869 74.3465 120.87 74.3255C120.871 74.2837 120.889 74.2439 120.919 74.2149C120.949 74.1859 120.989 74.1699 121.031 74.1699Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M125.009 74.2695L126.196 74.3258C126.238 74.329 126.277 74.3482 126.305 74.3796C126.333 74.4109 126.347 74.4519 126.346 74.4938C126.346 74.5145 126.341 74.535 126.333 74.554C126.325 74.573 126.313 74.5898 126.298 74.6038C126.282 74.6179 126.264 74.6287 126.245 74.6354C126.225 74.6422 126.205 74.6448 126.184 74.643L124.996 74.5871C124.975 74.5863 124.954 74.5814 124.935 74.5725C124.916 74.5636 124.898 74.5508 124.884 74.5352C124.869 74.5195 124.858 74.5013 124.851 74.4814C124.843 74.4614 124.84 74.4403 124.841 74.4191C124.844 74.3772 124.863 74.3381 124.894 74.3103C124.926 74.2824 124.967 74.2678 125.009 74.2695Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M128.979 74.5123L130.161 74.612C130.202 74.612 130.242 74.6284 130.271 74.6576C130.3 74.6868 130.316 74.7263 130.316 74.7676C130.316 74.8089 130.3 74.8484 130.271 74.8776C130.242 74.9068 130.202 74.9232 130.161 74.9232L128.979 74.8235C128.959 74.8228 128.938 74.8177 128.92 74.8086C128.901 74.7996 128.884 74.7871 128.871 74.7712C128.858 74.7554 128.847 74.7368 128.842 74.7169C128.836 74.6969 128.834 74.6761 128.836 74.6555C128.839 74.6185 128.855 74.5836 128.882 74.5573C128.908 74.5311 128.942 74.5152 128.979 74.5123Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M132.957 74.8923L134.139 75.0294C134.159 75.0317 134.179 75.0379 134.197 75.0479C134.215 75.058 134.231 75.0715 134.243 75.0877C134.256 75.1039 134.265 75.1225 134.271 75.1424C134.276 75.1623 134.278 75.183 134.275 75.2035C134.273 75.2232 134.267 75.2422 134.257 75.2594C134.248 75.2767 134.234 75.2919 134.219 75.3041C134.203 75.3163 134.185 75.3254 134.166 75.3305C134.147 75.3357 134.127 75.3368 134.108 75.3342L132.926 75.1974C132.903 75.199 132.881 75.1955 132.86 75.1874C132.838 75.1793 132.819 75.1669 132.803 75.1506C132.788 75.1343 132.776 75.1146 132.768 75.0932C132.761 75.0717 132.758 75.0489 132.76 75.0263C132.763 75.0038 132.77 74.982 132.781 74.9625C132.793 74.943 132.809 74.9264 132.827 74.9136C132.846 74.9008 132.868 74.8923 132.89 74.8887C132.912 74.885 132.935 74.8862 132.957 74.8923Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M136.878 75.397L138.054 75.5714C138.076 75.5714 138.098 75.5761 138.118 75.5854C138.138 75.5946 138.156 75.6081 138.171 75.6249C138.186 75.6417 138.196 75.6616 138.203 75.6829C138.209 75.7043 138.211 75.7268 138.207 75.7489C138.204 75.771 138.196 75.7921 138.184 75.8109C138.172 75.8296 138.157 75.8456 138.138 75.8577C138.119 75.8698 138.098 75.8776 138.076 75.8808C138.054 75.884 138.031 75.8825 138.01 75.8762L136.835 75.7021C136.799 75.6916 136.768 75.6685 136.748 75.6371C136.727 75.6056 136.719 75.5678 136.724 75.5307C136.73 75.4936 136.748 75.4595 136.776 75.435C136.805 75.4104 136.841 75.397 136.878 75.397Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M140.805 76.0262L141.975 76.2316C141.994 76.2349 142.013 76.2418 142.03 76.2523C142.046 76.2628 142.061 76.2765 142.073 76.2927C142.084 76.3089 142.092 76.3275 142.097 76.3468C142.101 76.3662 142.102 76.3862 142.099 76.4058C142.095 76.4254 142.088 76.4442 142.078 76.4611C142.067 76.478 142.054 76.4927 142.037 76.5042C142.021 76.5158 142.003 76.5238 141.984 76.5283C141.964 76.5327 141.944 76.5337 141.925 76.5304L140.749 76.3186C140.729 76.3163 140.709 76.3097 140.691 76.2994C140.674 76.2891 140.658 76.2753 140.646 76.2587C140.634 76.2421 140.625 76.2232 140.62 76.2031C140.616 76.183 140.616 76.1622 140.619 76.142C140.623 76.1218 140.631 76.1028 140.643 76.0858C140.654 76.0688 140.67 76.0544 140.687 76.0435C140.705 76.0326 140.724 76.0252 140.744 76.0223C140.765 76.0193 140.785 76.0208 140.805 76.0262Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M144.708 76.7611L145.871 77.0039C145.91 77.0114 145.944 77.0341 145.966 77.0668C145.988 77.0995 145.996 77.1393 145.989 77.178C145.986 77.197 145.978 77.2152 145.967 77.2312C145.956 77.2473 145.942 77.2612 145.926 77.2716C145.91 77.2821 145.892 77.2891 145.873 77.2923C145.854 77.2955 145.834 77.2947 145.815 77.2902L144.652 77.0537C144.614 77.0448 144.581 77.0219 144.559 76.9896C144.537 76.9573 144.528 76.9179 144.534 76.8793C144.543 76.8413 144.566 76.8082 144.598 76.7863C144.63 76.7644 144.67 76.7554 144.708 76.7611Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M148.592 77.6023L149.749 77.8761C149.767 77.8802 149.785 77.8881 149.8 77.8989C149.816 77.9097 149.829 77.9234 149.839 77.9393C149.849 77.9552 149.856 77.9727 149.859 77.9913C149.862 78.0099 149.862 78.0288 149.857 78.0472C149.853 78.0656 149.846 78.0831 149.835 78.0986C149.824 78.114 149.811 78.1271 149.795 78.1372C149.779 78.1473 149.761 78.1543 149.742 78.1575C149.724 78.1608 149.705 78.1604 149.687 78.1563L148.53 77.8886C148.511 77.8849 148.493 77.8776 148.477 77.8667C148.461 77.8558 148.447 77.8417 148.437 77.8254C148.426 77.8091 148.419 77.791 148.416 77.7719C148.413 77.7528 148.413 77.7333 148.418 77.7144C148.427 77.6769 148.45 77.6442 148.483 77.6233C148.515 77.6024 148.554 77.5948 148.592 77.6023Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M152.451 78.574L153.608 78.8727C153.644 78.8829 153.674 78.907 153.693 78.9396C153.712 78.9722 153.717 79.0105 153.707 79.0468C153.697 79.0833 153.673 79.1147 153.641 79.1344C153.608 79.154 153.57 79.1606 153.533 79.1529L152.383 78.8542C152.345 78.8451 152.313 78.8215 152.294 78.7888C152.274 78.7561 152.268 78.7169 152.277 78.6797C152.286 78.6426 152.309 78.6105 152.342 78.5907C152.375 78.5708 152.414 78.5649 152.451 78.574Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M156.292 79.5707L157.436 79.8943C157.468 79.9078 157.494 79.9324 157.509 79.9636C157.524 79.9948 157.527 80.0304 157.518 80.0639C157.509 80.0974 157.488 80.1265 157.459 80.1457C157.43 80.1648 157.395 80.1728 157.361 80.1682L156.21 79.8445C156.193 79.8395 156.176 79.8311 156.162 79.8196C156.147 79.808 156.135 79.7936 156.127 79.7773C156.118 79.7611 156.113 79.7432 156.111 79.7248C156.11 79.7064 156.112 79.6876 156.117 79.6701C156.127 79.6339 156.151 79.6033 156.184 79.5847C156.217 79.566 156.255 79.5611 156.292 79.5707Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M160.107 80.6794L161.245 81.0279C161.263 81.0329 161.279 81.0416 161.294 81.0532C161.308 81.0647 161.32 81.0788 161.329 81.0951C161.338 81.1114 161.343 81.1293 161.344 81.1477C161.346 81.1661 161.344 81.1848 161.338 81.2024C161.327 81.2374 161.302 81.2664 161.27 81.2838C161.237 81.3012 161.199 81.3055 161.164 81.2957L160.026 80.9471C160.008 80.9421 159.992 80.9334 159.977 80.9219C159.963 80.9103 159.951 80.8962 159.942 80.8799C159.934 80.8637 159.928 80.8458 159.927 80.8274C159.925 80.809 159.927 80.7902 159.933 80.7727C159.944 80.7377 159.969 80.7086 160.001 80.6912C160.033 80.6738 160.071 80.6695 160.107 80.6794Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M163.904 81.8631L165.035 82.2366C165.052 82.2415 165.068 82.25 165.082 82.2612C165.096 82.2724 165.107 82.2862 165.115 82.3019C165.123 82.3176 165.128 82.335 165.129 82.3527C165.131 82.3703 165.128 82.3879 165.123 82.4046C165.111 82.4396 165.086 82.469 165.054 82.4864C165.022 82.5038 164.984 82.5078 164.948 82.4979L163.817 82.1308C163.799 82.1252 163.784 82.1162 163.77 82.1044C163.756 82.0925 163.745 82.078 163.737 82.0618C163.729 82.0456 163.724 82.0279 163.722 82.0099C163.721 81.9918 163.724 81.9738 163.729 81.9567C163.741 81.9217 163.765 81.8923 163.798 81.8749C163.83 81.8576 163.868 81.8533 163.904 81.8631Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M167.676 83.1212L168.801 83.5072C168.818 83.5128 168.835 83.5218 168.848 83.5336C168.862 83.5455 168.873 83.56 168.881 83.5762C168.889 83.5924 168.894 83.6101 168.895 83.6282C168.897 83.6462 168.894 83.6645 168.888 83.6816C168.876 83.7153 168.85 83.7425 168.818 83.7576C168.785 83.7727 168.748 83.7746 168.714 83.7625L167.589 83.3765C167.555 83.3639 167.528 83.3386 167.513 83.306C167.497 83.2734 167.496 83.2362 167.508 83.2024C167.513 83.186 167.522 83.1707 167.534 83.1577C167.545 83.1447 167.559 83.1343 167.575 83.1267C167.59 83.1192 167.608 83.1148 167.625 83.114C167.642 83.1131 167.659 83.1154 167.676 83.1212Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M171.428 84.4359L172.548 84.8404C172.565 84.8452 172.58 84.8534 172.594 84.8647C172.607 84.8761 172.618 84.8902 172.626 84.9061C172.634 84.922 172.638 84.9395 172.638 84.9571C172.639 84.9748 172.636 84.9922 172.629 85.0085C172.623 85.0249 172.614 85.0402 172.603 85.0532C172.591 85.0662 172.577 85.0766 172.562 85.0842C172.546 85.0917 172.529 85.096 172.512 85.0969C172.494 85.0978 172.477 85.0952 172.461 85.0893L171.341 84.6848C171.308 84.6733 171.281 84.6492 171.266 84.6177C171.251 84.5862 171.249 84.5498 171.261 84.5168C171.272 84.4838 171.296 84.4566 171.328 84.4414C171.359 84.4262 171.395 84.4244 171.428 84.4359Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M175.163 85.8064L176.276 86.2233C176.305 86.2379 176.328 86.263 176.339 86.2932C176.351 86.3234 176.351 86.3569 176.339 86.3871C176.328 86.4173 176.305 86.4421 176.276 86.4567C176.248 86.4713 176.214 86.4746 176.183 86.4661L175.07 86.0492C175.038 86.0366 175.012 86.012 174.998 85.9805C174.984 85.949 174.983 85.9133 174.995 85.8811C175.001 85.8647 175.009 85.8496 175.021 85.8367C175.033 85.8239 175.047 85.8137 175.063 85.8067C175.079 85.7996 175.096 85.7961 175.113 85.796C175.13 85.796 175.147 85.7994 175.163 85.8064Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M178.873 87.2204L179.986 87.6562C180.017 87.6687 180.041 87.6924 180.055 87.7225C180.069 87.7525 180.071 87.7865 180.061 87.8179C180.048 87.85 180.024 87.8759 179.992 87.8899C179.961 87.9039 179.925 87.9048 179.893 87.8926L178.78 87.4632C178.749 87.4495 178.724 87.4244 178.712 87.393C178.699 87.3617 178.699 87.3266 178.711 87.2952C178.717 87.2795 178.725 87.2652 178.737 87.2529C178.748 87.2407 178.761 87.2307 178.776 87.2238C178.791 87.2168 178.808 87.2128 178.824 87.2122C178.841 87.2117 178.857 87.2145 178.873 87.2204Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M182.57 88.6784L183.677 89.1264C183.708 89.1401 183.732 89.1652 183.745 89.1966C183.758 89.2279 183.758 89.263 183.745 89.2944C183.733 89.3249 183.709 89.3494 183.678 89.3622C183.648 89.375 183.614 89.3751 183.583 89.3628L182.477 88.9149C182.461 88.9095 182.446 88.9007 182.434 88.8893C182.422 88.878 182.413 88.8645 182.406 88.8492C182.4 88.834 182.397 88.8175 182.397 88.8009C182.397 88.7843 182.401 88.7679 182.408 88.7529C182.414 88.7372 182.422 88.7229 182.433 88.7107C182.445 88.6984 182.458 88.6884 182.473 88.6815C182.488 88.6745 182.505 88.6708 182.521 88.6702C182.538 88.6697 182.554 88.6725 182.57 88.6784Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M186.248 90.1859L187.349 90.6463C187.364 90.6523 187.378 90.6614 187.389 90.673C187.4 90.6846 187.409 90.6986 187.415 90.7138C187.421 90.7289 187.424 90.7452 187.423 90.7615C187.422 90.7777 187.418 90.7937 187.411 90.8083C187.399 90.8388 187.375 90.8629 187.344 90.8757C187.314 90.8886 187.28 90.889 187.249 90.8766L186.155 90.4159C186.139 90.4105 186.125 90.402 186.113 90.3907C186.101 90.3794 186.091 90.3655 186.085 90.3503C186.078 90.335 186.075 90.3188 186.075 90.3023C186.076 90.2857 186.079 90.2693 186.086 90.2543C186.099 90.2238 186.123 90.1993 186.153 90.1865C186.183 90.1737 186.218 90.1736 186.248 90.1859Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M189.908 91.731L191.002 92.2038C191.032 92.2174 191.055 92.242 191.066 92.2722C191.078 92.3024 191.077 92.336 191.065 92.3658C191.059 92.3809 191.049 92.3945 191.038 92.4059C191.026 92.4174 191.013 92.4262 190.997 92.4321C190.982 92.4379 190.966 92.4407 190.95 92.44C190.934 92.4392 190.918 92.4353 190.903 92.4281L189.815 91.9613C189.786 91.9476 189.764 91.9234 189.753 91.8939C189.741 91.8643 189.741 91.8315 189.753 91.8021C189.765 91.7727 189.788 91.7491 189.817 91.7358C189.845 91.7226 189.878 91.7209 189.908 91.731Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M193.549 93.3255L194.638 93.8109C194.652 93.817 194.665 93.8259 194.676 93.837C194.686 93.8481 194.695 93.8614 194.701 93.8759C194.707 93.8904 194.71 93.9056 194.709 93.9212C194.709 93.9367 194.706 93.9522 194.7 93.9665C194.686 93.9958 194.662 94.0187 194.632 94.0303C194.601 94.0419 194.568 94.0415 194.538 94.0288L193.45 93.5431C193.422 93.5297 193.401 93.506 193.39 93.4769C193.38 93.4478 193.381 93.4157 193.394 93.3875C193.399 93.3726 193.408 93.3591 193.419 93.3477C193.43 93.3363 193.443 93.3275 193.458 93.3216C193.472 93.3157 193.488 93.313 193.504 93.3137C193.52 93.3144 193.535 93.3185 193.549 93.3255Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M197.172 94.9575L198.254 95.4556C198.268 95.4616 198.281 95.4705 198.292 95.4817C198.302 95.493 198.311 95.5063 198.316 95.5209C198.321 95.5356 198.324 95.5513 198.322 95.5668C198.321 95.5823 198.317 95.5974 198.31 95.6112C198.304 95.6256 198.295 95.6385 198.284 95.6492C198.272 95.6599 198.259 95.6682 198.244 95.6735C198.23 95.6788 198.214 95.681 198.199 95.6799C198.183 95.6788 198.168 95.6744 198.154 95.6671L197.072 95.1754C197.045 95.1597 197.024 95.1346 197.014 95.1049C197.004 95.0752 197.004 95.0429 197.016 95.0137C197.031 94.987 197.055 94.9663 197.083 94.956C197.112 94.9457 197.143 94.9462 197.172 94.9575Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M200.8 96.6394L201.876 97.1496C201.892 97.1545 201.907 97.163 201.92 97.1742C201.933 97.1854 201.943 97.1991 201.95 97.2146C201.957 97.2302 201.961 97.2471 201.961 97.2642C201.96 97.2812 201.957 97.298 201.949 97.3134C201.942 97.3288 201.932 97.3425 201.919 97.3535C201.906 97.3645 201.89 97.3724 201.874 97.3769C201.858 97.3815 201.84 97.3823 201.824 97.3796C201.807 97.3769 201.791 97.3709 201.777 97.3614L200.701 96.8509C200.685 96.846 200.67 96.8378 200.657 96.8266C200.644 96.8153 200.634 96.8014 200.627 96.7858C200.62 96.7703 200.617 96.7536 200.617 96.7366C200.617 96.7196 200.621 96.7028 200.628 96.6874C200.635 96.672 200.646 96.6582 200.659 96.6473C200.672 96.6363 200.687 96.6284 200.703 96.6239C200.72 96.6193 200.737 96.6181 200.753 96.6208C200.77 96.6235 200.786 96.6299 200.8 96.6394Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M204.361 98.3527L205.43 98.8754C205.444 98.8813 205.456 98.8903 205.466 98.9012C205.476 98.9122 205.484 98.9251 205.489 98.9392C205.493 98.9533 205.495 98.9682 205.494 98.983C205.492 98.9977 205.488 99.012 205.48 99.0249C205.474 99.0385 205.466 99.0506 205.455 99.0608C205.444 99.0709 205.431 99.0789 205.417 99.0842C205.404 99.0894 205.389 99.0917 205.374 99.0911C205.359 99.0906 205.344 99.087 205.331 99.0808L204.261 98.5581C204.235 98.5435 204.214 98.5196 204.204 98.4909C204.193 98.4623 204.194 98.4308 204.206 98.4025C204.22 98.3758 204.244 98.3556 204.273 98.3463C204.302 98.337 204.334 98.3394 204.361 98.3527Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M207.921 100.109L208.984 100.645C209.011 100.659 209.031 100.683 209.041 100.712C209.05 100.741 209.048 100.773 209.034 100.8C209.02 100.825 208.996 100.844 208.968 100.852C208.94 100.86 208.911 100.857 208.885 100.844L207.821 100.315C207.795 100.3 207.775 100.276 207.766 100.247C207.756 100.218 207.759 100.186 207.772 100.159C207.786 100.134 207.809 100.115 207.837 100.106C207.864 100.097 207.894 100.098 207.921 100.109Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M211.462 101.916L212.52 102.463C212.532 102.469 212.544 102.478 212.553 102.488C212.563 102.499 212.57 102.511 212.575 102.524C212.579 102.538 212.581 102.552 212.58 102.566C212.579 102.58 212.576 102.594 212.569 102.607C212.556 102.633 212.533 102.653 212.505 102.662C212.477 102.671 212.446 102.669 212.42 102.656L211.363 102.108C211.337 102.095 211.317 102.072 211.308 102.044C211.298 102.016 211.3 101.986 211.313 101.959C211.328 101.934 211.351 101.915 211.379 101.907C211.407 101.899 211.437 101.902 211.462 101.916Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M214.985 103.76L216.036 104.32C216.06 104.334 216.077 104.357 216.086 104.383C216.094 104.41 216.091 104.438 216.08 104.463C216.067 104.488 216.044 104.506 216.017 104.514C215.991 104.523 215.961 104.52 215.937 104.506L214.88 103.946C214.856 103.932 214.839 103.91 214.831 103.883C214.823 103.857 214.824 103.828 214.836 103.803C214.843 103.79 214.852 103.779 214.863 103.769C214.874 103.76 214.887 103.753 214.901 103.749C214.915 103.745 214.93 103.744 214.944 103.746C214.959 103.747 214.973 103.752 214.985 103.76Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M218.484 105.641L219.528 106.214C219.552 106.228 219.569 106.251 219.576 106.277C219.583 106.304 219.579 106.333 219.566 106.357C219.552 106.382 219.53 106.4 219.503 106.408C219.476 106.416 219.447 106.413 219.423 106.4L218.378 105.828C218.366 105.821 218.355 105.812 218.346 105.802C218.337 105.791 218.331 105.778 218.327 105.764C218.324 105.751 218.323 105.737 218.326 105.723C218.328 105.709 218.333 105.696 218.341 105.685C218.355 105.661 218.378 105.643 218.404 105.635C218.43 105.627 218.459 105.629 218.484 105.641Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M221.963 107.566L223.002 108.151C223.025 108.165 223.043 108.189 223.05 108.215C223.057 108.242 223.053 108.27 223.039 108.294C223.033 108.307 223.025 108.317 223.015 108.326C223.004 108.335 222.992 108.341 222.979 108.344C222.966 108.348 222.953 108.349 222.939 108.347C222.926 108.344 222.913 108.339 222.902 108.332L221.864 107.747C221.851 107.741 221.841 107.732 221.832 107.722C221.823 107.712 221.817 107.7 221.813 107.687C221.81 107.674 221.809 107.66 221.811 107.647C221.814 107.633 221.819 107.621 221.826 107.61C221.832 107.598 221.841 107.587 221.851 107.578C221.861 107.57 221.873 107.563 221.885 107.559C221.898 107.555 221.912 107.553 221.925 107.555C221.939 107.556 221.951 107.56 221.963 107.566Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M225.411 109.534L226.443 110.157C226.464 110.172 226.479 110.194 226.486 110.219C226.493 110.243 226.491 110.27 226.48 110.294C226.466 110.317 226.443 110.335 226.416 110.342C226.39 110.349 226.361 110.345 226.337 110.331L225.311 109.709C225.299 109.703 225.288 109.695 225.279 109.684C225.271 109.674 225.264 109.662 225.261 109.649C225.257 109.636 225.257 109.622 225.259 109.609C225.261 109.596 225.266 109.583 225.274 109.572C225.28 109.56 225.289 109.55 225.299 109.542C225.31 109.534 225.322 109.528 225.334 109.524C225.347 109.521 225.36 109.52 225.374 109.522C225.387 109.523 225.399 109.528 225.411 109.534Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M228.84 111.54L229.867 112.163C229.89 112.177 229.906 112.199 229.912 112.225C229.919 112.252 229.915 112.279 229.901 112.303C229.887 112.326 229.864 112.342 229.838 112.349C229.812 112.355 229.784 112.351 229.761 112.337L228.741 111.715C228.718 111.701 228.701 111.679 228.694 111.654C228.687 111.628 228.69 111.601 228.704 111.577C228.717 111.554 228.739 111.537 228.764 111.53C228.79 111.523 228.817 111.527 228.84 111.54Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M232.245 113.59L233.265 114.212C233.286 114.226 233.3 114.247 233.306 114.271C233.311 114.296 233.308 114.321 233.296 114.343C233.29 114.354 233.281 114.365 233.27 114.373C233.26 114.381 233.248 114.386 233.235 114.389C233.222 114.392 233.208 114.393 233.195 114.39C233.182 114.387 233.17 114.382 233.159 114.374L232.145 113.752C232.136 113.743 232.129 113.732 232.124 113.72C232.118 113.709 232.116 113.696 232.116 113.683C232.116 113.67 232.118 113.657 232.124 113.646C232.129 113.634 232.136 113.623 232.145 113.615C232.159 113.594 232.181 113.579 232.205 113.574C232.229 113.568 232.254 113.572 232.276 113.584L232.245 113.59Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M235.656 115.677L236.663 116.299C236.684 116.313 236.698 116.334 236.704 116.358C236.71 116.383 236.706 116.408 236.694 116.43C236.677 116.447 236.654 116.457 236.629 116.457C236.605 116.457 236.581 116.447 236.564 116.43L235.55 115.808C235.53 115.794 235.515 115.772 235.509 115.748C235.504 115.724 235.507 115.699 235.519 115.677C235.538 115.661 235.563 115.652 235.588 115.652C235.613 115.652 235.637 115.661 235.656 115.677Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M238.986 117.82L239.994 118.442C240.002 118.45 240.008 118.46 240.013 118.471C240.017 118.481 240.02 118.493 240.02 118.504C240.02 118.516 240.017 118.527 240.013 118.538C240.008 118.549 240.002 118.559 239.994 118.567C239.981 118.587 239.961 118.601 239.938 118.607C239.914 118.613 239.89 118.609 239.869 118.598L238.862 117.975C238.845 117.958 238.835 117.934 238.835 117.91C238.835 117.886 238.845 117.862 238.862 117.845C238.87 117.836 238.88 117.83 238.89 117.825C238.901 117.821 238.912 117.818 238.924 117.818C238.936 117.818 238.947 117.821 238.958 117.825C238.968 117.83 238.978 117.836 238.986 117.845V117.82Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M242.328 119.956L243.329 120.579C243.344 120.596 243.351 120.618 243.351 120.641C243.351 120.663 243.344 120.685 243.329 120.703C243.317 120.723 243.296 120.738 243.273 120.744C243.25 120.749 243.226 120.746 243.205 120.734L242.204 120.081C242.196 120.073 242.189 120.063 242.184 120.052C242.18 120.042 242.178 120.03 242.178 120.018C242.178 120.007 242.18 119.995 242.184 119.985C242.189 119.974 242.196 119.965 242.204 119.956C242.212 119.948 242.222 119.941 242.232 119.937C242.243 119.932 242.255 119.93 242.266 119.93C242.278 119.93 242.289 119.932 242.3 119.937C242.31 119.941 242.32 119.948 242.328 119.956Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M245.652 122.137L246.647 122.797C246.661 122.814 246.669 122.836 246.669 122.859C246.669 122.881 246.661 122.903 246.647 122.921C246.629 122.935 246.607 122.943 246.585 122.943C246.562 122.943 246.54 122.935 246.522 122.921L245.527 122.261C245.512 122.244 245.504 122.222 245.504 122.199C245.504 122.176 245.512 122.154 245.527 122.137C245.545 122.123 245.567 122.115 245.59 122.115C245.612 122.115 245.634 122.123 245.652 122.137Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M248.957 124.348L249.946 125.014C249.955 125.021 249.963 125.029 249.969 125.039C249.976 125.048 249.98 125.059 249.982 125.07C249.984 125.082 249.984 125.093 249.981 125.104C249.979 125.116 249.974 125.126 249.968 125.136C249.961 125.145 249.953 125.153 249.943 125.159C249.933 125.165 249.923 125.17 249.911 125.172C249.9 125.174 249.889 125.173 249.877 125.171C249.866 125.169 249.856 125.164 249.846 125.157L248.858 124.491C248.843 124.475 248.834 124.454 248.834 124.432C248.834 124.411 248.843 124.389 248.858 124.373C248.87 124.36 248.886 124.35 248.904 124.346C248.921 124.341 248.94 124.342 248.957 124.348Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M252.243 126.578L253.226 127.257C253.234 127.264 253.24 127.272 253.244 127.282C253.249 127.292 253.251 127.302 253.251 127.313C253.251 127.324 253.249 127.334 253.244 127.344C253.24 127.353 253.234 127.362 253.226 127.369C253.218 127.377 253.209 127.383 253.199 127.388C253.189 127.392 253.178 127.394 253.167 127.394C253.156 127.394 253.145 127.392 253.135 127.388C253.124 127.383 253.115 127.377 253.108 127.369L252.125 126.697C252.111 126.682 252.102 126.664 252.1 126.644C252.097 126.624 252.102 126.603 252.113 126.587C252.124 126.57 252.141 126.557 252.16 126.551C252.179 126.545 252.2 126.546 252.218 126.553L252.243 126.578Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M255.517 128.833L256.5 129.512C256.508 129.519 256.514 129.528 256.519 129.538C256.523 129.549 256.526 129.56 256.526 129.571C256.526 129.582 256.523 129.593 256.519 129.603C256.514 129.613 256.508 129.622 256.5 129.63C256.485 129.644 256.465 129.652 256.444 129.652C256.423 129.652 256.403 129.644 256.388 129.63L255.405 128.952C255.391 128.936 255.382 128.914 255.382 128.892C255.382 128.871 255.391 128.85 255.405 128.833C255.42 128.819 255.44 128.811 255.461 128.811C255.482 128.811 255.502 128.819 255.517 128.833Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M258.785 131.107L259.761 131.785C259.776 131.8 259.784 131.82 259.784 131.841C259.784 131.862 259.776 131.883 259.761 131.898C259.754 131.906 259.746 131.912 259.736 131.916C259.727 131.92 259.716 131.923 259.705 131.923C259.695 131.923 259.684 131.92 259.675 131.916C259.665 131.912 259.656 131.906 259.649 131.898L258.673 131.219C258.659 131.204 258.651 131.184 258.651 131.163C258.651 131.142 258.659 131.122 258.673 131.107C258.68 131.099 258.689 131.093 258.698 131.088C258.708 131.084 258.718 131.082 258.729 131.082C258.74 131.082 258.75 131.084 258.76 131.088C258.769 131.093 258.778 131.099 258.785 131.107Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M262.04 133.393L263.01 134.084C263.017 134.091 263.023 134.099 263.026 134.108C263.03 134.117 263.032 134.127 263.032 134.137C263.032 134.147 263.03 134.157 263.026 134.166C263.023 134.175 263.017 134.183 263.01 134.19C263.003 134.197 262.995 134.202 262.986 134.206C262.977 134.21 262.967 134.212 262.958 134.212C262.948 134.212 262.938 134.21 262.929 134.206C262.92 134.202 262.911 134.197 262.904 134.19L261.928 133.505C261.914 133.49 261.906 133.47 261.906 133.449C261.906 133.428 261.914 133.408 261.928 133.393C261.944 133.38 261.964 133.373 261.984 133.373C262.005 133.373 262.025 133.38 262.04 133.393Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M265.283 135.686L266.253 136.37C266.266 136.385 266.273 136.404 266.273 136.423C266.273 136.443 266.266 136.462 266.253 136.476C266.246 136.483 266.238 136.489 266.229 136.492C266.22 136.496 266.21 136.498 266.2 136.498C266.191 136.498 266.181 136.496 266.172 136.492C266.162 136.489 266.154 136.483 266.148 136.476L265.177 135.785C265.17 135.778 265.165 135.77 265.161 135.761C265.157 135.752 265.155 135.742 265.155 135.732C265.155 135.723 265.157 135.713 265.161 135.704C265.165 135.695 265.17 135.686 265.177 135.68C265.193 135.668 265.211 135.662 265.23 135.662C265.249 135.662 265.268 135.668 265.283 135.68V135.686Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M268.52 137.985L269.49 138.675C269.501 138.691 269.508 138.709 269.508 138.728C269.508 138.747 269.501 138.766 269.49 138.781C269.483 138.788 269.475 138.794 269.466 138.798C269.456 138.801 269.447 138.803 269.437 138.803C269.427 138.803 269.418 138.801 269.408 138.798C269.399 138.794 269.391 138.788 269.384 138.781L268.414 138.091C268.407 138.084 268.402 138.076 268.398 138.068C268.395 138.059 268.393 138.05 268.393 138.041C268.393 138.031 268.395 138.022 268.398 138.014C268.402 138.005 268.407 137.997 268.414 137.991C268.428 137.977 268.446 137.969 268.466 137.968C268.485 137.967 268.504 137.973 268.52 137.985Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M271.756 140.296L272.726 140.986C272.734 140.992 272.74 140.998 272.745 141.006C272.749 141.014 272.752 141.022 272.754 141.031C272.755 141.04 272.755 141.05 272.753 141.058C272.751 141.067 272.747 141.076 272.742 141.083C272.736 141.09 272.73 141.096 272.722 141.101C272.714 141.106 272.706 141.109 272.697 141.111C272.688 141.112 272.679 141.112 272.67 141.11C272.661 141.108 272.653 141.104 272.646 141.099L271.675 140.407C271.663 140.394 271.656 140.376 271.656 140.358C271.656 140.34 271.663 140.322 271.675 140.308C271.686 140.298 271.699 140.291 271.714 140.288C271.728 140.286 271.743 140.289 271.756 140.296Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M274.987 142.6L275.951 143.297C275.958 143.303 275.963 143.311 275.967 143.319C275.97 143.327 275.972 143.335 275.972 143.344C275.972 143.353 275.97 143.362 275.967 143.37C275.963 143.378 275.958 143.385 275.951 143.391C275.937 143.403 275.92 143.41 275.901 143.41C275.883 143.41 275.865 143.403 275.852 143.391L274.887 142.7C274.881 142.694 274.875 142.686 274.872 142.677C274.868 142.669 274.866 142.659 274.866 142.65C274.866 142.641 274.868 142.632 274.872 142.623C274.875 142.615 274.881 142.607 274.887 142.6C274.901 142.588 274.919 142.581 274.937 142.581C274.956 142.581 274.973 142.588 274.987 142.6Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M278.217 144.912L279.187 145.609C279.193 145.615 279.199 145.622 279.202 145.63C279.206 145.638 279.208 145.647 279.208 145.656C279.208 145.664 279.206 145.673 279.202 145.681C279.199 145.689 279.193 145.696 279.187 145.702C279.174 145.714 279.158 145.721 279.14 145.721C279.123 145.721 279.106 145.714 279.094 145.702L278.123 145.011C278.117 145.005 278.112 144.998 278.108 144.99C278.104 144.982 278.103 144.974 278.103 144.965C278.103 144.956 278.104 144.947 278.108 144.939C278.112 144.931 278.117 144.924 278.123 144.918C278.135 144.905 278.152 144.898 278.169 144.896C278.186 144.895 278.203 144.901 278.217 144.912Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M281.447 147.223L282.418 147.914C282.429 147.925 282.435 147.941 282.435 147.957C282.435 147.974 282.429 147.989 282.418 148.001C282.405 148.013 282.388 148.019 282.371 148.019C282.354 148.019 282.337 148.013 282.324 148.001L281.354 147.31C281.342 147.297 281.336 147.281 281.336 147.263C281.336 147.246 281.342 147.229 281.354 147.217C281.36 147.21 281.368 147.206 281.376 147.202C281.385 147.199 281.393 147.198 281.402 147.199C281.411 147.199 281.42 147.202 281.427 147.206C281.435 147.21 281.442 147.216 281.447 147.223Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M284.69 149.522L285.66 150.212C285.673 150.222 285.682 150.237 285.684 150.253C285.687 150.27 285.682 150.286 285.672 150.3C285.662 150.313 285.648 150.322 285.631 150.324C285.615 150.326 285.599 150.322 285.585 150.312L284.615 149.627C284.604 149.616 284.597 149.6 284.597 149.584C284.597 149.568 284.604 149.552 284.615 149.54C284.624 149.53 284.636 149.523 284.65 149.519C284.663 149.516 284.677 149.517 284.69 149.522Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M287.939 151.814L288.91 152.498C288.921 152.51 288.927 152.526 288.927 152.542C288.927 152.558 288.921 152.574 288.91 152.586C288.897 152.596 288.882 152.601 288.866 152.601C288.85 152.601 288.835 152.596 288.822 152.586L287.852 151.901C287.847 151.896 287.842 151.889 287.84 151.882C287.837 151.875 287.835 151.868 287.835 151.86C287.835 151.853 287.837 151.846 287.84 151.839C287.842 151.832 287.847 151.825 287.852 151.82C287.863 151.808 287.878 151.8 287.894 151.799C287.911 151.798 287.927 151.803 287.939 151.814Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M291.182 154.1L292.158 154.779C292.164 154.784 292.168 154.79 292.171 154.797C292.174 154.804 292.176 154.811 292.176 154.819C292.176 154.827 292.174 154.834 292.171 154.841C292.168 154.848 292.164 154.854 292.158 154.859C292.153 154.865 292.147 154.869 292.14 154.872C292.133 154.875 292.125 154.877 292.118 154.877C292.11 154.877 292.103 154.875 292.096 154.872C292.089 154.869 292.083 154.865 292.078 154.859L291.095 154.181C291.083 154.172 291.075 154.158 291.072 154.143C291.07 154.128 291.073 154.113 291.083 154.1C291.092 154.088 291.105 154.08 291.121 154.077C291.136 154.075 291.151 154.079 291.164 154.088L291.182 154.1Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M294.443 156.362L295.407 157.065C295.413 157.07 295.417 157.076 295.42 157.083C295.423 157.09 295.424 157.098 295.424 157.105C295.424 157.113 295.423 157.12 295.42 157.127C295.417 157.134 295.413 157.14 295.407 157.146C295.402 157.151 295.396 157.155 295.389 157.158C295.382 157.161 295.374 157.163 295.367 157.163C295.359 157.163 295.352 157.161 295.345 157.158C295.338 157.155 295.332 157.151 295.326 157.146L294.344 156.473C294.331 156.465 294.321 156.452 294.318 156.437C294.314 156.422 294.317 156.406 294.325 156.393C294.333 156.379 294.347 156.37 294.362 156.366C294.377 156.363 294.393 156.366 294.406 156.374L294.443 156.362Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M297.724 158.617L298.706 159.289C298.715 159.299 298.72 159.312 298.72 159.326C298.72 159.34 298.715 159.353 298.706 159.363C298.701 159.369 298.696 159.373 298.689 159.376C298.683 159.379 298.676 159.38 298.669 159.38C298.662 159.38 298.655 159.379 298.648 159.376C298.642 159.373 298.636 159.369 298.632 159.363L297.643 158.691C297.635 158.682 297.632 158.67 297.631 158.658C297.631 158.646 297.635 158.634 297.642 158.625C297.649 158.615 297.659 158.608 297.67 158.604C297.681 158.6 297.693 158.6 297.705 158.604L297.724 158.617Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M301.01 160.846L302.005 161.512C302.013 161.522 302.017 161.534 302.017 161.547C302.017 161.559 302.013 161.571 302.005 161.581C301.995 161.589 301.982 161.593 301.968 161.593C301.955 161.593 301.941 161.589 301.931 161.581L300.942 160.915C300.937 160.91 300.933 160.904 300.93 160.898C300.927 160.892 300.925 160.885 300.925 160.878C300.925 160.871 300.927 160.863 300.93 160.857C300.933 160.851 300.937 160.845 300.942 160.84C300.947 160.835 300.952 160.832 300.958 160.829C300.964 160.827 300.971 160.826 300.978 160.827C300.984 160.827 300.991 160.829 300.996 160.833C301.002 160.836 301.007 160.841 301.01 160.846Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M304.316 163.052L305.311 163.712C305.319 163.721 305.324 163.733 305.324 163.746C305.324 163.759 305.319 163.771 305.311 163.78C305.306 163.785 305.3 163.789 305.294 163.792C305.287 163.795 305.28 163.797 305.273 163.797C305.266 163.797 305.259 163.795 305.253 163.792C305.246 163.789 305.241 163.785 305.236 163.78L304.241 163.127C304.234 163.115 304.23 163.102 304.23 163.089C304.23 163.076 304.234 163.063 304.241 163.052C304.251 163.044 304.263 163.04 304.275 163.04C304.288 163.04 304.299 163.044 304.309 163.052H304.316Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M307.645 165.232L308.64 165.855C308.647 165.864 308.651 165.876 308.651 165.889C308.651 165.901 308.647 165.913 308.64 165.923C308.636 165.928 308.63 165.932 308.624 165.935C308.618 165.938 308.612 165.94 308.606 165.94C308.599 165.94 308.592 165.938 308.586 165.935C308.581 165.932 308.575 165.928 308.571 165.923L307.57 165.301C307.565 165.297 307.561 165.293 307.558 165.287C307.555 165.282 307.553 165.276 307.553 165.27C307.553 165.263 307.555 165.257 307.558 165.252C307.561 165.246 307.565 165.242 307.57 165.239C307.574 165.234 307.579 165.231 307.584 165.229C307.59 165.227 307.595 165.225 307.601 165.225C307.607 165.225 307.613 165.227 307.618 165.229C307.623 165.231 307.628 165.234 307.632 165.239L307.645 165.232Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M310.969 167.387L311.976 168.01C311.982 168.013 311.986 168.018 311.989 168.024C311.991 168.029 311.993 168.035 311.993 168.041C311.993 168.047 311.991 168.053 311.989 168.059C311.986 168.064 311.982 168.069 311.976 168.072C311.972 168.076 311.967 168.08 311.962 168.082C311.957 168.084 311.951 168.085 311.945 168.085C311.939 168.085 311.934 168.084 311.928 168.082C311.923 168.08 311.918 168.076 311.914 168.072L310.907 167.45C310.9 167.441 310.896 167.43 310.896 167.419C310.896 167.407 310.9 167.396 310.907 167.387C310.91 167.382 310.915 167.378 310.92 167.375C310.925 167.372 310.932 167.371 310.938 167.371C310.944 167.371 310.95 167.372 310.956 167.375C310.961 167.378 310.966 167.382 310.969 167.387Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M314.342 169.524L315.356 170.147C315.363 170.155 315.367 170.166 315.367 170.178C315.367 170.189 315.363 170.2 315.356 170.209C315.348 170.216 315.338 170.219 315.328 170.219C315.318 170.219 315.308 170.216 315.3 170.209L314.287 169.587C314.28 169.578 314.276 169.567 314.276 169.555C314.276 169.544 314.28 169.533 314.287 169.524C314.29 169.52 314.294 169.517 314.299 169.515C314.304 169.513 314.309 169.511 314.315 169.511C314.32 169.511 314.325 169.513 314.33 169.515C314.335 169.517 314.339 169.52 314.342 169.524Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M317.728 171.58L318.748 172.202C318.755 172.211 318.759 172.222 318.759 172.233C318.759 172.245 318.755 172.256 318.748 172.264C318.74 172.271 318.73 172.275 318.72 172.275C318.71 172.275 318.7 172.271 318.692 172.264L317.672 171.642C317.666 171.634 317.662 171.624 317.662 171.614C317.662 171.604 317.666 171.594 317.672 171.586C317.679 171.579 317.689 171.574 317.699 171.573C317.709 171.572 317.72 171.574 317.728 171.58Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M321.133 173.623L322.159 174.246C322.165 174.253 322.169 174.263 322.169 174.274C322.169 174.284 322.165 174.294 322.159 174.302C322.156 174.305 322.152 174.308 322.148 174.311C322.143 174.313 322.139 174.314 322.134 174.314C322.129 174.314 322.124 174.313 322.12 174.311C322.116 174.308 322.112 174.305 322.109 174.302L321.077 173.679C321.072 173.672 321.069 173.664 321.068 173.655C321.068 173.646 321.07 173.638 321.075 173.63C321.08 173.623 321.087 173.617 321.095 173.614C321.103 173.61 321.112 173.609 321.12 173.611L321.133 173.623Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M324.563 175.623L325.595 176.208C325.601 176.215 325.604 176.224 325.604 176.233C325.604 176.242 325.601 176.251 325.595 176.258C325.588 176.264 325.579 176.267 325.57 176.267C325.561 176.267 325.552 176.264 325.545 176.258L324.513 175.666C324.509 175.663 324.506 175.66 324.504 175.655C324.502 175.651 324.501 175.646 324.501 175.641C324.501 175.637 324.502 175.632 324.504 175.628C324.506 175.623 324.509 175.619 324.513 175.617C324.516 175.613 324.52 175.61 324.525 175.609C324.53 175.607 324.535 175.607 324.54 175.607C324.544 175.608 324.549 175.61 324.553 175.612C324.557 175.615 324.56 175.619 324.563 175.623Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M328.017 177.573L329.061 178.145C329.066 178.153 329.068 178.161 329.068 178.17C329.068 178.179 329.066 178.188 329.061 178.195C329.059 178.199 329.055 178.202 329.05 178.204C329.046 178.206 329.041 178.208 329.037 178.208C329.032 178.208 329.027 178.206 329.023 178.204C329.019 178.202 329.015 178.199 329.012 178.195L327.973 177.623C327.968 177.615 327.966 177.607 327.966 177.598C327.966 177.589 327.968 177.58 327.973 177.573C327.976 177.569 327.98 177.566 327.984 177.564C327.988 177.561 327.993 177.56 327.998 177.56C328.003 177.56 328.008 177.561 328.012 177.564C328.016 177.566 328.02 177.569 328.023 177.573H328.017Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M331.508 179.491L332.553 180.052C332.559 180.057 332.562 180.065 332.562 180.073C332.562 180.082 332.559 180.089 332.553 180.095C332.55 180.099 332.546 180.102 332.542 180.104C332.538 180.107 332.533 180.108 332.528 180.108C332.523 180.108 332.519 180.107 332.514 180.104C332.51 180.102 332.506 180.099 332.503 180.095L331.459 179.535C331.453 179.529 331.45 179.521 331.45 179.513C331.45 179.505 331.453 179.497 331.459 179.491C331.461 179.488 331.465 179.485 331.469 179.482C331.474 179.48 331.479 179.479 331.483 179.479C331.488 179.479 331.493 179.48 331.497 179.482C331.502 179.485 331.505 179.488 331.508 179.491Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M335.013 181.323L336.07 181.864C336.074 181.867 336.077 181.869 336.079 181.872C336.082 181.876 336.084 181.88 336.085 181.884C336.086 181.888 336.087 181.892 336.086 181.896C336.086 181.9 336.085 181.904 336.083 181.908C336.08 181.912 336.078 181.915 336.074 181.918C336.071 181.92 336.067 181.922 336.063 181.923C336.059 181.924 336.055 181.925 336.051 181.924C336.047 181.924 336.042 181.922 336.039 181.92L334.982 181.379C334.978 181.372 334.976 181.365 334.976 181.357C334.976 181.35 334.978 181.342 334.982 181.335L335.013 181.323Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M345.731 186.512L346.819 186.997V187.041L345.731 186.562V186.524V186.512Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M349.373 188.1L350.473 188.555V188.599L349.373 188.144C349.373 188.144 349.373 188.144 349.373 188.113V188.1Z"
      fill={determineColor('--primary-300')}
    />
    <path d="M353.05 189.589L354.163 190.019L353.05 189.596V189.565V189.589Z" fill={determineColor('--primary-300')} />
    <path
      d="M356.772 190.979L357.898 191.371L356.772 190.979C356.772 190.979 356.766 190.972 356.772 190.979Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M360.538 192.243L361.676 192.598L360.538 192.249C360.538 192.249 360.532 192.243 360.538 192.243Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M364.347 193.377L365.492 193.688L364.341 193.377C364.341 193.377 364.335 193.377 364.347 193.377Z"
      fill={determineColor('--primary-300')}
    />
    <path d="M368.194 194.361L369.351 194.623L368.188 194.361H368.194Z" fill={determineColor('--primary-300')} />
    <path d="M372.078 195.177L373.253 195.389L372.078 195.177Z" fill={determineColor('--primary-300')} />
    <path
      d="M376.006 195.813L377.181 195.962L376 195.813C376 195.813 376 195.813 376.006 195.813Z"
      fill={determineColor('--primary-300')}
    />
    <path d="M379.951 196.236L381.133 196.323L379.951 196.236Z" fill={determineColor('--primary-300')} />
    <path
      d="M383.916 196.448L385.104 196.467V196.473L383.916 196.454L383.91 196.448H383.916Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M389.299 196.127L388.113 196.184L388.113 196.19L389.299 196.134L389.299 196.127Z"
      fill={determineColor('--primary-300')}
    />
    <path d="M391.846 196.137L393.028 196L391.846 196.137Z" fill={determineColor('--primary-300')} />
    <path d="M395.774 195.563L396.943 195.327L395.774 195.557V195.563Z" fill={determineColor('--primary-300')} />
    <path
      d="M403.436 193.52L404.555 193.115C404.558 193.111 404.562 193.108 404.566 193.106C404.57 193.104 404.575 193.103 404.58 193.103C404.585 193.103 404.59 193.104 404.594 193.106C404.598 193.108 404.602 193.111 404.605 193.115C404.611 193.122 404.614 193.131 404.614 193.14C404.614 193.149 404.611 193.158 404.605 193.165L403.479 193.563C403.471 193.563 403.463 193.56 403.457 193.554C403.452 193.548 403.448 193.541 403.448 193.532C403.448 193.524 403.452 193.516 403.457 193.51C403.463 193.505 403.471 193.501 403.479 193.501L403.436 193.52Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M407.133 192.068L408.215 191.577C408.225 191.569 408.237 191.565 408.249 191.565C408.261 191.565 408.273 191.569 408.283 191.577C408.292 191.586 408.297 191.598 408.297 191.611C408.297 191.624 408.292 191.636 408.283 191.645L407.195 192.131C407.186 192.136 407.175 192.139 407.164 192.139C407.153 192.139 407.142 192.136 407.133 192.131C407.126 192.122 407.122 192.111 407.122 192.1C407.122 192.088 407.126 192.077 407.133 192.068Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M410.705 190.337L411.744 189.764C411.756 189.754 411.771 189.748 411.787 189.748C411.803 189.748 411.818 189.754 411.831 189.764C411.839 189.776 411.844 189.79 411.844 189.804C411.844 189.819 411.839 189.833 411.831 189.845L410.78 190.417C410.769 190.426 410.756 190.43 410.742 190.43C410.729 190.43 410.716 190.426 410.705 190.417C410.696 190.406 410.69 190.392 410.69 190.377C410.69 190.362 410.696 190.348 410.705 190.337Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M414.117 188.318L415.105 187.658C415.123 187.641 415.146 187.631 415.171 187.631C415.195 187.631 415.218 187.641 415.236 187.658C415.251 187.675 415.26 187.697 415.26 187.72C415.26 187.743 415.251 187.766 415.236 187.783L414.235 188.405C414.217 188.417 414.194 188.42 414.173 188.416C414.152 188.411 414.134 188.398 414.123 188.38C414.111 188.362 414.107 188.34 414.112 188.319C414.117 188.298 414.129 188.28 414.148 188.268L414.117 188.318Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M417.34 186.019L418.273 185.272C418.286 185.26 418.3 185.251 418.317 185.245C418.333 185.239 418.35 185.236 418.368 185.237C418.385 185.238 418.402 185.243 418.417 185.251C418.432 185.259 418.446 185.27 418.457 185.283C418.468 185.297 418.476 185.313 418.48 185.329C418.485 185.346 418.486 185.363 418.483 185.38C418.48 185.398 418.474 185.414 418.465 185.429C418.455 185.443 418.443 185.456 418.429 185.465L417.483 186.193C417.461 186.214 417.433 186.226 417.403 186.226C417.373 186.226 417.344 186.214 417.322 186.193C417.311 186.181 417.303 186.167 417.298 186.151C417.293 186.135 417.291 186.119 417.292 186.102C417.294 186.086 417.299 186.07 417.308 186.056C417.316 186.041 417.327 186.029 417.34 186.019Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M420.353 183.44L421.204 182.612C421.236 182.584 421.277 182.569 421.32 182.569C421.362 182.569 421.403 182.584 421.435 182.612C421.45 182.627 421.462 182.644 421.47 182.664C421.478 182.683 421.482 182.703 421.482 182.724C421.482 182.745 421.478 182.766 421.47 182.785C421.462 182.804 421.45 182.822 421.435 182.837L420.564 183.645C420.535 183.673 420.497 183.687 420.457 183.686C420.417 183.685 420.38 183.668 420.353 183.639C420.326 183.611 420.311 183.572 420.312 183.532C420.313 183.492 420.33 183.455 420.359 183.428L420.353 183.44Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M423.104 180.599L423.874 179.691C423.892 179.673 423.913 179.659 423.936 179.649C423.959 179.639 423.983 179.634 424.008 179.634C424.033 179.634 424.058 179.639 424.081 179.649C424.104 179.659 424.125 179.673 424.142 179.691C424.16 179.709 424.174 179.73 424.184 179.754C424.194 179.777 424.199 179.802 424.199 179.828C424.199 179.853 424.194 179.878 424.184 179.902C424.174 179.925 424.16 179.947 424.142 179.964L423.352 180.861C423.32 180.897 423.275 180.919 423.227 180.922C423.178 180.925 423.13 180.909 423.094 180.876C423.058 180.844 423.036 180.799 423.033 180.75C423.03 180.702 423.046 180.654 423.079 180.618L423.104 180.599Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M425.544 177.497L426.209 176.507C426.249 176.462 426.304 176.432 426.363 176.424C426.423 176.416 426.484 176.431 426.534 176.464C426.584 176.498 426.621 176.548 426.636 176.607C426.652 176.665 426.646 176.727 426.619 176.781L425.923 177.752C425.887 177.8 425.834 177.832 425.774 177.842C425.715 177.851 425.655 177.837 425.606 177.802C425.558 177.769 425.525 177.719 425.513 177.662C425.502 177.605 425.513 177.546 425.544 177.497Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M427.629 174.147L428.183 173.094C428.226 173.034 428.289 172.991 428.36 172.974C428.432 172.957 428.507 172.967 428.573 173.001C428.638 173.035 428.688 173.092 428.715 173.16C428.742 173.229 428.743 173.305 428.718 173.374L428.133 174.415C428.118 174.452 428.095 174.486 428.066 174.515C428.037 174.543 428.002 174.564 427.964 174.578C427.926 174.592 427.885 174.597 427.845 174.594C427.804 174.591 427.765 174.579 427.729 174.559C427.694 174.54 427.663 174.513 427.638 174.481C427.614 174.448 427.597 174.411 427.588 174.371C427.579 174.331 427.579 174.29 427.587 174.251C427.595 174.211 427.612 174.173 427.635 174.14L427.629 174.147Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M429.322 170.596L429.745 169.481C429.76 169.433 429.784 169.388 429.817 169.35C429.85 169.312 429.89 169.281 429.935 169.258C429.98 169.236 430.029 169.224 430.079 169.222C430.13 169.219 430.18 169.228 430.227 169.246C430.274 169.264 430.316 169.291 430.352 169.326C430.388 169.361 430.417 169.404 430.436 169.45C430.455 169.497 430.464 169.546 430.463 169.597C430.462 169.647 430.451 169.697 430.43 169.742L429.963 170.839C429.927 170.922 429.86 170.988 429.776 171.022C429.692 171.056 429.598 171.055 429.515 171.019C429.434 170.987 429.369 170.925 429.333 170.846C429.297 170.767 429.293 170.678 429.322 170.596Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M299.429 129.87L300.025 130.568C300.108 130.674 300.146 130.808 300.132 130.941C300.119 131.075 300.054 131.198 299.952 131.285C299.849 131.372 299.718 131.416 299.584 131.408C299.45 131.4 299.324 131.34 299.233 131.242L298.645 130.544C298.597 130.493 298.559 130.433 298.535 130.367C298.51 130.301 298.499 130.231 298.502 130.161C298.506 130.091 298.524 130.022 298.555 129.959C298.586 129.896 298.629 129.84 298.683 129.794C298.736 129.748 298.798 129.714 298.865 129.693C298.932 129.673 299.003 129.666 299.073 129.674C299.143 129.681 299.211 129.703 299.272 129.737C299.333 129.772 299.386 129.819 299.429 129.875V129.87Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M301.377 132.255L301.926 132.986C302.011 133.098 302.048 133.24 302.028 133.379C302.009 133.518 301.936 133.644 301.823 133.729C301.711 133.814 301.57 133.851 301.431 133.832C301.292 133.812 301.166 133.738 301.081 133.626L300.537 132.895C300.464 132.783 300.437 132.647 300.461 132.515C300.485 132.383 300.557 132.265 300.664 132.185C300.771 132.104 300.905 132.067 301.038 132.081C301.171 132.095 301.294 132.159 301.382 132.26L301.377 132.255Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M303.187 134.74L303.707 135.49C303.788 135.609 303.818 135.755 303.791 135.897C303.764 136.038 303.682 136.163 303.564 136.245C303.446 136.326 303.301 136.357 303.16 136.331C303.02 136.305 302.895 136.224 302.814 136.106L302.303 135.351C302.256 135.293 302.222 135.226 302.202 135.154C302.182 135.082 302.177 135.007 302.188 134.933C302.199 134.859 302.225 134.788 302.264 134.725C302.304 134.662 302.356 134.607 302.418 134.565C302.479 134.523 302.549 134.494 302.622 134.48C302.696 134.466 302.771 134.468 302.844 134.485C302.916 134.502 302.985 134.533 303.045 134.578C303.105 134.623 303.154 134.68 303.191 134.744L303.187 134.74Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M304.891 137.292L305.369 138.066C305.447 138.192 305.472 138.345 305.438 138.49C305.404 138.635 305.314 138.761 305.187 138.84C305.061 138.918 304.908 138.943 304.763 138.909C304.618 138.875 304.492 138.785 304.414 138.658L303.936 137.879C303.859 137.756 303.834 137.606 303.867 137.464C303.899 137.322 303.987 137.199 304.111 137.122C304.234 137.045 304.383 137.019 304.525 137.052C304.667 137.085 304.79 137.173 304.867 137.296L304.891 137.292Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M306.481 139.91L306.958 140.703C307.012 140.766 307.052 140.84 307.074 140.919C307.096 140.998 307.101 141.082 307.088 141.163C307.074 141.245 307.043 141.322 306.997 141.391C306.95 141.459 306.889 141.516 306.818 141.558C306.747 141.6 306.668 141.626 306.585 141.633C306.503 141.641 306.421 141.631 306.343 141.603C306.265 141.576 306.194 141.531 306.136 141.473C306.077 141.415 306.032 141.345 306.003 141.267L305.554 140.474C305.503 140.349 305.499 140.21 305.542 140.083C305.585 139.955 305.673 139.847 305.789 139.779C305.905 139.711 306.042 139.688 306.175 139.712C306.307 139.737 306.426 139.809 306.509 139.915L306.481 139.91Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M307.995 142.586L308.42 143.399C308.455 143.465 308.476 143.539 308.483 143.614C308.49 143.689 308.482 143.765 308.46 143.837C308.437 143.909 308.401 143.976 308.353 144.034C308.304 144.092 308.245 144.14 308.179 144.175C308.112 144.21 308.039 144.232 307.964 144.239C307.888 144.246 307.813 144.238 307.74 144.215C307.668 144.193 307.602 144.156 307.544 144.108C307.486 144.06 307.438 144.001 307.403 143.934L306.982 143.121C306.947 143.055 306.926 142.981 306.919 142.906C306.912 142.831 306.92 142.755 306.942 142.683C306.965 142.611 307.001 142.544 307.049 142.486C307.097 142.428 307.157 142.38 307.224 142.345C307.29 142.31 307.363 142.288 307.439 142.281C307.514 142.274 307.589 142.282 307.661 142.305C307.733 142.327 307.8 142.364 307.858 142.412C307.916 142.46 307.964 142.519 307.999 142.586H307.995Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M309.388 145.329L309.78 146.156C309.836 146.295 309.837 146.45 309.782 146.59C309.728 146.729 309.622 146.843 309.486 146.907C309.351 146.971 309.196 146.981 309.054 146.935C308.911 146.888 308.792 146.789 308.72 146.657L308.338 145.831C308.297 145.76 308.27 145.682 308.261 145.6C308.252 145.519 308.26 145.437 308.284 145.359C308.309 145.281 308.35 145.209 308.404 145.147C308.459 145.086 308.525 145.037 308.6 145.003C308.674 144.97 308.755 144.952 308.837 144.952C308.919 144.951 309 144.968 309.074 145.001C309.149 145.034 309.217 145.082 309.271 145.143C309.326 145.204 309.368 145.275 309.393 145.353L309.388 145.329Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M310.659 148.129L311.012 148.975C311.044 149.048 311.06 149.126 311.062 149.206C311.063 149.285 311.048 149.364 311.019 149.437C310.99 149.511 310.946 149.578 310.891 149.635C310.836 149.692 310.77 149.737 310.697 149.769C310.624 149.8 310.546 149.817 310.467 149.818C310.388 149.819 310.309 149.804 310.236 149.775C310.162 149.746 310.095 149.702 310.038 149.647C309.981 149.592 309.936 149.526 309.904 149.453L309.561 148.607C309.501 148.462 309.501 148.298 309.561 148.152C309.622 148.007 309.737 147.891 309.883 147.831C310.029 147.771 310.192 147.771 310.338 147.831C310.483 147.892 310.599 148.008 310.659 148.153V148.129Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M311.785 151.001L312.086 151.866C312.125 152.015 312.107 152.173 312.035 152.309C311.962 152.445 311.842 152.549 311.696 152.599C311.551 152.65 311.392 152.644 311.251 152.583C311.11 152.521 310.997 152.409 310.936 152.268L310.644 151.398C310.602 151.249 310.619 151.089 310.691 150.951C310.763 150.814 310.885 150.709 311.032 150.659C311.179 150.609 311.339 150.617 311.48 150.682C311.621 150.746 311.732 150.862 311.79 151.006L311.785 151.001Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M312.726 153.954L312.955 154.838C312.997 154.998 312.974 155.168 312.891 155.31C312.807 155.453 312.671 155.556 312.511 155.598C312.352 155.64 312.182 155.617 312.04 155.534C311.898 155.45 311.794 155.314 311.752 155.154L311.528 154.265C311.503 154.112 311.536 153.954 311.621 153.824C311.706 153.694 311.837 153.601 311.987 153.562C312.138 153.524 312.297 153.543 312.434 153.617C312.571 153.69 312.675 153.812 312.726 153.959V153.954Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M313.4 156.999L313.528 157.907C313.552 158.073 313.509 158.241 313.408 158.375C313.307 158.509 313.157 158.598 312.991 158.621C312.825 158.644 312.657 158.601 312.523 158.5C312.389 158.399 312.301 158.249 312.278 158.083L312.153 157.176C312.142 157.016 312.191 156.857 312.292 156.732C312.393 156.608 312.537 156.526 312.696 156.504C312.855 156.482 313.016 156.522 313.146 156.614C313.277 156.707 313.368 156.846 313.4 157.004V156.999Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M313.676 160.119V161.037C313.676 161.208 313.608 161.372 313.488 161.493C313.367 161.614 313.203 161.682 313.032 161.682C312.861 161.682 312.697 161.614 312.576 161.493C312.455 161.372 312.387 161.208 312.387 161.037V160.119C312.387 159.949 312.455 159.786 312.575 159.666C312.695 159.546 312.857 159.479 313.027 159.479C313.197 159.479 313.359 159.546 313.479 159.666C313.599 159.786 313.667 159.949 313.667 160.119H313.676Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M313.566 163.239L313.466 164.147C313.462 164.237 313.439 164.325 313.4 164.406C313.361 164.487 313.305 164.558 313.237 164.617C313.169 164.675 313.089 164.719 313.004 164.746C312.918 164.773 312.828 164.782 312.739 164.772C312.649 164.763 312.563 164.735 312.485 164.691C312.406 164.647 312.338 164.587 312.284 164.516C312.229 164.444 312.19 164.363 312.168 164.275C312.147 164.188 312.143 164.097 312.158 164.009L312.263 163.101C312.282 162.928 312.37 162.77 312.506 162.661C312.642 162.553 312.816 162.503 312.988 162.523C313.161 162.542 313.319 162.63 313.428 162.766C313.536 162.902 313.586 163.076 313.566 163.249V163.239Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M313.113 166.326L312.912 167.22C312.897 167.31 312.863 167.395 312.814 167.472C312.764 167.548 312.699 167.614 312.624 167.665C312.548 167.716 312.463 167.75 312.373 167.767C312.284 167.784 312.192 167.783 312.103 167.763C312.014 167.743 311.93 167.706 311.856 167.653C311.782 167.599 311.719 167.532 311.672 167.454C311.625 167.376 311.595 167.289 311.582 167.199C311.569 167.108 311.575 167.017 311.599 166.929L311.804 166.035C311.824 165.949 311.86 165.867 311.911 165.795C311.962 165.724 312.026 165.662 312.101 165.615C312.175 165.568 312.258 165.536 312.345 165.522C312.432 165.507 312.521 165.509 312.607 165.528C312.692 165.548 312.774 165.584 312.845 165.635C312.917 165.686 312.979 165.75 313.026 165.825C313.073 165.899 313.104 165.983 313.119 166.069C313.134 166.156 313.132 166.245 313.113 166.331V166.326Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M312.315 169.346L312.024 170.211C311.965 170.384 311.84 170.527 311.676 170.608C311.512 170.688 311.323 170.701 311.15 170.642C310.977 170.583 310.835 170.457 310.755 170.293C310.674 170.129 310.662 169.94 310.721 169.767L311.021 168.902C311.083 168.732 311.209 168.592 311.373 168.514C311.536 168.436 311.724 168.426 311.895 168.486C312.064 168.545 312.204 168.668 312.283 168.83C312.361 168.991 312.373 169.177 312.315 169.346Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M311.189 172.261L310.798 173.088C310.762 173.177 310.708 173.258 310.64 173.325C310.572 173.393 310.491 173.446 310.402 173.481C310.312 173.516 310.217 173.532 310.121 173.529C310.025 173.525 309.931 173.502 309.845 173.461C309.758 173.42 309.681 173.361 309.618 173.289C309.555 173.217 309.507 173.132 309.478 173.041C309.449 172.95 309.438 172.853 309.448 172.758C309.457 172.662 309.486 172.57 309.532 172.486L309.929 171.664C310.014 171.507 310.156 171.389 310.326 171.333C310.496 171.278 310.681 171.291 310.842 171.368C311.004 171.445 311.129 171.582 311.192 171.749C311.256 171.916 311.253 172.101 311.184 172.266L311.189 172.261Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M309.733 175.033L309.255 175.807C309.15 175.959 308.991 176.064 308.811 176.101C308.631 176.138 308.443 176.104 308.287 176.006C308.131 175.908 308.018 175.754 307.973 175.576C307.927 175.397 307.952 175.208 308.042 175.048L308.52 174.273C308.625 174.124 308.784 174.021 308.964 173.986C309.143 173.95 309.329 173.986 309.483 174.084C309.637 174.182 309.748 174.336 309.792 174.514C309.835 174.692 309.809 174.879 309.718 175.038L309.733 175.033Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M307.956 177.614L307.383 178.321C307.326 178.404 307.253 178.475 307.167 178.528C307.082 178.581 306.986 178.617 306.886 178.631C306.786 178.646 306.684 178.639 306.587 178.613C306.49 178.586 306.399 178.539 306.321 178.476C306.242 178.412 306.178 178.333 306.132 178.243C306.085 178.154 306.058 178.055 306.052 177.955C306.045 177.854 306.06 177.753 306.094 177.658C306.128 177.563 306.182 177.476 306.252 177.403L306.834 176.701C306.956 176.553 307.131 176.459 307.322 176.44C307.513 176.421 307.703 176.479 307.851 176.6C308 176.722 308.093 176.898 308.112 177.089C308.131 177.28 308.073 177.47 307.952 177.618L307.956 177.614Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M305.875 179.965L305.216 180.6C305.071 180.721 304.885 180.781 304.697 180.77C304.509 180.759 304.333 180.677 304.202 180.541C304.072 180.405 303.999 180.224 303.996 180.036C303.994 179.847 304.063 179.665 304.189 179.525L304.858 178.899C305 178.765 305.189 178.692 305.384 178.698C305.579 178.703 305.764 178.786 305.899 178.928C306.033 179.07 306.105 179.259 306.1 179.455C306.095 179.65 306.012 179.835 305.87 179.969L305.875 179.965Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M303.516 182.029L302.781 182.574C302.701 182.633 302.61 182.675 302.514 182.699C302.417 182.723 302.317 182.728 302.219 182.713C302.121 182.698 302.026 182.664 301.941 182.613C301.856 182.561 301.782 182.494 301.723 182.414C301.664 182.334 301.622 182.243 301.598 182.146C301.574 182.05 301.569 181.95 301.584 181.851C301.599 181.753 301.633 181.659 301.684 181.573C301.736 181.488 301.803 181.414 301.883 181.355L302.623 180.815C302.784 180.697 302.985 180.648 303.182 180.679C303.379 180.709 303.556 180.817 303.674 180.978C303.792 181.139 303.841 181.34 303.81 181.537C303.78 181.734 303.672 181.911 303.511 182.029H303.516Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M300.909 183.788L300.116 184.237C300.029 184.286 299.934 184.318 299.835 184.33C299.736 184.342 299.635 184.335 299.539 184.308C299.443 184.282 299.353 184.236 299.274 184.175C299.195 184.113 299.129 184.037 299.08 183.95C299.031 183.863 298.999 183.767 298.987 183.668C298.975 183.569 298.982 183.469 299.009 183.372C299.035 183.276 299.081 183.186 299.142 183.107C299.204 183.028 299.28 182.962 299.367 182.913L300.164 182.469C300.251 182.417 300.347 182.383 300.448 182.369C300.548 182.355 300.65 182.361 300.748 182.387C300.846 182.413 300.937 182.459 301.017 182.521C301.097 182.583 301.164 182.66 301.214 182.749C301.264 182.837 301.295 182.934 301.307 183.035C301.318 183.136 301.309 183.238 301.281 183.335C301.253 183.432 301.205 183.523 301.141 183.601C301.077 183.68 300.998 183.745 300.909 183.792V183.788Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M298.125 185.221L297.285 185.58C297.193 185.619 297.093 185.64 296.993 185.641C296.892 185.642 296.792 185.624 296.699 185.586C296.606 185.549 296.521 185.493 296.449 185.423C296.377 185.352 296.319 185.268 296.28 185.176C296.24 185.083 296.22 184.984 296.219 184.883C296.217 184.783 296.236 184.683 296.274 184.589C296.311 184.496 296.366 184.411 296.437 184.339C296.507 184.267 296.591 184.209 296.683 184.17L297.529 183.811C297.716 183.734 297.927 183.733 298.114 183.81C298.302 183.887 298.452 184.035 298.531 184.223C298.57 184.315 298.591 184.414 298.592 184.514C298.593 184.614 298.574 184.714 298.536 184.806C298.498 184.899 298.442 184.984 298.372 185.055C298.301 185.126 298.218 185.183 298.125 185.221Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M295.213 186.33L294.339 186.602C294.242 186.634 294.14 186.646 294.038 186.638C293.937 186.629 293.838 186.601 293.747 186.555C293.657 186.509 293.576 186.445 293.51 186.367C293.444 186.29 293.394 186.2 293.362 186.103C293.331 186.006 293.319 185.904 293.327 185.802C293.335 185.701 293.363 185.602 293.41 185.511C293.456 185.42 293.52 185.339 293.597 185.273C293.675 185.207 293.764 185.157 293.861 185.126L294.735 184.853C294.931 184.79 295.144 184.807 295.327 184.901C295.51 184.994 295.648 185.157 295.711 185.353C295.775 185.548 295.758 185.762 295.664 185.945C295.571 186.128 295.408 186.266 295.213 186.33Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M292.21 187.161L291.317 187.357C291.115 187.401 290.904 187.362 290.73 187.25C290.557 187.138 290.434 186.962 290.391 186.76C290.347 186.558 290.385 186.346 290.497 186.173C290.609 185.999 290.785 185.876 290.987 185.833L291.88 185.642C291.983 185.613 292.09 185.605 292.195 185.619C292.3 185.634 292.401 185.669 292.492 185.724C292.583 185.779 292.662 185.851 292.724 185.938C292.786 186.024 292.829 186.122 292.852 186.226C292.874 186.33 292.875 186.437 292.855 186.542C292.834 186.646 292.793 186.745 292.733 186.832C292.673 186.92 292.595 186.994 292.505 187.051C292.415 187.107 292.315 187.145 292.21 187.161Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M289.159 187.735L288.251 187.859C288.046 187.888 287.837 187.833 287.671 187.708C287.506 187.582 287.397 187.396 287.368 187.19C287.34 186.984 287.394 186.775 287.519 186.61C287.645 186.444 287.831 186.334 288.037 186.306L288.944 186.186C289.148 186.162 289.354 186.219 289.516 186.344C289.679 186.47 289.786 186.655 289.813 186.859C289.841 187.063 289.787 187.27 289.664 187.435C289.541 187.6 289.357 187.709 289.154 187.74L289.159 187.735Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M286.079 188.088L285.167 188.155C284.962 188.162 284.762 188.089 284.609 187.952C284.457 187.814 284.364 187.623 284.349 187.417C284.335 187.212 284.401 187.01 284.533 186.852C284.665 186.695 284.853 186.595 285.057 186.574L285.969 186.511C286.178 186.497 286.384 186.566 286.542 186.703C286.7 186.841 286.798 187.036 286.812 187.245C286.827 187.454 286.758 187.66 286.62 187.819C286.483 187.977 286.288 188.074 286.079 188.088Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M282.99 188.236H282.078C281.878 188.224 281.689 188.136 281.55 187.991C281.411 187.846 281.332 187.653 281.329 187.452C281.326 187.251 281.399 187.056 281.534 186.906C281.668 186.757 281.854 186.663 282.054 186.645H282.966C283.166 186.661 283.354 186.751 283.49 186.899C283.626 187.046 283.702 187.24 283.702 187.441C283.702 187.642 283.626 187.835 283.49 187.983C283.354 188.13 283.166 188.221 282.966 188.236H282.99Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M279.906 188.222L278.994 188.189C278.791 188.167 278.603 188.07 278.468 187.915C278.334 187.761 278.264 187.561 278.271 187.357C278.278 187.152 278.363 186.958 278.508 186.814C278.653 186.669 278.847 186.585 279.051 186.578L279.968 186.612C280.181 186.62 280.381 186.713 280.526 186.869C280.671 187.025 280.747 187.233 280.739 187.446C280.731 187.658 280.638 187.859 280.482 188.004C280.326 188.149 280.119 188.226 279.906 188.217V188.222Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M276.826 188.06L275.919 187.988C275.705 187.972 275.506 187.871 275.367 187.708C275.227 187.545 275.158 187.333 275.174 187.118C275.191 186.904 275.292 186.705 275.454 186.565C275.617 186.426 275.829 186.357 276.043 186.373L276.955 186.449C277.061 186.458 277.164 186.488 277.258 186.537C277.352 186.586 277.436 186.653 277.504 186.734C277.572 186.815 277.624 186.909 277.656 187.01C277.688 187.112 277.7 187.218 277.69 187.324C277.683 187.429 277.654 187.532 277.606 187.626C277.558 187.72 277.492 187.804 277.412 187.873C277.332 187.941 277.238 187.993 277.138 188.025C277.037 188.057 276.931 188.069 276.826 188.06Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M273.765 187.778L272.858 187.673C272.655 187.635 272.473 187.522 272.349 187.355C272.226 187.189 272.171 186.981 272.194 186.775C272.218 186.57 272.319 186.381 272.477 186.246C272.635 186.112 272.838 186.043 273.045 186.053L273.952 186.163C274.058 186.175 274.16 186.209 274.253 186.261C274.346 186.313 274.428 186.383 274.494 186.467C274.56 186.551 274.609 186.647 274.638 186.75C274.667 186.852 274.676 186.96 274.663 187.066C274.65 187.172 274.617 187.275 274.565 187.368C274.513 187.461 274.443 187.543 274.359 187.609C274.275 187.675 274.179 187.724 274.077 187.753C273.974 187.782 273.867 187.79 273.761 187.778H273.765Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M270.72 187.391L269.813 187.257C269.702 187.246 269.595 187.212 269.498 187.158C269.401 187.105 269.316 187.032 269.248 186.944C269.18 186.857 269.13 186.756 269.102 186.649C269.073 186.541 269.067 186.429 269.083 186.319C269.099 186.21 269.138 186.104 269.196 186.01C269.254 185.915 269.33 185.833 269.421 185.769C269.511 185.705 269.614 185.659 269.722 185.636C269.83 185.612 269.942 185.611 270.051 185.632L270.954 185.766C271.161 185.806 271.346 185.925 271.468 186.097C271.59 186.27 271.641 186.483 271.611 186.693C271.58 186.902 271.469 187.091 271.303 187.222C271.136 187.352 270.925 187.412 270.715 187.391H270.72Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M267.687 186.913L266.785 186.755C266.569 186.717 266.377 186.595 266.251 186.415C266.125 186.235 266.076 186.013 266.114 185.797C266.152 185.581 266.274 185.389 266.454 185.263C266.633 185.137 266.855 185.087 267.071 185.125L267.974 185.288C268.177 185.338 268.354 185.464 268.469 185.639C268.583 185.815 268.627 186.028 268.59 186.235C268.554 186.441 268.44 186.626 268.272 186.752C268.105 186.878 267.895 186.936 267.687 186.913Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M264.675 186.363L263.777 186.186C263.56 186.145 263.369 186.018 263.246 185.835C263.122 185.653 263.076 185.428 263.118 185.212C263.16 184.995 263.286 184.804 263.469 184.68C263.651 184.556 263.876 184.51 264.092 184.552L264.99 184.729C265.205 184.774 265.395 184.902 265.517 185.085C265.639 185.268 265.685 185.492 265.644 185.709C265.601 185.923 265.475 186.113 265.294 186.235C265.112 186.358 264.89 186.404 264.675 186.363Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M261.676 185.766L260.778 185.575C260.571 185.519 260.393 185.386 260.28 185.203C260.168 185.02 260.13 184.801 260.174 184.59C260.218 184.38 260.34 184.194 260.517 184.072C260.693 183.949 260.91 183.899 261.122 183.931L262.015 184.122C262.223 184.178 262.401 184.311 262.513 184.494C262.626 184.677 262.664 184.896 262.62 185.107C262.576 185.317 262.453 185.503 262.277 185.625C262.1 185.748 261.884 185.798 261.671 185.766H261.676Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M258.683 185.121L257.79 184.92C257.678 184.9 257.571 184.857 257.477 184.794C257.382 184.731 257.3 184.649 257.238 184.554C257.175 184.459 257.132 184.352 257.111 184.24C257.091 184.128 257.093 184.013 257.118 183.902C257.144 183.791 257.191 183.686 257.258 183.593C257.324 183.501 257.409 183.423 257.506 183.364C257.604 183.305 257.712 183.267 257.825 183.251C257.938 183.235 258.052 183.242 258.162 183.272L259.051 183.477C259.158 183.502 259.26 183.547 259.351 183.611C259.441 183.675 259.518 183.756 259.577 183.85C259.636 183.943 259.676 184.048 259.694 184.157C259.713 184.266 259.71 184.378 259.686 184.485C259.661 184.593 259.616 184.695 259.552 184.786C259.488 184.876 259.407 184.953 259.313 185.012C259.22 185.071 259.116 185.111 259.007 185.13C258.898 185.148 258.786 185.146 258.678 185.121H258.683Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M255.703 184.433L254.815 184.222C254.595 184.17 254.406 184.032 254.287 183.839C254.169 183.647 254.132 183.415 254.185 183.195C254.238 182.975 254.375 182.785 254.568 182.667C254.76 182.549 254.992 182.512 255.212 182.564L256.095 182.784C256.203 182.81 256.305 182.857 256.395 182.923C256.485 182.988 256.561 183.071 256.619 183.166C256.677 183.261 256.716 183.366 256.733 183.476C256.751 183.586 256.746 183.698 256.72 183.807C256.694 183.915 256.647 184.017 256.582 184.107C256.516 184.197 256.434 184.273 256.339 184.331C256.244 184.39 256.139 184.428 256.029 184.446C255.919 184.463 255.807 184.459 255.699 184.433H255.703Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M252.733 183.706L251.845 183.477C251.736 183.449 251.634 183.4 251.544 183.332C251.455 183.265 251.379 183.181 251.322 183.084C251.265 182.987 251.227 182.88 251.211 182.769C251.195 182.657 251.201 182.544 251.229 182.435C251.257 182.326 251.306 182.224 251.374 182.134C251.441 182.044 251.526 181.969 251.622 181.911C251.719 181.854 251.826 181.816 251.937 181.8C252.048 181.785 252.161 181.791 252.27 181.819L253.153 182.048C253.373 182.104 253.561 182.246 253.677 182.441C253.792 182.637 253.826 182.87 253.769 183.09C253.713 183.31 253.572 183.498 253.377 183.614C253.181 183.729 252.948 183.763 252.728 183.706H252.733Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M249.773 182.932L248.89 182.693C248.68 182.624 248.505 182.477 248.4 182.282C248.295 182.088 248.269 181.86 248.326 181.647C248.384 181.433 248.52 181.25 248.709 181.134C248.897 181.019 249.122 180.98 249.338 181.026L250.222 181.269C250.442 181.332 250.628 181.481 250.739 181.682C250.85 181.882 250.877 182.119 250.814 182.34C250.751 182.56 250.602 182.746 250.402 182.857C250.201 182.969 249.965 182.995 249.744 182.932H249.773Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M246.822 182.115L245.944 181.867C245.722 181.803 245.535 181.654 245.423 181.453C245.311 181.251 245.284 181.013 245.347 180.791C245.41 180.57 245.559 180.382 245.761 180.27C245.962 180.158 246.2 180.131 246.421 180.194L247.3 180.447C247.506 180.523 247.676 180.674 247.775 180.87C247.875 181.066 247.897 181.292 247.836 181.504C247.776 181.715 247.638 181.895 247.45 182.009C247.262 182.122 247.037 182.16 246.822 182.115Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M243.886 181.26L243.012 180.992C242.902 180.959 242.8 180.904 242.711 180.831C242.622 180.758 242.548 180.669 242.494 180.567C242.44 180.466 242.406 180.355 242.394 180.24C242.383 180.126 242.394 180.01 242.428 179.9C242.461 179.79 242.515 179.687 242.588 179.598C242.661 179.509 242.751 179.436 242.852 179.381C242.953 179.327 243.064 179.293 243.178 179.282C243.293 179.27 243.408 179.281 243.518 179.315L244.397 179.582C244.617 179.651 244.801 179.805 244.908 180.009C245.016 180.213 245.038 180.451 244.971 180.672C244.904 180.893 244.752 181.078 244.548 181.187C244.345 181.296 244.107 181.321 243.886 181.255V181.26Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M240.964 180.356L240.09 180.08C239.88 179.999 239.708 179.84 239.61 179.637C239.512 179.434 239.496 179.201 239.564 178.986C239.633 178.771 239.781 178.59 239.978 178.481C240.175 178.371 240.407 178.342 240.625 178.397L241.494 178.674C241.604 178.71 241.706 178.766 241.794 178.841C241.883 178.915 241.955 179.007 242.008 179.109C242.061 179.212 242.093 179.324 242.103 179.439C242.113 179.555 242.1 179.671 242.065 179.781C242.029 179.891 241.973 179.993 241.899 180.081C241.824 180.17 241.733 180.242 241.63 180.295C241.527 180.348 241.415 180.381 241.3 180.39C241.185 180.4 241.069 180.387 240.959 180.352L240.964 180.356Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M238.051 179.41L237.187 179.114C236.966 179.04 236.784 178.88 236.681 178.67C236.577 178.461 236.561 178.219 236.636 177.998C236.71 177.777 236.87 177.595 237.079 177.491C237.288 177.388 237.53 177.372 237.75 177.446L238.615 177.738C238.731 177.77 238.839 177.825 238.933 177.9C239.027 177.975 239.105 178.069 239.162 178.175C239.218 178.281 239.253 178.398 239.263 178.518C239.273 178.638 239.258 178.759 239.219 178.873C239.181 178.987 239.12 179.093 239.039 179.182C238.958 179.272 238.861 179.344 238.751 179.394C238.642 179.444 238.523 179.472 238.403 179.475C238.283 179.477 238.163 179.456 238.051 179.41Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M235.158 178.416L234.299 178.111C234.19 178.072 234.089 178.013 234.003 177.936C233.917 177.858 233.847 177.765 233.797 177.661C233.747 177.557 233.717 177.443 233.711 177.328C233.705 177.212 233.721 177.097 233.759 176.988C233.798 176.878 233.857 176.778 233.934 176.691C234.011 176.605 234.104 176.535 234.209 176.485C234.313 176.435 234.426 176.406 234.541 176.399C234.657 176.393 234.772 176.409 234.881 176.448L235.746 176.753C235.951 176.842 236.114 177.006 236.204 177.211C236.293 177.416 236.301 177.648 236.227 177.859C236.152 178.07 236 178.245 235.802 178.348C235.604 178.452 235.374 178.476 235.158 178.416Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M232.279 177.379L231.424 177.064C231.205 176.984 231.027 176.819 230.929 176.607C230.831 176.395 230.821 176.153 230.902 175.934C230.982 175.715 231.146 175.536 231.358 175.438C231.57 175.34 231.812 175.33 232.031 175.411L232.89 175.731C233 175.77 233.1 175.831 233.186 175.909C233.272 175.988 233.341 176.082 233.39 176.188C233.439 176.293 233.467 176.407 233.471 176.523C233.476 176.639 233.457 176.755 233.417 176.864C233.377 176.973 233.315 177.073 233.236 177.158C233.157 177.243 233.062 177.312 232.956 177.36C232.85 177.407 232.735 177.434 232.619 177.437C232.503 177.441 232.388 177.421 232.279 177.379Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M229.414 176.304L228.565 175.975C228.347 175.891 228.171 175.724 228.076 175.511C227.981 175.298 227.975 175.055 228.058 174.837C228.142 174.619 228.309 174.443 228.522 174.348C228.735 174.253 228.977 174.247 229.195 174.331L230.049 174.66C230.267 174.745 230.443 174.912 230.537 175.126C230.631 175.34 230.637 175.582 230.553 175.8C230.469 176.018 230.302 176.194 230.088 176.288C229.874 176.383 229.632 176.388 229.414 176.304Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M226.569 175.186L225.719 174.847C225.606 174.808 225.502 174.747 225.414 174.667C225.325 174.587 225.254 174.489 225.205 174.381C225.155 174.272 225.128 174.155 225.125 174.036C225.122 173.916 225.143 173.797 225.188 173.687C225.232 173.576 225.299 173.475 225.384 173.391C225.468 173.307 225.569 173.241 225.68 173.197C225.791 173.153 225.909 173.132 226.028 173.136C226.148 173.139 226.265 173.167 226.373 173.217L227.223 173.556C227.336 173.595 227.44 173.656 227.528 173.736C227.617 173.816 227.688 173.913 227.738 174.022C227.787 174.13 227.814 174.248 227.817 174.367C227.82 174.487 227.799 174.605 227.754 174.716C227.71 174.827 227.643 174.927 227.558 175.012C227.474 175.096 227.373 175.162 227.262 175.206C227.151 175.25 227.033 175.271 226.914 175.267C226.794 175.264 226.677 175.236 226.569 175.186Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M223.738 174.039L222.892 173.69C222.686 173.596 222.525 173.425 222.442 173.213C222.359 173.002 222.361 172.767 222.447 172.557C222.534 172.347 222.698 172.179 222.905 172.087C223.113 171.995 223.348 171.988 223.561 172.066L224.406 172.415C224.517 172.455 224.619 172.518 224.705 172.599C224.792 172.68 224.861 172.777 224.909 172.886C224.956 172.994 224.982 173.111 224.983 173.23C224.985 173.348 224.962 173.466 224.917 173.575C224.872 173.685 224.805 173.784 224.721 173.867C224.637 173.95 224.536 174.016 224.426 174.059C224.316 174.102 224.198 174.123 224.08 174.119C223.961 174.116 223.845 174.089 223.738 174.039Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M220.916 172.859L220.075 172.501C219.963 172.46 219.86 172.397 219.773 172.315C219.685 172.233 219.615 172.135 219.568 172.025C219.52 171.915 219.495 171.797 219.494 171.677C219.494 171.557 219.518 171.439 219.565 171.329C219.612 171.219 219.681 171.119 219.767 171.037C219.854 170.954 219.956 170.89 220.068 170.849C220.181 170.807 220.3 170.789 220.419 170.795C220.539 170.802 220.656 170.832 220.763 170.885L221.603 171.244C221.804 171.343 221.96 171.516 222.037 171.727C222.115 171.937 222.109 172.17 222.022 172.376C221.934 172.583 221.77 172.748 221.565 172.838C221.359 172.927 221.127 172.935 220.916 172.859Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M218.108 171.65L217.273 171.286C217.162 171.244 217.061 171.179 216.977 171.096C216.892 171.014 216.824 170.915 216.779 170.805C216.733 170.696 216.71 170.578 216.711 170.459C216.712 170.341 216.737 170.224 216.784 170.115C216.832 170.006 216.9 169.908 216.987 169.827C217.073 169.745 217.175 169.682 217.286 169.641C217.397 169.6 217.515 169.582 217.634 169.588C217.752 169.594 217.868 169.624 217.975 169.676L218.81 170.044C219.023 170.137 219.19 170.311 219.275 170.528C219.359 170.744 219.355 170.985 219.261 171.198C219.168 171.411 218.994 171.578 218.778 171.663C218.562 171.748 218.321 171.743 218.108 171.65Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M215.315 170.407L214.48 170.035C214.268 169.94 214.103 169.764 214.021 169.548C213.938 169.331 213.945 169.09 214.04 168.878C214.135 168.666 214.311 168.501 214.527 168.419C214.744 168.336 214.984 168.343 215.196 168.438L216.031 168.811C216.243 168.906 216.408 169.082 216.49 169.299C216.573 169.515 216.566 169.756 216.471 169.968C216.376 170.179 216.2 170.345 215.984 170.427C215.767 170.509 215.527 170.502 215.315 170.407Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M212.531 169.165L211.701 168.787C211.489 168.691 211.325 168.515 211.243 168.297C211.162 168.079 211.17 167.838 211.266 167.626C211.362 167.415 211.539 167.25 211.756 167.168C211.974 167.087 212.215 167.095 212.426 167.191L213.257 167.574C213.362 167.621 213.455 167.689 213.534 167.773C213.612 167.857 213.673 167.956 213.713 168.064C213.753 168.171 213.772 168.285 213.768 168.4C213.764 168.515 213.737 168.628 213.689 168.733C213.641 168.837 213.574 168.931 213.49 169.009C213.406 169.088 213.307 169.149 213.2 169.189C213.092 169.229 212.978 169.247 212.863 169.243C212.748 169.239 212.636 169.213 212.531 169.165Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M209.757 167.894L208.927 167.507C208.719 167.408 208.559 167.23 208.481 167.014C208.403 166.797 208.413 166.558 208.51 166.349C208.607 166.141 208.782 165.978 208.998 165.898C209.214 165.818 209.452 165.825 209.662 165.92L210.493 166.307C210.7 166.406 210.86 166.584 210.938 166.8C211.016 167.017 211.006 167.256 210.909 167.465C210.812 167.673 210.637 167.836 210.421 167.916C210.206 167.996 209.967 167.988 209.757 167.894Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M206.993 166.608L206.162 166.216C205.965 166.111 205.816 165.934 205.745 165.721C205.674 165.509 205.688 165.277 205.783 165.074C205.878 164.871 206.047 164.713 206.255 164.631C206.463 164.549 206.695 164.551 206.902 164.635L207.733 165.022C207.943 165.12 208.106 165.298 208.185 165.516C208.265 165.734 208.254 165.975 208.156 166.185C208.058 166.396 207.88 166.559 207.662 166.638C207.444 166.717 207.203 166.706 206.993 166.608Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M204.233 165.309L203.403 164.917C203.21 164.808 203.067 164.631 203 164.42C202.933 164.21 202.949 163.982 203.043 163.782C203.137 163.582 203.303 163.426 203.508 163.344C203.713 163.262 203.942 163.26 204.147 163.34L204.978 163.732C205.09 163.775 205.192 163.841 205.278 163.925C205.363 164.01 205.43 164.111 205.475 164.223C205.519 164.334 205.541 164.454 205.537 164.574C205.533 164.695 205.505 164.813 205.454 164.921C205.402 165.03 205.329 165.127 205.239 165.206C205.148 165.285 205.042 165.345 204.928 165.381C204.813 165.418 204.693 165.43 204.573 165.417C204.454 165.405 204.338 165.368 204.233 165.309Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M201.473 163.999L200.652 163.607C200.444 163.508 200.283 163.331 200.206 163.114C200.128 162.896 200.14 162.657 200.239 162.449C200.338 162.24 200.515 162.079 200.732 162.002C200.95 161.924 201.189 161.936 201.397 162.035L202.223 162.427C202.415 162.536 202.559 162.713 202.625 162.924C202.692 163.134 202.677 163.362 202.582 163.562C202.488 163.761 202.322 163.918 202.117 164C201.912 164.082 201.684 164.083 201.478 164.004L201.473 163.999Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M198.723 162.69L197.897 162.293C197.689 162.194 197.529 162.015 197.452 161.798C197.375 161.58 197.388 161.341 197.487 161.132C197.586 160.924 197.764 160.763 197.982 160.686C198.199 160.609 198.439 160.622 198.647 160.721L199.473 161.118C199.576 161.167 199.669 161.236 199.745 161.321C199.822 161.406 199.881 161.505 199.919 161.613C199.957 161.721 199.973 161.835 199.967 161.95C199.961 162.064 199.933 162.176 199.884 162.279C199.835 162.382 199.765 162.475 199.68 162.551C199.596 162.628 199.496 162.687 199.389 162.725C199.281 162.763 199.167 162.78 199.053 162.774C198.939 162.768 198.827 162.739 198.723 162.69Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M195.973 161.376L195.152 160.979C195.044 160.933 194.946 160.866 194.865 160.781C194.784 160.696 194.72 160.596 194.679 160.486C194.637 160.376 194.619 160.259 194.624 160.142C194.629 160.024 194.657 159.909 194.708 159.803C194.759 159.697 194.83 159.603 194.918 159.525C195.006 159.448 195.11 159.389 195.221 159.352C195.333 159.316 195.45 159.302 195.567 159.312C195.684 159.323 195.798 159.357 195.901 159.412L196.723 159.804C196.826 159.853 196.918 159.922 196.995 160.007C197.071 160.092 197.13 160.191 197.168 160.299C197.206 160.407 197.223 160.521 197.217 160.636C197.211 160.75 197.183 160.862 197.133 160.965C197.084 161.068 197.015 161.161 196.93 161.237C196.845 161.314 196.746 161.373 196.638 161.411C196.53 161.449 196.416 161.466 196.302 161.46C196.188 161.454 196.076 161.425 195.973 161.376Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M193.228 160.062L192.402 159.67C192.299 159.621 192.206 159.552 192.13 159.467C192.053 159.383 191.994 159.283 191.956 159.176C191.917 159.068 191.9 158.954 191.906 158.84C191.912 158.726 191.94 158.614 191.989 158.511C192.038 158.408 192.107 158.315 192.191 158.238C192.276 158.162 192.375 158.103 192.482 158.064C192.59 158.026 192.704 158.009 192.818 158.015C192.932 158.021 193.043 158.049 193.147 158.098L193.973 158.489C194.076 158.538 194.168 158.607 194.245 158.692C194.321 158.777 194.381 158.876 194.419 158.983C194.457 159.091 194.474 159.205 194.468 159.319C194.463 159.433 194.435 159.545 194.386 159.648C194.337 159.751 194.268 159.844 194.183 159.921C194.099 159.997 194 160.057 193.892 160.095C193.785 160.133 193.671 160.15 193.557 160.144C193.443 160.139 193.331 160.111 193.228 160.062Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M190.459 158.752L189.633 158.36C189.425 158.262 189.265 158.085 189.187 157.869C189.11 157.653 189.121 157.414 189.219 157.206C189.318 156.999 189.494 156.838 189.711 156.761C189.927 156.683 190.165 156.695 190.373 156.793L191.199 157.18C191.306 157.226 191.404 157.292 191.486 157.377C191.567 157.461 191.631 157.561 191.673 157.67C191.715 157.78 191.734 157.897 191.73 158.014C191.725 158.131 191.697 158.246 191.648 158.352C191.598 158.458 191.527 158.553 191.439 158.631C191.352 158.709 191.249 158.769 191.138 158.806C191.027 158.843 190.91 158.858 190.793 158.849C190.676 158.839 190.562 158.807 190.459 158.752Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M187.723 157.453L186.897 157.065C186.789 157.02 186.691 156.953 186.609 156.869C186.528 156.785 186.464 156.684 186.422 156.575C186.381 156.466 186.361 156.349 186.366 156.232C186.37 156.114 186.398 155.999 186.448 155.893C186.498 155.787 186.569 155.692 186.656 155.614C186.744 155.536 186.846 155.477 186.957 155.44C187.068 155.402 187.186 155.388 187.302 155.397C187.419 155.406 187.533 155.439 187.637 155.493L188.463 155.88C188.571 155.926 188.668 155.993 188.75 156.077C188.831 156.161 188.895 156.261 188.937 156.371C188.979 156.48 188.998 156.597 188.994 156.714C188.989 156.831 188.962 156.947 188.912 157.053C188.862 157.159 188.791 157.253 188.703 157.331C188.616 157.409 188.513 157.469 188.402 157.506C188.291 157.544 188.174 157.558 188.057 157.549C187.94 157.54 187.826 157.507 187.723 157.453Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M184.968 156.162L184.142 155.775C183.933 155.679 183.772 155.504 183.693 155.288C183.613 155.073 183.623 154.835 183.719 154.626C183.815 154.418 183.991 154.256 184.206 154.177C184.421 154.097 184.659 154.107 184.867 154.203L185.698 154.586C185.898 154.688 186.051 154.864 186.125 155.076C186.198 155.289 186.186 155.522 186.092 155.726C185.997 155.93 185.827 156.09 185.618 156.171C185.408 156.252 185.175 156.249 184.968 156.162Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M182.209 154.882L181.378 154.504C181.274 154.457 181.181 154.39 181.104 154.306C181.026 154.223 180.966 154.125 180.926 154.018C180.886 153.911 180.868 153.798 180.872 153.684C180.877 153.57 180.903 153.459 180.95 153.355C180.998 153.251 181.065 153.158 181.148 153.081C181.232 153.003 181.329 152.943 181.436 152.903C181.543 152.864 181.656 152.845 181.77 152.849C181.883 152.853 181.995 152.88 182.099 152.927L182.929 153.305C183.138 153.4 183.301 153.575 183.381 153.791C183.461 154.006 183.452 154.245 183.357 154.454C183.261 154.663 183.086 154.826 182.871 154.906C182.656 154.986 182.417 154.977 182.209 154.882Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M179.439 153.625L178.608 153.247C178.398 153.153 178.235 152.98 178.153 152.766C178.071 152.551 178.077 152.313 178.171 152.103C178.265 151.893 178.438 151.729 178.652 151.647C178.867 151.565 179.105 151.572 179.315 151.665L180.15 152.043C180.254 152.09 180.348 152.157 180.426 152.239C180.504 152.322 180.564 152.42 180.605 152.526C180.645 152.633 180.664 152.746 180.661 152.86C180.657 152.974 180.631 153.086 180.585 153.19C180.538 153.294 180.471 153.388 180.388 153.466C180.305 153.544 180.208 153.604 180.102 153.645C179.995 153.685 179.882 153.704 179.768 153.701C179.654 153.697 179.542 153.672 179.439 153.625Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M176.665 152.382L175.829 152.014C175.725 151.969 175.631 151.903 175.552 151.821C175.474 151.738 175.412 151.642 175.371 151.536C175.329 151.429 175.31 151.316 175.312 151.203C175.314 151.089 175.339 150.977 175.385 150.872C175.431 150.768 175.497 150.674 175.579 150.595C175.661 150.517 175.758 150.455 175.864 150.414C175.97 150.372 176.083 150.352 176.196 150.355C176.31 150.357 176.422 150.382 176.526 150.428L177.362 150.796C177.572 150.888 177.737 151.061 177.82 151.275C177.903 151.489 177.898 151.728 177.806 151.938C177.713 152.148 177.541 152.313 177.327 152.397C177.113 152.48 176.875 152.475 176.665 152.382Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M173.881 151.164L173.041 150.805C172.937 150.761 172.842 150.696 172.763 150.614C172.684 150.533 172.621 150.436 172.579 150.331C172.537 150.225 172.516 150.112 172.517 149.999C172.519 149.885 172.543 149.773 172.587 149.668C172.632 149.564 172.697 149.469 172.779 149.39C172.86 149.31 172.956 149.248 173.062 149.206C173.167 149.164 173.28 149.142 173.394 149.144C173.507 149.145 173.619 149.169 173.724 149.214L174.559 149.577C174.672 149.616 174.774 149.678 174.862 149.758C174.949 149.838 175.019 149.936 175.067 150.045C175.115 150.153 175.14 150.27 175.141 150.389C175.141 150.508 175.118 150.626 175.071 150.735C175.024 150.844 174.956 150.942 174.869 151.024C174.783 151.105 174.681 151.168 174.569 151.208C174.458 151.249 174.339 151.266 174.221 151.258C174.102 151.25 173.987 151.218 173.881 151.164Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M171.084 149.974L170.243 149.62C170.135 149.58 170.035 149.517 169.951 149.437C169.867 149.357 169.8 149.261 169.754 149.154C169.708 149.048 169.683 148.933 169.682 148.817C169.682 148.701 169.704 148.585 169.749 148.478C169.793 148.371 169.859 148.274 169.942 148.192C170.025 148.111 170.123 148.047 170.231 148.005C170.34 147.963 170.455 147.943 170.571 147.946C170.687 147.949 170.801 147.976 170.907 148.024L171.752 148.378C171.963 148.467 172.131 148.636 172.218 148.848C172.305 149.061 172.304 149.299 172.215 149.51C172.127 149.722 171.957 149.89 171.745 149.977C171.533 150.064 171.295 150.063 171.084 149.974Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M168.276 148.813L167.431 148.469C167.318 148.432 167.214 148.372 167.126 148.294C167.037 148.215 166.966 148.119 166.916 148.012C166.866 147.904 166.838 147.788 166.835 147.669C166.832 147.551 166.853 147.433 166.897 147.323C166.942 147.213 167.008 147.114 167.092 147.031C167.177 146.947 167.277 146.882 167.388 146.84C167.498 146.797 167.616 146.777 167.734 146.782C167.853 146.787 167.969 146.816 168.075 146.868L168.925 147.212C169.036 147.251 169.137 147.311 169.224 147.39C169.31 147.47 169.379 147.565 169.428 147.672C169.476 147.779 169.502 147.895 169.504 148.012C169.506 148.129 169.485 148.245 169.441 148.354C169.397 148.462 169.331 148.561 169.248 148.643C169.165 148.726 169.066 148.79 168.957 148.833C168.848 148.876 168.731 148.897 168.614 148.893C168.497 148.89 168.382 148.862 168.276 148.813Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M165.459 147.685L164.609 147.351C164.504 147.309 164.408 147.248 164.326 147.17C164.244 147.091 164.179 146.998 164.133 146.894C164.088 146.791 164.064 146.679 164.061 146.566C164.059 146.453 164.079 146.34 164.12 146.235C164.161 146.129 164.222 146.033 164.3 145.951C164.379 145.87 164.472 145.804 164.576 145.759C164.679 145.713 164.791 145.689 164.904 145.686C165.017 145.684 165.129 145.704 165.235 145.745L166.085 146.075C166.196 146.112 166.298 146.171 166.385 146.249C166.473 146.327 166.543 146.421 166.593 146.527C166.643 146.633 166.671 146.749 166.675 146.866C166.679 146.983 166.659 147.099 166.617 147.209C166.574 147.318 166.51 147.417 166.428 147.501C166.346 147.584 166.248 147.651 166.14 147.695C166.032 147.74 165.916 147.762 165.799 147.76C165.681 147.758 165.566 147.733 165.459 147.685Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M162.628 146.591L161.773 146.271C161.667 146.231 161.57 146.171 161.487 146.094C161.404 146.017 161.338 145.924 161.291 145.821C161.244 145.718 161.217 145.607 161.213 145.494C161.209 145.381 161.227 145.268 161.267 145.162C161.306 145.056 161.366 144.959 161.443 144.876C161.52 144.793 161.613 144.726 161.716 144.679C161.819 144.632 161.93 144.606 162.043 144.602C162.156 144.598 162.269 144.616 162.375 144.655L163.229 144.976C163.443 145.055 163.617 145.217 163.712 145.425C163.807 145.633 163.815 145.87 163.735 146.084C163.655 146.299 163.494 146.472 163.286 146.567C163.078 146.662 162.842 146.671 162.628 146.591Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M159.782 145.559L158.923 145.248C158.816 145.21 158.718 145.151 158.635 145.075C158.551 144.999 158.484 144.907 158.436 144.805C158.388 144.702 158.36 144.592 158.355 144.479C158.349 144.366 158.366 144.253 158.405 144.146C158.443 144.04 158.502 143.942 158.578 143.859C158.654 143.775 158.745 143.707 158.848 143.659C158.95 143.611 159.06 143.583 159.173 143.578C159.286 143.573 159.399 143.59 159.505 143.628L160.369 143.934C160.57 144.021 160.731 144.182 160.818 144.384C160.905 144.585 160.912 144.813 160.838 145.019C160.764 145.226 160.614 145.397 160.419 145.497C160.224 145.597 159.998 145.619 159.787 145.559H159.782Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M156.926 144.531L156.058 144.235C155.844 144.163 155.667 144.008 155.567 143.806C155.466 143.603 155.451 143.369 155.523 143.155C155.595 142.941 155.749 142.764 155.951 142.664C156.154 142.563 156.388 142.547 156.602 142.62L157.466 142.906C157.574 142.941 157.674 142.996 157.76 143.069C157.847 143.142 157.918 143.231 157.969 143.332C158.021 143.433 158.052 143.543 158.061 143.656C158.07 143.769 158.056 143.882 158.021 143.99C157.985 144.097 157.929 144.197 157.855 144.282C157.78 144.368 157.69 144.438 157.589 144.488C157.487 144.539 157.377 144.568 157.264 144.576C157.151 144.583 157.038 144.568 156.931 144.531H156.926Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M154.048 143.58L153.179 143.303C152.965 143.236 152.787 143.088 152.683 142.89C152.579 142.692 152.558 142.46 152.625 142.247C152.691 142.033 152.84 141.855 153.038 141.751C153.236 141.647 153.467 141.626 153.68 141.693L154.554 141.96C154.66 141.993 154.759 142.047 154.844 142.118C154.93 142.189 155.001 142.276 155.053 142.375C155.104 142.473 155.137 142.581 155.147 142.692C155.157 142.803 155.145 142.915 155.113 143.021C155.08 143.127 155.026 143.226 154.955 143.312C154.884 143.398 154.797 143.468 154.698 143.52C154.6 143.572 154.492 143.604 154.381 143.615C154.27 143.625 154.159 143.613 154.052 143.58H154.048Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M151.154 142.691L150.276 142.433C150.063 142.37 149.884 142.225 149.778 142.029C149.673 141.834 149.649 141.604 149.712 141.391C149.776 141.179 149.921 141 150.116 140.894C150.311 140.788 150.54 140.764 150.753 140.828L151.632 141.081C151.737 141.112 151.836 141.164 151.921 141.234C152.007 141.303 152.078 141.389 152.131 141.486C152.183 141.583 152.216 141.689 152.228 141.799C152.239 141.909 152.229 142.019 152.198 142.125C152.166 142.231 152.114 142.329 152.045 142.415C151.975 142.501 151.89 142.572 151.793 142.624C151.696 142.677 151.59 142.71 151.48 142.721C151.371 142.733 151.26 142.723 151.154 142.691Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M148.242 141.865L147.359 141.626C147.158 141.56 146.991 141.42 146.89 141.235C146.79 141.05 146.763 140.833 146.817 140.629C146.871 140.426 147 140.25 147.179 140.138C147.358 140.027 147.572 139.988 147.779 140.03L148.662 140.264C148.874 140.319 149.056 140.456 149.167 140.645C149.278 140.834 149.309 141.06 149.254 141.272C149.199 141.484 149.062 141.666 148.873 141.777C148.684 141.888 148.459 141.92 148.247 141.865H148.242Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M145.31 141.105L144.422 140.885C144.222 140.825 144.052 140.691 143.948 140.509C143.844 140.328 143.813 140.114 143.862 139.911C143.911 139.707 144.036 139.531 144.211 139.417C144.386 139.303 144.598 139.261 144.804 139.298L145.692 139.509C145.891 139.57 146.059 139.705 146.162 139.886C146.266 140.067 146.297 140.281 146.249 140.483C146.201 140.686 146.078 140.863 145.904 140.978C145.731 141.094 145.52 141.139 145.315 141.105H145.31Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M142.36 140.407L141.467 140.211C141.363 140.189 141.265 140.146 141.178 140.085C141.09 140.025 141.016 139.947 140.958 139.858C140.842 139.677 140.803 139.458 140.849 139.248C140.894 139.039 141.021 138.855 141.202 138.739C141.382 138.623 141.601 138.584 141.81 138.63L142.703 138.821C142.812 138.838 142.916 138.877 143.01 138.936C143.103 138.995 143.183 139.072 143.246 139.163C143.308 139.254 143.352 139.357 143.373 139.465C143.394 139.574 143.393 139.685 143.37 139.793C143.347 139.901 143.302 140.003 143.239 140.093C143.175 140.183 143.093 140.259 142.999 140.316C142.905 140.374 142.8 140.412 142.691 140.427C142.582 140.443 142.471 140.436 142.364 140.407H142.36Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M139.4 139.781L138.502 139.605C138.306 139.552 138.137 139.427 138.03 139.254C137.922 139.082 137.884 138.875 137.923 138.676C137.961 138.477 138.074 138.299 138.239 138.18C138.403 138.06 138.606 138.008 138.807 138.032L139.705 138.204C139.911 138.247 140.093 138.369 140.21 138.545C140.327 138.72 140.37 138.934 140.331 139.141C140.291 139.348 140.172 139.531 139.999 139.651C139.825 139.771 139.612 139.818 139.404 139.781H139.4Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M136.425 139.222L135.522 139.064C135.42 139.047 135.322 139.01 135.234 138.955C135.147 138.9 135.071 138.828 135.01 138.743C134.95 138.659 134.907 138.563 134.884 138.462C134.861 138.361 134.858 138.256 134.875 138.154C134.892 138.052 134.93 137.954 134.985 137.866C135.04 137.778 135.112 137.702 135.196 137.642C135.28 137.582 135.376 137.539 135.477 137.516C135.578 137.492 135.683 137.489 135.785 137.507L136.687 137.655C136.79 137.672 136.888 137.709 136.977 137.764C137.065 137.819 137.142 137.891 137.203 137.976C137.264 138.06 137.307 138.156 137.331 138.258C137.355 138.359 137.358 138.465 137.341 138.567C137.324 138.67 137.287 138.769 137.232 138.858C137.177 138.946 137.105 139.023 137.021 139.084C136.936 139.145 136.84 139.188 136.739 139.212C136.637 139.235 136.532 139.239 136.429 139.222H136.425Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M133.436 138.73L132.529 138.596C132.334 138.555 132.161 138.442 132.047 138.278C131.933 138.115 131.885 137.914 131.913 137.716C131.942 137.519 132.045 137.339 132.201 137.215C132.357 137.091 132.555 137.031 132.753 137.048L133.66 137.177C133.866 137.206 134.052 137.316 134.177 137.482C134.302 137.648 134.356 137.857 134.327 138.063C134.297 138.269 134.188 138.455 134.022 138.58C133.856 138.705 133.647 138.759 133.441 138.73H133.436Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M130.437 138.314L129.53 138.204C129.326 138.18 129.14 138.076 129.013 137.915C128.886 137.753 128.828 137.548 128.852 137.344C128.876 137.14 128.98 136.954 129.141 136.827C129.303 136.699 129.508 136.641 129.712 136.666L130.619 136.771C130.823 136.796 131.01 136.901 131.138 137.063C131.266 137.225 131.324 137.43 131.302 137.636C131.29 137.737 131.258 137.834 131.208 137.923C131.159 138.012 131.092 138.09 131.012 138.153C130.933 138.216 130.841 138.263 130.743 138.29C130.645 138.318 130.543 138.326 130.442 138.314H130.437Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M127.434 137.97L126.522 137.879C126.418 137.876 126.316 137.851 126.221 137.807C126.127 137.762 126.043 137.698 125.974 137.62C125.905 137.542 125.853 137.45 125.821 137.351C125.789 137.252 125.778 137.147 125.787 137.043C125.797 136.939 125.828 136.839 125.878 136.747C125.928 136.656 125.996 136.576 126.079 136.512C126.161 136.448 126.256 136.401 126.356 136.375C126.457 136.349 126.563 136.344 126.666 136.36L127.577 136.441C127.77 136.471 127.944 136.574 128.064 136.728C128.184 136.882 128.241 137.076 128.224 137.27C128.206 137.465 128.115 137.646 127.969 137.776C127.824 137.905 127.634 137.975 127.439 137.97H127.434Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M124.412 137.702L123.5 137.635C123.306 137.614 123.128 137.519 123.003 137.369C122.878 137.219 122.815 137.027 122.829 136.832C122.843 136.638 122.931 136.455 123.076 136.325C123.22 136.194 123.41 136.124 123.605 136.13L124.517 136.188C124.712 136.209 124.89 136.305 125.016 136.455C125.141 136.605 125.204 136.798 125.191 136.993C125.178 137.188 125.091 137.371 124.946 137.503C124.802 137.636 124.612 137.707 124.417 137.702H124.412Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M121.389 137.511L120.478 137.473C120.289 137.452 120.114 137.36 119.991 137.215C119.867 137.07 119.803 136.884 119.812 136.694C119.82 136.504 119.9 136.324 120.037 136.191C120.173 136.058 120.354 135.982 120.544 135.978L121.456 136.016C121.559 136.013 121.661 136.031 121.757 136.07C121.852 136.108 121.938 136.166 122.01 136.239C122.082 136.312 122.139 136.4 122.176 136.496C122.213 136.591 122.229 136.694 122.225 136.797C122.221 136.9 122.196 137 122.151 137.093C122.106 137.185 122.043 137.268 121.965 137.335C121.887 137.402 121.796 137.452 121.698 137.482C121.6 137.513 121.496 137.523 121.394 137.511H121.389Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M118.363 137.402H117.446C117.261 137.384 117.089 137.297 116.965 137.158C116.84 137.019 116.773 136.838 116.776 136.652C116.779 136.466 116.852 136.287 116.981 136.153C117.109 136.018 117.284 135.936 117.47 135.925H118.386C118.583 135.925 118.771 136.003 118.91 136.142C119.049 136.281 119.127 136.469 119.127 136.666C119.127 136.862 119.049 137.05 118.91 137.189C118.771 137.328 118.583 137.406 118.386 137.406L118.363 137.402Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M115.34 137.373H114.428C114.245 137.358 114.075 137.275 113.95 137.14C113.826 137.005 113.757 136.828 113.757 136.644C113.757 136.461 113.826 136.283 113.95 136.148C114.075 136.013 114.245 135.93 114.428 135.915H115.34C115.436 135.914 115.531 135.931 115.62 135.966C115.71 136.002 115.791 136.054 115.86 136.121C115.929 136.188 115.984 136.268 116.022 136.356C116.061 136.444 116.081 136.539 116.083 136.635C116.084 136.731 116.067 136.826 116.032 136.915C115.996 137.005 115.944 137.086 115.877 137.155C115.81 137.224 115.731 137.279 115.643 137.317C115.555 137.356 115.46 137.376 115.364 137.378L115.34 137.373Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M112.313 137.435L111.401 137.468C111.224 137.456 111.057 137.379 110.933 137.251C110.809 137.124 110.737 136.955 110.73 136.777C110.723 136.6 110.782 136.425 110.895 136.288C111.008 136.151 111.168 136.061 111.344 136.035L112.256 135.997C112.356 135.981 112.458 135.987 112.555 136.014C112.653 136.041 112.744 136.089 112.822 136.153C112.9 136.217 112.964 136.297 113.009 136.388C113.054 136.478 113.08 136.577 113.084 136.679C113.088 136.78 113.072 136.881 113.034 136.975C112.997 137.069 112.941 137.154 112.868 137.225C112.796 137.296 112.71 137.351 112.615 137.386C112.52 137.422 112.419 137.437 112.318 137.43L112.313 137.435Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M109.291 137.574L108.379 137.636C108.189 137.648 108.002 137.585 107.858 137.459C107.715 137.334 107.628 137.157 107.615 136.967C107.602 136.777 107.665 136.589 107.791 136.446C107.916 136.302 108.093 136.215 108.283 136.202L109.195 136.135C109.289 136.128 109.384 136.141 109.473 136.171C109.562 136.2 109.645 136.248 109.716 136.31C109.788 136.371 109.846 136.447 109.888 136.531C109.93 136.616 109.955 136.708 109.961 136.802C109.968 136.896 109.956 136.99 109.926 137.08C109.896 137.169 109.849 137.252 109.787 137.323C109.725 137.395 109.65 137.453 109.566 137.495C109.481 137.537 109.389 137.562 109.295 137.569L109.291 137.574Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M106.278 137.807L105.366 137.898C105.274 137.907 105.181 137.898 105.093 137.871C105.005 137.845 104.922 137.801 104.851 137.742C104.78 137.684 104.72 137.612 104.677 137.53C104.633 137.449 104.606 137.359 104.597 137.267C104.588 137.175 104.597 137.083 104.624 136.994C104.651 136.906 104.695 136.824 104.753 136.752C104.812 136.681 104.884 136.621 104.965 136.578C105.046 136.534 105.136 136.507 105.228 136.498L106.135 136.402C106.316 136.392 106.493 136.453 106.631 136.57C106.77 136.688 106.857 136.854 106.876 137.035C106.895 137.215 106.844 137.396 106.734 137.54C106.623 137.684 106.462 137.779 106.283 137.807H106.278Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M103.27 138.133L102.363 138.252C102.186 138.265 102.01 138.209 101.873 138.097C101.735 137.984 101.645 137.823 101.622 137.647C101.599 137.471 101.644 137.292 101.748 137.148C101.852 137.004 102.007 136.905 102.182 136.871L103.089 136.751C103.267 136.737 103.444 136.791 103.583 136.903C103.723 137.015 103.814 137.176 103.838 137.353C103.862 137.531 103.816 137.71 103.712 137.855C103.607 138 103.451 138.1 103.275 138.133H103.27Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M100.271 138.553L99.3737 138.696C99.285 138.711 99.1941 138.708 99.1065 138.688C99.0189 138.667 98.9363 138.63 98.8631 138.578C98.79 138.525 98.7279 138.459 98.6804 138.383C98.6328 138.306 98.6007 138.221 98.586 138.132C98.5712 138.044 98.574 137.953 98.5943 137.865C98.6147 137.777 98.6521 137.695 98.7044 137.621C98.7567 137.548 98.823 137.486 98.8993 137.439C98.9756 137.391 99.0605 137.359 99.1492 137.344L100.052 137.196C100.142 137.178 100.235 137.178 100.325 137.197C100.415 137.216 100.501 137.253 100.576 137.305C100.652 137.358 100.716 137.425 100.766 137.503C100.815 137.581 100.848 137.668 100.863 137.759C100.878 137.85 100.875 137.943 100.853 138.032C100.832 138.122 100.792 138.206 100.738 138.28C100.683 138.354 100.614 138.417 100.534 138.464C100.455 138.51 100.367 138.541 100.276 138.553H100.271Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M97.2922 139.064L96.3947 139.236C96.2206 139.271 96.0398 139.234 95.8925 139.135C95.7452 139.036 95.6434 138.883 95.6092 138.708C95.5751 138.534 95.6113 138.353 95.7102 138.206C95.8091 138.058 95.9627 137.956 96.1368 137.922L97.0295 137.736C97.2024 137.707 97.3796 137.747 97.524 137.846C97.6683 137.946 97.7688 138.097 97.804 138.269C97.8392 138.441 97.8064 138.62 97.7128 138.768C97.6192 138.916 97.472 139.022 97.3018 139.064H97.2922Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M94.3322 139.671L93.4393 139.872C93.2766 139.897 93.1109 139.859 92.9748 139.766C92.8388 139.674 92.7424 139.534 92.7051 139.373C92.6678 139.213 92.692 139.045 92.7732 138.901C92.8544 138.758 92.9867 138.651 93.1434 138.601L94.0314 138.386C94.1157 138.366 94.2032 138.363 94.2888 138.376C94.3744 138.39 94.4565 138.421 94.5303 138.466C94.6041 138.512 94.6681 138.571 94.7189 138.641C94.7697 138.712 94.8063 138.791 94.8264 138.876C94.8465 138.96 94.8497 139.048 94.836 139.133C94.8222 139.219 94.7916 139.301 94.7462 139.375C94.7008 139.449 94.6414 139.513 94.5711 139.564C94.5009 139.615 94.4214 139.651 94.3371 139.671H94.3322Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M91.3906 140.379L90.5075 140.613C90.3447 140.657 90.171 140.635 90.0246 140.551C89.8782 140.467 89.771 140.329 89.7267 140.166C89.6824 140.003 89.7045 139.829 89.7882 139.683C89.872 139.536 90.0104 139.429 90.1731 139.385L91.0565 139.141C91.139 139.113 91.2263 139.103 91.3129 139.11C91.3996 139.117 91.484 139.142 91.5608 139.183C91.6376 139.224 91.705 139.28 91.7594 139.348C91.8138 139.416 91.8538 139.494 91.8769 139.578C91.9 139.662 91.9057 139.75 91.8937 139.836C91.8817 139.922 91.8525 140.005 91.8074 140.08C91.7624 140.154 91.7025 140.219 91.6316 140.269C91.5608 140.32 91.4805 140.355 91.3955 140.374L91.3906 140.379Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M88.4733 141.186L87.5995 141.449C87.4473 141.486 87.287 141.463 87.151 141.385C87.015 141.308 86.9135 141.181 86.8673 141.032C86.8211 140.882 86.8336 140.72 86.9022 140.579C86.9708 140.438 87.0905 140.329 87.2368 140.273L88.1057 139.996C88.2633 139.948 88.4337 139.963 88.5801 140.039C88.7265 140.115 88.8373 140.246 88.8888 140.402C88.9139 140.481 88.9233 140.563 88.9165 140.645C88.9097 140.726 88.8869 140.806 88.8491 140.879C88.8114 140.952 88.7595 141.017 88.6967 141.069C88.6338 141.122 88.5612 141.162 88.4829 141.186H88.4733Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M85.5945 142.099L84.7254 142.395C84.5777 142.441 84.418 142.427 84.2801 142.357C84.1422 142.287 84.0369 142.167 83.9861 142.02C83.9354 141.874 83.9434 141.714 84.0083 141.574C84.0732 141.433 84.1899 141.323 84.334 141.268L85.1933 140.962C85.2679 140.934 85.3473 140.922 85.4267 140.925C85.506 140.929 85.5839 140.948 85.6559 140.982C85.7278 141.016 85.7922 141.064 85.8456 141.122C85.899 141.181 85.9402 141.25 85.9669 141.325C86.0205 141.476 86.012 141.643 85.9431 141.788C85.8742 141.933 85.7504 142.045 85.5992 142.099H85.5945Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M82.7442 143.117L81.8944 143.446C81.7513 143.503 81.5915 143.5 81.4505 143.438C81.3095 143.377 81.1987 143.262 81.1423 143.119C81.086 142.976 81.0889 142.816 81.1502 142.675C81.2116 142.534 81.3263 142.423 81.4694 142.366L82.3192 142.027C82.392 141.991 82.4716 141.97 82.5528 141.967C82.634 141.963 82.7152 141.976 82.7911 142.005C82.8669 142.034 82.9359 142.079 82.9936 142.136C83.0514 142.194 83.0967 142.262 83.1265 142.338C83.1564 142.414 83.1702 142.494 83.1671 142.576C83.164 142.657 83.1441 142.737 83.1086 142.81C83.0731 142.883 83.0229 142.948 82.961 143.001C82.8991 143.054 82.827 143.093 82.7491 143.117H82.7442Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M79.9511 144.244L79.1205 144.622C78.9856 144.685 78.8312 144.692 78.691 144.642C78.5509 144.591 78.4366 144.487 78.3733 144.352C78.31 144.217 78.3029 144.062 78.3535 143.922C78.4041 143.782 78.5082 143.667 78.643 143.604L79.469 143.212C79.5373 143.173 79.6129 143.148 79.6912 143.14C79.7695 143.131 79.8486 143.139 79.9236 143.163C79.9987 143.187 80.068 143.226 80.1271 143.278C80.1863 143.33 80.2341 143.394 80.2673 143.466C80.3005 143.537 80.3185 143.614 80.3202 143.693C80.3218 143.772 80.307 143.85 80.2768 143.923C80.2467 143.996 80.2017 144.062 80.1449 144.116C80.088 144.171 80.0204 144.213 79.9465 144.24L79.9511 144.244Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M77.2582 145.558L76.456 145.998C76.3941 146.033 76.3258 146.056 76.255 146.065C76.1843 146.073 76.1126 146.067 76.0441 146.048C75.9756 146.028 75.9117 145.995 75.8561 145.95C75.8006 145.906 75.7546 145.851 75.7207 145.788C75.6516 145.663 75.6345 145.516 75.6729 145.378C75.7113 145.24 75.8023 145.123 75.9261 145.052L76.7185 144.598C76.8451 144.526 76.9955 144.507 77.1361 144.545C77.2766 144.584 77.3961 144.677 77.4683 144.803C77.5404 144.93 77.5592 145.08 77.5207 145.221C77.4822 145.362 77.3895 145.482 77.2629 145.554L77.2582 145.558Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M74.6515 147.088L73.8779 147.566C73.8202 147.603 73.7555 147.629 73.6877 147.641C73.6199 147.653 73.5504 147.652 73.4832 147.637C73.416 147.621 73.3525 147.593 73.2965 147.553C73.2405 147.513 73.1929 147.462 73.1568 147.403C73.083 147.286 73.0583 147.145 73.0878 147.01C73.1173 146.875 73.1986 146.757 73.3144 146.682L74.0784 146.204C74.197 146.128 74.3408 146.102 74.4785 146.131C74.6161 146.161 74.7366 146.244 74.8137 146.361C74.8518 146.42 74.8777 146.485 74.8904 146.553C74.9031 146.622 74.9023 146.692 74.8876 146.76C74.873 146.828 74.8448 146.892 74.8051 146.949C74.7654 147.006 74.7148 147.055 74.6561 147.092L74.6515 147.088Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M72.1349 148.755L71.3948 149.286C71.2859 149.365 71.15 149.398 71.0171 149.377C70.8841 149.356 70.7649 149.283 70.6858 149.174C70.6067 149.065 70.5741 148.929 70.5951 148.796C70.6162 148.662 70.689 148.543 70.7979 148.464L71.5332 147.924C71.5875 147.884 71.649 147.855 71.7144 147.839C71.7797 147.823 71.8477 147.82 71.9142 147.83C71.9807 147.841 72.0445 147.864 72.1021 147.899C72.1596 147.934 72.2096 147.98 72.2494 148.034C72.2892 148.088 72.318 148.15 72.334 148.215C72.3501 148.281 72.3531 148.348 72.3429 148.415C72.3327 148.482 72.3095 148.545 72.2746 148.603C72.2397 148.661 72.1938 148.711 72.1396 148.751L72.1349 148.755Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M69.7193 150.562L69.0078 151.135C68.9582 151.176 68.9014 151.206 68.8402 151.225C68.7789 151.243 68.7145 151.249 68.6509 151.243C68.5872 151.236 68.5256 151.217 68.4692 151.187C68.4129 151.157 68.363 151.115 68.3226 151.066C68.2409 150.966 68.2024 150.837 68.2154 150.709C68.2283 150.58 68.2918 150.462 68.3918 150.38L69.0938 149.797C69.1425 149.754 69.1996 149.721 69.2614 149.701C69.3233 149.68 69.3885 149.673 69.4533 149.679C69.5181 149.685 69.5812 149.703 69.6384 149.735C69.6956 149.766 69.7458 149.808 69.786 149.859C69.83 149.908 69.8635 149.965 69.8844 150.028C69.9052 150.09 69.9128 150.156 69.907 150.222C69.9011 150.287 69.8821 150.351 69.8506 150.408C69.8191 150.466 69.7759 150.517 69.724 150.557L69.7193 150.562Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M67.4178 152.507L66.7494 153.128C66.6557 153.188 66.5438 153.214 66.4331 153.201C66.3223 153.188 66.2198 153.136 66.1433 153.055C66.0667 152.973 66.0209 152.868 66.0139 152.757C66.0069 152.645 66.039 152.535 66.1048 152.445L66.7685 151.814C66.862 151.737 66.981 151.699 67.1014 151.707C67.2219 151.715 67.3349 151.768 67.4178 151.856C67.5007 151.944 67.5472 152.06 67.5481 152.18C67.549 152.301 67.5043 152.418 67.4227 152.507H67.4178Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M65.2644 154.609L64.6438 155.283C64.5561 155.362 64.4419 155.406 64.3237 155.406C64.2055 155.406 64.0916 155.362 64.0039 155.283C63.922 155.199 63.8739 155.088 63.8687 154.971C63.8634 154.853 63.9012 154.738 63.9752 154.647L64.5865 153.969C64.6705 153.882 64.784 153.831 64.9045 153.824C65.0249 153.818 65.1433 153.858 65.2358 153.935C65.3203 154.02 65.3701 154.133 65.3754 154.252C65.3807 154.372 65.3411 154.489 65.2644 154.58V154.609Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M63.2922 156.879L62.7382 157.605C62.6674 157.697 62.5637 157.756 62.4494 157.771C62.335 157.786 62.2193 157.756 62.1272 157.687C62.0367 157.617 61.9773 157.515 61.9621 157.402C61.9469 157.289 61.9769 157.175 62.0458 157.085L62.5902 156.349C62.6246 156.302 62.6679 156.263 62.7175 156.233C62.7671 156.203 62.822 156.183 62.8793 156.175C62.9365 156.167 62.9948 156.17 63.0509 156.184C63.1069 156.198 63.1598 156.223 63.2061 156.258C63.299 156.328 63.3605 156.432 63.3775 156.547C63.3945 156.663 63.3655 156.78 63.2968 156.874L63.2922 156.879Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M61.5691 159.33L61.0916 160.114C61.0645 160.162 61.0283 160.203 60.9848 160.237C60.9414 160.27 60.8916 160.294 60.8387 160.308C60.7857 160.322 60.7308 160.325 60.6766 160.317C60.6225 160.309 60.5703 160.29 60.5235 160.262C60.4299 160.206 60.3617 160.116 60.3332 160.011C60.3047 159.906 60.3182 159.794 60.3708 159.698L60.8482 158.905C60.8755 158.857 60.9119 158.815 60.9555 158.781C60.999 158.747 61.0489 158.722 61.1021 158.708C61.1553 158.693 61.2108 158.689 61.2655 158.696C61.3203 158.703 61.3733 158.72 61.4213 158.747C61.5185 158.804 61.5894 158.896 61.619 159.004C61.6485 159.112 61.6339 159.228 61.5786 159.326L61.5691 159.33Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M60.1792 161.968L59.8402 162.819C59.7966 162.907 59.7215 162.975 59.6299 163.011C59.5383 163.046 59.437 163.045 59.3457 163.009C59.2545 162.973 59.1799 162.904 59.1371 162.816C59.0942 162.727 59.0863 162.626 59.1145 162.532L59.439 161.677C59.4838 161.588 59.5601 161.519 59.6528 161.483C59.7455 161.448 59.8482 161.448 59.9409 161.483C60.0337 161.519 60.1098 161.588 60.1547 161.677C60.1996 161.765 60.21 161.868 60.1839 161.964L60.1792 161.968Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M59.2485 164.793L59.0813 165.691C59.0642 165.786 59.0102 165.87 58.9309 165.925C58.8517 165.98 58.7538 166.002 58.6589 165.985C58.5639 165.968 58.4796 165.913 58.4246 165.834C58.3695 165.755 58.348 165.657 58.3651 165.562L58.5181 164.659C58.5355 164.562 58.5905 164.476 58.671 164.42C58.7515 164.363 58.8509 164.341 58.9477 164.358C58.9958 164.365 59.0419 164.382 59.0834 164.408C59.1249 164.433 59.1608 164.467 59.1893 164.506C59.2177 164.546 59.238 164.591 59.2489 164.638C59.2599 164.686 59.2615 164.735 59.2534 164.783L59.2485 164.793Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M58.9236 167.736L58.9618 168.649C58.9637 168.693 58.957 168.737 58.942 168.778C58.9269 168.819 58.9038 168.857 58.8741 168.889C58.8444 168.922 58.8086 168.948 58.7687 168.967C58.7289 168.985 58.6858 168.996 58.6419 168.997C58.5983 169 58.5545 168.994 58.5132 168.98C58.472 168.965 58.434 168.942 58.4016 168.913C58.3691 168.884 58.343 168.848 58.3244 168.809C58.3058 168.769 58.2952 168.726 58.2934 168.682L58.236 167.769C58.2332 167.724 58.2393 167.679 58.254 167.636C58.2686 167.593 58.2917 167.554 58.3216 167.52C58.3515 167.486 58.3877 167.458 58.4284 167.438C58.469 167.419 58.5133 167.407 58.5585 167.404C58.6036 167.401 58.6488 167.407 58.6916 167.422C58.7344 167.437 58.7738 167.46 58.8077 167.49C58.8416 167.519 58.8694 167.556 58.8893 167.596C58.9092 167.637 58.9207 167.681 58.9236 167.727V167.736Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M59.2913 170.675L59.5445 171.554C59.5667 171.633 59.557 171.717 59.5177 171.788C59.4784 171.86 59.4126 171.913 59.3344 171.937C59.2572 171.959 59.1742 171.95 59.1036 171.911C59.033 171.873 58.9805 171.808 58.9572 171.731L58.6849 170.857C58.661 170.776 58.6696 170.689 58.7089 170.615C58.7482 170.541 58.8152 170.485 58.8952 170.46C58.935 170.447 58.9768 170.443 59.0183 170.447C59.0599 170.451 59.1003 170.463 59.1372 170.482C59.1741 170.502 59.2065 170.528 59.233 170.561C59.2595 170.593 59.2793 170.63 59.2913 170.67V170.675Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M60.3371 173.466L60.7667 174.273C60.7844 174.305 60.7956 174.34 60.7996 174.377C60.8036 174.413 60.8005 174.45 60.7903 174.485C60.7801 174.52 60.763 174.553 60.7402 174.581C60.7173 174.61 60.689 174.633 60.6569 174.651C60.5914 174.685 60.5151 174.693 60.4438 174.673C60.3726 174.652 60.3119 174.605 60.2748 174.541L59.8309 173.743C59.8116 173.71 59.799 173.674 59.7941 173.636C59.7892 173.598 59.792 173.56 59.8023 173.523C59.8125 173.487 59.83 173.453 59.8538 173.423C59.8776 173.393 59.9072 173.369 59.9407 173.351C59.9736 173.332 60.0098 173.32 60.0473 173.316C60.0848 173.311 60.1228 173.314 60.1592 173.324C60.1956 173.335 60.2298 173.352 60.2594 173.375C60.2891 173.399 60.3138 173.428 60.3322 173.461L60.3371 173.466Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M61.9223 176.013L62.4858 176.73C62.5064 176.756 62.5216 176.786 62.5307 176.818C62.5399 176.85 62.5429 176.883 62.5391 176.916C62.5354 176.949 62.525 176.981 62.5088 177.01C62.4927 177.039 62.471 177.065 62.445 177.086C62.4189 177.106 62.3893 177.122 62.3573 177.131C62.3253 177.14 62.2919 177.143 62.2589 177.139C62.2259 177.135 62.1938 177.125 62.1647 177.109C62.1357 177.093 62.1101 177.071 62.0894 177.045L61.5117 176.338C61.486 176.312 61.4659 176.28 61.453 176.246C61.44 176.212 61.4342 176.175 61.4362 176.139C61.4382 176.102 61.448 176.066 61.4646 176.034C61.4813 176.001 61.5045 175.972 61.5329 175.949C61.5614 175.926 61.5943 175.909 61.6297 175.899C61.665 175.89 61.7021 175.888 61.7383 175.893C61.7745 175.899 61.8091 175.912 61.84 175.932C61.8708 175.951 61.8971 175.978 61.9174 176.008L61.9223 176.013Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M63.8936 178.287L64.5472 178.927C64.5902 178.968 64.615 179.025 64.6164 179.084C64.6177 179.144 64.5955 179.202 64.5544 179.245C64.5133 179.288 64.4567 179.312 64.3972 179.314C64.3377 179.315 64.2802 179.293 64.2372 179.252L63.5694 178.626C63.5472 178.605 63.5295 178.579 63.5174 178.55C63.5054 178.521 63.499 178.49 63.499 178.459C63.499 178.428 63.5054 178.397 63.5174 178.369C63.5295 178.34 63.5472 178.314 63.5694 178.292C63.6137 178.25 63.6725 178.226 63.7338 178.226C63.7951 178.226 63.8541 178.25 63.8985 178.292L63.8936 178.287Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M66.1382 180.318L66.8489 180.887C66.89 180.919 66.9167 180.966 66.9229 181.018C66.9292 181.07 66.9147 181.122 66.8824 181.163C66.8501 181.204 66.8027 181.231 66.7508 181.237C66.6989 181.244 66.6468 181.229 66.6057 181.197L65.8806 180.643C65.8458 180.607 65.8251 180.559 65.8223 180.509C65.8196 180.459 65.8348 180.409 65.8654 180.369C65.896 180.33 65.94 180.302 65.9891 180.292C66.0383 180.282 66.0893 180.289 66.1333 180.314L66.1382 180.318Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M68.5636 182.134L69.3171 182.645C69.3498 182.672 69.3717 182.71 69.3781 182.751C69.3846 182.793 69.3753 182.836 69.3523 182.871C69.3292 182.907 69.2941 182.933 69.2533 182.944C69.2125 182.955 69.169 182.95 69.1312 182.931L68.363 182.454C68.3236 182.428 68.2962 182.387 68.2864 182.341C68.2766 182.294 68.2852 182.246 68.3106 182.206C68.3232 182.186 68.3397 182.168 68.3591 182.155C68.3785 182.141 68.4005 182.132 68.4236 182.127C68.4467 182.122 68.4705 182.122 68.4937 182.126C68.5169 182.131 68.5391 182.14 68.5587 182.153L68.5636 182.134Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M71.1178 183.788L71.9051 184.265C71.9359 184.285 71.9579 184.316 71.9668 184.351C71.9757 184.387 71.9705 184.424 71.9526 184.456C71.9346 184.487 71.9052 184.51 71.8704 184.52C71.8356 184.53 71.7985 184.526 71.7667 184.508L70.9701 184.06C70.9523 184.051 70.9362 184.038 70.9233 184.023C70.9104 184.007 70.9009 183.99 70.8951 183.97C70.8893 183.951 70.8873 183.931 70.8896 183.911C70.8918 183.891 70.8981 183.872 70.908 183.855C70.928 183.82 70.9607 183.795 70.999 183.784C71.0373 183.773 71.0783 183.778 71.1132 183.797L71.1178 183.788Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M73.763 185.274L74.5741 185.699C74.5976 185.712 74.6152 185.733 74.6232 185.759C74.6312 185.785 74.6289 185.813 74.6169 185.837C74.6046 185.862 74.5834 185.88 74.5575 185.889C74.5317 185.898 74.5033 185.897 74.4786 185.885L73.6582 185.479C73.6327 185.464 73.6133 185.44 73.6037 185.412C73.5941 185.384 73.5947 185.354 73.6056 185.326C73.6199 185.299 73.6445 185.278 73.6738 185.268C73.7032 185.258 73.7351 185.26 73.763 185.274Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M76.4795 186.655L77.3048 187.046C77.313 187.05 77.3207 187.055 77.3269 187.062C77.3331 187.068 77.3377 187.076 77.3409 187.084C77.344 187.093 77.3455 187.102 77.345 187.111C77.3446 187.12 77.3423 187.129 77.3383 187.137C77.3301 187.153 77.3162 187.165 77.2994 187.171C77.2826 187.178 77.2642 187.177 77.2477 187.17L76.4129 186.798C76.401 186.795 76.3897 186.79 76.3801 186.783C76.3704 186.775 76.3625 186.766 76.357 186.755C76.3515 186.744 76.3486 186.732 76.3484 186.72C76.3482 186.707 76.3509 186.695 76.3561 186.684C76.3612 186.673 76.3687 186.663 76.3782 186.656C76.3877 186.648 76.3989 186.643 76.4108 186.64C76.4227 186.637 76.435 186.637 76.4469 186.639C76.4589 186.642 76.4699 186.647 76.4795 186.655Z"
      fill={determineColor('--primary-300')}
    />
    <path
      d="M65.0935 264.872C64.2925 263.634 66.58 261.565 67.8238 260.686L73.0117 262.324L77.1983 263.78C78.2601 264.144 80.8206 265.163 82.568 266.328C84.3155 267.493 83.6121 267.701 83.4782 268.149C83.3442 268.596 81.9856 269.623 80.3837 269.696C78.7819 269.769 73.9522 268.695 71.7375 268.149C69.8566 267.572 65.8944 266.11 65.0935 264.872Z"
      fill={determineColor('--gray-900')}
    />
    <path
      d="M104.666 266.983C103.578 265.865 105.286 263.159 106.277 261.946L111.734 262.472L116.171 263.038C117.295 263.176 120.041 263.658 122.032 264.481C124.023 265.303 123.391 265.684 123.372 266.187C123.354 266.689 122.289 268.083 120.748 268.53C119.208 268.976 114.24 268.961 111.949 268.898C109.975 268.726 105.755 268.101 104.666 266.983Z"
      fill={determineColor('--gray-900')}
    />
    <path
      d="M69.62 253.523L67.9615 260.121C67.8556 260.519 67.8903 260.941 68.0602 261.317C68.2301 261.692 68.5246 261.996 68.8935 262.179C69.9333 262.777 71.1218 263.067 72.3202 263.016C72.5957 262.979 72.8554 262.865 73.0698 262.688C73.2842 262.511 73.4448 262.278 73.5333 262.014L75.9661 255.079L69.62 253.523Z"
      fill="#E0A37F"
    />
    <path
      d="M105.531 255.767L106.348 262.521C106.392 262.931 106.576 263.313 106.869 263.602C107.162 263.891 107.546 264.07 107.956 264.108C109.141 264.294 110.355 264.139 111.455 263.661C111.699 263.528 111.901 263.329 112.037 263.087C112.174 262.845 112.24 262.569 112.228 262.291L112.013 254.945L105.531 255.767Z"
      fill="#E0A37F"
    />
    <path
      d="M127.902 166.657C127.902 166.657 113.098 259.257 113.345 259.799C110.613 260.895 107.579 260.978 104.792 260.032C104.792 260.032 106.163 210.515 109.267 191.528C109.267 191.528 83.9986 235.791 75.9732 257.844C73.2605 257.448 70.6574 256.5 68.3247 255.059C68.3247 255.059 95.5328 175.396 102.791 164.784L127.902 166.657Z"
      fill={determineColor('--primary-800')}
    />
    <path
      d="M108.407 193.961C108.325 193.96 108.244 193.936 108.174 193.892C108.125 193.862 108.082 193.823 108.048 193.776C108.015 193.729 107.991 193.676 107.978 193.619C107.965 193.563 107.964 193.505 107.974 193.448C107.984 193.391 108.006 193.337 108.037 193.289L113.568 184.44C113.6 184.39 113.642 184.347 113.692 184.314C113.741 184.281 113.797 184.259 113.856 184.248L117.926 183.425C118.04 183.403 118.158 183.426 118.254 183.491C118.35 183.555 118.417 183.654 118.44 183.768C118.452 183.824 118.453 183.883 118.442 183.939C118.431 183.996 118.409 184.05 118.377 184.098C118.346 184.145 118.304 184.187 118.256 184.218C118.208 184.25 118.154 184.272 118.098 184.282L114.212 185.064L108.777 193.755C108.737 193.817 108.683 193.869 108.618 193.905C108.553 193.941 108.481 193.96 108.407 193.961Z"
      fill="#007296"
    />
    <path
      d="M195.881 75.9434C195.881 75.9434 185.888 78.1521 179.809 80.9575C178.597 81.5141 177.624 82.4877 177.068 83.7012C175.252 87.6864 169.81 97.6804 159.249 102.448C160.77 104.464 163.217 108.662 164.28 115.713C170.612 110.994 176.081 103.113 178.706 90.19C179.416 90.5113 180.189 90.6672 180.968 90.6458C181.747 90.6245 182.511 90.4262 183.202 90.0664C184.567 89.3804 185.896 88.625 187.184 87.803C188.325 87.1036 189.152 85.9904 189.493 84.6956C189.932 82.9876 189.671 81.4855 189.671 79.8941L194.812 78.2959C195.264 78.1249 195.631 77.7816 195.832 77.3407C196.032 76.8997 196.05 76.3973 195.881 75.9434Z"
      fill="#E0A37F"
    />
    <path
      d="M164.293 115.706C163.231 108.655 160.784 104.457 159.262 102.441C152.71 105.404 144.185 106.35 133.343 102.585C132.34 102.242 131.296 102.036 130.238 101.974C124.461 101.631 105.429 101.014 89.6726 106.227C88.0388 106.764 86.5367 107.64 85.2648 108.799C83.9928 109.957 82.9796 111.371 82.2915 112.949C78.0287 122.737 71.1274 145.03 90.2827 156.279C91.5119 153.208 93.0603 150.274 94.9021 147.526C91.818 142.32 90.9065 135.18 96.3755 125.947C100.371 136.73 102.242 152.472 102.633 159.434C102.76 161.728 102.359 164.021 101.461 166.135L101.365 166.362C101.32 166.469 101.299 166.585 101.303 166.701C101.308 166.816 101.337 166.93 101.39 167.033C101.443 167.136 101.518 167.227 101.609 167.298C101.701 167.369 101.807 167.42 101.92 167.446C112.7 169.867 123.912 169.208 127.277 168.934C127.569 168.913 127.846 168.799 128.068 168.609C128.289 168.419 128.445 168.162 128.511 167.878C133.754 145.77 133.768 130.687 132.479 121.153C137.373 122.25 152.368 124.589 164.293 115.706Z"
      fill={determineColor('--secondary-600')}
    />
    <path
      d="M105.881 93.8527C105.881 93.8527 104.792 102.272 103.825 104.763C103.755 104.946 103.77 105.141 103.87 105.317C103.969 105.493 104.146 105.64 104.373 105.734C106.004 106.414 109.911 107.524 115.257 105.608C115.683 105.459 116.039 105.221 116.283 104.925C116.526 104.629 116.646 104.288 116.627 103.943L116.154 95.8087L105.881 93.8527Z"
      fill="#C78773"
    />
    <path
      d="M115.44 78.345C115.44 78.345 118.928 86.3703 119.161 90.5751C119.394 94.7798 120.299 97.5165 115.44 98.3671C110.581 99.2176 106.476 96.7893 104.255 92.5983C104.255 92.5983 100.78 86.1371 101.562 83.4894C102.343 80.8417 115.44 78.345 115.44 78.345Z"
      fill="#E0A37F"
    />
    <path
      d="M105.92 89.5735L106.194 89.4499C106.359 89.3726 106.486 89.2332 106.547 89.0621C106.609 88.891 106.601 88.7022 106.523 88.5376C105.838 87.2069 104.502 84.1683 106.229 83.4618C108.449 82.5495 117.269 80.7181 119.168 77.6177C120.998 74.6065 119.38 70.7584 116.947 69.6197C116.871 69.5808 116.782 69.5733 116.7 69.599C116.618 69.6246 116.549 69.6814 116.509 69.7571L116.303 70.1412C116.273 70.2003 116.226 70.2488 116.167 70.2805C116.109 70.3123 116.043 70.3257 115.977 70.3191C115.911 70.3124 115.849 70.2858 115.798 70.243C115.747 70.2003 115.711 70.1435 115.693 70.0796C115.676 70.0182 115.641 69.963 115.592 69.9212C115.544 69.8793 115.485 69.8525 115.422 69.8438C115.359 69.8351 115.294 69.8451 115.236 69.8723C115.179 69.8995 115.13 69.9429 115.097 69.9972C114.514 70.9301 112.938 72.4526 108.716 73.2346C102.713 74.3389 98.7446 76.7672 98.6487 80.9171C98.649 80.9771 98.6332 81.036 98.6025 81.0875C98.5718 81.1391 98.5276 81.1815 98.4746 81.2098C98.4217 81.238 98.3619 81.2512 98.302 81.248C98.2421 81.2447 98.1842 81.2251 98.1346 81.1914C97.696 80.9239 96.9832 80.7938 96.0375 81.5963C94.3858 82.9681 95.0643 86.5758 98.6761 89.539C98.7172 89.5696 98.7505 89.6097 98.7735 89.6556C98.7965 89.7014 98.8086 89.752 98.8086 89.8033C98.8086 89.8546 98.7965 89.9051 98.7735 89.951C98.7505 89.9968 98.7172 90.0365 98.6761 90.0672C97.9908 90.4925 97.045 91.5078 99.5739 92.8591C101.172 93.8186 103.04 94.228 104.892 94.025C104.936 94.0177 104.979 94.0014 105.017 93.9771C105.054 93.9528 105.087 93.9212 105.112 93.884C105.137 93.8467 105.154 93.8045 105.162 93.7604C105.17 93.7162 105.169 93.6709 105.159 93.6271L104.358 89.9781C104.346 89.9296 104.346 89.8793 104.357 89.8307C104.368 89.7821 104.39 89.7364 104.421 89.6974C104.452 89.6585 104.491 89.6272 104.536 89.6057C104.581 89.5842 104.63 89.5732 104.68 89.5735H105.92Z"
      fill={determineColor('--gray-900')}
    />
    <path
      d="M106.071 90.3419C106.071 90.3419 104.741 85.218 102.445 86.6584C100.149 88.0989 103.631 92.3995 105.482 92.5916L106.071 90.3419Z"
      fill="#E0A37F"
    />
    <path
      d="M181.386 88.9966C181.308 88.9919 181.233 88.9669 181.169 88.9239C181.104 88.8808 181.052 88.8215 181.018 88.7517C180.984 88.682 180.969 88.6044 180.974 88.527C180.98 88.4495 181.006 88.3747 181.05 88.3106C181.427 87.8442 184.778 83.7424 186.073 83.0085C186.539 82.7409 186.82 82.4668 186.882 82.213C186.905 82.0727 186.883 81.9289 186.82 81.8014L182.859 82.5627L180.632 84.4764C180.588 84.5145 180.538 84.5436 180.483 84.5618C180.428 84.5801 180.371 84.5871 180.313 84.5826C180.256 84.5781 180.2 84.5622 180.148 84.5357C180.097 84.5092 180.052 84.4729 180.015 84.4285C179.939 84.341 179.9 84.227 179.908 84.1114C179.916 83.9957 179.969 83.8876 180.056 83.8109L182.379 81.8218C182.436 81.7726 182.504 81.7393 182.578 81.7257L186.937 80.8479C187.022 80.8321 187.11 80.8412 187.19 80.874C187.27 80.9068 187.339 80.9621 187.389 81.0331C187.538 81.225 187.645 81.447 187.7 81.6838C187.756 81.9206 187.76 82.1665 187.711 82.4049C187.614 82.7077 187.454 82.9864 187.241 83.2228C187.029 83.4592 186.768 83.648 186.477 83.7768C185.511 84.3255 182.701 87.6385 181.68 88.8663C181.602 88.9461 181.497 88.9928 181.386 88.9966Z"
      fill="#C78773"
    />
    <path
      d="M106.841 161.828L122.261 162.02L116.867 156.937C116.215 156.318 115.373 155.935 114.477 155.851C113.581 155.766 112.683 155.984 111.926 156.471C110.89 157.136 109.965 157.96 109.185 158.913C109.185 158.913 99.5901 155.401 94.9023 147.526C93.0606 150.274 91.5122 153.208 90.283 156.279C94.4498 158.735 99.8779 160.669 106.841 161.828Z"
      fill="#E0A37F"
    />
    <path
      d="M108.499 163.817H108.595L147.735 163.776C148.195 163.776 148.636 163.593 148.961 163.268C149.286 162.942 149.468 162.501 149.468 162.041C149.468 161.58 149.286 161.139 148.961 160.813C148.636 160.488 148.195 160.305 147.735 160.305L108.595 160.353C108.152 160.367 107.732 160.55 107.42 160.864C107.108 161.179 106.928 161.6 106.916 162.043C106.905 162.486 107.063 162.917 107.359 163.246C107.654 163.576 108.064 163.78 108.506 163.817H108.499Z"
      fill={determineColor('--gray-900')}
    />
    <path
      d="M182.921 128.622H150.326C149.151 128.62 148 128.95 147.006 129.575C146.011 130.2 145.214 131.095 144.706 132.155L129.629 163.797H170.139C170.893 163.798 171.631 163.573 172.256 163.151C172.881 162.73 173.366 162.13 173.648 161.43L185.333 132.148C185.488 131.756 185.544 131.331 185.497 130.912C185.45 130.493 185.301 130.092 185.062 129.744C184.824 129.396 184.504 129.112 184.131 128.916C183.758 128.721 183.342 128.62 182.921 128.622Z"
      fill={determineColor('--gray-900')}
    />
    <path
      d="M159.955 144.851C159.269 147.026 159.769 149.132 161.12 149.564C162.47 149.996 164.142 148.583 164.848 146.409C165.554 144.234 165.033 142.121 163.683 141.696C162.333 141.271 160.661 142.677 159.955 144.851Z"
      fill={determineColor('--primary-500')}
    />
    <path
      d="M348.389 82.9122C348.426 83.3626 348.594 83.7925 348.871 84.1491C349.149 84.5056 349.524 84.7736 349.951 84.9201C350.379 85.0665 350.839 85.0853 351.277 84.974C351.714 84.8626 352.11 84.626 352.416 84.2931C352.721 83.9602 352.923 83.5453 352.997 83.0994C353.07 82.6535 353.012 82.1957 352.83 81.7822C352.648 81.3686 352.349 81.0174 351.971 80.771C351.592 80.5246 351.15 80.3938 350.699 80.3949C350.06 80.4269 349.461 80.709 349.028 81.18C348.596 81.651 348.367 82.2731 348.389 82.9122Z"
      fill="white"
    />
    <path
      d="M258.3 226.703L459.058 241.491C459.909 241.552 460.763 241.434 461.565 241.143C462.367 240.852 463.099 240.395 463.713 239.803C464.327 239.21 464.81 238.495 465.129 237.703C465.449 236.911 465.599 236.061 465.569 235.208V85.5805C465.6 83.82 464.932 82.1191 463.711 80.851C462.49 79.583 460.817 78.8516 459.058 78.8174H258.3C255.34 78.8174 252.948 81.5612 252.948 84.9291V220.193C252.948 223.568 255.34 226.483 258.3 226.703Z"
      fill={determineColor('--gray-300')}
    />
    <path
      d="M142.052 239.403L457.934 236.659V244.739C457.934 246.934 456.563 248.8 454.685 249.136L365.364 265.056C364.309 265.247 363.233 265.298 362.164 265.207L145.486 247.051C143.553 246.893 142.059 244.993 142.059 242.764L142.052 239.403Z"
      fill={determineColor('--gray-300')}
    />
    <path
      d="M255.1 226.703L455.864 241.491C456.715 241.552 457.57 241.434 458.372 241.143C459.174 240.852 459.906 240.395 460.52 239.803C461.134 239.21 461.616 238.495 461.936 237.703C462.256 236.911 462.405 236.061 462.375 235.208V85.5805C462.406 83.82 461.738 82.1191 460.518 80.851C459.297 79.583 457.623 78.8516 455.864 78.8174H255.1C252.139 78.8174 249.748 81.5612 249.748 84.9291V220.193C249.748 223.568 252.139 226.483 255.1 226.703Z"
      fill="white"
    />
    <path
      d="M261.511 217.203L448.5 230.071C449.195 230.115 449.892 230.012 450.545 229.77C451.198 229.529 451.793 229.153 452.293 228.667C452.792 228.181 453.185 227.596 453.445 226.95C453.706 226.304 453.828 225.61 453.805 224.913V93.9014C453.82 92.467 453.273 91.0837 452.281 90.0478C451.29 89.0119 449.933 88.4057 448.5 88.3591L261.511 87.5566C259.071 87.5566 257.098 89.7858 257.098 92.5638V211.88C257.098 214.645 259.099 217.039 261.511 217.203Z"
      fill={determineColor('--gray-100')}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M274.904 218.101C277.1 216.726 278.988 215.243 280.622 213.745C285.867 208.94 288.321 204.166 290.6 199.734C293.347 194.391 295.838 189.545 302.656 185.739C310.063 181.601 316.716 181.929 322.928 182.236C329.762 182.573 336.062 182.884 342.248 177.193C345.731 173.987 346.686 171.179 347.764 168.006C348.9 164.661 350.175 160.911 354.694 155.86C359.788 150.171 363.952 148.449 368.163 146.708C372.019 145.114 375.915 143.504 380.6 138.815C386.936 132.477 388.407 126.857 389.759 121.688C391.053 116.744 392.239 112.212 397.473 107.859C404.017 102.401 410.821 102.252 417.541 102.104C424.121 101.96 430.622 101.817 436.722 96.6926C439.487 94.3679 441.599 91.4947 443.206 88.3021L448.339 88.3242C449.772 88.3707 451.129 88.9769 452.121 90.0128C453.112 91.0488 453.659 92.432 453.644 93.8665V224.878C453.667 225.575 453.545 226.269 453.285 226.915C453.024 227.562 452.632 228.146 452.132 228.632C451.633 229.118 451.037 229.494 450.384 229.735C449.731 229.977 449.034 230.08 448.339 230.036L274.904 218.101Z"
      fill={determineColor('--gray-50')}
    />
    <path
      d="M245.635 225.88L457.934 241.45L365.364 257.954C364.309 258.142 363.233 258.19 362.164 258.098L142.052 239.639L242.263 225.969C243.381 225.819 244.511 225.789 245.635 225.88Z"
      fill={determineColor('--gray-50')}
    />
    <path
      d="M426.957 241.587L250.214 228.431L195.516 235.832L370.731 249.894L426.957 241.587Z"
      fill={determineColor('--gray-100')}
    />
    <path
      d="M425.833 114.746C443.378 124.637 462.691 123.91 470.106 113.374V113.326C470.414 112.895 470.7 112.449 470.963 111.989C471.288 111.408 471.579 110.807 471.833 110.191L480.221 94.9159L481.962 91.7402L461.703 80.7175C458.169 77.2803 454.202 74.3196 449.902 71.9103C445.117 69.1908 439.958 67.1913 434.591 65.9768L414.312 54.9541L404.855 74.4551C404.82 74.5168 404.772 74.5784 404.738 74.647C404.369 75.3087 404.048 75.9965 403.778 76.7047C403.347 77.8116 403.034 78.9613 402.846 80.1344C401.023 91.4796 410.18 105.918 425.833 114.746Z"
      fill={determineColor('--secondary-800')}
    />
    <path
      d="M425.75 114.883C430.459 117.748 435.624 119.785 441.02 120.905C446.503 121.976 453.938 122.38 459.318 120.665C468.269 117.826 471.257 111.063 475.424 103.579C477.028 100.691 478.817 97.9065 480.269 94.9365C474.787 95.4647 468.338 98.7638 463.766 101.645C459.819 104.135 456.289 107.523 451.896 109.238C442.198 113.011 430.068 109.238 423.112 101.823C418.863 97.303 418.54 90.2518 413.517 86.3146C410.261 83.7698 405.937 82.9604 402.894 80.1412C403.435 80.6488 402.983 85.2444 403.086 86.1772C403.324 88.3169 403.828 90.4184 404.587 92.4329C406.376 97.1384 409.494 101.261 412.996 104.821C416.805 108.702 421.091 112.083 425.75 114.883Z"
      fill={determineColor('--secondary-800')}
    />
    <path
      d="M398.699 46.4966L460.312 46.2771L497.937 95.1081L499.849 96.0478L499.445 96.8778L435.386 97.1179L396.287 46.3389L396.691 45.5157L398.699 46.4966Z"
      fill={determineColor('--secondary-800')}
    />
    <path
      d="M460.867 45.1385L500 95.9313L435.92 96.1576L396.787 45.3649L460.867 45.1385Z"
      fill={determineColor('--secondary-600')}
    />
    <path
      d="M482.661 129.535C482.892 129.519 483.108 129.416 483.266 129.247C483.424 129.077 483.512 128.854 483.511 128.622L483.271 96.9327C483.272 96.8036 483.245 96.6758 483.193 96.5576C483.141 96.4394 483.065 96.3335 482.97 96.2468L449.593 65.8534C449.505 65.7719 449.402 65.7086 449.289 65.6675C449.176 65.6264 449.056 65.6082 448.936 65.6139C448.816 65.6197 448.699 65.6493 448.59 65.701C448.482 65.7527 448.385 65.8254 448.305 65.915C448.144 66.095 448.06 66.3312 448.071 66.5725C448.083 66.8138 448.189 67.0408 448.367 67.2045L481.448 97.3236L481.688 128.616C481.689 128.735 481.713 128.854 481.76 128.964C481.807 129.075 481.874 129.175 481.96 129.259C482.045 129.343 482.146 129.409 482.257 129.454C482.368 129.499 482.486 129.522 482.606 129.521L482.661 129.535Z"
      fill={determineColor('--secondary-800')}
    />
    <path
      d="M484.367 127.648L483.565 126.592C483.416 126.396 483.217 126.244 482.989 126.152C482.76 126.06 482.512 126.031 482.268 126.068C482.025 126.105 481.796 126.207 481.606 126.363C481.416 126.519 481.271 126.724 481.187 126.956L480.803 128.224C480.724 128.537 480.724 128.865 480.803 129.178L482.174 139.213C482.174 139.426 483.065 139.487 484.23 139.329C485.395 139.172 486.197 138.884 486.163 138.644L484.792 128.581C484.731 128.239 484.585 127.918 484.367 127.648Z"
      fill={determineColor('--secondary-800')}
    />
  </svg>
);

export default Image;
