import { PageType } from '@core/blocks/edu-flow/components/Page/types';
import QUESTIONS from '@core/schemas/question_bank.json';

enum BlockTypes {
  EduFlow = 'EduFlow',
  EduMatch = 'EduMatch',
}

type TestSchema = {
  name: 'voyager';
  version: 'test';
  id: 'test';
  blocks: Array<{
    type: BlockTypes;
    pages?: PageType[];
    algolia?: {
      indexes: Record<string, string>;
    };
  }>;
};

const buildAllQuestionsFlow = (): TestSchema => {
  const testSchema: TestSchema = {
    name: 'voyager',
    version: 'test',
    id: 'test',
    blocks: [
      { type: BlockTypes.EduFlow, pages: [] },
      {
        type: BlockTypes.EduMatch,
        algolia: {
          indexes: {
            bestMatch: 'staging_edudirect_programs_voyager',
            distance: 'staging_edudirect_programs_voyager_distance',
          },
        },
      },
    ],
  };

  const questions = Object.keys(QUESTIONS);
  const controlQuestions = questions.filter((q) => !q.includes('test'));

  controlQuestions.forEach((questionId, index) => {
    testSchema.blocks[0].pages.push({
      questionId,
      slug: `/${questionId}`,
      stepNumber: index + 1,
    });
  });
  testSchema.blocks[0].pages[controlQuestions.length - 1].overrideNextStep = '/results';

  return testSchema;
};

export default buildAllQuestionsFlow;
