/* eslint-disable jsx-a11y/label-has-associated-control */
import formStyles from '@edu-flow/styles/Form.module.css';
import { type FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useFieldEvents from '@core/hooks/cohesion/useFieldEvents';
import { selectFormCorrelationId, selectStepContext } from '@core/reducers/eventingSlice';
import { setInput } from '@core/reducers/inputsSlice';
import cn from '@core/utils/classNames';

import type { FieldComponentProps } from '../types';
import styles from './CheckboxField.module.css';

const CheckboxField: FC<FieldComponentProps> = ({ field, fieldNumber }) => {
  const [fieldInput, setFieldInput] = useState(field?.default || false);
  const dispatch = useDispatch();

  // Form Correlation Id for events
  const formCorrelationId = useSelector(selectFormCorrelationId);
  // stepContext for for fields events
  const stepContext = useSelector(selectStepContext);

  const userInputField = {
    autofilled: true,
    fieldName: field.name,
    fieldLabel: field.label,
    fieldType: field.type,
    fieldValue: 'true',
    fieldNumber,
  };

  const { fieldSelected, fieldViewed } = useFieldEvents({
    correlationId: formCorrelationId,
    userInputField,
  });

  useEffect(() => {
    fieldViewed();
    dispatch(setInput({ key: field.name, value: fieldInput }));
  }, []);

  useEffect(() => {
    if (stepContext) {
      fieldSelected();
    }
  }, [stepContext]);

  const handleChange = () => {
    dispatch(setInput({ key: field.name, value: !fieldInput }));
    setFieldInput(!fieldInput);
    fieldSelected({
      userInputField: {
        ...userInputField,
        autofilled: false,
        fieldValue: (!fieldInput).toString(),
      },
    });
  };

  return (
    <button type="button" data-testid={field.name} className={cn(formStyles.fieldGroup, styles.checkboxContainer)}>
      <input
        type="checkbox"
        name={field.name}
        id={field.name}
        checked={fieldInput as boolean}
        onChange={handleChange}
        aria-label={field.label}
      />
      <label
        className={styles.label}
        htmlFor={field.name}
        dangerouslySetInnerHTML={{ __html: (field?.label || field?.name) as string }}
      />
    </button>
  );
};

export default CheckboxField;
