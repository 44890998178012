import { Dispatch, FC, SetStateAction, useEffect, useMemo } from 'react';
import { HiCheckCircle, HiOutlineChevronDoubleRight } from 'react-icons/hi2';
import { TiLocation } from 'react-icons/ti';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import Card from '@core/blocks/eduResults/components/Card';
import useElementEvents from '@core/hooks/cohesion/useElementEvents';
import useProductEvents from '@core/hooks/cohesion/useProductEvents';
import useSchoolConfig from '@core/hooks/useSchoolConfig';
import {
  selectRecommenderPopupListId,
  setHeclidMapEntry,
  setProductCorrelationIdMapEntry,
  setViewCorrelationIdMapEntry,
} from '@core/reducers/eventingSlice';
import { selectAllInputs } from '@core/reducers/inputsSlice';
import { selectSubmissions, setCurrentProgram } from '@core/reducers/matchesSlice';
import { mobiusRedirect } from '@core/services/mobius';
import { VoyagerResult } from '@core/ts/results';
import getToken from '@core/utils/getToken';
import { continueBrowsingBtn } from '@core/utils/objects/eventPayloads';

import SponsoredResultsPopover from '../components/SponsoredResultsPopover';
import styles from './SpotlightProgram.module.css';

type Props = {
  hit: VoyagerResult;
  eventingOverrides: Record<string, any>;
  setRecommenderStep: Dispatch<SetStateAction<string>>;
  showAdVerbiageTest?: boolean;
  closePopup: () => void;
};

const SpotlightProgram: FC<Props> = ({
  hit,
  eventingOverrides,
  setRecommenderStep,
  closePopup,
  showAdVerbiageTest = false,
}) => {
  const dispatch = useDispatch();
  const schoolConfig = useSchoolConfig(hit.school.id);
  const submissions = useSelector(selectSubmissions);
  const popupListId = useSelector(selectRecommenderPopupListId);
  const inputs = useSelector(selectAllInputs);
  const newViewCorrelationId = useMemo(() => uuid(), []);

  const { productLoaded, productViewed, productClicked } = useProductEvents({
    listId: popupListId,
    viewCorrelationId: newViewCorrelationId,
    product: {
      sku: String(hit.cap.id),
      variant: hit.program.degree.slug,
      productId: String(hit.program.id),
      name: hit.program.subject.slug,
      category: hit.program.category.slug,
      brand: hit.school.slug,
      position: 1,
      location: eventingOverrides?.location,
      formatType: 'app',
      formatSubtype: 'recommendation',
      positionEngine: 'algolia',
      ...eventingOverrides.product,
    },
    customDimensions: [{ key: 'isUserRequested', value: 'true' }, ...(eventingOverrides.customDimensions || [])],
  });

  const { elementClicked } = useElementEvents({
    webElement: {
      elementType: 'exit-element',
      text: 'Skip to all results',
      location: eventingOverrides?.location,
      htmlId: 'thank-you-button',
      name: 'skip-recommender',
    },
  });

  useEffect(() => {
    productLoaded();
    productViewed();
    dispatch(
      setViewCorrelationIdMapEntry({
        key: hit.program.id,
        value: newViewCorrelationId,
      })
    );
  }, []);

  const handleRequestInfo = async () => {
    dispatch(setCurrentProgram({ ...hit, leadSource: 'spotlight' }));

    const newProductCorrelationId = uuid();

    // add correlationId to map for LDS lead submission
    dispatch(
      setProductCorrelationIdMapEntry({
        key: hit.program.id,
        value: newProductCorrelationId,
      })
    );

    productClicked({
      correlationId: newProductCorrelationId,
    });

    // Mobius Redirect will fire the OutcomeTracked APP_VIEWED event
    const { heclid } = await mobiusRedirect({
      idToken: getToken(),
      productCorrelationId: newProductCorrelationId,
      link: hit.url,
      trackingContextOverride: {
        formatType: 'app',
        formatSubtype: 'recommendation',
      },
    });
    // add heclid to map for Mobius track if API call succeeds
    dispatch(setHeclidMapEntry({ key: hit.program.id, value: heclid }));

    setRecommenderStep('PII_CONFIRMATION');
  };

  if (!schoolConfig.data) return null;

  const { spotlight } = schoolConfig.data;
  const programHasBeenSubmitted = submissions.find((submission) => submission.program.id === hit.program.id);

  const handleContinueBtnClick = () => {
    elementClicked(continueBrowsingBtn);
    if (closePopup) closePopup();
  };

  return (
    <section className={styles.spotlightContainer}>
      <div className={styles.spotlightContent}>
        <p className={styles.spotlightPersonalization}>
          {inputs.firstName?.value ? `Great news, ${inputs.firstName.value}!` : 'Great news!'}
        </p>
        <h2 className={styles.spotlightIntro}>We&apos;ve found a great school near you!</h2>
        <div className={styles.spotlightInfo}>
          <div className={styles.spotlightProgramCard}>
            <Card
              result={hit}
              eventingOverrides={eventingOverrides}
              position={1}
              ctaLabel="Explore Program"
              onClick={programHasBeenSubmitted ? undefined : handleRequestInfo}
              isSpotlight
            />
          </div>

          <div className={styles.spotlightHighlights}>
            <p className={styles.ptag}>{spotlight?.header}</p>
            <div className={styles.highlights}>
              <h5 className={styles.highlightsTitle}>School Highlights</h5>
              <ul className={styles.checks}>
                {(spotlight?.blocks?.[0]?.list ?? []).map(({ text }) => (
                  <li className={styles.check} key={text}>
                    <HiCheckCircle size="1.2rem" /> {text}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className={styles.sponsoredResults}>
          <SponsoredResultsPopover spanText="Learn More About Our Partners" position="above" />
        </div>

        {programHasBeenSubmitted ? null : (
          <button className={styles.requestInfo} type="button" onClick={handleRequestInfo}>
            Explore Program
          </button>
        )}

        <button className={styles.continueToResults} onClick={handleContinueBtnClick} type="button">
          Skip to all results <HiOutlineChevronDoubleRight size={16} />
        </button>
      </div>
      <div className={styles.mobileCta}>
        <div className={styles.mobileCtaSchool}>
          <h3>{hit.school.name}</h3>
          {hit.school.state && hit.school.city && (
            <div className={styles.mobileCtaLocation}>
              <TiLocation size={16} />
              <p>
                {hit.school.city}, {hit.school.state}
              </p>
            </div>
          )}
        </div>
        {programHasBeenSubmitted ? null : (
          <button className={styles.requestInfo} type="button" onClick={handleRequestInfo}>
            Explore Program
          </button>
        )}
        <button className={styles.continueToResults} onClick={handleContinueBtnClick} type="button">
          Skip to all results <HiOutlineChevronDoubleRight size={16} />
        </button>
      </div>
    </section>
  );
};

export default SpotlightProgram;
