import { type FlagKey, type Flags } from '@core/context/FeatureFlagsContext';
import { type MonarchEditableAsset } from '@core/context/types';

import { type QuestionType } from '../blocks/edu-flow/components/Page/types';
import generateOptionGroupAssetQuestion from './assets/generateOptionGroupAssetQuestion';
import addAdditionalContent from './utils/addAdditionalContent';

const assetTypeToGenQuestionMap = {
  'option-group': generateOptionGroupAssetQuestion,
};

const generateAssetQuestion = (question: QuestionType, flags: Flags): QuestionType => {
  const monarchEditableAsset = flags[question?.monarchEditableAssetFlag as FlagKey] as MonarchEditableAsset;
  const { questionType } = monarchEditableAsset;

  if (!questionType) {
    console.error(`Invalid type found in monarchEditableAsset: ${question?.monarchEditableAssetFlag}`);
    return question;
  }

  const generateFunction = assetTypeToGenQuestionMap[questionType];

  if (!generateFunction) {
    console.error(`No asset generation function found for question type: ${questionType}`);
    return question;
  }

  const questionWithoutAdditionalContent = generateFunction(question, monarchEditableAsset);

  const finishedQuestion = addAdditionalContent(questionWithoutAdditionalContent, monarchEditableAsset);

  return finishedQuestion;
};
export default generateAssetQuestion;
