import type { Qualifier, VoyagerResult } from '@core/ts/results';

import whitelistedQualifiers from './data';

type WhitelistedQualifier = {
  id: string;
  field: string;
  rank?: number;
  label: string;
};

const getHighestRankWhitelistedQualifier = (whitelistedQualifiers: WhitelistedQualifier[]) =>
  whitelistedQualifiers.reduce((accumulator, current) => {
    if (accumulator.rank && current.rank) {
      return accumulator.rank < current.rank ? accumulator : current;
    }
    return accumulator;
  });

const getUniqueQualifierIdsInResults = (hits: VoyagerResult[]) => {
  const allQualifierIdsInResults = hits?.reduce((acc: string[], hit: VoyagerResult) => {
    if (hit.restrictions?.qualifierIds) {
      return [...acc, ...hit.restrictions.qualifierIds];
    }
    return acc;
  }, []);

  return [...new Set(allQualifierIdsInResults)];
};

const getQualifierIdsThatLeadToResults = (qualifiers: Qualifier[], uniqueQualifierIdsInResults: string[]) =>
  qualifiers?.reduce((acc: string[], qualifier: Qualifier) => {
    if (uniqueQualifierIdsInResults.includes(qualifier.id)) {
      return [...acc, qualifier.id];
    }
    return acc;
  }, []);

const getWhitelistedQualifierLabels = (
  qualifierIdsThatLeadToResults: string[],
  whitelistedQualifiers: WhitelistedQualifier[]
) =>
  qualifierIdsThatLeadToResults?.reduce((acc: string[], qualifierId: string) => {
    const currentQualifier = whitelistedQualifiers.find((_qualifier) => _qualifier.id === qualifierId);
    if (!currentQualifier) {
      return acc;
    }

    if (currentQualifier?.rank) {
      const sameFieldQualifiers = whitelistedQualifiers.filter(
        (_qualifier) =>
          _qualifier.field === currentQualifier?.field && qualifierIdsThatLeadToResults.includes(_qualifier.id)
      );

      const highestPriorityWhitelistedQualifier = getHighestRankWhitelistedQualifier(sameFieldQualifiers);

      return [...acc, highestPriorityWhitelistedQualifier.label];
    }

    return [...acc, currentQualifier?.label];
  }, []);

const getLabelsForMatchContextPills = (hits: VoyagerResult[], qualifiers?: Qualifier[]) => {
  if (!qualifiers) {
    return [];
  }
  const uniqueQualifierIdsInResults = getUniqueQualifierIdsInResults(hits);
  const qualifierIdsThatLeadToResults = getQualifierIdsThatLeadToResults(qualifiers, uniqueQualifierIdsInResults);
  const labels = getWhitelistedQualifierLabels(qualifierIdsThatLeadToResults, whitelistedQualifiers);
  const uniqueLabels = [...new Set(labels)];
  return uniqueLabels;
};

export default getLabelsForMatchContextPills;
