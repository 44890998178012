import { AnimationEventHandler, FC, KeyboardEventHandler, MouseEventHandler, RefObject, useState } from 'react';
import { FaChevronLeft } from 'react-icons/fa';

import cn from '@core/utils/classNames';

import EmptyCookiesPopup from '../EmptyCookiesPopup';
import Fields from '../Fields';
import Heading from '../Heading';
import ProgressBar from '../ProgressBar';
import styles from './Page.module.css';
import { PageType, QuestionType } from './types';

export type DefaultPageProps = {
  showAnimation?: 'NONE' | 'FORWARD' | 'BACK';
  flow?: PageType[];
  page?: PageType;
  prevPage?: PageType;
  onPreviousPage?: MouseEventHandler<HTMLButtonElement>;
  onContinue?: MouseEventHandler<HTMLButtonElement>;
  buttonRef: RefObject<HTMLButtonElement>;
  onAnimationEnd?: AnimationEventHandler<HTMLDivElement>;
  showContinueButton?: boolean;
  question: QuestionType;
  fieldErrors?: Error[];
  validating?: boolean;
  displayCookiesPopup?: boolean;
  cookiesPopupStateSetter?: (value: boolean) => void;
};

const DefaultPage: FC<DefaultPageProps> = ({
  showAnimation,
  flow,
  page,
  prevPage,
  onPreviousPage,
  onAnimationEnd,
  showContinueButton,
  question,
  fieldErrors,
  onContinue,
  buttonRef,
  validating,
  displayCookiesPopup,
  cookiesPopupStateSetter,
}) => (
  <section className={cn(showAnimation !== 'NONE' && styles.overflowXClipped)}>
    <ProgressBar flow={flow} page={page} />
    {displayCookiesPopup && (
      <EmptyCookiesPopup displayPopup={displayCookiesPopup} popupState={cookiesPopupStateSetter} />
    )}

    {prevPage && (
      <button data-testid="button-previous" onClick={onPreviousPage} type="button" className={styles.buttonPrevious}>
        <FaChevronLeft className={styles.buttonChevron} />
        <span className={styles.buttonText}>Back</span>
      </button>
    )}

    <div
      className={cn(
        styles.pageConatiner,
        showAnimation === 'NONE' && styles.notAnimated,
        showAnimation === 'FORWARD' && styles.animateLeft,
        showAnimation === 'BACK' && styles.animateRight
      )}
      // onAnimationEndCapture={() => setShowAnimation('NONE')}
      onAnimationEndCapture={onAnimationEnd}
    >
      <Heading
        heading={question.heading}
        preSubheading={question.preSubheading}
        postSubheading={question.postSubheading}
      />
      <Fields errors={fieldErrors} fields={question.fields} heading={question.heading} />

      <div className={styles.pageActions}>
        {showContinueButton && (
          <button
            type="button"
            className={`${styles.buttonContinue} ${styles.button}`}
            ref={buttonRef}
            onClick={onContinue}
            disabled={validating ?? undefined}
            data-testid="continue"
          >
            <span>Continue</span>
            <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.6774 0.468629C17.3023 -0.15621 18.3153 -0.15621 18.9402 0.468629L25.3402 6.86863C25.965 7.49347 25.965 8.50653 25.3402 9.13137L18.9402 15.5314C18.3153 16.1562 17.3023 16.1562 16.6774 15.5314C16.0526 14.9065 16.0526 13.8935 16.6774 13.2686L20.346 9.6H1.80879C0.925131 9.6 0.208786 8.88366 0.208786 8C0.208786 7.11634 0.925131 6.4 1.80879 6.4H20.346L16.6774 2.73137C16.0526 2.10653 16.0526 1.09347 16.6774 0.468629Z"
                fill="white"
              />
            </svg>
          </button>
        )}
      </div>
      {question.disclaimer && (
        <div className={styles.disclaimer} dangerouslySetInnerHTML={{ __html: question.disclaimer }} />
      )}
    </div>
  </section>
);

DefaultPage.defaultProps = {
  showAnimation: 'NONE',
  onPreviousPage: () => {},
  onAnimationEnd: () => {},
  onContinue: () => {},
  showContinueButton: true,
  validating: false,
  displayCookiesPopup: false,
  cookiesPopupStateSetter: () => {},
};

export default DefaultPage;
