import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Dots from '@core/blocks/edu-match/components/Loading/Dots';
import { useFeatureFlags } from '@core/context/FeatureFlagsContext';
import useElementEvents from '@core/hooks/cohesion/useElementEvents';
import { selectAllInputs } from '@core/reducers/inputsSlice';
import { removeProgramFromList, selectLeadDelivery } from '@core/reducers/matchesSlice';
import SponsoredResultsPopover from '@core/shared/components/SponsoredResultsPopover';
import { Hit } from '@core/ts/algolia';
import { VoyagerResult, VoyagerResultEnhancedSchoolData } from '@core/ts/results';
import cn from '@core/utils/classNames';

import { schoolBumpData } from '../../utils';
import RecommenderCardRevamp from '../RecommenderCardRevamp';
import styles from './ProgramSelectRevamp.module.css';

type Props = {
  enhancedSchoolData?: VoyagerResultEnhancedSchoolData;
  hits: VoyagerResult[];
  setRecommenderStep: Dispatch<SetStateAction<string>>;
  closePopup: () => void;
  eventingOverrides: { [key: string]: any };
  showAdVerbiageTest?: boolean;
};
const ProgramSelectRevamp: FC<Props> = ({
  hits,
  setRecommenderStep,
  closePopup,
  eventingOverrides,
  enhancedSchoolData,
  showAdVerbiageTest,
}) => {
  const [loading, setLoading] = useState(false);

  const flags = useFeatureFlags();

  // determine if recommender should be single submission
  // DEV NOTE: setting this to just be true for the purposes of a desired optimization
  // and we don't want to remove the multi-select code just yet

  // dispatch action
  const dispatch = useDispatch();

  // current programs from store
  const { currentPrograms } = useSelector(selectLeadDelivery);

  // inputs state properties
  const inputs = useSelector(selectAllInputs);

  const { elementViewed, elementClicked } = useElementEvents({
    webElement: {
      elementType: 'exit-element',
      text: 'Skip to all results',
      location: eventingOverrides?.location,
      htmlId: 'thank-you-button',
      name: 'skip-recommender',
    },
  });

  const onClose = () => {
    // if there are any selections and user closes the modal, we remove them from the currentPrograms list
    for (const current of currentPrograms) {
      dispatch(removeProgramFromList(current));
    }
    if (closePopup) closePopup();
    elementClicked();
  };
  useEffect(() => {
    elementViewed();
  }, []);

  const singleHit = hits.length === 1;

  if (loading) {
    return (
      <section className={styles.loadingContainer}>
        <h2>Loading...</h2>
        <Dots />
      </section>
    );
  }
  const schoolSpecificData: Record<string, { title: string; subtitle: string }> = schoolBumpData;

  const isGradResultsTest = flags?.voyagerGradResultsPage === 'test';

  return (
    <section className={cn(styles.programSelect, isGradResultsTest && styles.gradRPTest)}>
      <div className={styles.content}>
        <header>
          <p className={styles.personalization}>
            {inputs.firstName?.value ? `Great news, ${inputs.firstName.value}!` : 'Great news!'}
          </p>
          {singleHit ? (
            <>
              <h2 id="recommender_title" className={styles.title}>
                You’re a strong match for this school!
              </h2>
            </>
          ) : (
            <h2 id="recommender_title" className={styles.title}>
              You’re a strong match for these schools!
            </h2>
          )}
        </header>

        <div id="recommender_results" className={styles.cards}>
          {hits?.map((hit: Hit, index: number) => (
            <RecommenderCardRevamp
              isSingleResultPopupRecommender={hits.length === 1}
              enhancedSchoolData={enhancedSchoolData?.[hit.school.sdbSchoolId]}
              key={hit.program.id}
              result={hit}
              eventingOverrides={{
                location: eventingOverrides?.location,
                customDimensions: [
                  { key: 'isUserRequested', value: 'true' },
                  ...(eventingOverrides.customDimensions || []),
                ],
              }}
              position={index + 1}
              setLoading={setLoading}
              setRecommenderStep={setRecommenderStep}
            />
          ))}
        </div>
      </div>

      <div className={styles.actions}>
        <div className={styles.sponsoredResultsPopover}>
          {showAdVerbiageTest ? (
            <SponsoredResultsPopover position="above" spanText="Learn More About Our Partners" />
          ) : (
            <SponsoredResultsPopover position="above" spanText="Sponsored" />
          )}
        </div>

        <button type="button" onClick={onClose} className={styles.skipToResults}>
          Skip to all results &gt;&gt;
        </button>
      </div>
    </section>
  );
};

export default ProgramSelectRevamp;
