/* eslint-disable react/jsx-props-no-spreading */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import useFieldEvents from '@core/hooks/cohesion/useFieldEvents';
import { selectFormContext } from '@core/reducers/eventingSlice';
import deepMerge from '@core/utils/deepMerge';

import type { Option, TEventingHOC } from './types';

// This is a Higher-Order component that fires the FieldSelected events for the inputs

const withFieldSelected: TEventingHOC =
  ({ Component, schoolSlug, formCorrelationId, eventingOverrides }) =>
  (props) => {
    const { name, schema, uiSchema, formData } = props;
    const formContext = useSelector(selectFormContext);

    const getFieldNumber = () =>
      Array.from(document.getElementsByClassName('prq')).findIndex((field) => field.id === uiSchema?.['ui:id']) + 1;

    const userInputField = {
      autofilled: false,
      fieldName: name,
      fieldLabel: uiSchema?.['ui:title'],
      fieldType: schema.type ?? schema.anyOf?.[0]?.type ?? schema.oneOf?.[0]?.type ?? schema.allOf?.[0]?.type,
    };

    const { fieldViewed, fieldSelected } = useFieldEvents(
      deepMerge(
        {
          correlationId: formCorrelationId,
          formContext: {
            ...formContext,
            formVersion: 'dynamic-post-flow',
            formType: 'post-flow',
            formId: '1015',
            formBrand: schoolSlug,
          },
          stepContext: {
            stepNumber: 1,
            stepId: undefined,
            stepName: undefined,
            stepType: undefined,
          },
          userInputField,
        },
        eventingOverrides
      )
    );

    useEffect(() => {
      fieldViewed({
        userInputField: {
          ...userInputField,
          fieldNumber: getFieldNumber(),
        },
      });
    }, []);

    useEffect(() => {
      if (formData !== undefined) {
        const fieldValue =
          uiSchema?.['ui:options']?.options?.find((option: Option) => option.value === formData)?.label ?? formData;

        fieldSelected({
          userInputField: {
            ...userInputField,
            fieldNumber: getFieldNumber(),
            fieldValue: String(fieldValue),
          },
        });
      }
    }, [formData]);

    return <Component {...props} />;
  };

export default withFieldSelected;
