import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

import type { EventingContext } from '@core/context/types';
import { RootState } from '@core/store';

// Event State with default values
const eventingDefaults: EventingContext = {
  listId: uuid(),
  recommenderPopupListId: uuid(),
  formContext: {
    formId: '',
    formName: '',
    formVersion: '',
    formType: 'flow',
  },
  sourceCorrelationId: '',
  formCorrelationId: uuid(),
  productCorrelationIdMap: {},
  viewCorrelationIdMap: {},
  hasFiredFormViewed: false,
  hasFiredFormStarted: false,
  hasFiredFormSubmitted: false,
  heclidMap: {},
  userInputFieldList: [],
  allResultsCount: 0,
  numOfSkippedQuestions: 0,
};

export const eventingSlice = createSlice({
  name: 'eventing',
  initialState: eventingDefaults, // set our initial state with default values
  reducers: {
    // Update value for list id
    setListId: (state: EventingContext, action) => {
      state.listId = action.payload;
    },
    setRecommenderPopupListId: (state: EventingContext, action) => {
      state.recommenderPopupListId = action.payload;
    },
    setSourceCorrelationId: (state: EventingContext, action) => {
      state.sourceCorrelationId = action.payload;
    },
    setFormCorrelationId: (state: EventingContext, action) => {
      state.formCorrelationId = action.payload;
    },
    setProductCorrelationIdMapEntry: (state: EventingContext, action) => {
      state.productCorrelationIdMap[action.payload.key] = action.payload.value;
    },
    setViewCorrelationIdMapEntry: (state, action) => {
      state.viewCorrelationIdMap[action.payload.key] = action.payload.value;
    },
    setHeclidMapEntry: (state: EventingContext, action) => {
      state.heclidMap[action.payload.key] = action.payload.value;
    },
    setHasFiredFormStarted: (state) => {
      state.hasFiredFormStarted = true;
    },
    setHasFiredFormSubmitted: (state) => {
      state.hasFiredFormSubmitted = true;
    },
    setHasFiredFormViewed: (state) => {
      state.hasFiredFormViewed = true;
    },
    setStepContext: (state, action) => {
      state.stepContext = action.payload;
    },
    setFormContext: (state, action) => {
      state.formContext = action.payload;
    },
    initFormContext: (state, action) => {
      state.formContext.formId = action.payload.id;
      state.formContext.formName = 'voyager';
      state.formContext.formVersion = action.payload.version;
    },
  },
});

// Actions
export const {
  setListId,
  setFormCorrelationId,
  setHeclidMapEntry,
  setHasFiredFormStarted,
  setHasFiredFormViewed,
  setHasFiredFormSubmitted,
  setProductCorrelationIdMapEntry,
  setFormContext,
  initFormContext,
  setStepContext,
  setViewCorrelationIdMapEntry,
  setRecommenderPopupListId,
  setSourceCorrelationId,
} = eventingSlice.actions;
// Selectors

export const selectViewCorrelationIdMap = (state: RootState): Record<string, string> =>
  state.eventing.viewCorrelationIdMap;
export const selectListId = (state: RootState) => state.eventing.listId;
export const selectFormCorrelationId = (state: RootState) => state.eventing.formCorrelationId;
export const selectProductCorrelationIdMap = (state: RootState): Record<string, string> =>
  state.eventing.productCorrelationIdMap;
export const selectHeclidMap = (state: RootState): Record<string, string> => state.eventing.heclidMap;
export const selectHasFiredFormStarted = (state: RootState) => state.eventing.hasFiredFormStarted;
export const selectHasFiredFormSubmitted = (state: RootState) => state.eventing.hasFiredFormSubmitted;
export const selectHasFiredFormViewed = (state: RootState) => state.eventing.hasFiredFormViewed;
export const selectFormContext = (state: RootState) => state.eventing.formContext;
export const selectStepContext = (state: RootState) => state.eventing.stepContext;
export const selectNumOfSkippedQuestions = (state: RootState) => state.eventing.numOfSkippedQuestions;
export const selectRecommenderPopupListId = (state: RootState) => state.eventing.recommenderPopupListId;
export const selectSourceCorrelationId = (state: RootState) => state.eventing.sourceCorrelationId;

// Reducer
export default eventingSlice.reducer;
