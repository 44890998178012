import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Config files
import filterConfig from '@core/config/filter.json';
// Hooks
import useConfig from '@core/hooks/useConfig';
// Reducers selectors/actions
import { setFilters } from '@core/reducers/configSlice';
import { selectAllInputs } from '@core/reducers/inputsSlice';

/*
  Dynamic Qualifiers
    Loops through each of the filters that useConfig returns.
    It checks to see if curly brackets are found in the string.
    If found, it will replace that with the value of the inputs[key].
    If no brackets are found, we return the value as stated in the config.
*/

const processFilters = (results, inputs) =>
  results
    .map((result: string) => {
      if (result.includes('{')) {
        const key = result?.split(':')[0];
        const newFilter = !inputs[key].value?.length ? null : result.replace(/{.*}/, `"${inputs[key].value}"`);
        return newFilter;
      }
      return result;
    })
    .filter((result) => result);

/**
 * useFilterConfig handles filter config files for the voyager app
 * values will be stored in state for use in other components
 * @returns
 */
const useFilterConfig = () => {
  const dispatch = useDispatch();

  const inputs = useSelector(selectAllInputs);

  const { isCompleted, results } = useConfig(filterConfig);

  const [configFilters, setConfigFilters] = useState(results);

  // Once our config is completed, set the filters in state
  useEffect(() => {
    if (!isCompleted) return;

    const processedFilters = processFilters(results, inputs);
    // Add filters to local state
    setConfigFilters(processedFilters);

    // Add filters to global state
    dispatch(setFilters(processedFilters));
  }, [isCompleted, inputs]);

  return { isCompleted, configFilters };
};

export default useFilterConfig;
