export const applicationCtaBtn = {
  webElement: {
    elementType: 'linkout-cta',
    text: 'Start your application',
    location: 'drawer',
    htmlId: 'voyager-application-cta',
    name: 'application-cta',
  },
};
export const fullScreenApplicationCtaBtn = {
  webElement: {
    elementType: 'linkout-cta',
    text: 'Start your application',
    location: 'full-screen',
    htmlId: 'voyager-application-cta',
    name: 'application-cta',
  },
};

export const continueBrowsingBtn = {
  webElement: {
    elementType: 'exit-element',
    text: 'Continue Browsing Schools',
    location: 'drawer',
    htmlId: 'thank-you-button',
    name: 'thank-you-confirmation',
  },
};

export const slideOutClose = {
  webElement: {
    elementType: 'exit-element',
    location: 'drawer',
    name: 'thank-you-confirmation',
    htmlId: 'thank-you-x',
  },
};

export const slideOutDismiss = {
  webElement: {
    text: '',
    htmlId: 'outside-modal',
  },
};
