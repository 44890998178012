import { createSlice } from '@reduxjs/toolkit';

import { MonarchRequest } from '@core/hooks/monarch/monarch';

export const monarchSlice = createSlice({
  name: 'monarch',
  initialState: {},
  reducers: {
    setTestResults: (_state, action) => ({ ...action.payload }),
  },
});

// Action
export const { setTestResults } = monarchSlice.actions;

// Getters
export const selectTestResultById = (state: any, id: MonarchRequest['id']) => state.monarch[id];

export const selectAllTestResults = (state: any): Record<MonarchRequest['id'], any> => state.monarch;
// Reducer
export default monarchSlice.reducer;
