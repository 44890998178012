import { type FC } from 'react';
import { connectCurrentRefinements } from 'react-instantsearch-dom';

import styles from './ClearAllButton.module.css';

type Props = {
  items: any;
  refine: any;
  label?: string;
  type?: string;
};

const Button: FC<Props> = ({ items, refine, label, type }) => (
  <button
    type="button"
    className={type === 'button' ? styles.button : styles.text}
    onClick={() => {
      refine(items);
    }}
  >
    {label || 'Clear all'}
  </button>
);

const ClearAllButton = connectCurrentRefinements(Button);

export { ClearAllButton };
