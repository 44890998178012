/* eslint-disable no-console */
import { ENVIRONMENTS } from '@core/constants';
import type { CohesionEvents } from '@core/ts/cohesion';
import type { Track } from '@core/ts/track';

import { newRelicCustomAttribute } from './newRelic';

export const cohesionEventMap: { [key: string]: CohesionEvents } = {
  ProductClicked: 'redventures.ecommerce.v1.ProductClicked',
  ProductLoaded: 'redventures.ecommerce.v1.ProductLoaded',
  ProductViewed: 'redventures.ecommerce.v1.ProductViewed',
  FormStarted: 'redventures.usertracking.v3.FormStarted',
  FormViewed: 'redventures.usertracking.v3.FormViewed',
  FormContinued: 'redventures.usertracking.v3.FormContinued',
  FormSubmitted: 'redventures.usertracking.v3.FormSubmitted',
  FormErrored: 'redventures.usertracking.v3.FormErrored',
  PageViewed: 'redventures.usertracking.v2.PageViewed',
  FieldSelected: 'redventures.usertracking.v3.FieldSelected',
  FieldInputted: 'redventures.usertracking.v3.FieldInputted',
  FieldViewed: 'core.fieldViewed.v1',
  ElementClicked: 'redventures.usertracking.v3.ElementClicked',
  ElementViewed: 'redventures.usertracking.v3.ElementViewed',
  Identify: 'core.Identify.v1',
};

let hasWarned = false;
const warnOnce = (...args) => {
  if (hasWarned) return;
  hasWarned = true;
  console.warn(...args);
};

const eventsQueue = [];
let queuePid = null;

function enqueue(eventData) {
  eventsQueue.push(eventData);

  if (!queuePid) {
    queuePid = setInterval(() => {
      if ('tagular' in window) {
        clearInterval(queuePid);
        eventsQueue.forEach(track);
      }
    }, 250);
  }
}

/**
 * Main tracking function that fire a tagular beam event if
 * cohesion tagular is on the page.
 */

let hasFiredCustomAttribute = false;

export const track = ({ eventName, properties }: Track): void => {
  if (!window.__Cohesion && !hasFiredCustomAttribute) {
    newRelicCustomAttribute('cohesionNotFound', true);
    hasFiredCustomAttribute = true;
  }
  const event = cohesionEventMap[eventName];
  if (!event) console.error(`${eventName} is not a supported event`);

  const clientTrackingPayload = {
    '@type': event,
    ...properties,
  };

  if (import.meta.env.ENVIRONMENT === ENVIRONMENTS.TEST) {
    // Set up audit trail of events being fired
    const HEwindow = (window as Window).HE || { trackedEvents: [] };
    // Push event to the tracking list
    HEwindow.trackedEvents.push({
      name: event,
      payload: clientTrackingPayload,
    });
    // reset the window object
    window.HE = HEwindow;
  }

  // if tagular is loaded onto the page then fire the beam event
  if ('tagular' in window) {
    try {
      window.tagular('beam', event, clientTrackingPayload);
    } catch (e) {
      console.error(e);
    }
  } else {
    warnOnce('tagular not found');
    enqueue({ eventName, properties });
  }
};
