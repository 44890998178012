import { HiOutlineInformationCircle } from 'react-icons/hi';
import { useSelector } from 'react-redux';

import { AdditionalContentFieldComponent } from '@core/blocks/edu-flow-2/utils/additionalContentFieldTypeDictionary';
import usePageHeadingParser from '@core/blocks/edu-flow/components/Page/hooks/usePageHeadingParser';
import { selectDcsCategories, selectDcsSubjects } from '@core/reducers/dcsSlice';

import styles from './InfoPanel.module.css';

const InfoPanel: AdditionalContentFieldComponent = ({ field }) => {
  const parseDescription = usePageHeadingParser();
  const dcsCategory = useSelector(selectDcsCategories)[0];
  const dcsSubject = useSelector(selectDcsSubjects)?.[0];

  const parsingCategoryText =
    dcsCategory === 'General'
      ? field?.content?.replace('{dcsCategories}', 'any <strong>General</strong> category')
      : field?.content?.replace('{dcsCategories}', '<strong>{dcsCategories}</strong>');
  const parsingCategoryAndSubjectText = !dcsSubject
    ? parsingCategoryText?.replace('{dcsSubjects}', 'any <strong>General</strong> subject')
    : parsingCategoryText?.replace('{dcsSubjects}', '<strong>{dcsSubjects}</strong>');

  const parsedText = parseDescription(parsingCategoryAndSubjectText);

  return (
    <div className={styles.contentCta}>
      <div className={styles.ctaContainer}>
        <div className={styles.ctaHeading}>
          <HiOutlineInformationCircle size="18px" /> <p>{field.heading}</p>
        </div>
        {field.content && (
          <p
            className={styles.ctaDescription}
            dangerouslySetInnerHTML={{
              __html: parsedText,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default InfoPanel;
