/**
 * customPropertyOverride
 *
 * A simple utilty that takes a custom property and a fallback and returns the customproperty if
 * it exists in the publishers document object.
 * Useful for applying custom properties for components.
 *
 * @param {string} classes as many comma separated strings of classes names
 * @returns {string}
 */

const customPropertyOverride = (property: string, fallback: string): string => {
  if (getComputedStyle(document.documentElement).getPropertyValue(property)) {
    return property;
  }
  return fallback;
};

export default customPropertyOverride;
