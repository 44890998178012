import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import useFieldEvents from '@core/hooks/cohesion/useFieldEvents';
import { selectFormCorrelationId, selectStepContext } from '@core/reducers/eventingSlice';
import { selectInputObject, setInput, setInputs } from '@core/reducers/inputsSlice';

import type { TOptionGroupField } from './types';

const ObjectOptionGroup: TOptionGroupField = ({ field, heading, InputComponent, fieldNumber }) => {
  // Action Dispatcher
  const dispatch = useDispatch();

  // Form Correlation Id for events
  const formCorrelationId = useSelector(selectFormCorrelationId);
  // stepContext for for fields events
  const stepContext = useSelector(selectStepContext);

  const { fieldSelected, fieldViewed } = useFieldEvents({
    correlationId: formCorrelationId,
    userInputField: {
      autofilled: false,
      fieldName: field.name,
      fieldLabel: heading,
      fieldType: field.type,
      fieldNumber,
    },
  });

  useEffect(() => {
    fieldViewed();
  }, []);

  // Inputs State properties
  const fieldInputObject = useSelector((state) => selectInputObject(state, 'currentAcademicAward'));

  const isSelected = useCallback((selection, option) => selection?.value?.[field.currAcaAwardKey] === option.value, []);

  const handleSelection = useCallback(
    (option) => {
      fieldSelected({
        userInputField: {
          fieldValue: option.label,
        },
      });
      if (isSelected(fieldInputObject, option)) {
        const newValue = { ...(fieldInputObject?.value ?? {}), [field.currAcaAwardKey]: undefined };
        delete newValue[field.currAcaAwardKey];

        const value = Object.keys(newValue).length ? newValue : undefined;

        dispatch(
          setInputs([
            {
              key: field.name,
              value,
              options: [],
            },
            { key: 'inProgressAcademicAward', value },
          ])
        );
      } else {
        const newValue = { ...(fieldInputObject?.value ?? {}), [field.currAcaAwardKey]: option.value };
        const value = Object.keys(newValue).length ? newValue : undefined;
        dispatch(
          setInputs([
            {
              key: field.name,
              value,
              options: [option],
            },
            { key: 'inProgressAcademicAward', value },
          ])
        );
      }
    },
    [fieldInputObject, field, heading, isSelected, stepContext]
  );

  return (
    <InputComponent
      field={field}
      selection={fieldInputObject}
      handleSelection={handleSelection}
      isSelected={isSelected}
    />
  );
};

export default ObjectOptionGroup;
