import type { PageType, stepContext } from '@core/blocks/edu-flow/components/Page/types';
import getQuestionById from '@core/blocks/edu-flow/utils/getQuestionById';
import { Flags } from '@core/context/FeatureFlagsContext';

// Creates a step context from page if none is defined
const getStepContextFromPage = (page: PageType, flags: Flags): stepContext | null => {
  if (!page) return null;
  // Get the current page's step context if defined
  if (page.eventing?.stepContext) return page.eventing.stepContext;
  // Otherwise, return default context, based on question
  const question = getQuestionById(page.questionId, flags);
  return {
    stepNumber: page.stepNumber,
    stepName: question.stepName,
    stepId: question.stepId,
  };
};

export default getStepContextFromPage;
