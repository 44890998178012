import { v4 as uuid } from 'uuid';

import { getRestrictionLocation } from '@core/services/leadDelivery';
import { newRelicNoticeError, nrErrorMap } from '@core/services/newRelic';
import type { GetApplicationCta } from '@core/ts/mobius';
import getToken from '@core/utils/getToken';

const mobiusBaseUrl = import.meta.env.VITE_MOBIUS_BASE_URL;

/**
 * getDcs
 *
 * Reaches out to dcs api to get dcs mapping
 */
export const getDcs = async (idToken: string): Promise<any | null> => {
  // if idToken is not valid, return
  if (!idToken) {
    console.error(`Provided idToken: ${idToken} is not valid.`);
    return null;
  }

  // construct api url
  const url = `${mobiusBaseUrl}/v1/inventory/degrees/query?idToken=${idToken}`;

  try {
    // reach out to dcs api
    const res = await fetch(url, { method: 'POST', body: JSON.stringify({ criteria: {} }) });
    // If we get a non ok response, let's log it in New Relic

    // format response
    const dcs: any = await res.json();

    if (res.status !== 201) {
      throw new Error(JSON.stringify(dcs));
    }

    // return dcs object
    return dcs;
  } catch (error) {
    newRelicNoticeError(nrErrorMap.MOBIUS_DCS, error);
    return null;
  }
};

export const getBrowserLocation = async (idToken: string) => {
  try {
    const response = await fetch(`${mobiusBaseUrl}/v1/locate?idToken=${idToken}`);
    // If we get a non ok response, let's log it in New Relic
    const body = await response.json();

    if (!response.ok) {
      throw new Error(JSON.stringify(body));
    }

    return body;
  } catch (error) {
    newRelicNoticeError(nrErrorMap.MOBIUS_BROWSER_LOCATION, error);
    return null;
  }
};

export const mobiusRedirect = async ({ idToken, link, productCorrelationId, trackingContextOverride }) => {
  const { webContext } = window._Cohesion;
  const trackingContext = JSON.stringify({
    webContext,
    ...trackingContextOverride,
  });

  const url = new URL(link);
  url.searchParams.append('idToken', idToken);
  url.searchParams.append('correlationId', productCorrelationId);
  url.searchParams.append('helid', uuid());
  url.searchParams.append('trackingContext', trackingContext);
  url.searchParams.append('asjson', 'true');
  url.searchParams.append('strict', 'true');
  url.searchParams.append('monetizationChannel', 'voyager');

  try {
    const response = await fetch(url.href, { mode: 'cors' });

    const responseBody = await response.json();
    // If we get a non ok response, let's log it in New Relic
    if (!response.ok) throw new Error(JSON.stringify(responseBody));
    return responseBody;
  } catch (error) {
    newRelicNoticeError(nrErrorMap.MOBIUS_REDIRECT, error, { correlationId: productCorrelationId });
    return { error };
  }
};

export const mobiusTrack = async (heclid: string, productCorrelationId: string) => {
  const url = `${mobiusBaseUrl}/v1/track?heclid=${heclid}`;
  try {
    const response = await fetch(url, { mode: 'cors' });
    // If we get a non ok response, let's log it in New Relic
    if (!response.ok) {
      throw new Error(JSON.stringify(response));
    }
    return response;
  } catch (error) {
    newRelicNoticeError(nrErrorMap.MOBIUS_TRACK, error, { correlationId: productCorrelationId });
    return { error };
  }
};

export const mobiusGetApplicationCta: GetApplicationCta = async ({ programId, correlationId, heclid }) => {
  const idToken = getToken() as string;
  try {
    const url = new URL(`${mobiusBaseUrl}/v1/inventory/program/applicationcta/${programId}`);
    // Append required search params
    url.searchParams.append('experience', 'voyager');
    url.searchParams.append('idToken', idToken);
    // Append optional search params
    if (correlationId) url.searchParams.append('correlationId', correlationId);
    if (heclid) url.searchParams.append('heclid', heclid);

    const response = await fetch(url.href, { mode: 'cors' });

    if (!response.ok) throw new Error(`Failed to fetch application CTA data for ${programId}`);

    return await response.json();
  } catch (error) {
    newRelicNoticeError(nrErrorMap.MOBIUS_GET_APPLICATION_LINK, error, { programId, correlationId, heclid });
    return { error };
  }
};

export const mobiusGetAlgoliaCredentials = async ({ criteriaOverride, zip }) => {
  const idToken = getToken() as string;

  const body = {
    context: {
      channel: 'web',
      media: 'organic',
      experience: 'voyager',
      presentationUrl: window.location.href,
    },
    criteria: {
      ...criteriaOverride,
    },
  };

  // if valid zipcode adds restrictionLocation obj to criteria
  if (zip) body.criteria.restrictionLocation = await getRestrictionLocation(zip);

  const url = new URL(`${mobiusBaseUrl}/v1/algolia/key`);
  // Append required search params
  url.searchParams.append('idToken', idToken);

  try {
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(body),
    });

    const res = await response.json();

    // If we get a non ok response, let's log it in New Relic
    if (!response.ok) throw new Error(JSON.stringify(res));

    // once fetched, convert to readable JSON and return it
    return res;
  } catch (error) {
    newRelicNoticeError(nrErrorMap.MOBIUS_ALGOLIA_CREDENTIALS, error);
    return { error };
  }
};
