/* eslint-disable no-prototype-builtins */

/**
 * Diffy
 *
 * A diffing tool that compares two sets of json data and returns the difference.
 */
import clone from 'just-clone';

import { ILDSSchema } from '@core/ts/leadDelivery';

import buildLeadObject from './buildLeadObject';
import recursivelyCheckForUndefined from './objects/recursivelyCheckForUndefined';

export default class Diffy {
  // prop types
  baseData: any;
  diffedSchema: ILDSSchema;

  // class constructor
  constructor(data: any) {
    // set initial base data
    this.baseData = data;

    // set diffed schema
    this.diffedSchema = {};
  }

  // setBaseData - set the base data after initialization
  setBaseData(data: any): void {
    this.baseData = data;
  }

  // checkAnyOfValues - checks user inputs against expected values
  checkAnyOfValues(inputsKey: string, anyOf: any[]) {
    // extract all possible values from anyOf array
    const possibleValues = anyOf.reduce((acc, curr) => {
      // if current object has an enum
      // spread those values into the array
      if (curr?.enum) {
        return [...acc, ...curr.enum];
      }

      // if current is type null
      // add a null value to the array
      if (curr.type === 'null') {
        return [...acc, null];
      }

      // otherwise, return the accumulator
      return acc;
    }, []);

    // return true if any expected values are found
    return possibleValues.some((item: string | null) => item === this.baseData[inputsKey]?.value);
  }

  // refineSchema - refines the schema to be returned
  refineSchema(reqs: string[], lead: object, schema = this.diffedSchema) {
    // set new reqs
    schema.required = reqs;

    // handle the refinement
    const handleRefinement = (key: string, value: any) => {
      // refine the requirements of this object
      const refinedReqs = this.refineRequirements(lead[key], value);

      // refine the object agianst the refined requirements
      this.refineSchema(refinedReqs, lead[key], value);
    };

    // based on the new requirements, remove properties that no longer apply
    const properties = schema.properties ?? {};
    Object.entries(properties).forEach(([key, value]: [string, any]) => {
      // check if entry exists in required
      const isReq = schema.required.includes(key);

      // if property is not required, remove it from the schema
      if (!isReq) {
        delete schema.properties[key];
      }

      // check if value has a required prop
      if (isReq && value?.required) {
        // handle the refinement recursively
        handleRefinement(key, value);
      }

      // for fields that have the anyOf property
      if (value.hasOwnProperty('anyOf')) {
        // check if a required value is present in inputs
        const hasProperValue = this.checkAnyOfValues(key, value.anyOf);
        // if a proper value is found, do the refinement
        if (hasProperValue) handleRefinement(key, value);
      }
    });
  }

  // refineRequirements - refines the requirements array to values that have no value
  // in the lead object
  refineRequirements(lead: object, schema = this.diffedSchema): string[] {
    // loop through lead object, and remove reqs from newSchema that
    // have a value
    return (
      schema?.required?.filter((item) => {
        // check for any undefined properties recursively
        if (typeof lead[item] === 'object' && lead[item] !== null) {
          // check if object contains any undefined props
          const hasUndefinedProp = recursivelyCheckForUndefined(lead[item]);

          // if lead item has undefined prop, return it
          if (hasUndefinedProp) return lead[item];
        }

        // if item is undefined return it
        return lead[item] === undefined;
      }) || []
    );
  }

  // diffLdsSchema - an opinionated method that compares baseData against required fields from lds schema
  diffLdsSchema(schema: any, leadSource?: string): any {
    // build lead object based on schema
    const lead = buildLeadObject(this.baseData, schema, leadSource);

    // set up new schema
    this.diffedSchema = clone(schema);

    // refine the top level requirements
    const newReqs = this.refineRequirements(lead);

    // refine the diffed schema
    this.refineSchema(newReqs, lead);

    // return the diffed schema
    return this.diffedSchema?.required?.length ? this.diffedSchema : undefined;
  }
}
