/**
 * findNextDate
 *
 * A function that takes in an array of date objects and
 * returns the object with the earliest register date from today.
 *
 * @param {string} dates array of date objects
 * @returns {startDate: string, showDateOn: string, registerDate: string}
 */

export default (dates) => {
  const today = new Date().getTime();
  const nextDate = dates.find((date) => {
    const registerDate = new Date(date.registerDate).getTime();
    return registerDate > today;
  });
  if (nextDate) {
    Object.keys(nextDate).forEach((key) => {
      const formattedDate = new Date(nextDate[key]);
      formattedDate.setDate(formattedDate.getDate() + 1);
      nextDate[key] = new Date(formattedDate).toLocaleString('en-US', {
        month: 'long',
        day: 'numeric',
      });
    });
  }
  return nextDate;
};
