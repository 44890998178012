export const closeBtnEViewed = {
  webElement: {
    location: 'modal',
    name: 'dupe-info-drawer-close',
    elementType: 'BUTTON',
    text: 'close',
  },
};

export const closeBtnEClicked = {
  webElement: {
    location: 'modal',
    name: 'dupe-info-drawer-close',
    elementType: 'BUTTON',
    text: 'close',
  },
  actionOutcome: 'CLOSE',
};

export const nextStepsEViewed = {
  webElement: {
    location: 'modal',
    name: 'dupes-exit-experience',
    elementType: 'LINK',
    text: 'View next steps',
  },
};

export const nextStepsEClicked = {
  webElement: {
    location: 'modal',
    name: 'dupes-exit-experience',
    elementType: 'LINK',
    text: 'View next steps',
  },
  actionOutcome: 'OPEN',
};

export const keepBrowsingEViewed = {
  webElement: {
    location: 'modal',
    name: 'dupes-keep-browsing',
    elementType: 'BUTTON',
    text: 'Keep Browsing Schools',
  },
};

export const keepBrowsingEClicked = {
  webElement: {
    location: 'modal',
    name: 'dupes-keep-browsing',
    elementType: 'BUTTON',
    text: 'Keep Browsing Schools',
  },
  actionOutcome: 'CLOSE',
};

export const RevampEClicked = {
  webElement: {
    location: 'modal',
    name: 'dupes-application-cta',
    elementType: 'BUTTON',
    text: 'Start Your Application',
  },
  actionOutcome: 'CLOSE',
};
