/* eslint-disable react/no-danger */
import { useState, useEffect, useMemo, Dispatch, SetStateAction, FC } from 'react';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';
import { useSelector } from 'react-redux';

import { CompactCard } from '@core/blocks/edu-match/components';
import Dots from '@core/blocks/edu-match/components/Loading/Dots';
import { PiiFormSection } from '@core/blocks/edu-match/components/PiiFormSection';
import { selectLeadDelivery } from '@core/reducers/matchesSlice';
import { getSchoolConfig } from '@core/services/schoolConfig';
import cn from '@core/utils/classNames';

import TcpaSection from '../../TcpaSection';
import styles from './PiiConfirmation.module.css';

type Props = {
  setRecommenderStep: Dispatch<SetStateAction<string>>;
  piiLeadErrors: { field: string }[];
  editMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
};

const PiiConfirmation: FC<Props> = ({ setRecommenderStep, piiLeadErrors, editMode, setEditMode }) => {
  const { currentPrograms } = useSelector(selectLeadDelivery);

  // local state
  const [tcpa, setTcpa] = useState([]);
  const [loadingTcpa, setLoadingTcpa] = useState(true);

  // Build the TCPA data here to prevent the popup from jumping around after TCPA loads in
  const uniqueSchools = useMemo(
    () =>
      currentPrograms.reduce(
        (accumulator, current) =>
          // will only add the schoolId into the accumulator if it's not there already
          !accumulator.find((school) => school.schoolId === current.school.id)
            ? [...accumulator, { schoolId: current.school.id, hit: current }]
            : accumulator,
        [] // accumulator initial value
      ),
    [currentPrograms]
  );

  useEffect(() => {
    if (currentPrograms?.length === 0) {
      setRecommenderStep('PROGRAM_SELECT');
    }
  }, [currentPrograms?.length]);

  // useEffect for when uniqueSchoolIds is ready to loop through and call useSchoolConfig hook and store the data in tcpa state
  useEffect(() => {
    if (uniqueSchools.length > 0) {
      // build an array by looping through the uniqueSchoolIds and calling the async getSchoolConfig function
      const tcpaArray = uniqueSchools.map(async ({ schoolId, hit }) => {
        const schoolConfig = await getSchoolConfig(schoolId);
        return { ...schoolConfig, hit };
      });
      // once the array is built, set the tcpa state
      Promise.all(tcpaArray).then((data) => {
        setTcpa(data);
        setLoadingTcpa(false);
      });
    }
  }, [uniqueSchools]);

  const BackToTopMatches = (
    <button
      onClick={() => {
        setRecommenderStep('PROGRAM_SELECT');
      }}
      className={styles.backTopMatchesBtn}
      type="button"
    >
      <HiOutlineArrowNarrowLeft size={32} />
      Back to Top Matches
    </button>
  );

  if (loadingTcpa)
    return (
      <section className={styles.loadingContainer}>
        <h2>Loading...</h2>
        <Dots />
      </section>
    );

  return (
    <section className={styles.piiConfirmation}>
      <div className={styles.content}>
        <header>
          <h4>Before you submit, let’s make sure your information is correct.</h4>
        </header>

        <div className={styles.piiSection}>
          <div className={styles.panel}>
            <PiiFormSection
              editMode={editMode}
              piiLeadErrors={piiLeadErrors}
              setEditMode={setEditMode}
              location="recommender"
            />
          </div>

          <button
            className={cn(styles.submitInfoBtn, editMode && styles.disabledSubmitInfoBtn)}
            disabled={editMode}
            id="reward_click_popup"
            data-testid="edumatch-leaddelivery-submit"
            onClick={() => setRecommenderStep('SUBMISSIONS')}
            type="button"
          >
            Submit Info
          </button>
          {BackToTopMatches}
        </div>

        <div className={styles.selectedPrograms}>
          {currentPrograms?.map((hit) => (
            <CompactCard key={hit.program.id} hit={hit} />
          ))}
        </div>

        <div className={styles.tcpa}>
          <TcpaSection tcpa={tcpa} />
        </div>
      </div>

      <div className={styles.mobileCtas}>
        <button
          data-testid="recommender-popup-submission-request-info-btn"
          className={cn(styles.submitInfoBtn, editMode && styles.disabledSubmitInfoBtn)}
          disabled={editMode}
          id="reward_click_popup"
          onClick={() => setRecommenderStep('SUBMISSIONS')}
          type="button"
        >
          Submit Info
        </button>
        {BackToTopMatches}
      </div>
    </section>
  );
};

export default PiiConfirmation;
