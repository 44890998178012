import { useState } from 'react';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { connectRefinementList } from 'react-instantsearch-core';

import styles from './CustomRefinementComponent.module.css';

const CustomRefinementList = ({ items = [], limit, showMoreLimit, refine, refinementTitle }) => {
  const [showMore, setShowMore] = useState(false);

  const handleInputChange = (item) => {
    if (item.isRefined) {
      refine(!item.value);
    } else {
      refine(item.value);
    }
  };

  const handleShowMore = () => {
    if (limit === 0) setShowMore(!showMore);
  };

  return (
    <section>
      <button type="button" className={styles.refinementHeading} onClick={handleShowMore}>
        <h4 className={styles.label}>{refinementTitle}</h4>
        {showMore ? <FiChevronUp size={20} /> : <FiChevronDown size={20} />}
      </button>
      <ul className={styles.refinementList}>
        {items.slice(0, showMore ? showMoreLimit : limit)?.map((item) => (
          <li key={item.label}>
            <label htmlFor={`filter-${item.label}`}>
              <input
                type="checkbox"
                className={styles.refinementListCheckbox}
                id={`filter-${item.label}`}
                checked={item.isRefined}
                onChange={() => handleInputChange(item)}
                aria-label={item.label}
              />
              <span className="ais-RefinementList-labelText">{item.label}</span>
            </label>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default connectRefinementList(CustomRefinementList);
