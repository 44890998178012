/* eslint-disable no-nested-ternary */
import { type FC } from 'react';
import { HiChevronUp, HiChevronDown } from 'react-icons/hi';
import { connectCurrentRefinements } from 'react-instantsearch-dom';

// Utils
import cn from '@core/utils/classNames';

import styles from './CollapsibleTrigger.module.css';
import type { CollapsibleTriggerProps } from './types';

const Trigger: FC<CollapsibleTriggerProps> = ({ items, filter, triggerLabel, handleClick, isOpen }) => {
  // Try to find the filter that is passed to the component
  // in the refinement state
  // This is used to display the selected item(s) in the filter
  const currentFilter = items.find((item) => item.attribute === filter);

  return (
    <button type="button" className={cn(styles.collapsibleTrigger, isOpen && styles.active)} onClick={handleClick}>
      {/* 
          button text
          if one value is selected, show its name in the button
          if more than one is selected show (numSelected)
          if nothing selected, show the label being passed via `initialText` prop
      */}
      {currentFilter?.currentRefinement
        ? currentFilter.currentRefinement.length === 1
          ? `${triggerLabel}: ${currentFilter.currentRefinement[0]}`
          : `${triggerLabel} (${currentFilter.currentRefinement.length})`
        : triggerLabel}

      {/* chevron icon */}
      {isOpen ? <HiChevronUp className={styles.icon} /> : <HiChevronDown className={styles.icon} />}
    </button>
  );
};

// connect to the currentRefinements connector
const CollapsibleTrigger = connectCurrentRefinements(Trigger);

export { CollapsibleTrigger };
