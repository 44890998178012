import { FC } from 'react';

import { FieldComponentProps } from '../types';
import withFieldSelected from '../withFieldSelected';
import EnumRadioField from './EnumRadioField';
import GraduationYearField from './GraduationYearField';
import RadioField from './RadioField';
import SelectField from './SelectField';

const fields = (schoolSlug: string, formCorrelationId: string, eventingOverrides?: Record<string, unknown>) => ({
  enumRadioField: withFieldSelected({ Component: EnumRadioField, schoolSlug, formCorrelationId, eventingOverrides }),
  radioField: withFieldSelected({ Component: RadioField, schoolSlug, formCorrelationId, eventingOverrides }),
  selectField: withFieldSelected({ Component: SelectField, schoolSlug, formCorrelationId, eventingOverrides }),
  graduationYearField: withFieldSelected({
    Component: GraduationYearField as unknown as FC<FieldComponentProps>,
    schoolSlug,
    formCorrelationId,
    eventingOverrides,
  }),
});

export default fields;
