import React, { ErrorInfo, ReactNode } from 'react';

import { newRelicNoticeError, nrErrorMap } from '@core/services/newRelic';

// Error boundary component can be used to wrap functional components
type Props = {
  children: ReactNode;
  fallback: ReactNode;
};

type State = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    newRelicNoticeError(nrErrorMap.ERROR_BOUNDARY, error, { componentStack: info?.componentStack });
    console.log(error);
  }

  render() {
    const { children, fallback } = this.props;
    const { hasError } = this.state;
    return hasError ? fallback : children;
  }
}

export default ErrorBoundary;
