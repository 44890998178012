import { type FC } from 'react';

import type { FieldComponentProps } from '../types';
import styles from './CalloutField.module.css';

const CalloutField: FC<FieldComponentProps> = ({ field: { heading, content }, preampValue }) => (
  <div data-testid="callout-field" className={styles.callout}>
    {heading && <b dangerouslySetInnerHTML={{ __html: heading }} />}

    {/* if value is added from preamp */}
    {preampValue && <p dangerouslySetInnerHTML={{ __html: preampValue }} />}

    {/* if there is content and no value from preamp */}
    {!preampValue && content && <p dangerouslySetInnerHTML={{ __html: content }} />}
  </div>
);

export default CalloutField;
