import { createSlice } from '@reduxjs/toolkit';

import type { ContactContext } from '@core/context/types';

export const contactSlice = createSlice({
  name: 'contact',
  initialState: {}, // set our initial state with default values
  reducers: {
    // Update value for list id
    setContactId: (state: ContactContext, action) => {
      state.contactId = action.payload;
    },
  },
});

// Actions
export const { setContactId } = contactSlice.actions;
// Selectors

export const selectContactId = (state) => state.contact.contactId;

// Reducer
export default contactSlice.reducer;
